import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import ModalWithTrigger from 'components/ModalWithTrigger';
import Slideshow from 'components/SlidesCard/components/Slideshow';
import episodeShape from 'shapes/episodeShape';
import Icon from 'components/Icon';
import { dataLayerPush } from 'utils/dataLayer';
import Tooltip from '@material-ui/core/Tooltip';

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: fit-content;

  &:hover {
    svg {
      fill: ${theme('colors.ttRed')};
    }

    p {
      color: ${theme('colors.ttRed')};
    }
  }


  ${ifProp({noHover: false}, css`
    &:hover {
      svg {
        fill: ${theme('colors.ttRed')};
      }
    }
  `)};

  button {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 1rem;
    width: 2.75rem;
    height: 2.75rem;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background-color: ${theme('colors.iconHoverBackgroundGray')};
    }
  }
`;

ViewSlidesActionIcon.propTypes = {
  episode: episodeShape,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  optionalText: PropTypes.string,
  page: PropTypes.string,
  showToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  label: PropTypes.string,
  expand: PropTypes.bool,
  noHover: PropTypes.bool
};

function ViewSlidesActionIcon({
  episode,
  iconSize,
  iconColor,
  optionalText,
  page,
  showToolTip = false,
  toolTipText = 'View Slides',
  label,
  expand,
  noHover
}) {
  const [open, setOpen] = useState(false);
  const [displayToolTip, setDisplayToolTip] = useState(false);
  const theme = useTheme();
  const { pdfSlides } = episode;

  if(!pdfSlides || !pdfSlides.length) {
    return null;
  }

  const handleOpen = () => {
    setDisplayToolTip(false);
    setOpen(true);
    dataLayerPush('View slides modal opened');
  };

  const handleClose = () => {
    setOpen(false);
    dataLayerPush('View slides modal closed');
  };

  return (
    <Tooltip title={showToolTip ? toolTipText : ''} open={displayToolTip}>
      <IconWrapper
        onMouseEnter={() => setDisplayToolTip(true)}
        onMouseLeave={() => setDisplayToolTip(false)}
        noHover={noHover}
      >
        <ModalWithTrigger
          ariaLabel='View Slides'
          ariaDescribe='Slideshow related to the episode'
          contentType='slides'
          handleClose={handleClose}
          handleOpen={handleOpen}
          height={776}
          hideClose={true}
          width={1000}
          open={open}
          padding='none'
          triggerText={optionalText}
          triggerType='link'
          icon={
            <Icon
              color={iconColor || theme.palette.common.black}
              // ^ This nullish coallescing on purpse. we cant assign this default value in the prop destructure because that happens asynchronously with the theme import
              icon='slidesOutline'
              size={iconSize ? iconSize : 1}
            />}
          iconPosition='prefix'
          label={label}
          expand={expand}
          className='modal-trigger'
        >
          <Slideshow episode={episode} slideDecks={pdfSlides} page={page} onClose={handleClose} />
        </ModalWithTrigger>
      </IconWrapper>
    </Tooltip>
  );
}

export default React.memo(ViewSlidesActionIcon);
