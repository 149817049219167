import React, { useEffect } from 'react';
import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { useDashboardContext } from 'context/DashboardContext';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import IconList from 'components/AccordionGroup/IconList';
import dailyReplaysQuery from '../../../../graphql/queries/getDailyReplays.graphql';
import dayjs from 'dayjs';

function DailyReplaysSidebar() {
  const { state: dashboardState, setCurrentDailyReplay } = useDashboardContext();
  const { currentDailyReplay } = dashboardState;

  const { data, loading, error } = useQuery(dailyReplaysQuery, {
    fetchPolicy: 'no-cache',
  });
  const dailyReplays = data?.dailyReplays?.items ?? [];

  const iconListValues = dailyReplays?.map(dailyReplay => ({
      title: dayjs(dailyReplay.date).format('ddd, MMM D, YYYY'),
      icon: <PlaylistPlayIcon />,
      value: dailyReplay,
      active: currentDailyReplay && currentDailyReplay.uid === dailyReplay.uid,
      sortable: false,
    }));

  // Default to the first daily replay if none is selected.
  useEffect(() => {
    if (!currentDailyReplay && dailyReplays?.length > 0) {
      setCurrentDailyReplay(dailyReplays[0]);
    }
  }, [currentDailyReplay, setCurrentDailyReplay, dailyReplays]);

  if (error) {
    return <Alert severity='error'>Failed to load the Daily Replays.</Alert>
  }

  return (
    <IconList
      values={iconListValues}
      onSelect={setCurrentDailyReplay}
      loading={loading}
      mobile={true}
    />
  );
}

export default React.memo(DailyReplaysSidebar);
