import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTourContext } from 'context/TourContext';
import { REPLAY_TOUR_STEPS } from 'constants/replayTourSteps';

PrevButton.propTypes = {};

function PrevButton() {
  const { state: { currentStep } } = useTourContext();
  const { stepOne } = REPLAY_TOUR_STEPS;

  return (
    <>
     {(currentStep === stepOne || currentStep === null) ? (
       <></>
     ) : (
        <ArrowBackIcon />
     )}
    </>
  );
}

export default PrevButton;
