import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'components/Snackbar';
import useTheme from '@material-ui/core/styles/useTheme';
import episodeShape from 'shapes/episodeShape';
import TTSnackbarMessage from '../TTSnackbar/Message';

UndoSnackBar.propTypes = {
  episode: episodeShape.isRequired,
  actionText: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onAllowTimeOut: PropTypes.func,
  onUndo: PropTypes.func,
  timeOut: PropTypes.number,
};

function UndoSnackBar({
  episode,
  actionText = 'Undo',
  message = 'You removed an episode.',
  onAllowTimeOut,
  onUndo,
  timeOut = 6000
}) {
  const theme = useTheme();
  const timerRef = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(true);
  const undoMessage = typeof message === 'string' ? message : message(episode);

  useEffect(() => {
    handleTimeOut();
    //if they leave the page without pressing undo remove the timer
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [episode])

  function handleTimeOut() {
    timerRef.current = setTimeout(() => {
      onAllowTimeOut();
      setShowSnackbar(false);
    }, timeOut)
  }

  function handleClickUndo() {
    setShowSnackbar(false);
    onUndo();
    clearTimeout(timerRef.current)
  }

  function handleClose(event, reason){
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      buttonText={actionText}
      icon='undo'
      iconColor={theme.palette.common.white}
      message={<TTSnackbarMessage message={undoMessage} />}
      onClose={handleClose}
      open={showSnackbar}
      clickAction={handleClickUndo}
      bgColor='red'
      autoHideDuration={timeOut}
      iconColor='white'
      hoverIconColor='white'
    />
  );
}

export default React.memo(UndoSnackBar);
