import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import ReportProblem from '@material-ui/icons/ReportProblem';
import BaseContainer from '@material-ui/core/Container';
import InfoIcon from 'assets/icons/info-icon.svg';
import CtaButtons from 'components/CtaButtons';
import { calcPercent } from 'utils/functions';
import RawContentAlert from './RawContentAlert';
import ctaButtonShape from 'shapes/ctaButtonShape';
import { useSiteSettings } from 'context/SiteSettingsContext';

const XLARGE = 85;
const LARGE = 77;

const AlertWrapper = styled.div`
  color: ${theme('colors.white')};
  display: flex;
  left: 0;
  height: 3.5rem;
  top: 0;
  width: 100%;
  z-index: 1106;

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 8.5rem;
  }

  ${ifProp(
    '$sitewide',
    css`
      position: fixed;
    `
  )}

  ${switchProp('$alertColor', {
    lowlevel: css`
      background-color: ${theme('colors.mutedGray')};
    `,
    highlevel: css`
      background-color: ${theme('colors.ttRed')};
    `
  })}
`;

const Container = styled(BaseContainer)`
  display: flex;
  justify-content: space-between;
  max-width: 1644px;

  ${ifProp(
    '$tempAlert',
    css`
      padding: 0 1.25rem;
    `
  )}
`;

const MainSection = styled.div`
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 1rem 0.875rem 1rem 0;
  text-transform: uppercase;

  .MuiSvgIcon-root {
    width: 0.875em;

    ${(props) => props.theme.breakpoints.up('sm')} {
      margin-right: 0.5rem;
    }
  }
`;

const StyledIcon = styled.img`
  height: 2rem;

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 2rem;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'Heebo', 'Heebo-fallback-bold', 'sans-serif';
  font-size: 0.875rem;
  padding-top: 1rem;
  width: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    align-items: center;
    padding-top: 0;
  }
`;

const CloseContainer = styled.div`
  align-items: center;
  cursor: pointer;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  height: 2rem;
  width: 2.125rem;

  &:hover {
    background-color: ${theme('colors.ttMuted')};
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-left: 2rem;
  }
`;

const ButtonContainer = styled.div`
  position: relative;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-left: 1rem;
  }

  .MuiButton-containedPrimary:hover {
    background-color: transparent;
  }

  button, a {
    color: ${theme('colors.white')};
    margin: 0;
    background-color: inherit;

    &:hover {
      color: ${theme('colors.white')};
      background-color: inherit;
    }
  }
`;

Alert.propTypes = {
  siteAlerts: PropTypes.shape({
    isActive: PropTypes.bool,
    alertLevel: PropTypes.string,
    alertText: PropTypes.string,
    alertButton: PropTypes.shape({
      buttonCta: PropTypes.arrayOf(ctaButtonShape)
    })
  }),
  sitewide: PropTypes.bool,
  rawContent: PropTypes.shape({
    alertText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
  }),
};

function Alert({
  siteAlerts,
  sitewide,
  rawContent,
}) {
  const { displayAlert, setDisplayAlert } = useSiteSettings();

  const theme = useTheme();
  const [seeMore, setSeeMore] = useState(false);
  const [percentFull, setPercentFull] = useState();

  const button = get(siteAlerts?.alertButton, 'buttonCta');
  const isXlDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const checkPercent = isXlDesktop ? XLARGE : LARGE;
  const textSectionRef = useRef(null);

  useEffect(() => {
    let parent;
    let child;

    // Checking the width of the alert message section on component mount
    // and determining what percentage of the parent div the copy itself
    // is filling
    if (textSectionRef.current) {
      parent = textSectionRef.current.offsetWidth;
      child = textSectionRef.current.firstChild.offsetWidth;
      setPercentFull(calcPercent(child, parent));
    }

    // Re-checking the width of the alert message section if the user resizes
    // the screen and determining what percentage of the parent div the
    // copy itself is filling
    function handleResize() {
      if (textSectionRef.current) {
        parent = textSectionRef.current.offsetWidth;
        child = textSectionRef.current.firstChild.offsetWidth;
        setPercentFull(calcPercent(child, parent));
      }
    }

    window.addEventListener('resize', handleResize);
  }, [textSectionRef]);

  return (
    <>
      {displayAlert && (
        <AlertWrapper $sitewide={sitewide} $alertColor={siteAlerts.alertLevel}>
          <Container>
            <MainSection>
              {siteAlerts && (
                <>
                  <IconContainer>
                    {siteAlerts.alertLevel === 'lowlevel' && (
                      <StyledIcon src={InfoIcon} alt='icon' />
                    )}
                    {siteAlerts.alertLevel === 'highlevel' && (
                      <ReportProblem />
                    )}
                  </IconContainer>
                  {siteAlerts?.alertText && (
                    <TextSection ref={textSectionRef}>
                      {siteAlerts.alertText}
                      {siteAlerts?.alertButton && (
                        <ButtonContainer>
                          <CtaButtons items={button} icon={button?.[0]?.buttonIcon} alertIcon />
                        </ButtonContainer>
                      )}
                    </TextSection>
                  )}
                </>
              )}
              {rawContent && (
                <TextSection ref={textSectionRef}>
                  <RawContentAlert
                    rawContent={rawContent}
                    isMobile={isMobile}
                    seeMore={seeMore}
                  />
                </TextSection>
              )}
            </MainSection>
            {siteAlerts && (
              <>
                <CloseContainer onClick={() => setDisplayAlert(false)}>
                  <CloseIcon />
                </CloseContainer>
              </>
            )}
          </Container>
        </AlertWrapper>
      )}
    </>
  );
}

export default React.memo(Alert);
