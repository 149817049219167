import React from 'react';
import PropTypes from 'prop-types';
import menuItemShape from 'shapes/menuItemShape';
import Button from 'components/Button';
import { dataLayerPush } from 'utils/dataLayer';

UserLogin.propTypes = {
  menuItem: menuItemShape.isRequired,
  component: PropTypes.elementType,
};

function UserLogin({ menuItem, component }) {
  const linkText = menuItem.linkText || 'Log In';

  const CustomComponent = component || Button;

  return (
    <CustomComponent href='/api/login'>{linkText}</CustomComponent>
  );
}

export default React.memo(UserLogin);
