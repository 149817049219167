import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import RichTextField from 'components/RichTextField';
import StackHeader from 'components/StackHeader';
import TextAccordion from './TextAccordion';
import basicTextBlockShape from 'shapes/components/basicTextBlockShape';

const TextBlockContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1380px;
  }
`;

const GridItem = styled(Grid)`
  width: 100%;
`;

const propTypes = {
  data: basicTextBlockShape.isRequired,
  className: PropTypes.string,
};

function BasicTextBlock({ data, className, ...props }) {
  const { page } = props;
  const {
    header,
    bodyCopy,
    accordion
  } = data;

  return (
    <TextBlockContainer className={className} maxWidth='xl'>
      <Grid container>
        <GridItem item xs={12} md='auto'>
          {accordion &&
            <TextAccordion bodyCopy={bodyCopy} header={header} page={page} />
          }
          {!accordion &&
            <Box>
              {header && <StackHeader headerType='H3'>{header}</StackHeader>}
              {bodyCopy && <RichTextField richText={bodyCopy} />}
            </Box>
          }
        </GridItem>
      </Grid>
    </TextBlockContainer>
  );
}

BasicTextBlock.propTypes = propTypes;

export default BasicTextBlock;
