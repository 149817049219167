import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';
import Button from 'components/Button';

const StyledButton = styled(Typography).attrs({variant:'h4', component:'button'})`
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-left: .3rem;
`;

const StyledPlaylistButton = styled(Button)`
  width: 100%;
`

const StyledIcon = styled(Icon)`
  font-size: 1.5rem;
  padding-right: .5rem;
  padding-bottom: .2rem;

  ${({ invert }) => invert && `
    fill: white;
  `}
`;

const StyledTextField = styled(TextField).attrs({
  'data-testId': 'StyledTextField'
})`
  border: 1px solid ${theme('colors.black')};
  width: 100%;
  height: 1.8rem;
  
  .MuiInputBase-formControl {
    height: 1.8rem;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
    border: none;
  }

  .MuiOutlinedInput-input {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: .3rem;
  }

  .MuiInputBase-input {
    font-size: .5rem;
    height: 1.8rem;
    font-size: 0.875rem;
  }

  .MuiOutlinedInput-notchedOutline{
    border: none;
  }
`;

const PaddedDiv = styled.div`
  padding-bottom: .5rem;

  ${({ header }) => header && `
    text-transform: uppercase;
    font-weight: 600;
    font-size: .65rem;
    padding-bottom: .7rem;
  `}

  ${({ footer }) => footer && `
    padding-top: 1.6rem;
  `}
`;

ModalBody.propTypes = {
  createPlaylistFormOpen: PropTypes.bool,
  handleCreatePlaylistSubmit: PropTypes.func,
  newPlaylistName: PropTypes.string,
  playlistFormErrors: PropTypes.bool,
  setNewPlaylistName: PropTypes.func,
  setCreatePlaylistFormOpen: PropTypes.func,
};

function ModalBody({
  createPlaylistFormOpen,
  handleCreatePlaylistSubmit,
  newPlaylistName,
  playlistFormErrors,
  setNewPlaylistName,
  setCreatePlaylistFormOpen,
}) {
  return (
    <div>
      {createPlaylistFormOpen ? (
        <form noValidate autoComplete='off' onSubmit={handleCreatePlaylistSubmit}>
          <PaddedDiv header>
            {'Name'}
          </PaddedDiv>
          <PaddedDiv>
            <StyledTextField
              variant='outlined'
              placeholder='Give your playlist a name...'
              value={newPlaylistName}
              onChange={(event) => setNewPlaylistName(event.target.value)}
              error={!newPlaylistName && playlistFormErrors}
              required
              autoFocus={true}
              data-testid='newPlaylistNameInput'
            />
          </PaddedDiv>
          <PaddedDiv footer>
            <StyledPlaylistButton color='secondary' type='submit' variant='contained' data-testid='submitNewPlaylist'>
              <StyledIcon icon='plus' invert/>
              Create New Playlist
            </StyledPlaylistButton>
          </PaddedDiv>
        </form>
      ) : (
        <StyledButton onClick={() => setCreatePlaylistFormOpen(true)} data-testid='showCreateNewPlaylistToggle'>
          <StyledIcon icon='plus' /> <span>Create New Playlist</span>
        </StyledButton>
      )}
    </div>
  );
}

export default ModalBody;
