import React, { useEffect, useState } from 'react';
import config from 'config';

export function useWillShowFooterStickyAd() {
  const [showAd, setShowAd] = useState(true);

  useEffect(() => {
    if (typeof window !== undefined) {
      const showStickyFooterAd = sessionStorage.getItem(config.localStorageKeys.showStickyFooterAd);

      if (showStickyFooterAd === 'false') {
        setShowAd(false)
      }
    }
  }, []);

  return showAd;
}
