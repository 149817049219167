import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import useElementSize from 'hooks/useElementSize';
import DateFormatter from 'components/DateFormatter';
import RichTextField from 'components/RichTextField';
import EpisodeIcons from '../components/EpisodeIcons';
import episodeShape from 'shapes/episodeShape';
import { useAuth } from 'context/AuthContext';
import { DetailsLink } from '../DetailsLink';
import { dataLayerPush } from 'utils/dataLayer';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VerticalCardWrapper from './VerticalCardWrapper';

const EpisodeWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  color: ${theme('palette.common.black')};
  text-decoration: none;

  ${VerticalCardWrapper}:hover &{
    transform: scale(1);
    padding-top: 1rem;
  }
`;

const EpisodeWrapperExpand = styled(EpisodeWrapper)`
  transition: transform 250ms, padding 250ms;

  ${VerticalCardWrapper}:hover &{
    transform: scale(0.85);
    padding-top: 0.25rem;
  }
`

const DetailsInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${ifProp({$rollDown: true}, css`
    padding: 0rem 1rem 0rem;
  `)}
`;

const HeaderField = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  line-height: 1.31;
`;

const ShowTitle = styled(HeaderField)`
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;

  ${ifProp('$singleShowPage', css`
    display: none;
  `)}
`;

const EpisodeTitle = styled(HeaderField)`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;

  ${ifProp('$homepage', css`
    font-size: 1.125rem;
    font-family: ${theme('typography.fontFamily')};
  `)}
`;

const EpisodeDate = styled(HeaderField)`
  font-size: 0.8rem;
  font-weight: 400;
`;

const Description = styled(RichTextField)`
  display: -webkit-box;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
  overflow: hidden;

  ${ifProp('$rollDown'), css`
    padding-right: 1rem;
    opacity: 0;
    transition: opacity 125ms ease-in-out;
  `}

  ${ifProp({$expand: true}, css`
    position: absolute;
    display: block;
  `)};

   ${VerticalCardWrapper}:hover & {
      ${ifProp({$rollDown: true}, css`
        opacity: 1;
        padding-right: 1rem;
        position: absolute;
        visibility: visible;
      `)}

      ${ifProp({$expand: true}, css`
        visibility: visible;
        position: relative;
        opacity: 1;
      `)}

    }

  p {
    font-size: 0.8125rem;
    font-weight: 400;
    margin: 0;
  }
`;

const ContainerLink = styled(Link)`
  text-decoration: none;
  color: ${theme('colors.black')};
`;

const SaveIconWrapper = styled.div`
  margin-left: 0.25rem;
  visibility: ${ifProp('$active', 'visible', 'hidden')};

  ${props => props.theme.breakpoints.down('sm')} {
    display: block;
    visibility: visible;
  }

  ${VerticalCardWrapper}:hover & {
    visibility: visible;
  }

  &:hover {
    color: ${theme('colors.ttRed')};
  }
`;

EpisodeDetails.propTypes = {
  description: PropTypes.string,
  display: PropTypes.string,
  displayTitle: PropTypes.string,
  publishedAt: PropTypes.string,
  shortDescription: PropTypes.string,
  show: PropTypes.object,
  episode: episodeShape.isRequired,
  episodeBlurb: PropTypes.bool,
  onClick: PropTypes.func,
  singleShowPage: PropTypes.bool,
  edit: PropTypes.bool,
  onDelete: PropTypes.func,
  excludedActions: PropTypes.array,
  recommendation: PropTypes.string,
  expand: PropTypes.bool,
  rollDown: PropTypes.bool,
  onUnfollowOverride: PropTypes.func,
  homepage: PropTypes.bool,
};

function EpisodeDetails({
  description,
  display,
  displayTitle,
  publishedAt,
  shortDescription,
  show,
  episode,
  episodeUrl,
  episodeBlurb = true,
  onClick,
  singleShowPage = false,
  allowDelete,
  onDelete,
  edit,
  excludedActions,
  recommendation = '',
  expand = true,
  rollDown = false,
  onUnfollowOverride,
  homepage = false,
  setCardHeight,
}) {
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const { user } = useAuth();
  const showTitle = get(show, 'title');

  const clickLink = () => {
    dataLayerPush(`${recommendation} Episode clicked`);
  }
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const charCount = isDesktop ? 120 : 90;
  let descriptionDisplay = shortDescription;
  const detailsRef = useRef(null);
  const { height: detailsHeight } = useElementSize(detailsRef);

  useEffect(() => {
    setCardHeight(prevHeight => ({
      ...prevHeight,
      detailsHeight
    }))
  }, [detailsHeight]);

  // isTablet covers anything tablet sized and above. does not exclude isDesktop
  if (isTablet) {
    descriptionDisplay = `${shortDescription?.slice(0, charCount)}${shortDescription?.length > charCount ? '...' : ''}`;
  }

  const EpisodeDetailsContent = (
      <DetailsInner $rollDown={rollDown}>
        <Box display='flex' justifyContent='space-between'>
          <ContainerLink to={episodeUrl} onClick={clickLink}>
            <div ref={detailsRef}>
              <DetailsLink to={episodeUrl} onClick={clickLink} $rollDown={rollDown}>
                {showTitle && (
                  <ShowTitle $singleShowPage={singleShowPage}>{showTitle}</ShowTitle>
                )}
                <EpisodeTitle $homepage={homepage}>{displayTitle}</EpisodeTitle>
              </DetailsLink>
              <EpisodeDate>
                <DateFormatter date={publishedAt} format='MMM D, YYYY' />
              </EpisodeDate>
            </div>
            {episodeBlurb && shortDescription && !edit &&
            <Description $expand={expand} $rollDown={rollDown} richText={descriptionDisplay} $disableMargin />
            }
          </ContainerLink>
          <SaveIconWrapper $active={moreMenuOpen}>
            {!!user &&
            <EpisodeIcons
              episode={episode}
              display={display}
              onOpen={() => setMoreMenuOpen(true)}
              onClose={() => setMoreMenuOpen(false)}
              allowDelete={edit}
              onDelete={onDelete}
              excludedActions={excludedActions}
              expand={expand}
              onUnfollowOverride={onUnfollowOverride}
            />
            }
          </SaveIconWrapper>
        </Box>
      </DetailsInner>
  )

  // simply passing the expand prop and adjusting the css based on that is not working
  // it SEEMS to be related to the transform css property. This bug behavior is not observed anywhere else
  // in code base. This seems to be a forced solution to that issue.

  if (expand) {
    return (
        <EpisodeWrapperExpand>
          {EpisodeDetailsContent}
        </EpisodeWrapperExpand>
    )
  }

  return (
    <EpisodeWrapper>
      {EpisodeDetailsContent}
    </EpisodeWrapper>
  );
}

export default React.memo(EpisodeDetails);
