import PropTypes from 'prop-types';

const { oneOf } = PropTypes;

export default oneOf([
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
]);
