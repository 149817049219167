import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PageWrapper from 'components/PageWrapper';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import Button from 'components/Button';
import { ifProp } from 'styled-tools';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Icon from 'components/Icon';

const ButtonsWrap = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  
  ${ifProp('inverse', css`
    background-color: #000000;
  `)}

  & > * {
    margin: .5rem;
  }
`

function StyleGuide() {
  return (
    <PageWrapper>
      <Helmet>Style Guide</Helmet>
      <Container>

        {/* TYPOGRAPHY */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="typography-content"
            id="typography-header"
          >
            <Typography variant='h1' gutterBottom>Typography</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width='100%'>
              <Typography variant='h1' gutterBottom>Header 1</Typography>
              <Typography variant='h2' gutterBottom>Header 2</Typography>
              <Typography variant='h3' gutterBottom>Header 3</Typography>
              <Typography variant='h4' gutterBottom>Header 4</Typography>
              <Typography variant='h5' gutterBottom>Header 5</Typography>
              <Typography variant='h6' gutterBottom>Header 6</Typography>
              <Typography variant="subtitle1" gutterBottom>
                subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
              </Typography>
              <Typography variant="body1" gutterBottom>
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
                dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
              <Typography variant="body2" gutterBottom>
                body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
                dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
              <Typography variant="button" display="block" gutterBottom>
                button text
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                caption text
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                overline text
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* BUTTONS */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="buttons-content"
            id="buttons-header"
          >
            <Typography variant='h1' gutterBottom>Buttons</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width='100%'>
              <Box my={3}>
                <Typography variant='h3' gutterBottom>Contained Buttons</Typography>
                <ButtonsWrap>
                  <Button variant="contained">Default</Button>
                  <Button variant="contained" color="primary">
                    Primary
                  </Button>
                  <Button variant="contained" color="secondary">
                    Secondary
                  </Button>
                  <Button variant="contained" disabled>
                    Disabled
                  </Button>
                  <Button variant="contained" color="primary" href="#contained-buttons">
                    Link
                  </Button>
                </ButtonsWrap>
              </Box>
              <Box my={3}>
                <Typography variant='h4' gutterBottom>Contained Buttons - Large</Typography>
                <ButtonsWrap>
                  <Button variant="contained" size='large'>
                    Default
                  </Button>
                  <Button variant="contained" color="primary" size='large'>
                    Primary
                  </Button>
                  <Button variant="contained" color="secondary" size='large'>
                    Secondary
                  </Button>
                  <Button variant="contained" size='large' disabled>
                    Disabled
                  </Button>
                  <Button variant="contained" color="primary" size='large' href="#contained-buttons">
                    Link
                  </Button>
                </ButtonsWrap>
              </Box>
              <Box my={3}>
                <Typography variant='h4' gutterBottom>Contained Buttons - Small</Typography>
                <ButtonsWrap>
                  <Button variant="contained" size='small'>
                    Default
                  </Button>
                  <Button variant="contained" color="primary" size='small'>
                    Primary
                  </Button>
                  <Button variant="contained" color="secondary" size='small'>
                    Secondary
                  </Button>
                  <Button variant="contained" size='small' disabled>
                    Disabled
                  </Button>
                  <Button variant="contained" color="primary" size='small' href="#contained-buttons">
                    Link
                  </Button>
                </ButtonsWrap>
              </Box>

              <Divider variant='middle' />

              <Box my={3}>
                <Typography variant='h3' gutterBottom>Outlined Buttons</Typography>
                <ButtonsWrap>
                  <Button variant="outlined">Default</Button>
                  <Button variant="outlined" color="primary">
                    Primary
                  </Button>
                  <Button variant="outlined" disabled>
                    Disabled
                  </Button>
                  <Button variant="outlined" color="primary" href="#outlined-buttons">
                    Link
                  </Button>
                </ButtonsWrap>
                <ButtonsWrap>
                  <Button variant="outlined" startIcon={<Icon icon='play' />}>
                    Default
                  </Button>
                  <Button variant="outlined" color="primary" startIcon={<Icon icon='play' />}>
                    Primary
                  </Button>
                  <Button variant="outlined" disabled startIcon={<Icon icon='play' />}>
                    Disabled
                  </Button>
                  <Button variant="outlined" color="primary" startIcon={<Icon icon='play' />} href="#outlined-buttons">
                    Link
                  </Button>
                </ButtonsWrap>
                <ButtonsWrap inverse>
                  <Button variant="outlined" color="secondary">
                    Secondary
                  </Button>
                  <Button variant="outlined" color="secondary" startIcon={<Icon icon='play' />}>
                    Secondary
                  </Button>
                </ButtonsWrap>
              </Box>
              <Box my={3}>
                <Typography variant='h4' gutterBottom>Outlined Buttons - Large</Typography>
                <ButtonsWrap>
                  <Button variant="outlined" size='large'>
                    Default
                  </Button>
                  <Button variant="outlined" color="primary" size='large'>
                    Primary
                  </Button>
                  <Button variant="outlined" size='large' disabled>
                    Disabled
                  </Button>
                  <Button variant="outlined" color="primary" href="#outlined-buttons" size='large'>
                    Link
                  </Button>
                </ButtonsWrap>
                <ButtonsWrap inverse>
                  <Button variant="outlined" color="secondary" size='large'>
                    Secondary
                  </Button>
                </ButtonsWrap>
              </Box>
              <Box my={3}>
                <Typography variant='h4' gutterBottom>Outlined Buttons - Small</Typography>
                <ButtonsWrap>
                  <Button variant="outlined" size='small'>
                    Default
                  </Button>
                  <Button variant="outlined" color="primary" size='small'>
                    Primary
                  </Button>
                  <Button variant="outlined" size='small' disabled>
                    Disabled
                  </Button>
                  <Button variant="outlined" color="primary" href="#outlined-buttons" size='small'>
                    Link
                  </Button>
                </ButtonsWrap>
                <ButtonsWrap inverse>
                  <Button variant="outlined" color="secondary" size='small'>
                    Secondary
                  </Button>
                </ButtonsWrap>
              </Box>

              <Divider variant='middle' />

              <Box my={3}>
                <Typography variant='h3' gutterBottom>Text Buttons</Typography>
                <ButtonsWrap>
                  <Button>Default</Button>
                  <Button color="primary">Primary</Button>
                  <Button disabled>Disabled</Button>
                  <Button href="#text-buttons" color="primary">
                    Link
                  </Button>
                </ButtonsWrap>
                <ButtonsWrap inverse>
                  <Button color="secondary">Secondary</Button>
                </ButtonsWrap>
              </Box>


            </Box>
          </AccordionDetails>
        </Accordion>


      </Container>
    </PageWrapper>
  );
}

StyleGuide.propTypes = {};

export default React.memo(StyleGuide);
