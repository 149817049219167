import React, { useRef } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import MuiGridList from '@material-ui/core/GridList';
import { ifProp, prop, switchProp } from 'styled-tools';
import contentCardsShape from 'shapes/components/contentCardsShape';
import FeedContentCard from './components/FeedContentCard';
import useElementSize from 'hooks/useElementSize';
import ContentCard from './components/ContentCard';
import { MOBILE_LAYOUTS } from 'utils/constants';

const ContentCardsContainer = styled(Container)`
  padding-bottom: 3rem;
  padding-top: 3rem;

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }

  ${ifProp('$fullWidth', css`
   ${props => props.theme.breakpoints.up('md')} {
      max-width: 100%;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      padding-left: 7.75rem;
      padding-right: 7.75rem;
    }
  `)}

  ${ifProp('$color', css`
    color: ${prop('$color')};
  `)}

  ${ifProp('$marginTop', css`
    margin-top: ${prop('$marginTop')}rem;
  `)}

  ${switchProp('$contentAlignment', {
    left: css`
      * {
        text-align: left;
      }

      a {
        margin-right: auto;
      }
    `,
    center: css`
      * {
        text-align: center;
      }

      a {
          margin: auto;
      }
    `,
    right: css`
      * {
        text-align: right;
      }

      a {
        margin-left: auto;
      }
    `
  })}

  ${ifProp({page: 'homepage'}, css`
    ${props => props.theme.breakpoints.up('md')} {
      width: 88%;
      padding-left: 0;
      padding-right: 0;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      max-width: 1280px;
    }

    @media (min-width: 1440px) {
      max-width: 1380px;
    }

    ${props => props.theme.breakpoints.up('xl')} {
      max-width: 1580px;
    }
  `)}

  ${ifProp({page: 'trader-resources'}, css`
    ${props => props.theme.breakpoints.up('md')} {
      min-height: 21.875rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      max-width: 100%;
      min-height: 25rem;
      padding-left: 8.75rem;
      padding-right: 8.75rem;
    }

    @media (min-width: 1820px) {
      padding-left: 11.5rem;
      padding-right: 11.5rem;
    }
  `)}
`;

const GridListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const GridList = styled(MuiGridList)`
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;

  > div {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
`;

ContentCards.propTypes = {
  data: contentCardsShape.isRequired,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
};

function ContentCards({ data, className, ...props }) {
  const cardRef = useRef(null);
  const { width } = useElementSize(cardRef);
  let blockData = data.blockReference || data;
  let singleCards;

  if (blockData.contentCards && blockData.contentCards.length > 0) {
    singleCards = blockData.contentCards;
  } else {
    const cards = get(blockData, 'header');
    if (!cards) {
      return null;
    }

    singleCards = cards.reduce((allCards, card) => {
      return allCards.concat(card.contentCard?.singleContentCard || []);
    }, []);
  }

  if (!singleCards || singleCards.length === 0) {
    return null;
  }

  const { additionalStyles } = blockData;
  const { blockStyle } = additionalStyles || {};

  const mobileLayout = blockData?.mobileLayout ?? MOBILE_LAYOUTS.grid;
  const { backgroundColor } = blockStyle?.background || {};
  const { marginTop } = blockStyle?.spacing || {};
  const { alignment: contentAlignment } = blockStyle?.alignmentAndOrientation || {};
  const fullWidth = blockStyle?.width === 'full';

  const cardLength = singleCards.length;
  const smSize = cardLength > 1 ? 6 : 12;
  const size = cardLength <= 4 ? 12 / cardLength : true;

  return (
    <ContentCardsContainer
      $className={className}
      $backgroundColor={backgroundColor}
      $marginTop={marginTop}
      $contentAlignment={contentAlignment}
      $fullWidth={fullWidth}
      {...props}
    >
      {mobileLayout === MOBILE_LAYOUTS.feed ? (
        <>
          <Hidden mdUp>
            <GridListWrapper>
              <GridList
                cols={1.5}
                cellHeight={116}
                spacing={13}
                component='div'
              >
                {(singleCards).map((singleCard, index) => (
                  <FeedContentCard key={index} data={singleCard} size={size} width={width} />
                ))}
              </GridList>
            </GridListWrapper>
          </Hidden>

          <Hidden smDown>
            <Grid container spacing={2}>
              {singleCards.map((singleCard, index) => (
                <Grid item key={`single-card-${index}`} xs={12} sm={smSize} md={size} ref={cardRef}>
                  <ContentCard data={singleCard} size={size} fullWidth={fullWidth} />
                </Grid>
              ))}
            </Grid>
          </Hidden>
        </>
      ) : (
        <Grid container spacing={2}>
          {singleCards.map((singleCard, index) => (
            <Grid item key={`single-card-${index}`} xs={12} sm={smSize} md={size} ref={cardRef}>
              <ContentCard data={singleCard} size={size} fullWidth={fullWidth} />
            </Grid>
          ))}
        </Grid>
      )}
    </ContentCardsContainer>
  );
}

export default React.memo(ContentCards);
