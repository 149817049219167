import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import { theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import BasePaper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BaseIconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveToPlaylistModal from 'components/SaveToPlaylistModal';
import episodeShape from 'shapes/episodeShape';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import SimpleDialog from 'components/SimpleDialog';
import DialogContent from '@material-ui/core/DialogContent';
import RichTextField from 'components/RichTextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import linkResolver from 'utils/linkResolver';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAuth } from 'context/AuthContext';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import { dataLayerPush } from 'utils/dataLayer';
import FollowShowActionIcon from 'components/ActionIcons/FollowShow';
import WatchLaterActionIcon from 'components/ActionIcons/WatchLater';
import { useDashboardContext } from 'context/DashboardContext'
import {formatStringForTestId} from "__testUtils__/functions";

export const Wrapper = styled.div`
  position: relative;
`;

const IconButton = styled(BaseIconButton)`
  border-radius: 0.25rem;
  width: 2rem;
  height: 2rem;
  color: ${theme('palette.common.black')};
  border-radius: 50%;
  margin-right: auto;

  &:hover {
    background-color: ${theme('colors.iconHoverBackgroundGray')};
  }

  &[aria-controls='menu-list-grow'] {
    p {
      color: ${theme('colors.ttRed')};
    }

    svg {
      fill: ${theme('colors.ttRed')};
    }
  }
`;

const Paper = styled(BasePaper)`
  border: 1px solid ${theme('colors.mediumGray')};
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: fit-content;

  .MuiIconButton-root {
    color: ${theme('palette.common.black')};

    &:hover {
      background-color: transparent;
    }
  }

  ${Wrapper}:hover & {
    svg {
      fill: ${theme('colors.ttRed')};
    }

    p {
      color: ${theme('colors.ttRed')};
    }

    .MuiIconButton-root {
      color: ${theme('colors.ttRed')};
    }
  }
`;

const IconLabel = styled(Typography)`
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  text-transform: uppercase;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: .65rem;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: ${theme('colors.white')};
  }

  .MuiListItemIcon-root {
    min-width: 1.875rem;
    fill: black;
  }

  &:hover {
    span,
    .MuiSvgIcon-root {
      fill: ${theme('colors.ttRed')};
      color: ${theme('colors.ttRed')};
    }
  }

  .MuiSvgIcon-root {
    fill: black;
  }
`;

const MobileShowTitle = styled(Typography)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 1.31;
  text-transform: uppercase;
  margin-bottom: 0.25rem;

  // Truncate text
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`}`;

const MobileEpisodeTitle = styled(Typography)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.31;
`}`;

const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ACTION_PLAY_EPISODE = 'playEpisode';
export const ACTION_WATCH_LATER = 'watchLater';
export const ACTION_SAVE_TO_PLAYLIST = 'saveToPlaylist';
export const ACTION_FOLLOW_SHOW = 'followShow';
export const ACTION_REMOVE_ITEM = 'removeShow';

AddToPlaylist.propTypes = {
  episode: episodeShape.isRequired,
  optionalText: PropTypes.string,
  children: PropTypes.node,
  iconButtonProps: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  excludedActions: PropTypes.array,
  includedActions: PropTypes.array,
  onUnfollowOverride: PropTypes.func
};

function AddToPlaylist({
  episode,
  optionalText,
  children,
  onOpen,
  onClose,
  iconButtonProps = {},
  iconProps = {},
  onDelete,
  excludedActions = [],
  includedActions = [],
  onUnfollowOverride
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const prevOpen = useRef(open);
  const [saveToPlaylistOpen, setSaveToPlaylistOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const episodeUrl = linkResolver({ ...episode, contentTypeUid: 'episode' });
  const { user } = useAuth();
  const { preferences } = useGetUserDataSettings();
  const { state: dashboardContext } = useDashboardContext();
  const { isOpen: isDashboardOpen, showFirstTimeHelper } = dashboardContext;

  useEffect(() => {
    if (showFirstTimeHelper) {
      handleSaveToPlaylistClose();
    }
  }, [showFirstTimeHelper]);

  const handleToggle = (event) => {
    if (onOpen && !open) {
      onOpen(event);
    }

    if (onClose && open) {
      onClose(event);
    }

    if (!isMobile) {
      dataLayerPush(`Episode card actions dropdown opened`);
    } else {
      dataLayerPush(`Episode card actions modal opened`);
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    onClose && onClose(event);
    setOpen(false);
  };

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    // When closing here, we reset our prevOpen ref so that focus isn't sent to the button from a click away.
    prevOpen.current = false;
    handleClose(event);
  }

  const handlePlayEpisodeClick = (event) => {
    handleClose(event);

    history.push(episodeUrl);
    dataLayerPush('Play episode clicked - mobile only episode card action');
  }

  const handleSaveToPlaylistClick = (event) => {
    handleClose(event);

    setSaveToPlaylistOpen(true);
    dataLayerPush('Save to playlist modal opened');
  }

  const handleSaveToPlaylistClose = () => {
    setSaveToPlaylistOpen(false);
    dataLayerPush('Save to playlist modal closed');
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleDeleteItemClick = (event) => {
    handleClose(event);
    onDelete();
  }

  // return focus to the button when we transitioned from !open -> open
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let actionItems = [
    {
      value: ACTION_PLAY_EPISODE,
      label: 'Play Episode',
      icon: <PlayArrowIcon style={{ marginLeft: '-0.25rem' }} />, // Negative margin-left to left-align play arrow icon with others.
      hide: !isMobile,
      callback: handlePlayEpisodeClick
    },
    {
      icon: <WatchLaterActionIcon episode={episode} postLabel={true} fontSize='small' />,
      hide: !preferences?.acceptedAt,
      callback: handleClose
    },
    {
      value: ACTION_SAVE_TO_PLAYLIST,
      label: 'Save to Playlist',
      icon: <PlaylistAddIcon />,
      hide: !preferences?.acceptedAt,
      callback: handleSaveToPlaylistClick
    },
    {
      icon: <FollowShowActionIcon show={episode.show} postLabel={true} fontSize='small' onUnfollowOverride={onUnfollowOverride} />,
      hide: !preferences?.acceptedAt,
      callback: handleClose
    },
    {
      value: ACTION_REMOVE_ITEM,
      label: 'Remove This Episode',
      icon: isDashboardOpen ? <DeleteIcon fontSize='small' /> : <RemoveCircleOutlineIcon fontSize='small' />,
      hide: !onDelete,
      callback: handleDeleteItemClick
    },
    ...includedActions
  ];

  // filter out excluded action items, and hidden action items
  actionItems = actionItems
    .filter(actionItem => !excludedActions.find(excludedAction => actionItem.value === excludedAction))
    .filter(actionItem => !actionItem.hide);

  const handleAction = (actionName, event) => {
    const action = actionItems.find(actionItem => actionItem.value === actionName);

    if(action?.callback) {
      action?.callback(event);
    }

    handleClose(event);
  }

  if (!user || actionItems.length === 0) {
    return null
  }

  return (
    <Wrapper onMouseLeave={() => setOpen(false)}>
      <IconWrapper>
        <IconButton
          data-testid='saveToDashboard'
          ref={anchorRef}
          aria-label='Save to Dashboard'
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          disableRipple
          size='small'
          edge='end'
          {...iconButtonProps}
        >
          {children ? children : <MoreVertIcon {...iconProps} />}
          {optionalText && <IconLabel>{optionalText}</IconLabel>}
        </IconButton>
      </IconWrapper>

      {/* Dropdown menu - desktop and tablet */}
      <Popper
        open={open && !isMobile}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 1301 }}
        placement='bottom-end'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway} touchEvent='onTouchStart'>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {
                    actionItems.map((item, i) => (
                      <StyledMenuItem className='modal-trigger' key={i} $type={i === 0 && 'top'} onClick={item.callback} data-testid={item?.label ? formatStringForTestId(item.label) : null}>
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <span>{item.label}</span>
                      </StyledMenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/* Show Details Dialog with Menu - mobile only */}
      <SimpleDialog
        onClose={handleAction}
        open={open && isMobile}
        options={actionItems}
        TransitionComponent={TransitionSlideUp}
        title={
          <>
            {episode.show && <MobileShowTitle>{episode.show.title}</MobileShowTitle>}
            <MobileEpisodeTitle>{episode.displayTitle}</MobileEpisodeTitle>
          </>
        }
        absolutePosClose
      >
        <DialogContent>
          <DialogContentText color='textPrimary'>
            <RichTextField richText={episode.shortDescription || episode.description || ''} component='span' $disableMargin />
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>

      {/* Save to Playlist modal */}
      <SaveToPlaylistModal
        episode={episode}
        open={saveToPlaylistOpen}
        onClose={handleSaveToPlaylistClose}
        className='modal-trigger'
      />
    </Wrapper>
  );
}

export default AddToPlaylist;
