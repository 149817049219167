import React  from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AccordionWrapper from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Icon from 'components/Icon';
import { theme } from 'styled-tools';

const StyledAccordion = styled(AccordionWrapper)`
  box-shadow: none;

  &.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordion-root {
    &:before {
      background-color: transparent;
    }
  }

  .MuiAccordionDetails-root {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const AccordionHeader = styled(AccordionSummary)`
  .MuiTypography-h4 {
    font-family: ${prop('theme.typography.fontFamilySecondary')};
    font-weight: 700;
  }

  .MuiAccordionSummary-expandIcon {
    color: ${theme('colors.black')};
  }
`;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  expandedCallback: PropTypes.func,
  initialExpanded: PropTypes.bool,
}

function Accordion({ children, className, icon, id, title, variant, expandedCallback, initialExpanded }) {
  const [expanded, setExpanded] = React.useState(initialExpanded !== false ? id : false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    expandedCallback && expandedCallback(panel, newExpanded ? panel : false);
  };

  return (
    <div className={className}>
      <StyledAccordion
        square
        expanded={expanded === `${id}`}
        onChange={handleChange(`${id}`)}
      >
        <AccordionHeader
          expandIcon={<Icon icon={icon} size={0.75} />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Typography variant={variant}>{title}</Typography>
        </AccordionHeader>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}

Accordion.propTypes = propTypes;

export default React.memo(Accordion);
