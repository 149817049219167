import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import RichTextField from 'components/RichTextField';
import BaseAccordion from 'components/Accordion';

const Accordion = styled(BaseAccordion)`
  &.text-accordion {
    .MuiAccordionDetails-root {
      padding: 0;

      div {
        margin: 0;
      }

      p {
        margin-top: 0;
      }
    }

    .MuiAccordionSummary-root {
      background-color: ${theme('colors.backgroundGray')};
      padding-left: 0;
    }

    .MuiAccordion-root {
      background-color: ${theme('colors.backgroundGray')};
      border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
      padding-bottom: 1rem;
    }

    .MuiTypography-h4 {
      font-weight: 400;
    }

    &.concepts-faq {
      .MuiTypography-h4 {
        font-weight: 700;
      }
    }

    .MuiAccordionSummary-expandIcon {
      color: ${theme('colors.black')};
      &.Mui-expanded {
        transform: rotate(225deg);
      }
    }
  }
`;

const propTypes = {
  bodyCopy: PropTypes.string,
  header: PropTypes.string,
  page: PropTypes.string,
};

function TextAccordion({ bodyCopy, header, page }) {
  const conceptsPage = page?.includes('concepts-strategies');

  return (
    <Accordion
      className={`${conceptsPage && 'concepts-faq'}` + ' text-accordion'}
      icon='plus'
      title={header}
      variant='h4'
    >
      {bodyCopy && <RichTextField richText={bodyCopy} />}
    </Accordion>
  );
}

TextAccordion.propTypes = propTypes;

export default TextAccordion;
