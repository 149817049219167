import React from 'react';
import PropTypes from 'prop-types';
import DateFormatter from 'components/DateFormatter';
import { theme, ifProp, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import blogPostShape from 'shapes/blogPostShape';
import { autoOptimizeImage } from 'utils/functions';

const StyledBlogPost = styled(Link)`
  display: flex;
  border-bottom: 1px solid ${theme('colors.mutedGray')};
  grid-column-gap: 1rem;
  color: rgba(255, 255, 255, 0.85);
  flex-direction: row;
  transition: color 0.3s, background-color 0.3s;
  text-decoration: none;
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${switchProp('hoverAnimation', css`
    font-size: 0.8rem;
    margin-top: 0.5rem;
  `)};

  &:hover {
    color: #fff;
    background-color: ${theme('colors.darkCharcoal')};
    cursor: pointer;  
  }
`;

const StyledBlogImage = styled.img`
  align-self: flex-start;
  object-fit: cover;
  height: 4.7rem;
  width: 7rem;
`;

const BlogDetails = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
  font-weight: 500;
  font-size: 1rem;

  ${ifProp('isBlogPostDate', css`
    font-size: 0.8rem;
    margin-top: 0.5rem;
  `)};
`;

ListCard.propTypes = {
  blogPost: blogPostShape,
};

function ListCard({ blogPost }) {
  return (
    <StyledBlogPost to={blogPost?.url}>
      {blogPost?.metadata?.thumbnailImage && (
        <div>
          <StyledBlogImage
            loading='lazy'
            src={autoOptimizeImage(blogPost?.metadata?.thumbnailImage?.url, true, 230)}
            alt={blogPost?.metadata?.thumbnailAltText || 'Thumbnail'}
          />
        </div>
      )}
      <div>
        <BlogDetails>{blogPost?.title}</BlogDetails>
        <BlogDetails isBlogPostDate>
          <DateFormatter
            date={blogPost?.postDate}
            format='MMM D, YYYY'
          />
        </BlogDetails>
      </div>
    </StyledBlogPost>
  );
};

export default ListCard;