import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {ifProp, ifNotProp, theme} from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText';
import episodeShape from 'shapes/episodeShape';
import BaseListItem from '@material-ui/core/ListItem';
import { useAuth } from 'context/AuthContext';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import MuiListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddToPlaylist from 'components/CardButtons/AddToPlaylist';
import { getEpisodeExpirationDays } from 'utils/episodeHelpers';
import DateFormatter from 'components/DateFormatter';
import ImageDetails from '../components/ImageDetails';
import NewContentFlag from 'components/NewContentFlag';
import playlistShape from 'shapes/playlistShape';
import { getEpisodeUrl } from 'utils/linkUtils';
import PlaylistIndexIndicator from 'components/EpisodeCard/components/PlaylistIndexIndicator';
import dailyReplayShape from 'shapes/dailyReplayShape';
import scheduleItemShape from 'shapes/scheduleItemShape';
import DragIcon from '../components/DragIcon';
import { useDashboardContext } from 'context/DashboardContext';
import { COLOR_THEMES } from 'utils/constants';
import ClampLines from 'components/ClampLines';
import Checkbox from '@material-ui/core/Checkbox';
import AbsoluteOverlay from 'components/AbsoluteOverlay';
import EpisodeDisplayOptions from 'constants/EpisodeDisplayOptions';
import Icon from 'components/Icon';
import { ListItem } from './ListItem';

const ImageDetailsContainer = styled.div`${({ theme }) => css`
  flex: 0 0 auto;
  width: 8.625rem;

  @media (max-width: ${theme.breakpoints.custom.mobileLg}) {
    width: 8.625rem;
  }

  @media (max-width: ${theme.breakpoints.custom.mobileSm}) {
    width: 7rem;
  }
`}`;

const BaseText = styled(Typography)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};

  ${ifProp('$upcoming', css`
    color: ${ifProp({ $colorTheme: COLOR_THEMES.dark }, theme.colors.upcomingPlaceholderTextDark, theme.colors.upcomingPlaceholderText)};
  `)}
`}`;

const ShowTitle = styled(BaseText)`${({ theme }) => css`
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0.125rem;

  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: false }, css`
    color: ${theme.colors.whiteTransparent};
  `)}

  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: true }, css`
    color: ${theme.colors.upcomingPlaceholderTextDark};
  `)}

  ${ifNotProp('$upcoming', css`
    &:hover {
      color: ${theme.colors.ttRed};
    }
  `)}

  ${theme.breakpoints.down('sm')} {
    font-weight: 400;
    margin-right: 2.5rem;

    ${ifProp('$singleShowPage', css`
        display: none;
    `)}
  }
`}`;

const EpisodeTitle = styled(BaseText)`${({ theme }) => css`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1875;
  margin-bottom: 0.25rem;
  padding-right: 1rem;

  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: false }, css`
    color: ${theme.colors.whiteTransparent};
  `)}

  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: true }, css`
    color: ${theme.colors.upcomingPlaceholderTextDark};
  `)}

  ${theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
`}`;

const EpisodeDate = styled(BaseText)`${({ theme }) => css`
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.3;
  text-transform: uppercase;

  ${ifNotProp('$upcoming', css`
    color: ${theme.palette.common.black};

    ${ifProp({ $colorTheme: COLOR_THEMES.dark }, css`
      color: ${theme.colors.whiteTransparent};
    `)}
  `)}
`}`;

const ListItemSecondaryAction = styled(MuiListItemSecondaryAction)`${({ theme }) => css`
  top: 0;
  right: 0;
  transform: none;
  visibility: ${ifProp('$active', 'visible', 'hidden')};

  ${ifProp({$colorTheme: COLOR_THEMES.dark}, css`
    svg {
      fill: white;
    }
  `)}

  ${theme.breakpoints.down('sm')} {
    display: block;
    visibility: visible;
  }

  ${ifProp('$dashboardOpen', css`
    ${theme.breakpoints.down('xs')} {
      margin-right: -0.5rem;
    }
  `)}

  & button {
    padding-right: 0;
  }

  .MuiIconButton-root {
    color: ${theme.palette.common.black};
  }
`}`;

const ListItemTextWrapper = styled(ListItemText)`${({ theme }) => css`
  margin-left: 1.25rem;
  margin-top: 0;
  margin-right: 0.4rem;

  @media (max-width: ${theme.breakpoints.custom.mobileLg}) {
    margin-left: 1rem;
  }
`}`;

const TitleLink = styled.div`${({ theme }) => css`
  text-decoration: none;
  color: inherit;

  ${ListItemTextWrapper}:hover & {
    color: ${theme.palette.common.black};
  }
`}`;

const ContainerLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const CheckboxWrapper = styled(Checkbox)`
  position: absolute;
  left: -2.5rem;
  bottom: 45%;

  ${props => props.theme.breakpoints.down('xs')} {
    left: -1.5rem;
    bottom: 45%;
  }

  svg {
    fill: ${theme('colors.black')};

    ${ifProp({checked: true}, css`
      fill: ${theme('colors.ttRed')};
    `)};
  }
`;

const ListItemCardWrapper = styled.span`
  ${ifProp({$edit: true}, css`
    cursor: pointer;
  `)};
`;

ListItemCard.propTypes = {
  episode: episodeShape,
  edit: PropTypes.bool,
  playlist: playlistShape,
  playlistIndex: PropTypes.number,
  dailyReplay: dailyReplayShape,
  loading: PropTypes.bool,
  hideShowTitle: PropTypes.bool,
  singleShowPage: PropTypes.bool,
  onClick: PropTypes.func,
  excludedActions: PropTypes.array,
  onDelete: PropTypes.func,
  isNewContent: PropTypes.bool,
  recommendation: PropTypes.string,
  active: PropTypes.bool,
  scheduleItem: scheduleItemShape,
  colorTheme: PropTypes.oneOf(Object.values(COLOR_THEMES)),
  percentWatched: PropTypes.number,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  draggable: PropTypes.bool,
  onUnfollowOverride: PropTypes.bool
};

function ListItemCard({
  episode,
  playlist,
  playlistIndex,
  dailyReplay,
  loading = false,
  hideShowTitle = false,
  singleShowPage = false,
  onClick,
  edit,
  excludedActions,
  onDelete,
  isNewContent = false,
  recommendation = '',
  active = false,
  scheduleItem,
  colorTheme = COLOR_THEMES.light,
  percentWatched = 0,
  checked = false,
  onCheck,
  draggable = false,
  onUnfollowOverride,
  ...props
}) {
  const { user } = useAuth();
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const { state: dashboardContext } = useDashboardContext();
  const { isOpen } = dashboardContext;

  if (loading) {
    return (
      <ListItem button disableGutters {...props}>
        <Skeleton variant='rect' width={'10.625rem'} height={'5.9375rem'} style={{ marginRight: '1.5rem' }} />
        <ListItemText
          primary={<Skeleton height={16} width='70%' />}
          secondary={<Skeleton height={14} width='40%' />}
        />
      </ListItem>
    );
  }

  if (!episode && !scheduleItem) {
    return null;
  }

  const isUpcomingPlaceholder = !episode && !!scheduleItem;

  let { publishedAt, displayTitle } = episode || {};
  const episodeUrl = getEpisodeUrl(episode, { playlist, dailyReplay });
  const expirationDate = getEpisodeExpirationDays(episode);
  const show = episode ? episode.show : scheduleItem.show;

  // If this is upcoming episode, populate some of the variables from the scheduleItem.
  if (isUpcomingPlaceholder) {
    publishedAt = scheduleItem.startDateTimeUtc;
    displayTitle = <>&mdash;</>;
  }

  return (
    <ListItemCardWrapper
      onMouseEnter={() => setMoreMenuOpen(true)}
      onMouseLeave={() => setMoreMenuOpen(false)}
      $edit={edit}
      onClick={() => edit && onCheck()}
    >
      {!edit && draggable &&
        <DragIcon />
      }
      {edit &&
        <CheckboxWrapper
          checked={checked}
          onChange={onCheck}
        />
      }
      <ListItem
        onClick={onClick}
        disableGutters
        alignItems='flex-start'
        $user={user}
        $isPlaylist={!!playlist || !!dailyReplay}
        $active={active}
        $upcoming={isUpcomingPlaceholder}
        $colorTheme={colorTheme}
        $edit={edit}
        {...props}
      >
        {(playlist || dailyReplay) && (
          <PlaylistIndexIndicator $active={active} $upcoming={isUpcomingPlaceholder} $colorTheme={colorTheme}>
            {active ? (
              <Icon icon='play' size={0.7} />
            ) : playlistIndex}
          </PlaylistIndexIndicator>
        )}
        <ImageDetailsContainer>
          <ImageDetails
            episode={episode}
            expirationDate={expirationDate}
            recommendation={recommendation}
            playIconSize={0.6}
            linkUrlOverride={episodeUrl}
            upcomingPlaceholder={isUpcomingPlaceholder}
            percentWatched={percentWatched}
            colorTheme={colorTheme}
            edit={edit}
            active={active}
            display={EpisodeDisplayOptions.listItem}
          />
        </ImageDetailsContainer>
        {isNewContent &&
          <NewContentFlag height={1.625} text='New' type='short' width={2.5} />
        }
        <ContainerLink to={episodeUrl} as={isUpcomingPlaceholder ? 'span' : undefined}>
          <ListItemTextWrapper
            primary={
              <>
                {!hideShowTitle && show && (
                  <TitleLink to={show.url} as={isUpcomingPlaceholder ? 'span' : undefined}>
                    <ShowTitle $singleShowPage={singleShowPage} $upcoming={isUpcomingPlaceholder} $colorTheme={colorTheme}>
                      {show.title}
                    </ShowTitle>
                  </TitleLink>
                )}
                <TitleLink to={episodeUrl} as={isUpcomingPlaceholder || active ? 'span' : undefined}>
                  <EpisodeTitle
                    $upcoming={isUpcomingPlaceholder}
                    $colorTheme={colorTheme}
                    component='div'
                  >
                    <ClampLines text={displayTitle} lines={2} addTitleOnClamp />
                  </EpisodeTitle>
                </TitleLink>
              </>
            }
            secondary={
              <TitleLink to={episodeUrl} as={isUpcomingPlaceholder || active ? 'span' : undefined}>
                <EpisodeDate $upcoming={isUpcomingPlaceholder} $colorTheme={colorTheme}>
                  <DateFormatter date={publishedAt} format='MMM D, YYYY' />
                </EpisodeDate>
              </TitleLink>
            }
            disableTypography
          />
        </ContainerLink>
        {episode && (
          <div>
            <ListItemSecondaryAction
              $active={moreMenuOpen}
              $dashboardOpen={isOpen}
              $colorTheme={colorTheme}
            >
              <AddToPlaylist
                episode={episode}
                onDelete={onDelete}
                excludedActions={excludedActions}
                onClose={() => setMoreMenuOpen(false)}
                iconButtonProps={{
                  edge: false,
                  'aria-label': 'open more options menu',
                  'className': 'modal-trigger',
                  size: undefined,
                }}
                onUnfollowOverride={onUnfollowOverride}
              />
            </ListItemSecondaryAction>
          </div>
        )}
      </ListItem>
      {edit &&
        <AbsoluteOverlay zIndex={11} />
      }
    </ListItemCardWrapper>
  );
}

export default ListItemCard;
