import { ifProp, ifNotProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseListItem from '@material-ui/core/ListItem';
import { COLOR_THEMES } from 'utils/constants';

export const ListItem = styled(BaseListItem)`${({ theme }) => css`
  border-bottom: 1px solid ${theme.colors.lightBorderGray};
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

  ${ifProp({ $colorTheme: COLOR_THEMES.dark }, css`
    border-bottom: 1px solid ${theme.colors.mutedGray};
  `)}

  ${ifNotProp('$user', css`
    padding-right: 0;
  `)}

  ${ifProp('$isPlaylist', css`
    padding-left: 0.375rem;
  `)}

  ${ifProp('$active', css`
    background: ${theme.colors.activeBackgroundGray};
  `)};

  &:hover {
    ${ifProp({ $colorTheme: COLOR_THEMES.light }, css`
      background: ${ifProp('$active', theme.colors.activeBackgroundGray, theme.palette.common.white)};
    `)}

    ${ifProp({$edit: true}, css`
      background: transparent;
    `)};

    ${ifProp('$upcoming', css`
      background: none;

      &, a {
        cursor: default;
      }
    `)}
  }

  .MuiListItem-container:last-of-type & {
    border-bottom: none;
  }
`}`;
