import React, { useState, useRef, useMemo } from 'react'
import get from 'lodash.get';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/client';
import EpisodeCard from 'components/EpisodeCard';
import getWatchHistoryQuery from '../../../../graphql/queries/getWatchHistory.graphql';
import deleteWatchEventMutation from './deleteWatchEvent.graphql';
import SortOptions from 'constants/SortOptions';
import { useDashboardContext } from 'context/DashboardContext';
import uniqueBy from 'lodash.uniqby'
import UndoSnackBar from 'components/UndoSnackBar';
import DashboardPageHeader from '../DashboardPageHeader';
import DashboardAlert from '../DashboardAlert';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ACTION_FOLLOW_SHOW, ACTION_REMOVE_ITEM } from 'components/CardButtons/AddToPlaylist';
import InfiniteScroll from 'react-infinite-scroll-component'
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import OptInAlert from '../OptInAlert';
import { dataLayerPush } from 'utils/dataLayer';
import EmptyWatchHistory from './Empty';

const GridWrapper = styled(Grid)`
  padding: 0.5rem;
`;

const InfiniteScrollContainer = styled.div`
  &&& {
    .infinite-scroll-component {
      overflow: visible !important;
    }
  }
`;

function Episodes({ edit }) {
  const [watchList, setWatchList] = useState({ current: [], previous: [] });
  const [exclusiveStartKey, setExclusiveStartKey] = useState();
  const [toDelete, setToDelete] = useState(null);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false)
  const { state: dashboardState } = useDashboardContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [deleteWatchEvent] = useMutation(deleteWatchEventMutation);
  const { history } = useGetUserDataSettings();
  const accepted = !!history.acceptedAt

  const sortDirection = useMemo(() => {
    return dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending';
  }, [dashboardState.sortBy]);

  const { loading, error, fetchMore } = useQuery(getWatchHistoryQuery, {
    variables: {
      sort: { sortDirection: sortDirection },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const watchEvents = get(data, 'watchHistory.items', []);
      const uniqueWatchEvents = uniqueBy(watchList.current.concat(watchEvents), x => x.episode);

      setWatchList({ ...watchList, current: uniqueWatchEvents });
      setExclusiveStartKey(get(data, 'watchHistory.exclusiveStartKey'));
    }
  });

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        sort: { sortDirection: sortDirection },
        exclusiveStartKey: exclusiveStartKey,
      },
    }).then(({ data }) => {
      const moreWatchEvents = get(data, 'watchHistory.items', []);
      const uniqueWatchEvents = uniqueBy(watchList.current.concat(moreWatchEvents), x => x.episode);
      dataLayerPush(`'Watch History' infinite scroll pagination loaded`);
      setWatchList({ ...watchList, current: uniqueWatchEvents });
      setExclusiveStartKey(get(data, 'watchHistory.exclusiveStartKey'));
    });
  };

  const fetchSingleEpisode = () => {
    if (!exclusiveStartKey) {
      // no next item to fetch
      return null;
    }

    return fetchMore({
      variables: {
        sort: { sortDirection: sortDirection },
        pagination: { limit: 1 },
        exclusiveStartKey: exclusiveStartKey,
      },
    }).then(({ data }) => {
      setExclusiveStartKey(get(data, 'watchHistory.exclusiveStartKey'));
      return get(data, 'watchHistory.items[0]');
    });
  };

  async function handleClickDelete(watchEvent) {
    await setShowSnackBar(false)

    //if we delete multiple episodes in rapid succession we need to delete the old one before replacing the state prop with the new one
    handleDeleteWatchEvent(toDelete);

    // set the next episode to be deleted
    setToDelete(watchEvent);
    setShowSnackBar(true)

    // remove the to be deleted watch event from the page display
    let updatedWatchList = watchList.current.filter(x => x.eventTime !== watchEvent.eventTime);

    // gets episode to replace one deleted from list
    const replacementEpisode = await fetchSingleEpisode();
    if (replacementEpisode) {
      updatedWatchList = [...updatedWatchList, replacementEpisode];
    }

    setWatchList({ previous: watchList.current, current: updatedWatchList })
  }

  function handleAllowTimeout() {
    handleDeleteWatchEvent(toDelete);
    setShowSnackBar(false);
  }

  async function handleDeleteWatchEvent(episode) {
    episode && await deleteWatchEvent({
      variables: {
        input: {
          eventTime: episode.eventTime,
        }
      }
    });

    dataLayerPush(`Episode removed from 'Watch History'`);
  }

  function handleUndoDeleteWatchEvent() {
    setWatchList({ current: watchList.previous, previous: [] })
    setToDelete(null)
  }

  if (!loading && !history.acceptedAt) {
    return <OptInAlert loading={loading} accepted={false} />
  }

  return (
      <>
        {watchList.current.length > 0 &&
          <DashboardPageHeader edit={edit}>Recently Watched Episodes</DashboardPageHeader>
        }
        {error &&
          <DashboardAlert
            message='Failed to load your watch history, please reload to try again.'
            severity='error'
          />
        }
        <InfiniteScrollContainer>
          <InfiniteScroll
            dataLength={watchList.current.length}
            next={fetchNextPage}
            hasMore={exclusiveStartKey !== null}
            loader={<h4>Loading...</h4>}
            className='MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2'
            scrollableTarget='dashboardScrollContainer'
          >
            {(loading ? Array.from(new Array(5)) : watchList.current).map((watchEvent, index) => (
              <GridWrapper key={index} item xs={12} sm={12} md={6} lg={3}>
                <EpisodeCard
                  display={isMobile ? 'listItem' : 'vertical'}
                  episode={watchEvent && watchEvent.episode}
                  loading={loading}
                  onDelete={() => handleClickDelete(watchEvent)}
                  onUndoDelete={handleUndoDeleteWatchEvent}
                  allowDelete
                  edit={edit}
                  excludedActions={[ACTION_FOLLOW_SHOW, ACTION_REMOVE_ITEM]}
                />
              </GridWrapper>
            ))}
          </InfiniteScroll>
        </InfiniteScrollContainer>
        {!loading && !error && watchList.current.length === 0 &&
          <EmptyWatchHistory />
        }
        {showSnackBar && <UndoSnackBar episode={toDelete} onAllowTimeOut={handleAllowTimeout} onUndo={handleUndoDeleteWatchEvent} />}
      </>
  );
}

export default React.memo(Episodes);
