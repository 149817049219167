import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import episodeShape from 'shapes/episodeShape';
import RichTextField from 'components/RichTextField';
import HorizontalSkeleton from './HorizontalSkeleton';
import ImageDetails from '../components/ImageDetails';
import EpisodeIcons from '../components/EpisodeIcons';
import NewContentFlag from 'components/NewContentFlag';
import EpisodeDetails from './EpisodeDetails';
import { getEpisodeExpirationDays } from 'utils/episodeHelpers';
import { useAuth } from "context/AuthContext";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getEpisodeUrl } from 'utils/linkUtils';
import PlaylistIndexIndicator from 'components/EpisodeCard/components/PlaylistIndexIndicator';
import playlistShape from 'shapes/playlistShape';
import dailyReplayShape from 'shapes/dailyReplayShape';
import scheduleItemShape from 'shapes/scheduleItemShape';
import DragIcon from '../components/DragIcon';
import Checkbox from '@material-ui/core/Checkbox';

const Wrapper = styled.div`
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;

  ${ifProp('$boxShadow', css`
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-bottom: none;
  `)}

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    padding-left: 0.625rem;
    padding-right: 0;
  }

  ${ifProp({$edit: false}, css`
      &:hover {
      background-color: ${theme('colors.white')};
      border-left: 0.0625rem solid ${theme('colors.borderGray')};
      border-right: 0.0625rem solid ${theme('colors.borderGray')};
  
      * {
        visibility: visible;
      }
    }
  `)};
  
  ${ifProp({$edit: true}, css`
    cursor: pointer;
  `)};
`;

const CardCol = styled.div`
  display: flex;
  position: relative;

  &:first-child {
    flex: 1 0;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;

    ${props => props.theme.breakpoints.up('md')} {
      margin-bottom: 0;
      padding-right: 1rem;
      width: 30%;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      flex-direction: row;
      padding-right: 1rem;
      width: 40%;
    }
  }

  &:last-child {
    width: 100%;

    ${props => props.theme.breakpoints.between('sm', 'md')} {
      padding-left: 1.0625rem;
    }

    ${props => props.theme.breakpoints.up('md')} {
      justify-content: space-between;
      padding-left: 1rem;
      width: 60%;
    }

    .remove {
      ${props => props.theme.breakpoints.down('sm')} {
        display: none;
      }
    }
  }

  .new-content {
    left: -12px;
    top: 6px;
    z-index: 12;
  }
`;

const InnerCardCol = styled.div`
  display: flex;
`;

const PlayerDetailsWrapper = styled.div`
  display: flex;

  ${props => props.theme.breakpoints.between('sm', 'md')} {
    flex-direction: column;
    width: 100%;
  }
`;

const PlayerContainer = styled.div`
  margin-bottom: 0.75rem;
  width: 100%;
  position: relative;

  ${props => props.theme.breakpoints.up('lg')} {
    margin-bottom: 0;
    margin-right: 1.875rem;
    min-width: 10.25rem;
    width: 10.25rem;
  }
`;

const CheckboxWrapper = styled(Checkbox)`
  position: absolute;
  left: -3.5rem;
  bottom: 35%;
  
  svg {
    fill: ${theme('colors.black')};
    
    ${ifProp({checked: true}, css`
      fill: ${theme('colors.ttRed')};
    `)};
  }
`;

const PlayerContainerOverlay = styled.div`
  background-color: ${theme('colors.black')};
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  cursor: pointer;
`;

const Description = styled(RichTextField)`
  display: -webkit-box;
  margin: 0;
  max-height: 11rem;
  overflow: hidden;
  padding-right: 1rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;

  ${ifProp('$upcoming', css`
    color: ${theme('colors.upcomingPlaceholderText')};
  `)}

  // // Apply plain text tag styling to all descr. text for consistent truncation
  p {
    font-size: 0.875rem;
    font-weight: inherit;
    line-height: inherit;
    font-stretch: inherit;
    letter-spacing: inherit;
    //make margin same height as line height to avoid cutting off text
    margin-bottom: 20px;

    &:last-of-type {
      ${props => props.theme.breakpoints.between('sm', 'md')} {
        margin-bottom: 0;
      }
    }
  }


  ${props => props.theme.breakpoints.up('lg')} {
    max-height: 5.5rem;
    padding-right: 3rem;
    -webkit-line-clamp: 4;
  }

  p {
    margin-top: 0;
  }
`;

const EpisodeIconsWrapper = styled.div`
  visibility: hidden;
  margin-left: auto;
`;

HorizontalCard.propTypes = {
  display: PropTypes.string,
  episode: episodeShape,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  episodeBlurb: PropTypes.bool,
  onClick: PropTypes.func,
  edit: PropTypes.bool,
  excludedActions: PropTypes.array,
  isNewContent: PropTypes.bool,
  watchLaterList: PropTypes.bool,
  recommendation: PropTypes.string,
  playlist: playlistShape,
  playlistIndex: PropTypes.number,
  dailyReplay: dailyReplayShape,
  scheduleItem: scheduleItemShape,
  percentWatched: PropTypes.number,
  draggable: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  onUnfollowOverride: PropTypes.func
};

function HorizontalCard({
  display,
  episode,
  onDelete,
  allowDelete = false,
  loading = false,
  episodeBlurb = true,
  onClick,
  edit,
  excludedActions,
  isNewContent = false,
  watchLaterList,
  recommendation = '',
  playlist,
  playlistIndex,
  dailyReplay,
  scheduleItem,
  percentWatched = 0,
  draggable = false,
  checked = false,
  onCheck,
  onUnfollowOverride
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [dragHover, setDragHover] = useState(false);
  let {
    description,
    publishedAt,
    displayTitle,
    shortDescription,
    show,
  } = episode || {};
  const episodeUrl = getEpisodeUrl(episode, { playlist, dailyReplay });
  const expirationDate = getEpisodeExpirationDays(episode);
  const { user } = useAuth();

  if (!episode && !scheduleItem) {
    return null;
  }

  if (loading) {
    return (
      <HorizontalSkeleton />
    );
  }

  const isUpcomingPlaceholder = !episode && !!scheduleItem;

  // If this is upcoming episode, populate some of the variables from the scheduleItem.
  if (isUpcomingPlaceholder) {
    show = scheduleItem.show;
    publishedAt = scheduleItem.startDateTimeUtc;
    description = 'This video isn\'t ready yet. Check back in a bit. :)';
    displayTitle = <>&mdash;</>;
  }

  return (
    <Wrapper $boxShadow={dragHover} $edit={edit} onClick={() => edit && onCheck()}>
      <CardCol>
        {edit
          ?
            <CheckboxWrapper
              checked={checked}
              onChange={onCheck}
            />
          : draggable &&
          <DragIcon editMode={edit} onMouseEnter={() => setDragHover(true)} onMouseLeave={() => setDragHover(false)} />
        }
        {isNewContent &&
          <NewContentFlag height={1.625} text='New' type='short' width={2.5} />
        }
        <InnerCardCol>
          {(playlist || dailyReplay) && playlistIndex && (
            <PlaylistIndexIndicator>
              {playlistIndex}
            </PlaylistIndexIndicator>
          )}
          <PlayerDetailsWrapper>
            <PlayerContainer>
              {edit && <PlayerContainerOverlay onClick={onCheck} />}
              <ImageDetails
                display={display}
                expirationDate={expirationDate}
                onClick={onClick}
                episode={episode}
                recommendation={recommendation}
                watchLaterList={watchLaterList}
                linkUrlOverride={episodeUrl}
                upcomingPlaceholder={isUpcomingPlaceholder}
                percentWatched={percentWatched}
                edit={edit}
              />
            </PlayerContainer>
            <EpisodeDetails
              episodeUrl={episodeUrl}
              displayTitle={displayTitle}
              publishedAt={publishedAt}
              recommendation={recommendation}
              show={show}
              upcomingPlaceholder={isUpcomingPlaceholder}
              edit={edit}
            />
          </PlayerDetailsWrapper>
        </InnerCardCol>
      </CardCol>
      <CardCol>
        {!isMobile &&
          <Description richText={shortDescription || description || ''} $upcoming={isUpcomingPlaceholder} />
        }
        {!!user && episode &&
          <EpisodeIconsWrapper>
            <EpisodeIcons
              episode={episode}
              display={display}
              allowDelete={true}
              onDelete={onDelete}
              excludedActions={excludedActions}
              onUnfollowOverride={onUnfollowOverride}
            />
          </EpisodeIconsWrapper>
        }
      </CardCol>
    </Wrapper>
  );
}

export default React.memo(HorizontalCard);
