import React from 'react';
import customIconListShape from 'shapes/customIconListShape';
import { iconReference } from './const';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'theme';
import StandardBlockHeader from 'components/StandardBlockHeader';

const IconList = styled.ul`
  list-style-type: none;
  column-count: ${prop('$columnCount')};
  padding-inline-start: 0;
`;

const IconListItem = styled.li`
  display: flex;
  padding-bottom: 0.75rem;

  & svg {
    width: 1.25rem;
    color: ${prop('theme.colors.ttRed')};
  }
`

const IconLabel = styled.span`
  font-weight: 600;
  margin-left: 0.5rem;
`;

CustomIconList.propTypes = {
  data: customIconListShape
};

function CustomIconList({ data }) {
  const { header, subheader, columnCount, listItems } = data[0];

  if (!listItems) {
    return null;
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const colCount = isMobile && columnCount > 2 ? 2 : columnCount;

  return (
    <>
      <StandardBlockHeader header={header} subheader={subheader} />
      <IconList $columnCount={colCount}>
        {listItems.map((item, index) => {
          const Icon = iconReference[item.icon];

          return (
            <IconListItem key={`icon-list-item-${index}`}>
              <Icon />
              <IconLabel>{item.label}</IconLabel>
            </IconListItem>
          )
        })}
      </IconList>
    </>
  )
}

export default CustomIconList;
