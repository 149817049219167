export const SHARE_CAMPAIGNS = {
  ios: 'tt-ios',
  android: 'tt-android',
  dailyRecap: 'daily_recap',
  archive: 'archive',
  learn: 'learn',
  home: 'home',
};

export const SHARE_MEDIUMS = {
  googlePlus: 'google-plus',
  sms: 'sms',
  link: 'link',
  email: 'email',
  twitter: 'twitter',
  facebook: 'facebook'
};

export const SHARE_SOURCES = {
  socialShare: 'social-share',
};
