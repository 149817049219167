import React from 'react';
import BaseButton from '@material-ui/core/Button';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const DashboardButton = styled(BaseButton)`
  color: ${theme('palette.common.white')};
  background-color: ${theme('colors.ttRed')};
  border: none;
  height: 2rem;

  &:hover {
    background-color: ${theme('colors.ttMuted')};
    border: none;
  }
  
`;

export default DashboardButton;