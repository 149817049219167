import React from 'react';
import get from 'lodash/get';
import { theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import CtaButtons from 'components/CtaButtons';
import eventShape from 'shapes/eventShape';
import { autoOptimizeImage } from 'utils/functions';
import RichTextField from 'components/RichTextField';
import { ButtonContainer, Container, GridContainer } from 'components/Events/events.styles'

const DateDetails = styled(Typography)`
  font-weight: 400;
  margin: 0;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 1rem;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.custom.deskLg}) {
      font-size: 2rem;
    }
  `}
`;

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  font-size: 1rem;
  margin-right: 0.5rem;
`;

const SponsorLogo = styled.img`
  display: flex;
  flex-direction: column;
  width: 7rem;
  margin-right: 1rem;
  margin-top: 1rem;
`;

const RichDescription = styled(RichTextField)`
  margin-bottom: 1rem;
  margin-top: 0;

  p {
    margin-top: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EventPresenter = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  text-transform: uppercase;
  font-size: 0.8rem;
`;

const propTypes = {
  eventItem: eventShape,
  overrideButtonStyle: PropTypes.string,
  overrideButtonJustify: PropTypes.string,
  useLatestEvent: PropTypes.bool,
};

function EventContent({
  eventItem,
  overrideButtonStyle,
  overrideButtonJustify,
  useLatestEvent,
}) {
  const {
    buttonCta,
    eventDateDetails,
    eventPresenter,
    eventDescription,
    eventSponsorLogo,
    title,
    eventTime,
  } = eventItem;
  const eventButtons = buttonCta && get(buttonCta[0], 'buttonCta');
  const allEventsButton = buttonCta && get(buttonCta[1], 'buttonCta');

  return (
    <Container maxWidth='lg'>
      <GridContainer container>
        <Grid item>
          <EventDetails>
            {title &&
              <Title>{title}</Title>
            }
            {((useLatestEvent && eventDateDetails) || (!useLatestEvent && eventTime)) &&
              <TimeContainer>
                <StyledAccessTimeIcon />
                <DateDetails>{useLatestEvent ? eventDateDetails : eventTime}</DateDetails>
              </TimeContainer>
            }
            {eventDescription && !useLatestEvent ?
              <RichDescription richText={eventDescription} /> :
              <Typography>{eventDescription}</Typography>
            }
            {eventPresenter && 
              <EventPresenter>{eventPresenter}</EventPresenter>
            }
            {eventSponsorLogo && eventSponsorLogo.length > 0 && (
              <LogoContainer>
                {eventSponsorLogo.map((sponsor, index) => (
                  sponsor.logoImage && (
                    <SponsorLogo
                      key={index}
                      src={autoOptimizeImage(sponsor.logoImage?.url)}
                      alt={sponsor.logoImageAltText || ''}
                    />
                  )
                ))}
              </LogoContainer>
            )}
            {eventButtons &&
              <ButtonContainer>
                <CtaButtons items={eventButtons} overrideStyle={overrideButtonStyle} />
                {allEventsButton && (
                  <CtaButtons
                    items={allEventsButton}
                    icon={allEventsButton[0].buttonIcon}
                    overrideJustify={overrideButtonJustify}
                  />
                )}
              </ButtonContainer>
            }
          </EventDetails>
        </Grid>
      </GridContainer>
    </Container>
  );
}

EventContent.propTypes = propTypes;

export default React.memo(EventContent);
