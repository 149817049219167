import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, switchProp, theme } from 'styled-tools';
import Typography from '@material-ui/core/Typography';
import BaseIcon from 'components/Icon';

const LONG = 'long';
const SHORT = 'short';

const FlagContainer = styled.div`
  position: absolute;
  z-index: 11;
`;

const Icon = styled(BaseIcon)`
  height: ${prop('height')}rem;
  width: ${prop('width')}rem;
  margin-top: -.2rem;
`;

const FlagText = styled(Typography)`
  color: ${theme('colors.white')};
  font-family: ${theme('typography.fontFamilySecondary')};
  line-height: normal;
  position: absolute;

  ${switchProp('type', {
    long: css`
      left: 39px;
      top: 5px;
    `,
    short: css`
      font-size: 0.625rem;
      font-weight: 700;
      left: 8px;
      text-transform: uppercase;
      top: 4px;
    `
  })}
`;

NewContentFlag.propTypes = {
  height: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
};

function NewContentFlag({ height, text, type, width }) {
  return (
    <FlagContainer className='new-content'>
      {type === LONG &&
        <Icon icon='alertTagLong' height={height} width={width} />
      }
      {type === SHORT &&
        <Icon icon='alertTagShort' height={height} width={width} />
      }
      <FlagText type={type} variant='h6'>{text}</FlagText>
    </FlagContainer>
  );
}

export default NewContentFlag;
