import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { theme } from 'styled-tools';

const MegaMenuDate = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export default MegaMenuDate;