
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccoladesBlockFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccoladesBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"headerImage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subheader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"accolades"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sourceLogo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"sourceLogoAltText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"columnCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"listItems"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":290}};
    doc.loc.source = {"body":"#import \"../AssetFields.graphql\"\n\nfragment AccoladesBlockFields on AccoladesBlock {\n  headerImage {\n    ...AssetFields\n  }\n  header\n  subheader\n  accolades {\n    sourceLogo {\n      ...AssetFields\n    }\n    sourceLogoAltText\n    title\n    columnCount\n    listItems {\n      label\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));


      module.exports = doc;
    
