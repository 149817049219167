import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CopyContainer from './CopyContainer';
import ExpandImageContainer from './ExpandImageContainer';
import singleContentCardShape from 'shapes/singleContentCardShape';
import Modal from 'components/Modal';

CardModal.propTypes = {
  data: singleContentCardShape,
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.number,
  width: PropTypes.number,
};

function CardModal({ data, modalOpen, onClose, size, width }) {
  const { altImageText, cardImage, hoverAnimation } = data;

  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      width={400}
      padding='none'
      contentType='content-cards'
    >
      <ExpandImageContainer
        altImageText={altImageText}
        cardImage={cardImage}
        size={size}
        expand={true}
      />
      <CopyContainer
        data={data}
        size={size}
        hoverAnimation={hoverAnimation}
      />
    </Modal>
  );
}

export default CardModal;
