
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MegaMenuFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MegaMenu"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"menuItems"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"menuColumns"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"columnWidth"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cssClassName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"submenu"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuFeaturedFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"submenu"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]}]}}]}}]}}]}}]}}],"loc":{"start":0,"end":352}};
    doc.loc.source = {"body":"#import \"./MenuItemFields.graphql\"\n#import \"./MenuFeaturedFields.graphql\"\n\nfragment MegaMenuFields on MegaMenu {\n  menuItems {\n    ...MenuItemFields\n    menuColumns {\n      columnWidth\n      cssClassName\n      submenu {\n        ...MenuItemFields\n        ...MenuFeaturedFields\n        submenu {\n          ...MenuItemFields\n        }\n      }\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./MenuItemFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./MenuFeaturedFields.graphql").definitions));


      module.exports = doc;
    
