import './utils/pip-polyfill';
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { ensureReady, After } from '@jaredpalmer/after';
import routes from './routes';
import App from './App';

/**
 * Here you see I moved everything to app.js from here, and wrap the After SSR call in that component.
 */

function Main({ data }) {
  return (
    <App>
      <After data={data} routes={routes}/>
    </App>
  );
}

Promise.all([ensureReady(routes), loadableReady()]).then(([data]) => {
  return hydrate(<Main data={data} />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
