import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import episodeShape from 'shapes/episodeShape';
import { useTheme } from '@material-ui/core/styles';
import ContentList from '../ContentList';
import EmptyShows from './Empty';

ShowsList.propTypes = {
  episodes: episodeShape.isRequired,
  fetchNextPage: PropTypes.func,
  hasMore: PropTypes.bool,
  excludedActions: PropTypes.array,
  lastViewedDate: PropTypes.object,
  onUndoUnfollow: PropTypes.func
};

function ShowsList({ episodes, fetchNextPage, hasMore, excludedActions, lastViewedDate, onUndoUnfollow }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <ContentList
        items={episodes}
        display={isMobile ? 'listItem' : 'vertical'}
        fetchNextPage={fetchNextPage}
        hasMore={hasMore}
        deletedMessage={(show) => `No longer following "${show.title}"`}
        excludedActions={excludedActions}
        lastViewedDate={lastViewedDate}
        onUndoRemoveEpisodes={onUndoUnfollow}
        onUnfollowOverride={true}
      />
      {episodes.length === 0 &&
        <EmptyShows />
      }
    </>
  );
}

export default React.memo(ShowsList);
