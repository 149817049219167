import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import { Header } from 'components/ModularBlocks/CalloutBlock';
import RichTextField from 'components/RichTextField';
import calloutShape from 'shapes/calloutShape';
import CalloutTable from './CalloutTable';
import { autoOptimizeImage, createHeaderId } from 'utils/functions';

const CalloutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  overflow: hidden;
`;

export const HeaderBar = styled.div`
  background: #333333;
  padding: 0.9375rem 1.875rem;
`;

// callout boxes within RichTextField
export const CalloutContent = styled.div`
  background: ${theme('colors.white')};
  display: flex;
  height: 100%;

  h3,
  .h3 {
    font-size: 1rem;
    text-transform: uppercase;
  }

  h5,
  .h5 {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  ${ifProp(
    { $columns: 'connected' },
    css`
      ${(props) => props.theme.breakpoints.down('md')} {
        flex-direction: column;
      }
    `
  )}
`;

const Image = styled.img`
  height: 100%;

  ${switchProp(
    '$objectFit',
    {
      cover: css`
        object-fit: cover;
      `,
      contain: css`
        object-fit: contain;
      `,
    },
    css`
      object-fit: contain;
    `
  )}
`;

const Body = styled(RichTextField)`
  font-weight: 400;
  padding: 1rem 2rem;
  width: 100%;

  li {
    font-size: 1rem;
  }

  .video-wrapper {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    // using !important here instead of asking team to always remember to remove width and height attributes from a youtube embed
    iframe {
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }
`;

Callout.propTypes = {
  callout: calloutShape,
  columnsType: PropTypes.string,
};

function Callout({ callout, columnsType, blockHeader }) {
  const { body, header, headerType, image, rows } = callout;
  const rowCount = rows?.length;
  const imageAsset = image?.imageAsset;

  if (!body && !imageAsset && rowCount === 0) {
    return null;
  }

  const setSubheaderId = (blockHeader) => {
    const { displayOnToc, tocHeader } = blockHeader;

    if (displayOnToc && tocHeader) {
      return createHeaderId(tocHeader);
    }

    return null;
  };

  return (
    <CalloutContainer $columns={columnsType} id={setSubheaderId(callout) || setSubheaderId(blockHeader)} className='callout'>
      {header && (
        <HeaderBar id={setSubheaderId(callout)}>
          <Header headerType={headerType ?? 'H2'} $type='callout'>
            {header}
          </Header>
        </HeaderBar>
      )}
      <CalloutContent $columns={columnsType}>
        {body && <Body richText={body} />}
        {imageAsset && (
          <Image
            alt={image?.altImageText ? image.altImageText : 'uploaded image'}
            $objectFit={image.objectFit}
            src={autoOptimizeImage(imageAsset?.url)}
          />
        )}
        {rowCount > 0 && <CalloutTable rowCount={rowCount} rows={rows} />}
      </CalloutContent>
    </CalloutContainer>
  );
}

export default Callout;
