import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import useTheme from '@material-ui/core/styles/useTheme';
import RemoveItem from 'components/CardButtons/RemoveItem';
import DateFormatter from 'components/DateFormatter';
import RichTextField from 'components/RichTextField';
import episodeShape from 'shapes/episodeShape';
import Slideshow from './components/Slideshow';
import ModalWithTrigger from 'components/ModalWithTrigger';
import Icon from 'components/Icon';
import { dataLayerPush } from 'utils/dataLayer';
import { getSlideCoverImage } from 'utils/episodeHelpers';
import Checkbox from '@material-ui/core/Checkbox';
import CheckboxFloating from '../CheckboxFloating';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  ${ifProp({$edit: true}, css`
    cursor: pointer;
  `)};
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
`;

const CheckboxWrapper = styled(Checkbox)`
  svg {
    fill: white;
    
    ${ifProp({checked: true}, css`
      fill: ${theme('colors.ttRed')};
    `)};
  }
`;

const WhiteBackground = styled.div`
  position: absolute;
  background-color: ${theme('colors.white')};
  top: 0.8rem;
  left: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
`;

const EditWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${theme('palette.common.white')};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  padding-left: 0.25rem;
  justify-content: center;
  opacity: 0;
  transition-duration: 500ms;
  
  ${ifProp('$edit', css`
    opacity: 1;
    color: ${theme('palette.common.white')};
    cursor: pointer;
  `)}
`

const ModalWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: ${theme('palette.common.white')};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  padding-left: 0.25rem;
  justify-content: center;
  opacity: 0;
  transition-duration: 500ms;
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 400;

  ${ifProp({'edit': false}, css`
     &:hover, &:focus-within {
        opacity: 1;
        color: ${theme('palette.common.white')};
        cursor: pointer;
      }
  `)}
 
  button,
  div {
    height: 100%;
    width: 100%;
  }

  button {
    justify-content: center;
  }
`;

const DetailsWrapper = styled.div`
  padding: 1rem  0;
  position: relative;
`;

const EpisodeTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin: 0.25rem 0;
`;

const ShowTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
`;

const Description = styled(RichTextField)`
  font-weight: normal;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  font-size: 0.8rem;

  p {
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0;
  }
`;

const Date = styled.div`
  align-self: flex-end;
  font-size: 0.8rem;
  font-weight: 400;
`;

const RemoveItemContainer = styled.div`
  display: inline-flex;
  float: right;
  position: absolute;
  top: 0.25rem;
  right: 0;
  visibility: hidden;

  ${Wrapper}:hover & {
    visibility: visible;
  }

  .MuiIconButton-label {
    font-size: 1rem;
  }

  ${props => props.theme.breakpoints.down('md')} {
    visibility: visible;
  }
`;

SlidesCard.propTypes = {
  episode: episodeShape,
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
};

function SlidesCard({
  loading = false,
  edit = false,
  episode, onDelete,
  onCheck,
  checked = false,
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  if (loading) {
    return (
      <Box>
        <Skeleton variant='rect' height={125} />
        <Skeleton height={12} width='30%' />
        <Skeleton height={13} width='60%' />
        <Skeleton height={14} />
        <Skeleton height={14} />
        <Skeleton height={14} />
      </Box>
    );
  }

  if (!episode) {
    return null;
  }

  const {
    displayTitle,
    publishedAt,
    shortDescription,
    show,
    pdfSlides
  } = episode;
  const showTitle = get(show, 'title');
  const slideCoverImage = getSlideCoverImage(episode);

  const handleOpen = () => {
    if (!edit) {
      setOpen(true);
      dataLayerPush('Opened slides modal from slides card');
    }
  };

  const handleClose = () => {
    setOpen(false);
    dataLayerPush('Closed slides modal');
  };

  return (
    <Wrapper $edit={edit} onClick={() => edit && onCheck()}>
      <ImageWrapper>
        {edit &&
          <CheckboxFloating
            checked={checked}
            onCheck={onCheck}
          />
        }
        <EditWrapper $edit={edit} onClick={() => edit && onCheck()}>
          <ModalWrapper $edit={edit}>
            <ModalWithTrigger
              ariaLabel='View Slides'
              ariaDescribe='Slideshow related to the episode'
              contentType='slides'
              handleClose={handleClose}
              handleOpen={handleOpen}
              hideClose={true}
              height={776}
              width={1000}
              open={open}
              padding='none'
              triggerType='link'
              icon={<Icon color={theme.palette.common.white} icon='popOut' size={3.5} />}
            >
              <Slideshow episode={episode} onClose={handleClose} slideDecks={pdfSlides} onDelete={onDelete}/>
            </ModalWithTrigger>
          </ModalWrapper>
        </EditWrapper>
        <img alt={displayTitle} src={slideCoverImage} />
      </ImageWrapper>
      <DetailsWrapper>
        {showTitle && <ShowTitle>{showTitle}</ShowTitle>}
        {displayTitle && <EpisodeTitle>{displayTitle}</EpisodeTitle>}
        <Date>
          <DateFormatter date={publishedAt} format='MMM DD, YYYY' />
        </Date>
        {shortDescription && <Description richText={shortDescription} /> }
        {onDelete &&
          <RemoveItemContainer>
            <RemoveItem episode={episode} onDelete={onDelete} />
          </RemoveItemContainer>
        }
      </DetailsWrapper>
    </Wrapper>
  );
}

export default SlidesCard;