import React from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from 'components/SimpleDialog';
import { useDashboardContext } from 'context/DashboardContext';
import SortOptions from 'constants/SortOptions';
import IconTitle from 'components/IconTitle';
import SortIcon from '@material-ui/icons/Sort';
import Slide from '@material-ui/core/Slide';

const DASHBOARD_SORT_OPTIONS = [
  { value: SortOptions.newest, label: 'Newest' },
  { value: SortOptions.oldest, label: 'Oldest' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

MobileSortDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function MobileSortDialog({ open, onClose }) {
  const { state: { sortBy }, setSortBy } = useDashboardContext();

  const handleClose = (value) => {
    onClose && onClose(value);
    setSortBy(value);
  };

  return (
    <SimpleDialog
      title={
        <IconTitle>
          <SortIcon /> <span>Sort</span>
        </IconTitle>
      }
      options={DASHBOARD_SORT_OPTIONS}
      selectedValue={sortBy}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      mobileSort
      disableTitlePadding
    />
  );
}

export default React.memo(MobileSortDialog);
