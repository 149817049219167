import React from 'react';
import { ifProp } from 'styled-tools'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography';
import { useAuth } from 'context/AuthContext';
import { useQuery } from '@apollo/client';
import getLuckboxSubscriptions from './getLuckboxSubscriptions.graphql';
import GetLuckbox from './GetLuckbox';
import LuckboxDetails from './LuckboxDetails';

const LuckboxContainer = styled.div`
  ${ifProp('logout', css`
    padding-bottom: 4.5rem;
  `)}
`;

function LuckboxSubscription() {
  const { isAuthenticated } = useAuth();
  const { data, loading } = useQuery(getLuckboxSubscriptions, {
    skip: typeof window === 'undefined',
  });
  const hasSubscription = data?.luckboxSubscriptions.hasDigitalSubscription || data?.luckboxSubscriptions.hasPrintSubscription;
  const hasLoggedIn = isAuthenticated();
  const doesNotHaveSubscription = !hasLoggedIn || !hasSubscription;

  return (
    <LuckboxContainer logout={doesNotHaveSubscription} >
      {loading ? (
        <Typography>Loading your subscription information...</Typography>
      ) : (
        <>
          {doesNotHaveSubscription && (
            <GetLuckbox luckboxOptions={data?.luckboxOptions} />
          )}
          {hasSubscription && (
            <LuckboxDetails luckboxData={data} />
          )}
        </>
      )}
    </LuckboxContainer>
  );
}

export default React.memo(LuckboxSubscription);
