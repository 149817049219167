import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ButtonPrimary from 'components/ButtonPrimary';

const DashboardAlertGrid = styled(Grid)`
  height: calc(100vh - 175px);
`;

const IconWrapper = styled.div`
  svg {
    font-size: 8.25rem;
  }

  ${props => props.theme.breakpoints.down('md')} {
    svg {
      font-size: 6rem;
    }
  }
`

const TitleWrapper = styled.h1`
  font-size: 2.5rem;

  ${props => props.theme.breakpoints.down('md')} {
    font-size: 2rem;
  }
`;

const TextWrapper = styled.span`
  font-size: 1.25rem;

  ${props => props.theme.breakpoints.down('md')} {
    font-size: 0.875;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 3rem;
`;

EmptyPage.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

function EmptyPage({ icon, title, text, buttonText, onClick }) {

  return (
    <DashboardAlertGrid
      container
      alignItems="center"
      justify="center"
    >
      <Grid item xs={12} md={8} align='center'>
        <IconWrapper>
          {icon}
        </IconWrapper>
        <TitleWrapper>{title}</TitleWrapper>
        <TextWrapper>{text}</TextWrapper>
        <ButtonContainer>
          <ButtonPrimary onClick={onClick}>
            {buttonText}
          </ButtonPrimary>
        </ButtonContainer>
      </Grid>
    </DashboardAlertGrid>
  );
};

export default React.memo(EmptyPage);
