import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTourContext } from 'context/TourContext';
import { REPLAY_TOUR_STEPS } from 'constants/replayTourSteps';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

PrevButton.propTypes = {};

function PrevButton() {
  const { state: { currentStep, startAt }, disableTour, updateCurrentStep, enableTour } = useTourContext();
  const { stepOne, stepThree, stepFour, stepFive } = REPLAY_TOUR_STEPS;
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const goToHomepage = () => {
    if (currentStep === stepFour && isDesktop) {
      disableTour();
      updateCurrentStep(stepThree);
      history.push('/');
    }

    if (currentStep === stepFive && !isDesktop) {
      disableTour();
      updateCurrentStep(stepFour);
      history.push('/');
    }
  }

  return (
    <>
     {(currentStep === stepOne || currentStep === null) && startAt !== stepFour ? (
       <></>
     ) : (
        <ArrowBackIcon onClick={goToHomepage} />
     )}
    </>
  );
}

export default PrevButton;
