import React, { useReducer, useContext, useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import getSettingsQuery from '../../graphql/queries/getSettings.graphql';
import { useAuth } from '../AuthContext';
import config from 'config'
import { useMutation } from '@apollo/client';
import updateSettingsMutation from '../../graphql/mutations/updateSettings.graphql';

function getDefaultState() {
  const DEFAULT_STATE = {
    volume: '1',
    bitrate: null,
    speed: '1',
    autoPlay: false,
    playsinline: false,
    muted: false,
    videoDuration: null,
    coverClicked: false
  };

  let storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_SET_VOLUME = 'setVolume';
const ACTION_SET_BITRATE = 'setBitrate';
const ACTION_SET_SPEED = 'setSpeed';
const ACTION_SET_AUTO_PLAY = 'setAutoPlay';
const ACTION_SET_MUTED = 'setMuted';
const ACTION_SET_SETTINGS = 'setSettings';
const ACTION_SET_VIDEO_DURATION = 'setVideoDuration';
const ACTION_SET_PLAYSINLINE = 'setPlaysinline';
const ACTION_SET_COVER_CLICK = 'setCoverClick';

function videoPlayerReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_VOLUME:
      return {
        ...state,
        volume: action.payload ?? state.volume,
      };
    case ACTION_SET_BITRATE:
      return {
        ...state,
        bitrate: action.payload ?? state.bitrate,
      };
    case ACTION_SET_SPEED:
      return {
        ...state,
        speed: action.payload ?? state.speed,
      };
    case ACTION_SET_AUTO_PLAY:
      return {
        ...state,
        autoPlay: action.payload ?? state.autoPlay,
      };
    case ACTION_SET_MUTED:
      return {
        ...state,
        muted: action.payload ?? state.muted,
      }
    case ACTION_SET_VIDEO_DURATION:
      return {
        ...state,
        videoDuration: action.payload ?? state.videoDuration,
      }
    case ACTION_SET_PLAYSINLINE:
      return {
        ...state,
        playsinline: action.payload ?? state.playsinline,
      }
    case ACTION_SET_COVER_CLICK:
      return {
        ...state,
        coverClicked: action.payload ?? state.coverClicked,
      }
    case ACTION_SET_SETTINGS:
      const { volume, bitrate, speed, autoPlay, muted, playsinline, coverClicked } = action.payload;

      return {
        ...state,
        volume: volume ?? state.volume,
        bitrate: bitrate ? bitrate : state.bitrate,
        speed: speed ?? state.speed,
        playsinline: playsinline ? playsinline === 'true' : state.playsinline,
        autoPlay: autoPlay ? autoPlay === 'true' : state.autoPlay,
        muted: muted ? muted === 'true' : state.muted,
        coverClicked: coverClicked ?? state.coverClicked,
      }
    default:
      return state;
  }
}

const VideoPlayerContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  setVolume: (volume) => {},
  setBitrate: (bitrate) => {},
  setSpeed: (speed) => {},
  setAutoPlay: (autoPlay) => {},
  setMuted: (muted) => {},
  setSettings: (settings) => {},
  setVideoDuration: (videoDuration) => {},
  setPlaysinline: (playsinline) => {},
  setCoverClicked: (coverClicked) => {},
  storeSettings: () => {}
});

export const VideoPlayerProvider = ({ children }) => {
  const { user } = useAuth();
  const [updateSettings] = useMutation(updateSettingsMutation);

  const { data: videoPlayerSettings, loading } = useQuery(getSettingsQuery, {
    variables: {
      settingGroup: 'video'
    },
    skip: !user || typeof window === 'undefined'
  })

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const [state, dispatch] = useReducer(
    videoPlayerReducer,
    getDefaultState()
  );
  const stateRef = useRef(state);
  stateRef.current = state;

  useEffect(() => {
    if (!user && typeof localStorage !== "undefined") {
      const videoSettings = localStorage.getItem(config.localStorageKeys.videoPlayerSettings);
      const { volume, bitrate, speed, autoPlay, muted } = JSON.parse(videoSettings || '{}');
      const { coverClicked } = JSON.parse(sessionStorage.getItem(config.localStorageKeys.videoPlayerSettings) || '{}')

      dispatch({ type: ACTION_SET_SETTINGS, payload: { volume, bitrate, speed, autoPlay, muted, coverClicked } });
    }
  }, [user]);

  useEffect(() => {
    const volume = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'volume');
    const bitrate = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'bitrate');
    const speed = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'speed');
    const autoPlay = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'autoPlay');
    const muted = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'muted');
    const playsinline = videoPlayerSettings?.getSettings?.find(setting => setting.settingName === 'playsinline');

    dispatch({ type: ACTION_SET_SETTINGS, payload: {
        volume: volume?.settingValue,
        bitrate: bitrate?.settingValue,
        speed: speed?.settingValue,
        autoPlay: autoPlay?.settingValue,
        muted: muted?.settingValue,
        playsinlne: playsinline?.settingValue,
      }
    });
  }, [videoPlayerSettings]);

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    setVolume: (volume) => dispatch({ type: ACTION_SET_VOLUME, payload: volume }),
    setBitrate: (bitrate) => dispatch({ type: ACTION_SET_BITRATE, payload: bitrate }),
    setSpeed: (speed) => dispatch({ type: ACTION_SET_SPEED, payload: speed }),
    setAutoPlay: (autoPlay) => dispatch({ type: ACTION_SET_AUTO_PLAY, payload: autoPlay }),
    setMuted: (muted) => dispatch({ type: ACTION_SET_MUTED, payload: muted }),
    setVideoDuration: (videoDuration) => dispatch({ type: ACTION_SET_VIDEO_DURATION, payload: videoDuration }),
    setPlaysinline: (playsinline) => dispatch({ type: ACTION_SET_PLAYSINLINE, payload: playsinline }),
    setCoverClicked: (coverClicked) => dispatch({ type: ACTION_SET_COVER_CLICK, payload: coverClicked}),
    storeSettings: () => storeSettings()
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state
    }));

    storeSettings();
  }, [state]);

  function storeSettings() {
    if (user) {
      storeSettingsInDatabase();
    }

    storeSettingsLocally();
  }

  async function storeSettingsInDatabase() {
    if (!loadingRef.current) {
      storeSettingsLocally();

      await updateSettings({
        variables: {
          input: [
            {
              settingGroup: 'video',
              settingName: 'volume',
              settingValue: `${stateRef?.current?.volume}`
            },
            {
              settingGroup: 'video',
              settingName: 'bitrate',
              settingValue: `${stateRef?.current?.bitrate?.toString()}`
            },
            {
              settingGroup: 'video',
              settingName: 'speed',
              settingValue: `${stateRef?.current?.speed}`
            },
            {
              settingGroup: 'video',
              settingName: 'autoPlay',
              settingValue: `${stateRef?.current?.autoPlay?.toString()}`
            },
            {
              settingGroup: 'video',
              settingName: 'muted',
              settingValue: `${stateRef?.current?.muted?.toString()}`
            },
          ]
        }
      })
    }
  }

  function storeSettingsLocally () {
    if (typeof localStorage !== undefined) {
      localStorage.setItem(
        config.localStorageKeys.videoPlayerSettings,
        JSON.stringify({
          volume: stateRef.current.volume,
          bitrate: stateRef.current.bitrate,
          speed: stateRef.current.speed,
          autoPlay: stateRef.current.autoPlay,
          muted: stateRef.current.muted,
          playsinline: stateRef.current.playsinline,
        })
      );
      sessionStorage.setItem(
        config.localStorageKeys.videoPlayerSettings,
        JSON.stringify({
          coverClicked: stateRef.current.coverClicked,
        })
      );
    }
  }

  return (
    <VideoPlayerContext.Provider value={contextValue}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerContext = () => useContext(VideoPlayerContext);
