import React from 'react';
import PropTypes from 'prop-types';
import menuItemShape from 'shapes/menuItemShape';
import Button from 'components/Button';

UserSignUp.propTypes = {
  menuItem: menuItemShape.isRequired,
  component: PropTypes.elementType,
};

function UserSignUp({ menuItem, component }) {
  const linkText = menuItem.linkText || 'Sign Up';

  const CustomComponent = component || Button;

  return (
    <CustomComponent href='/api/signup'>{linkText}</CustomComponent>
  );
}

export default React.memo(UserSignUp);
