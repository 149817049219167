
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VideoSourceFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VideoSource"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bcVideoFile"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"livePlayer"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnailImage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"videoLink"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"href"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":185}};
    doc.loc.source = {"body":"#import \"./AssetFields.graphql\"\n\nfragment VideoSourceFields on VideoSource {\n  bcVideoFile\n  livePlayer\n  thumbnailImage {\n    ...AssetFields\n  }\n  videoLink {\n    href\n    title\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./AssetFields.graphql").definitions));


      module.exports = doc;
    
