import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash.get';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { useDashboardContext } from 'context/DashboardContext';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import IconList from 'components/AccordionGroup/IconList';
import BaseIconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import myPlaylistsQuery from '../../../../graphql/queries/myPlaylists.graphql';
import deletePlaylistMutation from './deletePlaylist.graphql';
import UndoSnackBar from 'components/UndoSnackBar';
import updatePlaylistMutation from './updatePlaylistsOrder.graphql';
import sortBy from 'lodash.sortby'
import { dataLayerPush } from 'utils/dataLayer';

const IconButton = styled(BaseIconButton)`
  margin-right: -0.25rem;
  margin-top: -1px;

  &:hover {
    ${props => props.theme.breakpoints.up('sm')} {
      color: ${prop('theme.colors.ttRed')};
      background-color: transparent;
    }
  }
`;

const DragIndicatorIconWrapper = styled(DragIndicatorIcon)`
  margin-top: -1px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    margin-left: -5px;
  }
`;

function PlaylistsSidebar() {
  const [customPlaylists, setCustomPlaylists] = useState([]);
  const [toDelete, setToDelete] = useState(null);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [deletePlaylist] = useMutation(deletePlaylistMutation);
  const [updatePlaylist] = useMutation(updatePlaylistMutation)
  const { state: dashboardState, setCurrentPlaylist, setWatchLaterPlaylist, refetchPlaylists } = useDashboardContext();
  const { currentPlaylist, playlistsData } = dashboardState;
  const { loading, playlists, error } = playlistsData;
  let watchLaterPlaylist = playlists?.find(playlist => playlist.isDefault);

  const iconListValues = customPlaylists.map(playlist => ({
      title: playlist.name,
      icon: <PlaylistPlayIcon />,
      value: playlist,
      active: currentPlaylist && currentPlaylist.id === playlist.id,
      sortable: true
    }));

  // if the watch later playlist exists, push it to the top of the list. this should not be sortable.
  if (watchLaterPlaylist) {
    iconListValues.unshift(
      {
        title: 'Watch Later',
        icon: <WatchLaterIcon fontSize='small' />,
        value: watchLaterPlaylist,
        active: !currentPlaylist || (watchLaterPlaylist?.id === currentPlaylist.id),
        sortable: false
      }
    )
  }

  useEffect(() => {
    refetchPlaylists();
  }, [])

  // Default to the Watch Later playlist if no playlist is set.
  useEffect(() => {
    setCustomPlaylists(playlists?.filter(playlist => !playlist.isDefault));

    if (!currentPlaylist && watchLaterPlaylist) {
      setCurrentPlaylist(watchLaterPlaylist);
    }

    if (watchLaterPlaylist) {
      setWatchLaterPlaylist(watchLaterPlaylist);
    }

    return () => handleDeletePlaylist(toDeleteRef.current);
  }, [watchLaterPlaylist, setCurrentPlaylist, playlists]);

  const handleClickDelete = async (playlist) => {
    await setShowSnackBar(false);

    //if we delete multiple playlists in rapid succession we need to delete the old one before replacing the state prop with the new one
    await handleDeletePlaylist(toDelete);

    // set the next playlist to be deleted
    setToDelete(playlist);
    setShowSnackBar(true);

    // if the deleted playlist is the active playlist, lets reset to the watch later list or the next available playlist
    if (playlist.id === currentPlaylist.id) {
      if (watchLaterPlaylist) {
        setCurrentPlaylist(watchLaterPlaylist);
      } else {
        setCurrentPlaylist(playlists.find(savedPlaylist => savedPlaylist.id !== playlist.id) ?? null);
      }
    }

    dataLayerPush(`${playlist.name} deleted`);
    // remove the to be deleted playlist from the page display
    const updatedPlaylist = customPlaylists.filter(playlistListItem => playlistListItem !== playlist);
    setCustomPlaylists(updatedPlaylist);
  }

  const handleAllowTimeout = () => {
    handleDeletePlaylist(toDelete);
    setShowSnackBar(false);
  }

  const handleDeletePlaylist = async (playlist) => {
    if (playlist) {
      await deletePlaylist({ variables: { playlistId: playlist.id } });
    }
  }

  function handleUndoDeletePlaylist() {
    // if we undo lets add the item to be deleted back and then sort the array back into its defined sort order
    const currentCustomPlaylists = sortBy([...customPlaylists, toDelete], 'sortOrder');
    setCustomPlaylists(currentCustomPlaylists);
    setToDelete(null);
  }

  function updatePlaylistsOrder(reorderedPlaylists) {
    const newCustomPlaylists = []
    reorderedPlaylists.map((orderedPlaylist, i) => {
      const playlistItem = playlists.find(playlist => playlist.id === orderedPlaylist.value.id);
      newCustomPlaylists.push(playlistItem);
      handleUpdatePlaylist(playlistItem, i)
    });
    setCustomPlaylists(newCustomPlaylists.filter(playlist => !playlist.isDefault))
  }

  function handleUpdatePlaylist(playlist, newSortOrder) {
    const id = playlist.id;
    const name = playlist.name;
    if(!!name) { // this is because currently the default playlist (watch later) is auto generated on accounts with no name. We currently do not have the ability to change or set default playlists.
      updatePlaylist({
        variables: {
          input: {
            id: id,
            name: name,
            sortOrder: newSortOrder + 1
          }
        }
      })
    }
  }

  if (error) {
    return <Alert severity='error'>Failed to load your playlists.</Alert>
  }
  return (
    <>
      <IconList
        values={iconListValues}
        onSelect={setCurrentPlaylist}
        loading={loading}
        mobile={true}
        onUpdateOrder={(reorderedPlaylists) => updatePlaylistsOrder(reorderedPlaylists)}
        hoverComponentStart={({ item }) => {
          // Do not show reorder button for Watch Later playlist.
          if (!item.value || item.value.isDefault) {
            return null;
          }

          return (
            <IconButton
              aria-label='Reorder playlist'
              size='small'
            >
              <DragIndicatorIconWrapper fontSize='small'/>
            </IconButton>
          );
        }}
        hoverComponentEnd={({ item }) => {
          // Do not show delete button for Watch Later playlist.
          if (!item.value || item.value.isDefault) {
            return null;
          }

          return (
            <IconButton
              aria-label='Add to Playlist'
              onClick={() => handleClickDelete(item.value)}
              size='small'
            >
              <DeleteIcon fontSize='small'/>
            </IconButton>
          );
        }}
      />
      {showSnackBar && (
        <UndoSnackBar
          episode={toDelete}
          onAllowTimeOut={handleAllowTimeout}
          onUndo={handleUndoDeletePlaylist}
          message={`You removed a playlist.`}
        />
      )}
    </>
  );
}

export default React.memo(PlaylistsSidebar);
