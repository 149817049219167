import React from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CopyContainer from './CopyContainer';
import CardContainer from './CardContainer';
import ImageContainer from './ImageContainer';
import ExpandImageContainer from './ExpandImageContainer';
import { theme } from 'styled-tools';
import singleContentCardShape from 'shapes/singleContentCardShape';
import { flattenBlockStyle } from 'utils/blockStyleUtils';

const CardLink = styled.a`
  text-decoration: none;
  color: ${theme('colors.black')};
`;

ContentCard.propTypes = {
  data: singleContentCardShape,
  size: PropTypes.number,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
};

function ContentCard({ data, size, width, fullWidth }) {
  const {
    additionalStyles,
    altImageText,
    cardImage,
    hoverAnimation,
    cardLink,
  } = data;
  const { blockStyle } = additionalStyles || {};

  const {
    applyBoxShadow,
    applyBorder,
    flipOrientation,
    selectTextColor,
    backgroundColor,
    marginRight,
    marginTop,
    paddingTop,
  } = flattenBlockStyle(blockStyle);
  const cardLinkUrl = get(cardLink, 'linkUrl.href');
  return (
    <CardLink as={cardLinkUrl ? 'a' : 'div'} href={cardLinkUrl}>
      <CardContainer
        background={backgroundColor}
        border={applyBorder}
        boxShadow={applyBoxShadow}
        flip={flipOrientation}
        $hover={hoverAnimation}
        size={size}
        cardLink={cardLink}
        textColor={selectTextColor}
        marginRight={marginRight}
        marginTop={marginTop}
        paddingTop={paddingTop}
        $width={width}
      >
        {hoverAnimation === 'expand' ? (
          <ExpandImageContainer
            altImageText={altImageText}
            cardImage={cardImage}
            expand={true}
          />
        ) : (
          <ImageContainer
            altImageText={altImageText}
            cardImage={cardImage}
            size={size}
            fullWidth={fullWidth}
            hoverAnimation={hoverAnimation}
          />
        )}
        <CopyContainer
          data={data}
          size={size}
          hoverAnimation={hoverAnimation}
          cardLink={cardLink}
        />
      </CardContainer>
    </CardLink>
  );
}

export default ContentCard;
