import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, ifNotProp, prop, theme } from 'styled-tools';

// for Search Result and Concept of the Day Cards

// to be refactored with ConceptBlockContainer: /ConceptOfTheDay/index.js
const FeatureCardContainer = styled.div``;

const FeatureCardWrapper = styled.div`
  width: 100%;
  min-height: 10rem;
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
  margin: 3rem 0 5rem 0;
  font-family: ${theme('typography.fontFamilySecondary')};
  border-radius: 1rem;
  display: flex;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const FeatureCardContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto auto 1fr;
  grid-auto-columns: 1fr;
  flex-direction: column;
  min-height: 10rem;
  width: 50%;
  padding: 4rem;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    order: 2;
  }
`;

const FeatureCardTag = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${prop('theme.colors.ttRed')};
  padding: 0.5rem;
  justify-self: start;
`;

// to be refactored with other types of Card Details
const FeatureCardDetailsWrapper = styled.div``;

const FeatureCardDetails = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  max-width: 43.125rem;
  margin-bottom: 1rem;

  > p {
    margin: 0.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
`;

const FeatureCardImageWrapper = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 50%;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    order: 1;
  }
`;

const FeatureCardImage = styled.img`
  display: block;
  width: ${ifProp({ $type: 'talent' }, 'auto', '100%')};
  object-fit: cover;
  height: 100%;
`;

// temporary component created to mark refactoring of buttons on Trading Concepts and Search Results cards
const ConceptsButtonWrapper = styled.div``;

export {
  FeatureCardContainer,
  FeatureCardWrapper,
  FeatureCardContentWrapper,
  FeatureCardDetailsWrapper,
  FeatureCardDetails,
  FeatureCardImageWrapper,
  FeatureCardImage,
  ConceptsButtonWrapper,
  FeatureCardTag,
};
