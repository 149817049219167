import React, { useEffect } from 'react';
import get from 'lodash/get';
import { prop, theme } from 'styled-tools';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useEventbrite from 'hooks/useEventBrite';
import { ButtonContainer, Container } from 'components/Events/events.styles'
import CtaButtons, { ButtonsWrap } from 'components/CtaButtons';
import eventShape from 'shapes/eventShape';
import { autoOptimizeImage, extractLastSegment, scrollToHash } from 'utils/functions';
import { JSONLD, Generic } from 'react-structured-data';
import config from 'config';
import { getRawText } from 'utils/rawTextWordCount';

const MainImage = styled.div`
  background-image: url("${prop('url')}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  min-height: 25rem;

  ${props => props.theme.breakpoints.down('xs')} {
    min-height: 15rem;
  }
`;

const GridContainer = styled(Grid)`
  flex-direction: row;
  flex-wrap: nowrap;

  ${props => props.theme.breakpoints.down('md')} {
    gap: 2rem;
    padding: 2rem 0;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: ${props => props.$imageAbove ? `column-reverse` : `column`};
  }
`;

const GridItem = styled(Grid)`
  padding: 0rem 1.5rem 0 1.5rem;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 2rem 1rem 2rem 0;
  }
`;

const DateDetails = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 2rem;

  ${props => props.theme.breakpoints.up('lg')} {
    margin-right: 3rem;
    padding: 0.375rem 0 6.375rem 1.5rem;
  }

  ${props => props.theme.breakpoints.down('md')} {
    padding: ${({ $noPadding }) => ($noPadding ? '0' : '0 2rem')};
  }
`;

const Title = styled(Typography)`
  margin-bottom: 1.25rem;
`;

const Description = styled(Typography)`
  margin-bottom: 0.75rem;
`;

const MaybeButtonContainer = styled(ButtonContainer)`
  margin: 1.75rem 0 0.5rem 0;
  display: ${props => `${props.$initialDisplay}`};

  ${props => props.theme.breakpoints.down('xs')} {
    display: ${props => `${props.$mobileDisplay}`};
  }

  ${ButtonsWrap} {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > * {
      margin: 0;
    }
  }
`

const propTypes = {
  eventItem: eventShape,
};

function Event({
  eventItem,
}) {
  const {
    buttonCta,
    eventDate,
    eventDateDetails,
    imageAboveCta,
    eventDescription,
    eventImage,
    eventbriteId,
    title,
    url,
    hashValue
  } = eventItem;
  const eventButtons = buttonCta && get(buttonCta[0], 'buttonCta');
  const idValue = hashValue ?? extractLastSegment(url);

  let modalButtonCheckout;
  if (eventbriteId) {
    modalButtonCheckout = useEventbrite({
      widgetType: 'checkout',
      eventId: eventbriteId,
      modal: true,
      onOrderComplete: () => 'Order was completed successfully',
    });
  }

  useEffect(() => {
    scrollToHash();
  }, []);

  return (
    <div id={`scroll-${idValue}`}>
      {/*SEO Structured Data Events Code*/}
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="Event"
          jsonldtype="Event"
          schema={{
            name: getRawText(title),
            startDate: eventDate,
            endDate: eventDate,
            description: getRawText(eventDescription),
            image: eventImage && eventImage[0].eventImage.url,
          }}
        >
          <Generic
            type="location"
            jsonldtype="location"
            schema={{
              type: "VirtualLocation",
              url: url,
            }}
          />
          <Generic
            type="organizer"
            jsonldtype="organizer"
            schema={{
              type: "Organization",
              name: "tastylive",
              url: config.prodSiteBaseUrl
            }}
          />
        </Generic>
      </JSONLD>

      <Container maxWidth='lg'>
        <GridContainer container $imageAbove={imageAboveCta}>
          <Grid item xs={12} md={6}>
            <EventDetails>
              {eventDateDetails &&
                <DateDetails variant='h4'>{eventDateDetails}</DateDetails>
              }
              {title &&
                <Title variant='h2'>{title}</Title>
              }
              {eventDescription &&
                <Description>{eventDescription}</Description>
              }
              {eventButtons && (
                <MaybeButtonContainer
                  $initialDisplay={'flex'}
                  $mobileDisplay={imageAboveCta ? 'flex' : 'none'}
                >
                  <CtaButtons items={eventButtons} eventbriteId={modalButtonCheckout && modalButtonCheckout.id}/>
                </MaybeButtonContainer>
              )}
            </EventDetails>
          </Grid>
          <GridItem item xs={12} md={6}>
            {eventImage?.map((image, index) => {
              const { eventImage, imageAltText } = image;

              return (
                <MainImage key={index} alt={imageAltText} url={autoOptimizeImage(eventImage.url, true)} />
              )
            })}
            {eventButtons &&
              <MaybeButtonContainer
                $initialDisplay={'none'}
                $mobileDisplay={imageAboveCta ? 'none' : 'flex'}
              >
                <CtaButtons items={eventButtons} />
              </MaybeButtonContainer>
            }
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
}

Event.propTypes = propTypes;

export default React.memo(Event);
