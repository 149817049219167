import React from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { ifProp, theme } from 'styled-tools';
import episodeShape from 'shapes/episodeShape';
import { liveEndTime } from 'utils/constants';
import LiveIndicatorAnimation from 'components/VideoPlayer/LiveIndicatorAnimation';
import AudioOnlyButton from 'routes/Home/components/VideoControls/AudioOnlyButton';

const StatusBar = styled.div`
  background-color: ${theme('colors.black')};
  display: flex;
  min-height: 3rem;
  height: 3rem;
  padding: 0;
  width: 100%;

  ${ifProp(
    '$flexStart',
    css`
      justify-content: flex-start;
    `
  )}
`;

const StatusContainer = styled.div`
  width: 100%;
`;

const VideoStatus = styled.div`
  align-items: center;
  justify-content: center;
  background: ${theme('colors.ttRed')};
  color: ${theme('colors.white')};
  display: flex;
  font-size: 0.8125rem;
  min-height: inherit;
  padding: 0.5rem 0.3rem;
  text-transform: uppercase;
  width: 100%;
  
  ${ifProp(
    { $status: 'Live' },
    css`
      cursor: pointer;
      width: 3rem;
    `
  )}

  &:hover {
    text-decoration: none;
  }
`;

const Day = styled.div`
  align-items: center;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 0.8125rem;
  min-height: inherit;
  text-transform: uppercase;
  padding-left: 0.5rem;
  width: 100%;
`;

const LiveStatusContianer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8125rem;
  min-height: inherit;
  height: 100%;
  padding: 0.7rem 1rem;
  align-items: center;

`;

const ScheduleCopy = styled.div`
  font-size: 0.8125rem;
  min-height: inherit;
  padding: 0 1.125rem;
  text-transform: uppercase;
`;

const LiveStatusContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

LiveVideoStatusBar.propTypes = {
  episode: episodeShape,
  onClickBackToLive: PropTypes.func,
  children: PropTypes.node,
  onClickStatus: PropTypes.func,
};

function LiveVideoStatusBar({
  episode,
  onClickBackToLive,
  children,
  onClickStatus,
  ...props
}) {
  const today = new Date();
  const todayFormatted = today.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  // day + time logic to determine video status display
  const dateJs = dayjs().tz('America/Chicago');
  const dayOfWeek = dateJs.day();
  const time = Number(dateJs.format('HH:mm').replace(':', '.'));
  const isWeekday = dateJs.day() !== 0 && dateJs.day() !== 6;
  const broadcastStatus =
    time >= 6.3 && time < liveEndTime[dayOfWeek] ? 'Live' : 'Rebroadcast';

  if (!isWeekday && !children && !episode) {
    return null;
  }
  function handleClickStatus() {
    if (broadcastStatus === 'Live' && onClickStatus) {
      onClickStatus();
    }
  }

  return (
    <StatusBar {...props}>
      <StatusContainer>
        {!episode && isWeekday && (
          <LiveStatusContianer>
            <LiveStatusContentContainer>
              {broadcastStatus === 'Live' ? (
                <LiveIndicatorAnimation onClick={handleClickStatus} />
              ) : (
                <VideoStatus onClick={handleClickStatus} $status={broadcastStatus}>
                  {broadcastStatus}
                </VideoStatus>
              )}
              <ScheduleCopy>SCHEDULE</ScheduleCopy>
              <div>|</div>
              <Day>{todayFormatted}</Day>
            </LiveStatusContentContainer>
            <AudioOnlyButton />
          </LiveStatusContianer>
        )}
        {episode && (
          <>
            <VideoStatus
              as={Link}
              component='button'
              onClick={onClickBackToLive}
              backToLive
            >
              Go to Live Broadcast
            </VideoStatus>
            <Day>
              Watching: {episode.displayTitle}
              {episode.subtitle && ` - ${episode.subtitle}`}
            </Day>
          </>
        )}
      </StatusContainer>
      {children}
    </StatusBar>
  );
}

export default React.memo(LiveVideoStatusBar);
