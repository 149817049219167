import React from 'react';
import PropTypes from 'prop-types';
import { DashboardShowsProvider } from './FollowedShows';
import { DashboardSlidesProvider } from './Slides';

DashboardContextsProvider.propTypes = {
  children: PropTypes.node
}

export function DashboardContextsProvider({ children }) {
  return (
    // nest individual dashboard related contexts here so they can be wrapped about client.js with one component
    <DashboardShowsProvider>
      <DashboardSlidesProvider>
        { children }
      </DashboardSlidesProvider>
    </DashboardShowsProvider>
  )
}