import React, { useReducer, useContext, useState, useEffect } from 'react';

function getDefaultState() {
  const DEFAULT_STATE = {
    videoPlayerProps: null,
    enabled: false,
    videos: [],
    pipVideoId: null,
    pipReturnUrl: null,
    pipReturnId: null,
    floatPipSource: false,
  };

  let storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_ENABLE_PIP = 'enablePictureInPicture';
const ACTION_DISABLE_PIP = 'disablePictureInPicture';
const ACTION_ADD_VIDEO = 'addVideo';
const ACTION_UPDATE_VIDEO = 'updateVideo';
const ACTION_REMOVE_VIDEO = 'removeVideo';
const ACTION_SET_PIP_ID = 'setPipId';
const ACTION_SET_RETURN_PIP_ID = 'setReturnPipId';
const ACTION_SET_FLOAT_PIP_SOURCE = 'floatPipSource';

function pictureInPictureReducer(state, action) {
  switch (action.type) {
    case ACTION_ENABLE_PIP:
      return {
        ...state,
        enabled: true,
        videoPlayerProps: action.payload.videoPlayerProps,
      };
    case ACTION_DISABLE_PIP:
      return {
        ...state,
        enabled: false,
        videoPlayerProps: null,
      };
    case ACTION_ADD_VIDEO:
      if (!action.payload.mountId) {
        console.error('Cannot add video, missing required mountId field on payload');
        return state;
      }

      if (state.videos.find(x => x.mountId === action.payload.mountId)) {
        console.error(`Trying to add video to PIP context that is already in the array: ${action.payload.mountId}`);
        return state;
      }

      return {
        ...state,
        videos: [...state.videos, action.payload],
      };
    case ACTION_UPDATE_VIDEO:
      // If trying to remove the current PIP video, don't remove it from videos.
      const itemIndex = state.videos.findIndex(x => x.mountId === action.payload.mountId);

      if (itemIndex >= 0) {
        return {
          ...state,
          videos: [
            ...state.videos.slice(0, itemIndex),
            action.payload,
            ...state.videos.slice(itemIndex + 1)
          ],
        };
      } else {
        return state;
      }
    case ACTION_REMOVE_VIDEO:
      // If trying to remove the current PIP video, don't remove it from videos.
      if (state.pipVideoId && state.pipVideoId === action.payload.mountId) {
        return state;
      }

      return {
        ...state,
        videos: state.videos.filter(x => x.mountId !== action.payload.mountId),
      };
    case ACTION_SET_PIP_ID:
      return {
        ...state,
        pipVideoId: action.payload.pipId,
        pipReturnUrl: action.payload.sourceUrl,
        floatPipSource: false,
      };
    case ACTION_SET_RETURN_PIP_ID:
      return {
        ...state,
        pipReturnId: action.payload.pipId,
      };
    case ACTION_SET_FLOAT_PIP_SOURCE:
      return {
        ...state,
        floatPipSource: action.payload,
      };
    default:
      return state;
  }
}

const PictureInPictureContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  enablePip: (videoPlayerProps) => {},
  disablePip: () => {},
  addVideo: (mountId, video) => {},
  updateVideo: (mountId, video) => {},
  removeVideo: (mountId) => {},
  setPipId: (pipId, sourceUrl) => {},
  setReturnPipId: (pipId) => {},
  setFloatPipSource: (float) => {},
});

export const PictureInPictureProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    pictureInPictureReducer,
    getDefaultState()
  );

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    enablePip: (videoPlayerProps) => dispatch({ type: ACTION_ENABLE_PIP, payload: { videoPlayerProps } }),
    disablePip: () => dispatch({ type: ACTION_DISABLE_PIP }),
    addVideo: (mountId, video) => dispatch({ type: ACTION_ADD_VIDEO, payload: { mountId, videoProps: video } }),
    updateVideo: (mountId, video) => dispatch({ type: ACTION_UPDATE_VIDEO, payload: { mountId, videoProps: video } }),
    removeVideo: (mountId) => dispatch({ type: ACTION_REMOVE_VIDEO, payload: { mountId } }),
    setPipId: (pipId, sourceUrl) => dispatch({ type: ACTION_SET_PIP_ID, payload: { pipId, sourceUrl } }),
    setReturnPipId: (pipId) => dispatch({ type: ACTION_SET_RETURN_PIP_ID, payload: { pipId } }),
    setFloatPipSource: (float) => dispatch({ type: ACTION_SET_FLOAT_PIP_SOURCE, payload: float }),
  });

  // Update context value and trigger re-render
  // This pattern avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state
    }));
  }, [state]);

  return (
    <PictureInPictureContext.Provider value={contextValue}>
      {children}
    </PictureInPictureContext.Provider>
  );
};

export const usePictureInPictureContext = () => useContext(PictureInPictureContext);
