import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import PageLink from 'components/PageLink';
import RichTextField from 'components/RichTextField';
import MobileSection, { Accordion } from './MobileSection';
import menuShape from 'shapes/menuShape';
import { autoOptimizeImage } from 'utils/functions';

const FooterWrapper = styled.footer`
  background-color: ${theme('colors.white')};
  border-bottom: 0.0625rem solid ${theme('colors.mutedGray')};
  position: relative;

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: relative;
    z-index: 11;
  }
`;

const Logo = styled(RouterLink)`
  display: flex;
  align-items: center;
  margin-bottom: 2.625rem;
  margin-left: 1rem;
  max-width: 8.125rem;
  height: 3.63rem;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: 3.625rem;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-bottom: 2rem;
    margin-left: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const FooterContainer = styled(Container)`
  padding-top: 3rem;

  ${props => props.theme.breakpoints.up('md')} {
    width: 88%;
    padding-left: 0;
    padding-right: 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }

  @media (min-width: 1440px) {
    max-width: 1380px;
  }

  ${props => props.theme.breakpoints.up('xl')} {
    max-width: 1580px;
  }
`;

const FooterHeader = styled(Typography)`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
`;

const FooterItem = styled.p`
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: 1.25rem;
  }
`;

const FooterGrid = styled.div`
  color: ${theme('colors.black')};
  grid-column-gap: 25px;
  grid-row-gap: 40px;
  grid-template-rows: auto auto;
  display: grid;
  grid-auto-columns: 1fr;
  padding-bottom: 5rem;
  grid-row-gap: 0;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 40px;
    padding-bottom: 1rem;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-left: 1rem;
    padding-right: 1rem;
    grid-row-gap: 20px;
  }
`;

const GridItem = styled.div`
  width: 100%;

  .MuiAccordionDetails-root {
    background-color: ${theme('colors.white')};
    border-top: none;
    padding: 0;

    p {
      margin-top: 1rem;
    }

    a {
      color: ${theme('colors.black')};
      text-decoration: underline 1px rgba(227, 27, 35, 0);
      text-underline-offset: 0.4em;
      transition: text-decoration-color 300ms, text-underline-offset 300ms;
      font-weight: 400;
      font-size: 0.85rem;

      &:hover {
        text-decoration-color: rgba(227, 27, 35, 1);
        padding-bottom: .25rem;
      }
    }
  }

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    margin-bottom: 1.25rem;
  }
`;

const FooterLink = styled(Link)`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  text-decoration: underline 1px rgba(227, 27, 35, 0);
  text-underline-offset: 0.4em;
  transition: text-decoration-color 300ms, text-underline-offset 300ms;
  font-weight: 400;

  &:hover {
    padding-bottom: .25rem;
    text-decoration-color: rgba(227, 27, 35, 1);
  }
`;

Footer.propTypes = {
  data: PropTypes.shape({
    menus: PropTypes.arrayOf(
      PropTypes.shape({
        menuHeader: PropTypes.string,
        menu: menuShape,
      })
    ),
  }),
};

function Footer({ data }) {
  const theme = useTheme();
  const { logo, menus } = data;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FooterWrapper>
      <FooterContainer>
        {logo && (
          <Logo to='/'>
            <LazyLoad
              debounce={true}
              offset={100}
              width={150}
              height='100%'
              placeholder={
                <Skeleton variant='rect' width={150} height='100%' />
              }
            >
              <img
                src={autoOptimizeImage(logo.url) + '&width=150'}
                alt='tastylive'
              />
            </LazyLoad>
          </Logo>
        )}
        <FooterGrid container>
          {menus.map(({ menu, menuHeader }, key) => (
            <Fragment key={`parent_menu_item_${key}`}>
              {isDesktop && (
                <GridItem item xs={12} md={2}>
                  {menuHeader && (
                    <FooterHeader variant='h4'>{menuHeader}</FooterHeader>
                  )}
                  {menu.menuItems &&
                    menu.menuItems.map(
                      ({ link, linkText, allowHtmlInLinkText }, key) => (
                        <FooterItem key={`child_menu_item_${key}`}>
                          <PageLink component={FooterLink} link={link}>
                            {allowHtmlInLinkText ? (
                              <RichTextField
                                richText={linkText}
                                component='span'
                                $disableMargin
                              />
                            ) : (
                              linkText
                            )}
                          </PageLink>
                        </FooterItem>
                      )
                    )}
                </GridItem>
              )}
              {!isDesktop && (
                <GridItem item xs={12} md={2} key={`parent_menu_item_${key}`}>
                  <MobileSection menu={menu} menuHeader={menuHeader} />
                </GridItem>
              )}
            </Fragment>
          ))}
        </FooterGrid>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default React.memo(Footer);
