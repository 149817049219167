import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: bold;
  line-height: 1;

  ${ifProp('$fontSize', css`
    font-size: ${switchProp('$fontSize', {
      inherit: 'inherit',
    }, '0.8125rem')}
  `, css`
    font-size: 0.8125rem;
  `)};

  .MuiSvgIcon-root {
    margin-right: .625rem;
  }
`;

export default IconTitle;
