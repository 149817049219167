import React, { useEffect, useState } from 'react';
import SimpleDialog from 'components/SimpleDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import updateShowWelcomeMessageMutation from './updateShowWelcomeMessage.graphql';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonPrimary from 'components/ButtonPrimary';
import PropTypes from 'prop-types';
import { useDashboardContext } from 'context/DashboardContext';
import Icon from 'components/Icon';
import { theme } from 'styled-tools';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CloseIconWrapper from 'components/CloseButton';

const SimpleDialogWrapper = styled(SimpleDialog)`
  .MuiDialogContent-root {
    padding-top: 0;
  }

  .MuiDialogTitle-root {
    padding-top: 0.25rem;
    padding-right: 1rem;

    svg {
      fill: ${theme('colors.black')};
    }
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  padding: 3rem 2rem 1.5rem 2rem;
  font-weight: 400;
`;

const WelcomeTitle = styled.div`
  font-size: 1.875rem;
  font-weight: 600;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
`

const SectionIcon = styled.div`
  svg {
    width: 4.5rem;
    height: 4.5rem;
  }

 ${props => props.theme.breakpoints.down('sm')} {
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;

const WelcomeSubheader = styled.p`
  font-size: 1.375rem;
  margin-top: 1rem;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
`

const WelcomeText = styled.p`
  text-align: left;
  font-size: 1rem;
  font-weight: 400;

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 0.875rem;
  }
`;

const HideWelcomeMessageText = styled.label`
  font-size: 0.8125rem;
  margin-top: 1rem;
  cursor: pointer;
`;

const GridWrapper = styled(Grid)`
  margin-top: 0.5rem;
`;

const GetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  flex-direction: column;
`;

const ButtonPrimaryWrapper = styled(ButtonPrimary)`
  margin-bottom: 1.5rem;
`;

WelcomeMessage.propTypes = {
  onClose: PropTypes.func,
  showOverride: PropTypes.bool,
  show: PropTypes.bool
}

function WelcomeMessage({ onClose, showOverride = false, show = false }) {
  const [showModal, setShowModal] = useState(false);
  const [updateShowWelcomeMessage] = useMutation(updateShowWelcomeMessageMutation);
  const { state: dashboardState, updateDashboardLogs } = useDashboardContext();
  const { showWelcomeMessage } = dashboardState?.logs;
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setShowModal(show)
  }, [show])

  useEffect(() => {
    setDoNotShowAgain(!showWelcomeMessage);
    !showOverride && setShowModal(showWelcomeMessage)
  }, [showWelcomeMessage, show]);

  const dashboardSections = [
    {
      title: 'My Shows',
      text: "Follow your favorite shows and get the latest episodes in your 'My Shows' page.",
      icon: <PlayCircleFilledIcon />
    },
    {
      title: 'My Slides',
      text: "Save research slides to your 'My Slides' page so you can refer back to them later",
      icon: <Icon icon='slidesOutline' />
    },
    {
      title: 'Playlists',
      text: "Make custom playlists of your favorite episodes!",
      icon: <PlaylistPlayIcon />
    },
  ]

  async function handleClose(e) {
    e?.stopPropagation();

    if(doNotShowAgain === showWelcomeMessage) {
      await updateShowWelcomeMessage({ variables: { showWelcomeMessage: !doNotShowAgain }})
      updateDashboardLogs();
    }
    setShowModal(false)
    onClose && onClose();
  }

  return (
    <SimpleDialogWrapper
      open={showModal}
      maxWidth='md'
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <DialogContent>
        <CloseIconWrapper onClick={handleClose} >
          <CloseIcon />
        </CloseIconWrapper>
        <StyledDialogContentText color='textPrimary'>
          <WelcomeTitle>Welcome to your dashboard!</WelcomeTitle>
          <WelcomeSubheader>Save your favorite tasty<em>live</em> content here so you can find it quickly and easily!</WelcomeSubheader>
          <GridWrapper
            container
            justify='center'
            spacing={isMobile ? 0 : 10}
          >
            {dashboardSections.map((section, index) => (
              <Grid item xs={12} sm={4} align='center' key={index}>
                <SectionIcon>{section.icon}</SectionIcon>
                <SectionTitle>{section.title}</SectionTitle>
                <WelcomeText>{section.text}</WelcomeText>
              </Grid>
            ))}
          </GridWrapper>
          <GetStartedContainer>
            <ButtonPrimaryWrapper onClick={handleClose}>
              Get Started
            </ButtonPrimaryWrapper>

            <HideWelcomeMessageText>
              <Checkbox onChange={() => setDoNotShowAgain(!doNotShowAgain)} checked={doNotShowAgain} />
              Got it, don't show me this again.
            </HideWelcomeMessageText>
          </GetStartedContainer>
        </StyledDialogContentText>
      </DialogContent>
    </SimpleDialogWrapper>
  )
}

export default React.memo(WelcomeMessage);
