import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import blockStyleShape from 'shapes/blockStyleShape';
import { combineBlockStyles } from 'utils/blockStyleUtils';
import blockStylesCss from 'components/ModularBlocks/BlockStyle/blockStylesCss';

const BoxContainer = styled(Box)`
  font-size: 1rem;
  font-weight: 300;
  margin: 0;  
  ${blockStylesCss}
`;

BlockStyle.propTypes = {
  blockStyle: PropTypes.arrayOf(blockStyleShape),
  children: PropTypes.node,
  className: PropTypes.string,
};

function BlockStyle({ blockStyle = [], className, children, ...props }) {
  const combinedBlockStyles = combineBlockStyles(blockStyle);
  const combinedClassName = (className || '') + ' ' + (combinedBlockStyles?.customCss?.cssClassName);

  return (
    <BoxContainer $blockStyle={combinedBlockStyles} className={combinedClassName.trim()} {...props}>
      {children}
    </BoxContainer>
  );
}

export default React.memo(BlockStyle);
