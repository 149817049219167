import React from 'react';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styled, { css } from 'styled-components';
import AccordionWrapper from '@material-ui/core/Accordion';
import { ifProp, ifNotProp, prop, theme } from 'styled-tools';
import AccordionSummary from '@material-ui/core/AccordionSummary';

const StyledAccordion = styled(AccordionWrapper)`
  box-shadow: none;

  &.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordion-root {
    &:before {
      background-color: transparent;
    }
  }

  .MuiAccordionDetails-root {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Counter = styled.div`
  align-items: center;
  background-color: ${prop('theme.colors.ttRed')};
  border-radius: 50%;
  color: ${prop('theme.colors.white')};
  display: flex;
  font-size: 0.6875rem;
  font-weight: 700;
  height: 1.25rem;
  justify-content: center;
  margin-left: 0.625rem;
  width: 1.25rem;
`;

const AccordionHeader = styled(AccordionSummary)`
  height: 3.125rem;
  
  ${ifProp('active', css`
    &&& {
      background-color: ${prop('theme.colors.ttRed')};
      color: ${prop('theme.colors.white')};
      
      .MuiSvgIcon-root {
        color: white;
      }
    }         
  `)}

  ${ifNotProp('active', css`
    &:hover * {
      color: ${theme('colors.ttRed')};
    } 
  `)}
  
  ${ifProp({ type: 'sidebar' }, css`
    color: ${prop('theme.colors.black')};

    .MuiSvgIcon-root {
      color: ${prop('theme.colors.black')};
      font-size: 1.25rem;
    }

    .MuiAccordionSummary-content {
      align-items: center;
      padding-left: 1rem;
    }

    &.Mui-expanded {
      ${ifNotProp('active', css`
        background-color: ${prop('theme.colors.ttRed')};
        color: ${prop('theme.colors.white')};
      `)}
    
      height: 3.125rem;
      min-height: auto;

      ${Counter} {
        background-color: ${prop('theme.colors.white')};
        color: ${prop('theme.colors.ttRed')};
      }

      svg {
        fill: ${prop('theme.colors.white')};
      }

      p {
        margin: 0.75rem 0;
      }
    }

    p {
      font-size: 0.8125rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  `)}
`;

const HeaderIcon = styled.div`
  display: flex;
  margin-right: 0.625rem;
  max-width: 1.125rem;

  .MuiSvgIcon-root {
    font-size: 0.9375rem;
  }
`;

AccordionGroupItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  count: PropTypes.number,
  dash: PropTypes.elementType,
  type: PropTypes.string,
  expanded: PropTypes.string,
  handleChange: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.bool
};

function AccordionGroupItem({ id, icon, count, title, dash, type, expanded, handleChange, children, active }) {
  const isExpanded = expanded === `panel${id}`;

  return (
    <StyledAccordion
      square
      expanded={isExpanded}
      onChange={handleChange(`panel${id}`, dash)}
      type={type}
    >
      <AccordionHeader
        expandIcon={children && <ExpandLessIcon />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
        type={type}
        active={active}
      >
        <HeaderIcon>{icon}</HeaderIcon>
        <Typography>{title}</Typography>
        {count && <Counter>{count}</Counter>}
      </AccordionHeader>
      {children && isExpanded && (
        <AccordionDetails>
          {children}
        </AccordionDetails>
      )}
    </StyledAccordion>
  );
}

export default AccordionGroupItem;
