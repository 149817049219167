
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ComparisonTableFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ComparisonTable"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mobileTableStyle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"noPadding"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tableWidth"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tableHeaders"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"footnoteReference"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"noBorder"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"headerWidth"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"redBackground"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"tableColumns"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rows"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":252}};
    doc.loc.source = {"body":"fragment ComparisonTableFields on ComparisonTable {\n  mobileTableStyle\n  noPadding\n  tableWidth\n  tableHeaders {\n    content\n    footnoteReference\n    noBorder\n    headerWidth\n    redBackground\n  }\n  tableColumns {\n    rows {\n     content\n    }\n  }\n}\n\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
