import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FeaturedComponent from '../FeaturedComponent';
import getRandomShowQuery from '../../../../../../graphql/queries/getRandomShow.graphql';

RandomizedShow.propTypes = {
  megaMenuClick: PropTypes.func.isRequired,
  subItem: PropTypes.object,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourAction: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.func,
  menuOpen: PropTypes.bool,
};

function RandomizedShow({
  megaMenuClick,
  subItem,
  tourHighlighted,
  traderResourcesTourMenuClick,
  traderResourcesTourAction,
  menuOpen
}) {
  const { data } = useQuery(getRandomShowQuery);
  const items = get(data, 'randomShow.items', []);
  const featuredItem = items[0];

  if (!featuredItem) {
    return null;
  }

  const title = get(featuredItem, 'title');

  return (
    <FeaturedComponent
      exploreMore={`Explore ${title}`}
      featuredImage={featuredItem?.image?.url}
      featuredItem={featuredItem}
      featuredTitle={title}
      featuredSnippet={featuredItem?.description}
      megaMenuClick={megaMenuClick}
      tourHighlighted={tourHighlighted}
      traderResourcesTourMenuClick={traderResourcesTourMenuClick}
      traderResourcesTourAction={traderResourcesTourAction}
      subItem={subItem}
      menuOpen={menuOpen}
    />
  )
};

export default RandomizedShow;
