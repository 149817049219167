export const dataStoreItems = {
  "preferences": {
    data: 'Name, Avatar, Saved shows, episodes, slides and other content',
    deletedItems: [
      'Followed Shows',
      'Playlists',
      'Saved Slides'
    ]
  },
  'personal history': {
    data: 'The history of your video views, following shows, saving slideshows and other content',
    deletedItems: [
      'Watch History'
    ]
  }
}
