const baseUrlPageTypes = ['utility_page', 'on_demand_page'];

function getWithRoutePrefix(url, prefix, otherPrefix = null) {
  if (url && url.startsWith(prefix)) {
    return url;
  }

  if (url && otherPrefix && url.startsWith(otherPrefix)) {
    return url;
  }

  return `${prefix}${url}`;
}

export default function linkResolver(link) {
  const { url, uid, contentTypeUid, linkUrl } = link || {};

  if (contentTypeUid === 'link_url') {
    return linkUrl && linkUrl.href;
  }

  // If content type is one where the url field is the page route,
  // then we'll return url directly.
  if (baseUrlPageTypes.includes(contentTypeUid)) {
    return url;
  }

  if (contentTypeUid === 'show') {
    // If url already contains /shows/, then return directly.
    return getWithRoutePrefix(url, '/shows/');
  }

  // TODO: once we have the show data available we need to build episode URLs to be:
  //   /shows/:showSlug/episodes/:episodeSlug
  if (contentTypeUid === 'episode') {
    return getWithRoutePrefix(url, '/episodes/', '/shows/');
  }

  if (contentTypeUid === 'events') {
    return getWithRoutePrefix(url, '/events/');
  }

  if (contentTypeUid === 'talent') {
    return getWithRoutePrefix(url, '/talent/');
  }

  return url || `/entries/${uid}`;
}
