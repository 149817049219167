
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VideoHeroBlockFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VideoHeroBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subheader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"selectSubheaderType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"selectHeaderType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"body"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonCta"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CtaButtonFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"featureVideo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayKpis"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"videoDisplayOptions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"videoSource"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VideoSourceFields"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":465}};
    doc.loc.source = {"body":"#import \"../BlockStyleFields.graphql\"\n#import \"../CtaButtonFields.graphql\"\n#import \"../AssetFields.graphql\"\n#import \"../VideoSourceFields.graphql\"\n\nfragment VideoHeroBlockFields on VideoHeroBlock {\n  subheader\n  selectSubheaderType\n  header\n  selectHeaderType\n  body\n  buttonCta {\n    ...CtaButtonFields\n  }\n  featureVideo {\n    displayKpis\n    videoDisplayOptions\n    videoSource {\n      ...VideoSourceFields\n    }\n  }\n  blockStyle {\n    ...BlockStyleFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../BlockStyleFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../CtaButtonFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../VideoSourceFields.graphql").definitions));


      module.exports = doc;
    
