import { useEffect } from 'react';

const VIEWPORT_OFFSET = 200;

export function useElementOnScreen(callback, selector) {

  useEffect(() => {
    const handleScroll = () => {
      let topElementId = null;
      let smallestPositiveTop = Infinity;

      document.querySelectorAll(selector).forEach(element => {
        const rect = element.getBoundingClientRect();
        let threshold = rect.height 

        // This is to determine if the element is at the top of viewport
        if (rect.top >= (-threshold + VIEWPORT_OFFSET) && rect.top < smallestPositiveTop) {
          smallestPositiveTop = rect.top;
          topElementId = element.id;
        }
      });

      if (topElementId) {
        callback(topElementId);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [callback, selector]);
}