import React from 'react';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

export const Panel = styled(Grid)`
  border-bottom: 0.0625rem solid ${prop('theme.colors.borderGray')};
  padding: 2.625rem 1.25rem;

  ${props => props.theme.breakpoints.up('md')} {
    border-bottom: none;
    border-right: 0.0625rem solid ${prop('theme.colors.borderGray')};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding: 3.5rem 1.75rem;
  }

  &:last-of-type {
    border-bottom: none;
    border-right: none;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InfoCol = styled.div`
  flex-direction: column;
`;

export const Label = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const LinkContainer = styled.div`
  align-items: flex-start;
  display: flex;

  button {
    font-family: ${prop('theme.typography.fontFamily')};
    font-weight: 500;
  }
`;

export const Title = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.75rem;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 2.375rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.75rem;

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
    margin-top: 2.25rem;
  }

  button {
    ${props => props.theme.breakpoints.down('xs')} {
      width: 100%;

      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }
`;
