import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PropTypes from 'prop-types';

const DragIconContainer = styled.div`
  position: absolute;
  left: -2.5rem;
  bottom: 45%;
  cursor: grab;
  
  svg {
    margin: auto 0;
    visibility: hidden;
  }

  &:active {
    cursor: grabbing;
  }

  ${ifProp({$editMode: false}, css`
    &:hover {
      color: ${theme('colors.ttRed')};
      
      svg {
        visibility: visible;
      }
    }
        
    ${props => props.theme.breakpoints.down('sm')} {
      svg {
        visibility: visible;
      }
    }
    
    ${props => props.theme.breakpoints.down('xs')} {
      left: 0;
      margin-left: -1.25rem;
      bottom: 45%;
    }
  `)};
`;

DragIcon.propTypes = {
  editMode: PropTypes.bool
}

function DragIcon({ editMode = false }) {
  return (
    <DragIconContainer $editMode={editMode}>
      <DragIndicatorIcon />
    </DragIconContainer>
  )
}

export default React.memo(DragIcon);
