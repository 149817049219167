export const Tours = {
  DailyReplays: 'dailyReplaysTour',
  TraderResources: 'traderResourcesTour',
}

export const TraderResourcesStepKeys = {
  MenuItem: 0,
  MenuOpen: 1,
  ConceptsColumns: 2,
  ConceptsPage: 3,
};

/**
 * CSS Class Name used for the Trader Resources menu item.
 * @type {string}
 */
export const TRADER_RESOURCES_MENU_CLASS = 'trader-resources';
export const TRADER_RESOURCES_CONCEPTS_COL_CLASS = 'concepts-strategies-column';
