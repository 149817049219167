import { getShowThumbnail, getOptimizedImage } from 'utils/showHelpers';
import { episodeImageSizes } from 'utils/constants';
import dayjs from 'dayjs';
import { SHARE_CAMPAIGNS, SHARE_MEDIUMS, SHARE_SOURCES } from 'constants/shortUrls';
import get from 'lodash.get';

const MAX_EXPIRATION_DISPLAY_DAYS = 5

export function getEpisodeVideoId(episode) {
  if (!episode) {
    return null;
  }

  const video = getEpisodeVideoMedia(episode);

  return video ? video.mediaFile : null;
}

export function getEpisodeVideoMedia(episode) {
  if (!episode) {
    return null;
  }

  return episode.media && episode.media.find(v => v.mediaType === 'video' && !!v.mediaFile);
}

export function getEpisodeThumbnail(episode, size = 'sm') {
  const { thumbnailImage, show } = episode || {};
  const thumbnail = thumbnailImage || getShowThumbnail(show);

  return getOptimizedImage(thumbnail, size, episodeImageSizes);
}

export function getSlideCoverImage(episode, size = 'sm') {
  const firstSlideImage = get(episode, 'pdfSlides[0].slideImages[0]');

  return firstSlideImage
    ? getOptimizedImage(firstSlideImage, size, episodeImageSizes)
    : getEpisodeThumbnail(episode, size);
}

export function getEpisodeExpirationDays(episode) {
  if (!episode) {
    return null;
  }

  const { websiteRemovalDate, retainIndefinitely, show } = episode;
  const { enforceRetentionPolicy } = show || {};
  const daysToExpiration = Math.round(dayjs(websiteRemovalDate).diff(new Date(), 'day', true));

  if (!enforceRetentionPolicy || retainIndefinitely || !websiteRemovalDate || daysToExpiration > MAX_EXPIRATION_DISPLAY_DAYS) {
    return null;
  }

  return dayjs(websiteRemovalDate).fromNow(true);
}

export function isExpired(episode) {
  const { show, retainIndefinitely, websiteRemovalDate } = episode || {};

  // Check if episode's show enforces its retention policy.
  if (!show?.enforceRetentionPolicy) {
    return false;
  }

  // If retained indefinitely or no websiteRemovalDate set, it will not expire.
  if (retainIndefinitely || !websiteRemovalDate) {
    return false;
  }

  // If websiteRemovalDate is in the future, it is not expired.
  return dayjs().isAfter(dayjs(websiteRemovalDate));
}

export function getExpirationDate(episode) {
  const { show, retainIndefinitely, websiteRemovalDate } = episode || {};

  // Check if episode's show enforces its retention policy.
  if (!show?.enforceRetentionPolicy) {
    return null;
  }

  // If retained indefinitely or no websiteRemovalDate set, it will not expire.
  if (retainIndefinitely || !websiteRemovalDate) {
    return null;
  }

  return websiteRemovalDate;
}

export function getShareUrl(
  episode,
  medium = SHARE_MEDIUMS.link,
  campaign = SHARE_CAMPAIGNS.archive,
  source = SHARE_SOURCES.socialShare
) {
  const shortLink = (episode.shortLinks || []).find(
    ({ utmCampaign, utmMedium, utmSource }) => utmCampaign === campaign && utmMedium === medium && utmSource === source
  );

  return shortLink?.shortUrl
    ?? `${getSiteUrl()}${episode.url}?utm_campaign=${campaign}&utm_medium=${medium}&utm_source=${source}`;
}

export function getSiteUrl() {
  return typeof window !== 'undefined' ? window.location.origin : 'https://www.tastylive.com';
}