import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MartiniGlassIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_497" data-name="Group 497" transform="translate(-16.808)">
          <path id="Path_273" data-name="Path 273" d="M34.907-86.86a4.152,4.152,0,0,0-4.047,3.334H19.069a.491.491,0,0,0-.434.263.487.487,0,0,0,.034.506l7.7,10.963v7.955h-3.5a.488.488,0,0,0-.488.488.488.488,0,0,0,.488.488h7.987a.488.488,0,0,0,.488-.488.488.488,0,0,0-.488-.488H27.348v-7.628c.009-.011.02-.02.029-.033l5.406-7.7a4.081,4.081,0,0,0,2.122.593,4.135,4.135,0,0,0,4.131-4.129,4.135,4.135,0,0,0-4.131-4.131Zm-2.225,6.114a.56.56,0,0,0-.065-.006H21.457a.477.477,0,0,0-.165.031l-1.283-1.827H33.948Zm2.225,1.169a3.1,3.1,0,0,1-1.559-.417l1.941-2.762a.493.493,0,0,0,.034-.506.488.488,0,0,0-.434-.263H31.862a3.173,3.173,0,0,1,3.046-2.358,3.157,3.157,0,0,1,3.154,3.154,3.159,3.159,0,0,1-3.155,3.153Z" transform="translate(0 86.86)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default React.memo(MartiniGlassIcon);
