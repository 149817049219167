import React from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from 'styled-components';
import { ifProp } from 'styled-tools';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import BaseEpisodeCard from '../EpisodeCard';
import episodeShape from 'shapes/episodeShape';

const EPISODES_TO_SHOW = '4';

const EpisodeCard = styled(BaseEpisodeCard)`
  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: 1.25rem;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(80%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AnimatedGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 25%;
    flex-basis: 25%;
  }
  
  ${ifProp({rollDown: true}, css`
    &:nth-last-child(-n+4) {
      ${ifProp('$added', css`
        animation: ${slideUp} 0.35s backwards;
        animation-delay: 0.05s;
      `)}
    }
  
    &:nth-last-child(-n+3) {
      ${ifProp('$added', css`
        animation: ${slideUp} 0.35s backwards;
        animation-delay: 0.2s;
     `)}
    }
  
    &:nth-last-child(-n+2){
      ${ifProp('$added', css`
        animation: ${slideUp} 0.35s backwards;
        animation-delay: 0.35s;
      `)}
    }
  
    &:nth-last-child(-n+1) {
       ${ifProp('$added', css`
        animation: ${slideUp} 0.35s backwards;
        animation-delay: 0.5s;
      `)}
    }
  `)}
`;

EpisodeSection.propTypes = {
  episodes: PropTypes.arrayOf(episodeShape),
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
  rollDown: PropTypes.bool,
  homepage: PropTypes.bool,
};

function EpisodeSection({ episodes, loading = false, handleClick, rollDown, homepage }) {

  return(
    <Grid container spacing={2}>
      {(loading ? Array.from(new Array(20)) : episodes).map((episode, index) => (
        <AnimatedGrid key={index} item xs={12} sm={6} md={3} $added={index >= EPISODES_TO_SHOW ? 'added' : ''} expand={!rollDown} rollDown={rollDown} $homepage={homepage}>
          <EpisodeCard
            display='vertical'
            episode={episode}
            loading={loading}
            onClick={handleClick}
            expand={!rollDown}
            rollDown={rollDown}
            homepage={homepage}
          />
        </AnimatedGrid>
      ))}
    </Grid>
  )
}

export default EpisodeSection;
