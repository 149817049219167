
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FileListFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileListBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"files"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileDescription"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fileLink"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"href"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fileSource"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":298}};
    doc.loc.source = {"body":"#import \"../BlockStyleFields.graphql\"\n#import \"../AssetFields.graphql\"\n\nfragment FileListFields on FileListBlock {\n  files {\n    fileName\n    fileDescription\n    fileType\n    fileLink {\n      title\n      href\n    }\n    fileSource { ...AssetFields }\n  }\n  blockStyle {\n    ...BlockStyleFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../BlockStyleFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));


      module.exports = doc;
    
