import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ModalWithTrigger from 'components/ModalWithTrigger';
import { Panel, InfoRow, InfoCol, Label, LinkContainer } from './SharedStyles';
import auth0UserShape from 'shapes/auth0UserShape';
import dbUserShape from 'shapes/dbUserShape';
import UpdateInfo from './UpdateInfo';
import { dataLayerPush } from 'utils/dataLayer';

PersonalPanel.propTypes = {
  auth0Id: PropTypes.string,
  dbUser: dbUserShape,
  personalInfo: auth0UserShape,
  refetchAuth0: PropTypes.func,
  refetchDb: PropTypes.func,
  socialUser: PropTypes.bool,
};

function PersonalPanel({
  auth0Id,
  dbUser,
  personalInfo,
  refetchDb,
  refetchAuth0,
  socialUser
}) {
  const [open, setOpen] = useState(false);
  const { email, user_metadata: metadata } = personalInfo;
  const { firstName, lastName, id } = dbUser;

  const address = get(metadata, 'address');
  const addressTwo = get(metadata, 'addressTwo');
  const phone = get(metadata, 'phone');

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Update Info'`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Panel item xs={12} sm={12} md={4}>
      <InfoRow>
        <InfoCol>
          <Label>Name</Label>
          <Typography>
            {firstName && firstName} {lastName && lastName}
          </Typography>
        </InfoCol>
        <LinkContainer>
          <ModalWithTrigger
            ariaLabel='Update info'
            ariaDescribe='Form where a user can update their contact info'
            handleOpen={handleOpen}
            handleClose={handleClose}
            height={502}
            icon={<ChevronRightIcon />}
            open={open}
            padding='medium'
            triggerText='Update Info'
            triggerType='link'
            width={792}
            testId='profileUpdateInfoTrigger'
          >
            <UpdateInfo
              auth0Id={auth0Id}
              dbUser={dbUser}
              handleClose={handleClose}
              personalInfo={personalInfo}
              refetchDb={refetchDb}
              refetchAuth0={refetchAuth0}
              socialUser={socialUser}
              userId={id}
            />
          </ModalWithTrigger>
        </LinkContainer>
      </InfoRow>
      <InfoRow>
        <InfoCol>
          <Label>Address</Label>
          {address && <Typography>{address}</Typography>}
          {addressTwo && <Typography>{addressTwo}</Typography>}
          {!address && !addressTwo && <span>---</span>}
        </InfoCol>
      </InfoRow>
      <InfoRow>
        <InfoCol>
          <Label>Phone</Label>
          {phone && <Typography>{phone}</Typography>}
          {!phone && <span>---</span>}
        </InfoCol>
      </InfoRow>
    </Panel>
  );
}

export default React.memo(PersonalPanel);
