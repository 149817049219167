import React from 'react';
import styled, { css } from 'styled-components';
import { prop, switchProp, theme } from 'styled-tools';
import BaseButton from 'components/Button';
import BaseSnackbar from '@material-ui/core/Snackbar';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';

const Snackbar = styled(BaseSnackbar)`
  &.MuiSnackbar-anchorOriginBottomCenter {
    bottom: 6.625rem;
    ${props => props.theme.breakpoints.down('xs')} {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  
  .MuiSnackbarContent-root {
    color: ${theme('colors.white')};
    padding-bottom: 0;
    padding-top: 0;
    
    ${switchProp('$bgColor', {
      'red': css`
        background-color: ${prop('theme.colors.ttRed')};  
      `  
    }, css`
      background-color: ${theme('colors.black')};
    `)}
  }
 
  .MuiButton-text svg {
    ${switchProp('$iconColor', {
      'red': css`
        fill: ${theme('colors.ttRed')};
      `,
      'white': css`
        fill: ${theme('colors.white')}
      `,
      'black': css`
        fill: ${theme('colors.black')};
      `
    }, css`
      fill: ${theme('colors.black')};
    `)}
  }
  
  .MuiButton-text:hover {
    svg {
      ${switchProp('$hoverIconColor', {
        'red': css`
              fill: ${theme('colors.ttRed')};
            `,
        'white': css`
              fill: ${theme('colors.white')};
            `,
        'black': css`
          fill: ${theme('colors.black')};
        `
      }, css`
        fill: ${theme('colors.ttRed')};
      `)}
    }
  }
  
  .MuiSnackbarContent-message {
    padding-left: 0.5rem;
    padding-right: 1rem;
    @media (max-width: 700px) {
      padding-right: 0;
    }
    ${props => props.theme.breakpoints.down('xs')} {
      padding: 0.75rem 0;
    }
  }
  .MuiSnackbarContent-action {
    border-left: 0.0625rem solid ${theme('colors.mutedLightGray')};
    padding: 0.5rem;
    @media (max-width: 700px) {
      padding: 0;
    }
  }
  .MuiButton-text {
    color: ${theme('colors.white')};
    text-transform: uppercase;
  }
`;

const Button = styled(BaseButton)`
  svg {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem
  }
  
  span {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

SimpleSnackbar.propTypes = {
  anchorOrigin: PropTypes.object,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  clickAction: PropTypes.func,
  icon: PropTypes.string,
  iconColor: PropTypes.oneOf(['red', 'black', 'white']),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  bgColor: PropTypes.oneOf(['red', 'black', 'white']),
  autoHideDuration: PropTypes.number,
  hoverIconColor: PropTypes.oneOf(['red', 'black', 'white'])
}

function SimpleSnackbar({
  anchorOrigin,
  buttonText,
  clickAction,
  icon,
  iconColor,
  message,
  onClose,
  open,
  bgColor,
  autoHideDuration = 5000,
  hoverIconColor
}) {
  return (
    <Snackbar
      action={
        <Button onClick={clickAction}>
          {icon && <Icon color={iconColor} icon={icon} size={1.375} />}
          {buttonText}
        </Button>
      }
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      message={message}
      onClose={onClose}
      open={open}
      $bgColor={bgColor}
      $hoverIconColor={hoverIconColor}
      $iconColor={iconColor}
    />
  );
}

export default React.memo(SimpleSnackbar);
