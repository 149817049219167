import React from 'react';
import Icon from 'components/Icon';
import EmptyPage from '../EmptyPage';
import { useHistory } from 'react-router-dom';
import { useDashboardSlidesContext } from 'context/Dashboard/Slides';

function EmptySlides() {
  const message = "Save slides to your dashboard whenever you see this icon. " +
    "Just click 'Save to My Slides' so you can come back to reference them at any time.";
  const icon = <Icon icon='addToSlides' />;
  const history = useHistory();
  const { shows } = useDashboardSlidesContext();

  const title = shows.selected.length > 0
    ? "There are no slides saved for your selected shows."
    : "You haven't saved any slides yet."
  
  return (
    <EmptyPage
      title={title}
      text={message}
      icon={icon}
      buttonText='Discover Shows'
      onClick={() => history.push('/on-demand')}
    />
  )
}

export default React.memo(EmptySlides);
