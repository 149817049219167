import React, { useContext } from 'react';

const ServerContext = React.createContext({});

export const ServerProvider = ({ context = {}, children }) => {
  return (
    <ServerContext.Provider value={context}>
      {children}
    </ServerContext.Provider>
  );
};

export const useServerContext = () => useContext(ServerContext);
