import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import NextButton from './components/NextButton';
import PrevButton from './components/PrevButton';
import { useAuth } from 'context/AuthContext';
import { useTourContext } from 'context/TourContext';
import getAllToursToShowQuery from '../../../graphql/queries/getAllToursToShow.graphql';
import upsertShowTraderResourcesTourMutation from '../../../graphql/mutations/upsertShowTraderResourcesTour.graphql';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Tours, TraderResourcesStepKeys } from 'components/Tours/constants';
import { DATA_PRIVACY_CLAIM } from 'utils/constants';

// Must load in Tour via @loadable/component since it tries accessing document and that breaks in SSR.
const LoadedTour = loadable(() => import('reactour'));

const Tour = styled(LoadedTour)`
  [data-tour-elem="controls"] {
    justify-content: space-between;
  }

  .reactour__close {
    display: none;
  }

  p {
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: 0.75rem;
    }
  }
`;

const CustomClose = styled.button`
  background: transparent;
  border: none;
  color: ${theme('colors.black')};
  cursor: pointer;
  font-size: 0.625rem;
  font-weight: 600;
  position: absolute;
  right: 8px;
  text-transform: uppercase;
  top: 8px;

  &:active {
    color: ${theme('colors.black')};
  }
`;

const DoneButton = styled(Typography)`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
`;

const steps = [
  {
    selector: '.trader-resources',
    position: 'bottom',
    content: (
      <>
        <p><strong>Introducing: Trader Resources!</strong></p>
        <p>We’ve made some changes to the navigation - learn more about what’s changed with a quick step-by-step tutorial. Click LET’S GO to get started.</p>
      </>
    ),
  },
  {
    selector: '.trader-resources .mega-menu-container',
    position: 'center',
    content: (
      <>
        <p>Trader Resources is your one-stop shop for useful tools &amp; resources to aid you in your trading journey.</p>
        <p>This is where you can get market news &amp; insights from the tasty<em>live</em> blog, simulate your trade ideas with Lookback, or catch the latest issue of Luckbox Magazine.</p>
      </>
    ),
    mutationObservables: ['.trader-resources .mega-menu-container'],
    resizeObservables: ['.trader-resources .mega-menu-container'],
    stepInteraction: false,
  },
  {
    selector: '.trader-resources .mega-menu-container .featured-concept-column',
    position: 'right',
    content: (
      <>
        <p>You can now read up on your favorite trading concepts & strategies from Trader Resources. Click SEE ALL TRADING CONCEPTS to see how we’ve given these pages a new makeover.</p>
      </>
    ),
    mutationObservables: ['.trader-resources .mega-menu-container'],
    highlightedSelectors: ['.featured-concept-column', '.concepts-strategies-column .mega-menu-group-header-link'],
    resizeObservables: ['.trader-resources .mega-menu-container']
  },
  {
    // This selector is not meant to match anything on purpose.
    // By using this, the popover will default to go into the center of the page,
    // and it will overlay the mask over the entire page, which is exactly what's needed for this step.
    selector: '.no-match-selector',
    content: (
      <>
        <p>Ramp up your trading know-how with all of the fundamental terms now at your fingertips!</p>
        <p>Quickly find the information you need to calculate your break even points on an Iron Condor trade, or impress your friends with your expertise on Standard Deviation after reading our thorough walkthrough. All in one slick space.</p>
      </>
    ),
  },
];

TraderResourcesTour.propTypes = {};

function TraderResourcesTour() {
  const { user } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const { data: toursData } = useQuery(getAllToursToShowQuery, {
    fetchPolicy: 'no-cache',
    skip: !user || typeof window === 'undefined'
  });

  const [upsertShowTraderResourcesTour] = useMutation(upsertShowTraderResourcesTourMutation);
  const {
    state: { activeTour, currentStep, newStep, startAt },
    enableTour,
    disableTour,
    setWillShowTour,
    updateCurrentStep
  } = useTourContext();

  const closeAndUpdateSettings = () => {
    disableTour();
    setWillShowTour(false);
    if (user) {
      upsertShowTraderResourcesTour({ variables: { showTraderResourcesTour: false } });
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        setIsMounted(true);
      }, 0);
    }
  }, []);

  const userPrivacy = user?.[DATA_PRIVACY_CLAIM]?.dataUsageItems ?? false;
  const userPrivacyComplete = userPrivacy && Object.keys(userPrivacy)?.length;

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (toursData?.getAllToursToShow && userPrivacyComplete > 0) {
        if (!toursData?.getAllToursToShow?.id) {
          // New users won't get the trader resources tour automatically shown.
          upsertShowTraderResourcesTour({ variables: { showTraderResourcesTour: false } });
        } else if (toursData?.getAllToursToShow?.showTraderResourcesTour) {
          enableTour(Tours.TraderResources);
        }
      }
    }, 1000);
    return () => { // Return callback to run on unmount.
      window.clearTimeout(timer);
    };
  }, [toursData, userPrivacyComplete]);

  // Disable tour for mobile.
  if (isMobile) {
    return null;
  }

  const isOpen = activeTour === Tours.TraderResources;

  if (!isOpen) {
    document.body.style.overflow = ''
  }

  return (
    <>
      {isMounted && (
        <Tour
          steps={steps}
          isOpen={isOpen}
          badgeContent={(curr, tot) => `${curr}/${tot}`}
          maskSpace={currentStep === TraderResourcesStepKeys.ConceptsColumns ? 10 : 0}
          showNavigation={false}
          goToStep={newStep ? newStep : currentStep}
          inViewThreshold={250}
          lastStepNextButton={<DoneButton onClick={closeAndUpdateSettings}>Done</DoneButton>}
          nextButton={<NextButton endTour={closeAndUpdateSettings} />}
          prevButton={<PrevButton />}
          getCurrentStep={curr => updateCurrentStep(curr)}
          onAfterOpen={() => (document.body.style.overflow = 'hidden')}
          onBeforeClose={() => (document.body.style.overflow = '')}
          startAt={startAt}
        >
          <CustomClose onClick={closeAndUpdateSettings}>Skip Tutorial</CustomClose>
        </Tour>
      )}
    </>
  );
}

export default TraderResourcesTour;
