import { useCallback, useEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

function useElementSize(elementRef) {
  const [size, setSize] = useState({
    width: null,
    height: null,
    offsetTop: 0,
  });
  const observerRef = useRef(null);

  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = elementRef?.current;
    if ((node !== null) && (typeof(node) !== 'undefined')) {

      setSize({
        width: node.offsetWidth || size.width,
        height: node.offsetHeight || size.height,
        offsetTop: node.offsetTop || size.offsetTop,
      })
    }
  }, [elementRef]);

  // Initial size on mount
  useEffect(() => {
    const node = elementRef?.current;
    if (node) {
      if (!observerRef.current) {
        observerRef.current = new ResizeObserver(updateSize);
      }
      observerRef.current.observe(node);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [elementRef.current]);

  return size;
}

export default useElementSize;
