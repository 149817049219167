import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import Skeleton from '@material-ui/lab/Skeleton';
import BaseListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const ListItem = styled(BaseListItem)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: ${theme('palette.common.white')};

  .MuiListItemIcon-root {
    min-width: auto;
  }
`;

const RemoveIcon = styled(RemoveCircleOutlineIcon)`
  font-size: 1.125rem;
  margin-right: 0.5rem;
`;

function getCheckedValues(values) {
  return values.filter(v => v.checked).map(v => v.name);
}

MobileFilterList.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
  })),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

function MobileFilterList({ values = [], onChange, onRemove, loading = false }) {
  const [checked, setChecked] = useState(() => getCheckedValues(values));

  useEffect(() => {
    setChecked(getCheckedValues(values));
  }, [values])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange && onChange(newChecked, value, currentIndex === -1);
  };

  const handleRemove = (e, value) => {
    e.preventDefault();
    onRemove(value)
  }

  return (
    <List disablePadding>
      {loading ? Array.from(new Array(6)).map((item, index) => (
        <ListItem key={index} button divider>
          <ListItemIcon>
            <Skeleton variant='rect' width='100%' height='100%' />
          </ListItemIcon>
          <ListItemText primary={<Skeleton width='100%' />} />
        </ListItem>
      )) : values.map((value, index) => {
        const { name, label } = value;
        const labelId = `checkbox-list-label-${name}`;

        return (
          <ListItem key={value.name} role={undefined} button divider onClick={handleToggle(name)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(name) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
                size='small'
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={label} disableTypography />
            {onRemove && <RemoveIcon onClick={(e) => handleRemove(e, value)} />}
          </ListItem>
        );
      })}
    </List>
  );
}

export default React.memo(MobileFilterList);
