import Add from 'components/Icon/components/Add';
import AddToDash from 'components/Icon/components/AddToDash';
import AddToPlaylist from 'components/Icon/components/AddToPlaylist';
import AddToSlides from 'components/Icon/components/AddToSlides';
import Alert from 'components/Icon/components/Alert';
import AlertTagLong from 'components/Icon/components/AlertTagLong';
import AlertTagShort from 'components/Icon/components/AlertTagShort';
import Apple from 'components/Icon/components/Apple';
import ArrowRight from 'components/Icon/components/ArrowRight';
import ArrowForward from 'components/Icon/components/ArrowForward';
import BookmarkFill from 'components/Icon/components/BookmarkFill';
import BookmarkOutline from 'components/Icon/components/BookmarkOutline';
import BulletList from 'components/Icon/components/BulletList';
import Calendar from 'components/Icon/components/Calendar';
import Checkmark from 'components/Icon/components/Checkmark';
import CheckmarkBold from 'components/Icon/components/CheckmarkBold';
import ChevronDown from 'components/Icon/components/ChevronDown';
import ChevronLeft from 'components/Icon/components/ChevronLeft';
import ChevronRight from 'components/Icon/components/ChevronRight';
import ChevronUp from 'components/Icon/components/ChevronUp';
import Close from 'components/Icon/components/Close';
import ContentCopy from 'components/Icon/components/ContentCopy';
import CircleOutline from 'components/Icon/components/CircleOutline';
import Dashboard from 'components/Icon/components/Dashboard';
import Data from 'components/Icon/components/Data';
import Download from 'components/Icon/components/Download';
import Edit from 'components/Icon/components/Edit';
import Explore from 'components/Icon/components/Explore';
import Facebook from 'components/Icon/components/Facebook';
import Filter from 'components/Icon/components/Filter';
import FilterConcepts from 'components/Icon/components/FilterConcepts';
import FilterSlider from 'components/Icon/components/FilterSlider';
import FilterSliderThick from 'components/Icon/components/FilterSliderThick';
import FollowFill from 'components/Icon/components/FollowFill';
import FollowOutline from 'components/Icon/components/FollowOutline';
import GooglePlay from 'components/Icon/components/GooglePlay';
import Grid from 'components/Icon/components/Grid';
import Home from 'components/Icon/components/Home';
import Instagram from 'components/Icon/components/Instagram';
import Linkedin from 'components/Icon/components/Linkedin';
import Lisbyn from 'components/Icon/components/Lisbyn';
import Listen from 'components/Icon/components/Listen';
import Login from 'components/Icon/components/Login';
import NewsIcon from 'components/Icon/components/NewsIcon';
import Play from 'components/Icon/components/Play';
import PlayCircle from 'components/Icon/components/PlayCircle'
import Playlist from 'components/Icon/components/Playlist';
import Plus from 'components/Icon/components/Plus';
import PopOut from 'components/Icon/components/PopOut';
import Reverse from 'components/Icon/components/Reverse';
import Remove from 'components/Icon/components/Remove';
import RemoveFill from 'components/Icon/components/RemoveFill';
import RemoveOutline from 'components/Icon/components/RemoveOutline';
import Reorder from 'components/Icon/components/Reorder';
import Replay from 'components/Icon/components/Replay';
import Rss from 'components/Icon/components/Rss';
import Schedule from 'components/Icon/components/Schedule';
import Search from 'components/Icon/components/Search';
import SelectAll from 'components/Icon/components/SelectAll';
import Share from 'components/Icon/components/Share';
import Show from 'components/Icon/components/Show';
import SharpPlayCircle from 'components/Icon/components/SharpPlayCircle';
import SlidesFill from 'components/Icon/components/SlidesFill';
import SlidesOutline from 'components/Icon/components/SlidesOutline';
import Spotify from 'components/Icon/components/Spotify';
import Trade from 'components/Icon/components/Trade';
import Twitter from 'components/Icon/components/Twitter';
import Undo from 'components/Icon/components/Undo';
import VideoTrade from 'components/Icon/components/VideoTrade';
import WatchLaterFill from 'components/Icon/components/WatchLaterFill';
import WatchLaterOutline from 'components/Icon/components/WatchLaterOutline';
import Youtube from 'components/Icon/components/Youtube';
import HeadsetRoundedIcon from '@material-ui/icons/HeadsetRounded';
import ShareIcon from '@material-ui/icons/Share';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BarChartIcon from '@material-ui/icons/BarChart';
import SpeedIcon from '@material-ui/icons/Speed';
import { asyncComponent } from '@jaredpalmer/after';

const icons = {
  youtube: Youtube,
  watchLaterOutline: WatchLaterOutline,
  watchLaterFill: WatchLaterFill,
  undo: Undo,
  twitter: Twitter,
  trade: Trade,
  spotify: Spotify,
  slidesOutline: SlidesOutline,
  slidesFill: SlidesFill,
  sharpPlayCircle: SharpPlayCircle,
  show: Show,
  share: Share,
  selectAll: SelectAll,
  search: Search,
  schedule: Schedule,
  rss: Rss,
  reverse: Reverse,
  reorder: Reorder,
  replay: Replay,
  removeOutline: RemoveOutline,
  removeFill: RemoveFill,
  popOut: PopOut,
  plus: Plus,
  playlist: Playlist,
  play: Play,
  login: Login,
  linkedin: Linkedin,
  lisbyn: Lisbyn,
  listen: Listen,
  instagram: Instagram,
  home: Home,
  grid: Grid,
  googlePlay: GooglePlay,
  followOutline: FollowOutline,
  followFill: FollowFill,
  filter: Filter,
  filterSlider: FilterSlider,
  filterSliderThick: FilterSliderThick,
  filterConcepts: FilterConcepts,
  facebook: Facebook,
  explore: Explore,
  edit: Edit,
  download: Download,
  dashboard: Dashboard,
  close: Close,
  chevronUp: ChevronUp,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  chevronDown: ChevronDown,
  checkmark: Checkmark,
  checkmarkBold: CheckmarkBold,
  calendar: Calendar,
  bookmarkOutline: BookmarkOutline,
  bookmarkFill: BookmarkFill,
  bulletList: BulletList,
  apple: Apple,
  alertTagLong: AlertTagLong,
  alertTagShort: AlertTagShort,
  alert: Alert,
  addToSlides: AddToSlides,
  addToPlaylist: AddToPlaylist,
  addToDash: AddToDash,
  add: Add,
  data: Data,
  arrowRight: ArrowRight,
  arrowForward: ArrowForward,
  contentCopy: ContentCopy,
  circleOutline: CircleOutline,
  videoTrade: VideoTrade,
  headset: HeadsetRoundedIcon,
  shareV2: ShareIcon,
  upArrow: ArrowUpwardIcon,
  barChart: BarChartIcon,
  gaugeFast: SpeedIcon,
  playCircle: PlayCircle,
  remove: Remove,
  news: NewsIcon,
  
}

export default icons;
