import React from 'react';
import PropTypes from 'prop-types';
import blogPostShape from '../../shapes/blogPostShape';
import Grid from '@material-ui/core/Grid';
import BlogCard from '../../routes/Blog/BlogListPage/components/BlogCard';
import styled from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import MuiGridList from '@material-ui/core/GridList';

const GridListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const GridList = styled(MuiGridList)`
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;

  > div {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
`;

const StyledGridItem = styled(Grid)`
  padding-right: 1rem;
`;

BlogPostFeed.propTypes = {
  blogPosts: PropTypes.arrayOf(blogPostShape),
  page: PropTypes.string,
};

function BlogPostFeed({ blogPosts, page }) {
  if (!blogPosts) {
    return <></>;
  }

  return (
    <>
      <Hidden smDown>
        {blogPosts.map(blogPost => (
          <StyledGridItem item md={3} key={blogPost.uid}>
            <BlogCard
              blogPost={blogPost}
              cardSize={page && page.includes('trader-resources') ? 'regular' : 'smaller'}
            />
          </StyledGridItem>
        ))}
      </Hidden>

      {/* Mobile-only blog post feed */}
      <Hidden mdUp>
        <GridListWrapper>
          <GridList
            cols={1.5}
            cellHeight={116}
            spacing={13}
            component='div'
          >
            {blogPosts.map(blogPost => (
              <BlogCard
                blogPost={blogPost}
                key={blogPost.uid}
                $noMax
              />
            ))}
          </GridList>
        </GridListWrapper>
      </Hidden>
    </>
  );
}

export default BlogPostFeed;
