import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import followedShowsEpisodesQuery from './followedShowsEpisodes.graphql';
import { useDashboardContext } from 'context/DashboardContext';
import EpisodeCard from 'components/EpisodeCard';
import SortOptions from 'constants/SortOptions';
import DashboardPageHeader from '../DashboardPageHeader';
import DashboardAlert from '../DashboardAlert';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ACTION_FOLLOW_SHOW, ACTION_REMOVE_ITEM } from 'components/CardButtons/AddToPlaylist';
import ShowsList from './List';
import useUpdateDashboardPageViewed from 'hooks/useUpdateDashboardPageViewed';
import getDashboardViewedQuery from '../../../../graphql/queries/getDashboardViewedAt.graphql';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import OptInAlert from '../OptInAlert';
import { dataLayerPush } from 'utils/dataLayer';
import { useFollowedShowsContext } from 'context/Dashboard/FollowedShows';

const PER_PAGE_LIMIT = 24;

MyShows.propTypes = {
  edit: PropTypes.bool,
};

function MyShows({ edit }) {
  const { state: dashboardState, setMyShowsFilters } = useDashboardContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [updateMyShowsViewedAt] = useUpdateDashboardPageViewed('myShows');
  const { preferences } = useGetUserDataSettings();
  const { data: dashboardViewed } = useQuery(getDashboardViewedQuery, {
    fetchPolicy: 'no-cache',
  });
  const [episodes, setEpisodes] = useState([]);
  const followedShowsContext = useFollowedShowsContext();

  const { data, loading, error, fetchMore, refetch } = useQuery(followedShowsEpisodesQuery, {
    variables: {
      filters: dashboardState?.myShowsFilters ?? [],
      pagination: {
        skip: 0,
        limit: PER_PAGE_LIMIT,
      },
      sort: {
        sortBy: 'published_at',
        sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
      },
    },
    fetchPolicy: 'network-only'
  });

  const fetchNextPage = () => {
    dataLayerPush(`'My Shows' infinite scroll pagination loaded`);
    return fetchMore({
      variables: {
        pagination: {
          skip: episodes.length,
          limit: PER_PAGE_LIMIT
        },
        sort: {
          sortBy: 'published_at',
          sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
        },
      },
    })
  };

  const totalItems = data?.followedShowsEpisodes?.count ?? 0;
  const hasMore = episodes?.length < totalItems;

  useEffect(() => {
    handleUpdateEpisodeList();
  }, [data]);

  useEffect(() => {
    refetch();

    // we only want to remove episodes from filters list when they are no episodes to display to avoid unnecessary re renders
    if (episodes.length === 0 && dashboardState.myShowsFilters.showUids.length > 0) {
      const newFilters = dashboardState.myShowsFilters.showUids.filter(filteredShow => followedShowsContext.followedShows.items.find(show => show.uid === filteredShow));
      setMyShowsFilters({ ...dashboardState.myShowsFilters, showUids: newFilters });
    }

  }, [followedShowsContext.followedShows])

  useEffect(() => {
    if (!loading && !error) {
      updateMyShowsViewedAt();
    }
  }, [loading, error]);

  function handleUpdateEpisodeList() {
    // if the cache has updated shows as unfollowed, filter them out of the list
    const updatedItemList = data?.followedShowsEpisodes?.items?.filter(item => item.show.isFollowing) ?? [];

    setEpisodes(updatedItemList);
  }

  if (!preferences?.acceptedAt) {
    return <OptInAlert loading={loading} accepted={false} />
  }

  return (
    <>
      {episodes?.length > 0 && (
        <DashboardPageHeader edit={edit}>
          My Shows
        </DashboardPageHeader>
      )}
      <div>
        {error &&
          <DashboardAlert severity='error' message='Failed to load your shows, please reload to try again.' />
        }
        {loading && (
          <Grid container spacing={2}>
            {Array.from(new Array(8)).map((episode, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
                <EpisodeCard display={isMobile ? 'listItem' : 'vertical'} loading={true} />
              </Grid>
            ))}
          </Grid>
        )}
        {!error && !loading &&
          <ShowsList
            edit={edit}
            episodes={episodes}
            fetchNextPage={fetchNextPage}
            hasMore={hasMore}
            excludedActions={[ACTION_FOLLOW_SHOW, ACTION_REMOVE_ITEM]}
            lastViewedDate={dashboardViewed?.getDashboardViewedAt?.myShowsViewedAt}
            onUndoUnfollow={(show) => followedShowsContext.followShow(show)}
          />
        }
      </div>
    </>
  );
};

export default React.memo(MyShows);
