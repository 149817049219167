import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import PersonalPanel from './components/PersonalPanel';
import PasswordPanel from './components/PasswordPanel';
import EmailPanel from './components/EmailPanel';
import getAuth0FullUserQuery from '../../../graphql/queries/getAuth0FullUser.graphql';
import getUserQuery from '../../../graphql/queries/getUser.graphql';

const GOOGLE = 'google';

YourInfo.propTypes = {
  auth0Id: PropTypes.string,
};

function YourInfo({ auth0Id }) {
  const { data, refetch: refetchDb } = useQuery(getUserQuery, {
    skip: typeof window === 'undefined',
  });
  const { data: auth0Data, refetch: refetchAuth0 } = useQuery(getAuth0FullUserQuery, {
    skip: typeof window === 'undefined',
  });

  const dbUser = get(data, 'myUser');
  const socialUser = auth0Id.includes(GOOGLE);
  const personalInfo = get(auth0Data, 'auth0FullUser');

  if (!personalInfo) {
    return null;
  }

  return (
    <Grid container>
      <PersonalPanel
        auth0Id={auth0Id}
        dbUser={dbUser}
        refetchDb={refetchDb}
        refetchAuth0={refetchAuth0}
        personalInfo={personalInfo}
        socialUser={socialUser}
        user={dbUser}
      />
      <EmailPanel
        auth0Id={auth0Id}
        refetchAuth0={refetchAuth0}
        personalInfo={personalInfo}
        socialUser={socialUser}
        user={dbUser}
      />
      <PasswordPanel
        auth0Id={auth0Id}
        personalInfo={personalInfo}
        socialUser={socialUser}
      />
    </Grid>
  );
}

export default React.memo(YourInfo);
