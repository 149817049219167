import React from 'react';
import styled, { css } from 'styled-components';
import PageLink from 'components/PageLink';
import { ifProp, theme } from 'styled-tools';

const MegaMenuImageLink = styled(PageLink)`
  ${ifProp(
    '$maxWidth',
    css`
      width: max-content;
    `
  )}

  ${ifProp(
    '$tourDisabled',
    css`
      &:hover {
        cursor: default;
      }
    `
  )}

  .avatar-icon {
    background-color: ${theme('colors.backgroundGray')};
    color: ${theme('colors.black')};
    height: 5rem;
    width: 5rem;
  }
`;

export default MegaMenuImageLink;
