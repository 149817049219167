import React from 'react';
import { useAuth } from '../context/AuthContext';
import { DATA_PRIVACY_CLAIM } from 'utils/constants';

export function useGetUserDataSettings() {
  const { user } = useAuth();
  const dataUsage = user && user[DATA_PRIVACY_CLAIM]?.dataUsageItems;

  if (!user || !dataUsage) {
    return {
      marketing: {},
      preferences: {},
      history: {},
      functional: {}
    }
  }


  return dataUsage && {
    marketing: dataUsage.marketing,
    preferences: dataUsage.preferences,
    history: dataUsage['personal history'],
    functional: dataUsage.functional
  };
}
