import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import BaseFormControl from '@material-ui/core/FormControl';

const FormControl = styled(BaseFormControl)`
  margin-bottom: 0.875rem;

  ${switchProp('width', {
    full: css`
      width: 100%;
    `,
    half: css`
      width: 50%;
    `,
    third: css`
      width: 33.33%;
    `,
  })}
`;

const Label = styled(InputLabel)`${({ theme }) => css`
  color: ${theme.colors.black};
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 0.8125rem;
  font-weight: 700;
  margin-bottom: 0.875rem;
  position: relative;
  text-transform: uppercase;
  transform: initial;
`}`;

const TextInput = styled(TextField)`${({ theme }) => css`
  min-height: 2.5rem;

  input {
    border: 0.0625rem solid ${theme.colors.inputGray};
    border-radius: 0;
    height: 1.5625rem;
    padding-left: 0.25rem;
  }

  .Mui-error {
    input {
      border: 0.0625rem solid ${theme.colors.ttRed};
    }
  }

  .MuiInput-underline {
    &:after {
      position: relative;
    }

    &.Mui-focused {
      &:after {
        transform: none;
      }
    }

    &.Mui-error {
      &:before {
        border-bottom: 0.0625rem solid rgb(227, 27, 35);
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      &:hover:not(.Mui-disabled):before {
        border-bottom: 0.125rem solid ${theme.colors.ttRed};
      }
    }
  }
`}`;

const Select = styled(TextField)`${({ theme }) => css`
  height: 2.5rem;

  .MuiInputBase-root {
    border: 0.0625rem solid ${theme.colors.inputGray};
    height: inherit;
    padding-left: 0.25rem;
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 0;
    }
  }
`}`;

const propTypes = {
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  select: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  error: PropTypes.bool,
}

function Input({
  defaultValue,
  label,
  id,
  onChange,
  options,
  select,
  type,
  variant,
  value,
  width,
  error,
  ...props
}) {
  return (
    <FormControl width={width} error={error}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      {!select &&
        <TextInput
          defaultValue={defaultValue}
          id={id}
          onChange={onChange}
          type={type}
          variant={variant}
          value={value}
          error={error}
          {...props}
        />
      }
      {select &&
        <Select defaultValue={''} id={id} onChange={onChange} variant={variant} value={value} select error={error} {...props}>
          {options && options.map((option, index) => (
            <MenuItem key={`option-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      }
    </FormControl>
  );
}

Input.propTypes = propTypes;

export default React.memo(Input);
