import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import ListItem from '@material-ui/core/ListItem';
import RemoveIcon from '@material-ui/icons/Remove';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BaseListSubheader from '@material-ui/core/ListSubheader';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import VisibilityWrapper from 'components/VisibilityWrapper';
import DateFormatter from 'components/DateFormatter';
import RichTextField from 'components/RichTextField';
import useElementSize from 'hooks/useElementSize';
import menuItemShape from 'shapes/menuItemShape';
import PageLink from 'components/PageLink';
import Button from 'components/Button';
import Icon from 'components/Icon';


const Drawer = styled(BaseDrawer)`
  /* !important needed here to override MUI inline styles */
  inset: 60px 0 0 !important;

  .MuiDrawer-paper {
    right: 0;
    top: 60px;
  }

  .MuiBackdrop-root {
    background-color: transparent;
    top: 60px;
  }

  ${ifProp(
    '$displayAlert',
    css`
      /* !important needed here to override MUI inline styles */
      inset: 116px 0 0 !important;
      
       ${props => props.theme.breakpoints.down('xs')} {
         inset: 195px 0 0 !important;
       }

      .MuiDrawer-paper {
        top: 116px;
        
        ${props => props.theme.breakpoints.down('xs')} {
          top: 195px;
        }
      }

      .MuiBackdrop-root {
        top: 116px;
        
        ${props => props.theme.breakpoints.down('xs')} {
          top: 195px;
        }
      }
    `
  )}
`;

const SidebarMain = styled.div`
  background-color: ${theme('palette.common.white')};
  font-family: ${theme('typography.fontFamilySecondary')};
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(100vh - 60px);
  left: 0px;
  // A lot of mobile browsers deliberately do not support {position:fixed;} on the grounds that fixed elements could get in the way on a small screen.
  // It is entirely possible to use {position:absolute;} when not have any issues.
  position: absolute;
  right: 0px;
  transition: all 200ms ease-in-out;
  transform: translateX(0px);
  overflow-x: hidden;

  ${ifProp(
    'childOpen',
    css`
      transform: translateX(-100vw);
      overflow-x: visible;
    `
  )}

  ${ifProp(
    '$displayAlert',
    css`
      height: calc(100vh - 116px);
      
      ${props => props.theme.breakpoints.down('xs')} {
        height: calc(100vh - 195px);
      }
    `
  )}
`;

const ListSubheader = styled(BaseListSubheader)`
  text-transform: uppercase;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  color: ${theme('palette.common.black')};
  background-color: ${theme('palette.common.white')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.31;
  margin: 0 0 1.5rem;
  padding-left: ${ifProp('$disableGutters', '0.3rem', '1.875rem')};
`;

const BackButton = styled(Button)`
  display: flex;
  align-items: center;

  .MuiButton-startIcon {
    margin-right: 0;

    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }
`;

const BackButtonLabel = styled.span`
  padding: 0.4375rem 0.0625rem;
  font-size: 0.8125rem;
  font-weight: bold;
`;

const TopLevelListItem = styled(ListItem)`
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.875rem 0.875rem 0.875rem 1.875rem;
`;

const Submenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(100vh - 60px);
  transition: all 0.5s ease-in-out;
  background-color: ${theme('palette.common.white')};
  transform: translateX(100vw);
  z-index: 1301;
  visibility: hidden;

  ${ifProp(
    'open',
    css`
      visibility: visible;
    `
  )}

  ${ifProp(
    '$displayAlert',
    css`
      height: calc(100vh - 116px);
      
      ${props => props.theme.breakpoints.down('xs')} {
        height: calc(100vh - 195px);
      }
    `
  )}
`;

const SubmenuList = styled(List)`
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  padding-bottom: 8.375rem;
  -webkit-overflow-scrolling: touch;
`;

const SubmenuListParentItem = styled.div`
  ${ifProp(
    '$hideItem',
    css`
      display: none;
    `
  )}
`;

const SubmenuAccordion = styled(Accordion)`
  box-shadow: none;

  &:before {
    background-color: transparent;
    height: 0;
  }
`;

const SubmenuAccordionHeader = styled(AccordionSummary)`
  font-weight: 700;
  min-height: auto;
  padding: 0.5rem 1.875rem;

  &.MuiAccordionSummary-root {
    &.Mui-expanded {
      min-height: auto;
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon {
    color: ${theme('palette.common.black')};
    margin-right: 0;
    padding: 0;
    transform: none;

    svg {
      font-size: 1.125rem;
    }
  }
`;

const SeeAllLinkItem = styled(ListItem)`
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 1.875rem;
`;

const SeeAllTextWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${theme('colors.lightRed')};
  display: flex;
  max-width: fit-content;

  svg {
    margin-left: 0.5rem;
  }
`;

const SubmenuGroup = styled.ul`
  padding-left: 0;
  width: 100%;

  ${ifProp(
    '$noHeaderItem',
    css`
      margin-top: -1rem;
    `
  )}
`;

const SubmenuGroupHeader = styled.div`
  display: block;
  flex: 1;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: condensed;
  font-family: ${theme('typography.fontFamilySecondary')};
  text-decoration: none;
  line-height: 1.31;
  letter-spacing: 0.32px;
  color: ${theme('palette.common.black')};
`;

const SubmenuListItem = styled(ListItem)`
  font-size: 0.8125rem;
  padding: 0.5rem 1.875rem;
`;

const SubmenuListItemText = styled(ListItemText)`
  align-items: center;
  display: flex;

  svg {
    margin-left: 0.375rem;
  }

  ${ifProp(
    '$withIcon',
    css`
      color: ${theme('colors.ttRed')};
    `
  )}
`;

const SubmenuDate = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  padding: 0 1.875rem;
  text-transform: uppercase;
`;

const SubmenuSnippet = styled(RichTextField)`
  font-size: 0.8125rem;
  margin-top: 0;
  padding: 0.5rem 1.875rem;

  h3,
  h5 {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 0.8125rem;
    margin-top: 0.75rem;
  }

  h5 {
    margin-top: 0.5rem;
  }

  p {
    font-size: 0.8125rem;
    margin-top: 0;
  }
`;

const SubmenuAccordionDetails = styled(AccordionDetails)`
  background-color: ${theme('colors.backgroundGray')};
  padding: 0;

  ${SubmenuListItem} {
    padding: 0.5rem 2.5rem;
  }
`;

MainNavMobile.propTypes = {
  displayAlert: PropTypes.bool,
  menuItems: PropTypes.arrayOf(menuItemShape),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function MainNavMobile({ displayAlert, menuItems, open, onClose }) {
  const menuRef = useRef(null);
  const { height } = useElementSize(menuRef);
  const menuHeight = height - 116;
  const container =
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  const [expanded, setExpanded] = React.useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const onLinkClick = () => {
    // When a link is clicked, reset the menu and close the drawer.
    setActiveItem(null);
    onClose();
  };

  const handleClose = () => {
    setActiveItem(null);
    onClose();
  };

  const handleMenuItemChange = (item) => (event, newExpanded) => {
    setExpanded(newExpanded ? item : false);
  };

  return (
    <Drawer
      container={container}
      $displayAlert={displayAlert}
      variant='temporary'
      anchor='left'
      open={open}
      onClose={handleClose}
      ref={menuRef}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <SidebarMain childOpen={null !== activeItem} $displayAlert={displayAlert}>
        <List
          component='nav'
          subheader={
            <ListSubheader component='div'>
              <span>Main Menu</span>
            </ListSubheader>
          }
        >
          {menuItems &&
            menuItems.map((menuItem, i) => {
              const { link, linkText, menuColumns, visibility } =
                menuItem || {};
              const hasChildren = menuColumns && menuColumns.length > 0;

              return (
                <VisibilityWrapper visibility={visibility} key={i}>
                  {hasChildren ? (
                    <TopLevelListItem button onClick={() => setActiveItem(i)}>
                      <ListItemText primary={linkText} disableTypography />
                      <ChevronRightIcon />
                    </TopLevelListItem>
                  ) : (
                    <PageLink
                      component={TopLevelListItem}
                      componentProps={{ button: true }}
                      link={link}
                      onClick={onLinkClick}
                      absoluteComponent='a'
                    >
                      <ListItemText primary={linkText} disableTypography />
                    </PageLink>
                  )}

                  {hasChildren && (
                    <Submenu
                      open={activeItem === i}
                      $menuHeight={menuHeight}
                      $displayAlert={displayAlert}
                    >
                      <SubmenuList
                        subheader={
                          <ListSubheader component='div' $disableGutters>
                            <BackButton
                              startIcon={<ChevronLeftIcon />}
                              onClick={() => setActiveItem(null)}
                            >
                              <BackButtonLabel>Main Menu</BackButtonLabel>
                            </BackButton>
                          </ListSubheader>
                        }
                      >
                        {link && (
                          <PageLink
                            component={SeeAllLinkItem}
                            componentProps={{ button: true }}
                            link={link}
                            onClick={onLinkClick}
                            absoluteComponent='a'
                          >
                            <SeeAllTextWrapper>
                              <ListItemText
                                primary={linkText}
                                disableTypography
                              />
                              <Icon icon='explore' />
                            </SeeAllTextWrapper>
                          </PageLink>
                        )}
                        {menuColumns.map((column, columnIndex) => (
                          <Fragment key={columnIndex}>
                            {column.submenu.map((subItem, subIndex) => {
                              const snippet = subItem.snippet;
                              const hideItem =
                                subItem.deviceVisibility === 'desktop_only';
                              const featuredItem =
                                subItem.menuFeatured?.featuredItem;

                              return (
                                <Fragment key={subItem.linkText}>
                                  <SubmenuListParentItem $hideItem={hideItem}>
                                    {subItem.submenu &&
                                      subItem.submenu.length > 0 && (
                                        <SubmenuAccordion
                                          expanded={
                                            expanded === subItem.linkText
                                          }
                                          onChange={handleMenuItemChange(
                                            subItem.linkText
                                          )}
                                        >
                                          <SubmenuAccordionHeader
                                            expandIcon={
                                              expanded === subItem.linkText ? (
                                                <RemoveIcon className='minus-icon' />
                                              ) : (
                                                <AddIcon className='plus-icon' />
                                              )
                                            }
                                          >
                                            <ListItemText
                                              primary={subItem.linkText}
                                              disableTypography
                                            />
                                          </SubmenuAccordionHeader>
                                          <SubmenuAccordionDetails>
                                            {subItem.submenu &&
                                              subItem.submenu.length > 0 && (
                                                <SubmenuGroup
                                                  $noHeaderItem={
                                                    !subItem.linkText
                                                  }
                                                >
                                                  {subItem.submenu.map(
                                                    (groupItem, groupIndex) => (
                                                      <PageLink
                                                        component={
                                                          SubmenuListItem
                                                        }
                                                        componentProps={{
                                                          button: true,
                                                        }}
                                                        link={groupItem.link}
                                                        key={groupIndex}
                                                        onClick={onLinkClick}
                                                        absoluteComponent='a'
                                                      >
                                                        <SubmenuListItemText
                                                          $withIcon={
                                                            groupItem.linkIcon
                                                          }
                                                          primary={
                                                            <>
                                                              {
                                                                groupItem.linkText
                                                              }
                                                              {groupItem.linkIcon && (
                                                                <Icon
                                                                  icon={
                                                                    groupItem.linkIcon
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          }
                                                          disableTypography
                                                        />
                                                      </PageLink>
                                                    )
                                                  )}
                                                </SubmenuGroup>
                                              )}
                                          </SubmenuAccordionDetails>
                                        </SubmenuAccordion>
                                      )}
                                    {subItem.submenu &&
                                      subItem.submenu.length === 0 && (
                                        <SubmenuGroupHeader>
                                          {subItem.link ? (
                                            <PageLink
                                              component={SubmenuListItem}
                                              componentProps={{ button: true }}
                                              link={subItem.link}
                                              onClick={onLinkClick}
                                              absoluteComponent='a'
                                            >
                                              <SubmenuListItemText
                                                primary={
                                                  <>
                                                    {subItem.linkText}
                                                    {subItem.linkIcon && (
                                                      <Icon
                                                        icon={subItem.linkIcon}
                                                      />
                                                    )}
                                                  </>
                                                }
                                                disableTypography
                                              />
                                            </PageLink>
                                          ) : (
                                            <SubmenuListItem>
                                              <ListItemText
                                                primary={subItem.linkText}
                                                disableTypography
                                              />
                                            </SubmenuListItem>
                                          )}
                                        </SubmenuGroupHeader>
                                      )}
                                    {featuredItem && (
                                      <>
                                        <SubmenuGroupHeader>
                                          <SubmenuListItem
                                            button={true}
                                            component='a'
                                            href={featuredItem.url}
                                          >
                                            <ListItemText
                                              primary={featuredItem.title}
                                              disableTypography
                                            />
                                          </SubmenuListItem>
                                        </SubmenuGroupHeader>
                                        <SubmenuDate>
                                          <DateFormatter
                                            date={featuredItem.postDate}
                                          />
                                        </SubmenuDate>
                                      </>
                                    )}
                                    {snippet && (
                                      <SubmenuSnippet richText={snippet} />
                                    )}
                                  </SubmenuListParentItem>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        ))}
                      </SubmenuList>
                    </Submenu>
                  )}
                </VisibilityWrapper>
              );
            })}
        </List>
      </SidebarMain>
    </Drawer>
  );
}

export default MainNavMobile;
