import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import Button from 'components/Button';
import IconTitle from 'components/IconTitle';
import config from '../../config';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1301;
  top: 94px;
  left: 0;
  right: 0;
  height: calc(100vh - 94px);
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  transform: translateY(-100vh) translateY(94px);
  
  background: ${theme('palette.common.white')};
  
  ${ifProp('open', css`
    transform: translateY(0px);
    visibility: visible;
  `)};
`;

const ContentWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${theme('typography.fontFamilySecondary')};
`;

const FiltersToolbar = styled(Toolbar)`
  flex: 0 0 auto;
  background-color: ${theme('palette.common.white')};
  border-bottom: 0.0625rem solid ${prop('theme.colors.mediumGray')};
  display: flex;
  justify-content: space-between;
  height: 2.75rem;
  min-height: 2.75rem;
  padding: 0 1.25rem;
  
  button {
    margin-right: 0;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0 5.625rem;
  }
`;

const FiltersBody = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const FiltersActionBar = styled.div`
  flex: 0 0 auto;
  height: 3.5rem;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, .16);
  padding: .5625rem 1.875rem;
  z-index: 1302;
  
  .MuiButtonBase-root {
    padding: 0.625rem 2.8125rem 0.5625rem;
  }
`;

const HelpContainer = styled.div`
  margin-top: auto;
  margin-left: 1rem;
  padding-top: 1rem;
  
  a {
    text-decoration: none;
    color: ${theme('colors.black')};
    cursor: pointer;
    font-size: 0.8125rem;
    
    & :hover {
      color: ${theme('colors.ttRed')};
    }
  }
`;

MobileFilters.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  header: PropTypes.node,
};

function MobileFilters({ open, onClose, children, header }) {

  return (
    <Wrapper open={open}>
      <ContentWrap>
        <FiltersToolbar>
          <IconTitle>
            {header ? header : (
              <><TuneIcon fontSize='small' /><span> Filter</span></>
            )}
          </IconTitle>
          <IconButton
            aria-label='Close filters'
            color='inherit'
            edge='end'
            onClick={onClose}
          >
            <CloseIcon fontSize='small' className='cancel' />
          </IconButton>
        </FiltersToolbar>
        <FiltersBody>
          {children}
          <HelpContainer>
            <a href={config.freshDesk.articles.dashboard} target='blank'>Need Help?</a>
          </HelpContainer>
        </FiltersBody>
        <FiltersActionBar>
          <Button variant='contained' color='primary' fullWidth onClick={onClose}>
            Apply Filters
          </Button>
        </FiltersActionBar>
      </ContentWrap>
    </Wrapper>
  );
}

export default React.memo(MobileFilters);
