import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxList from 'components/AccordionGroup/CheckboxList';
import get from 'lodash.get';
import Alert from '@material-ui/lab/Alert';
import { useDashboardContext } from 'context/DashboardContext';
import UndoSnackBar from 'components/UndoSnackBar';
import { dataLayerPush } from 'utils/dataLayer';
import { useFollowedShowsContext } from 'context/Dashboard/FollowedShows';

MyShowsSidebar.propTypes = {
  component: PropTypes.elementType,
};

function MyShowsSidebar({ component: Component = CheckboxList }) {
  const [showsList, setShowsList] = useState({ current: [], previous: [] });
  const [toDelete, setToDelete] = useState(null);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const { state: dashboardState, setMyShowsFilters, setFollowedShowsCount } = useDashboardContext();
  const { followedShowsCount, myShowsFilters } = dashboardState;
  const followedShowsContext = useFollowedShowsContext();
  const { followedShows, loading, error, unfollowShow } = followedShowsContext;

  const shows = followedShows?.items.map(show => ({
    __typename: "Show",
    uid: show.uid,
    name: show.uid,
    label: show.title,
    isFollowing: show.isFollowing,
    checked: (dashboardState?.myShowsFilters.showUids ?? []).includes(show.uid),
  }));

  useEffect(() => {
    setShowsList({ ...showsList, current: shows });
    setFollowedShowsCount(shows.length);
    return () => handleUnfollowShow(toDeleteRef.current);
  }, [shows.length]);

  async function handleClickUnfollow(show) {
    await setShowSnackBar(false);
    setFollowedShowsCount(followedShowsCount - 1)

    // if we delete multiple shows in rapid succession we need to delete the old one before replacing the state prop with the new one
    handleUnfollowShow(toDelete);

    // set the next show to be deleted
    setToDelete(show);
    setShowSnackBar(true);

    // remove the to be deleted show from the page display
    const updatedShowsList = showsList.current.filter(showsListItem => showsListItem !== show);
    setShowsList({ previous: showsList.current, current: updatedShowsList });

    updateFiltersAfterUnfollow(show);
  }

  function handleAllowTimeout() {
    handleUnfollowShow(toDelete);
    setShowSnackBar(false);
  }

  async function handleUnfollowShow(show) {
    if (show) {
      return unfollowShow(show);
    }
  }

  function handleUndoUnfollowShow() {
    setShowsList({ current: showsList.previous, previous: [] });
    setFollowedShowsCount(followedShowsCount + 1)
    setToDelete(null);
    setMyShowsFilters({ ...dashboardState.myShowsFilters, notShowUids: [] });
    setShowsList({...showsList, current: shows})
  }

  function filterShows(showUids) {
    // we cant just mutate the existing array because the children components use effect wont detect the changes
    // so we reassign the values to a new array and modify the respective properties here
    const newValues = [];

    for (let show of showsList.current) {
      if (showUids.includes(show.uid)) {
        show.checked = true;
        newValues.push({...show, checked: true})
      } else {
        newValues.push({...show, checked: false})
      }
    }

    setShowsList({...showsList, current: newValues})
    setMyShowsFilters({ showUids });
    dataLayerPush(`Clicked to filter 'My Shows' on the Dashboard`);
  }

  function updateFiltersAfterUnfollow(unfollowedShow) {
    // create deep copy of array so we can mutate it
    const showUids = [...myShowsFilters.showUids];

    // sets shows that have been deleted
    const notShowUids = [...dashboardState.myShowsFilters.notShowUids, unfollowedShow.name];

    // remove to be deleted show from the filters list
    const index = showUids.indexOf(unfollowedShow.uid);
    
    if (index > -1) {
      showUids.splice(index, 1);
    }

    // update context state, clear showUids since the sidebar selected shows gets cleared too
    setMyShowsFilters({ showUids, notShowUids });
  }

  if (error) {
    return <Alert severity='error'>Failed to load your followed shows.</Alert>
  }
  return (
    <>
      {showsList.current.length > 0 && (
          <Component
            values={showsList.current}
            loading={loading}
            onChange={(showUids) => filterShows(showUids)}
            onRemove={show => handleClickUnfollow(show)}
          />
        )}
        {showSnackBar &&
          <UndoSnackBar
            episode={toDelete}
            onAllowTimeOut={handleAllowTimeout}
            onUndo={handleUndoUnfollowShow}
            message={`No longer following "${toDelete?.label}"`}
          />
        }
    </>
  );
}

export default React.memo(MyShowsSidebar);
