import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled  from 'styled-components';
import RichTextField from 'components/RichTextField';

const Header = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1.65em;
  line-height: normal;

  div {
    margin: 0;
  }

  h1 {
    font-size: 1.5em;
  }

  h2, h3 {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  h3 {
    font-size: 1.4em;
  }

  .MuiBox-root {
    h1, h2, h3 {
      margin-top: 0;
    }
  }
`;

const Subheader = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};

  div {
    margin: 0;
  }

  h2, h3 {
    margin-bottom: 1rem;

    ${props => props.theme.breakpoints.down('md')} {
      margin-top: 0;
    }
  }
`;

StandardBlockHeader.proptypes = {
  header: PropTypes.string,
  subheader: PropTypes.string
}

function StandardBlockHeader({ header, subheader }) {
  return (
    <>
      {subheader && (
        <Subheader><RichTextField richText={subheader} /></Subheader>
      )}
      {header && (
        <Header><RichTextField richText={header} /></Header>
      )}
    </>
  )
}

export default React.memo(StandardBlockHeader);
