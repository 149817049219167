import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

ClampLines.propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number.isRequired,
  ellipsis: PropTypes.string,
  trimRight: PropTypes.bool,
  component: PropTypes.elementType,
  basedOn: PropTypes.oneOf([undefined, 'letters', 'words']),
  addTitleOnClamp: PropTypes.bool,
  onReflow: PropTypes.func,
};

function ClampLines({ text, lines = 1, ellipsis = '…', addTitleOnClamp = false, onReflow = undefined, ...props }) {
  const [isClamped, setIsClamped] = useState(false);

  const handleReflow = useCallback((rleState) => {
    setIsClamped(rleState.clamped);

    if (onReflow) {
      onReflow(rleState);
    }
  }, [setIsClamped, onReflow]);

  return (
    <ResponsiveEllipsis
      title={addTitleOnClamp && isClamped && text}
      text={text}
      maxLine={lines}
      basedOn='letters'
      ellipsis={ellipsis}
      trimRight
      onReflow={handleReflow}
      {...props}
    />
  );
}

export default ClampLines;
