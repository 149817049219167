import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FeaturedComponent from '../FeaturedComponent';
import getRandomTraderQuery from '../../../../../../graphql/queries/getRandomTrader.graphql';

RandomizedTrader.propTypes = {
  megaMenuClick: PropTypes.func.isRequired,
  subItem: PropTypes.object,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourAction: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.func,
  menuOpen: PropTypes.bool
};

function RandomizedTrader({
  megaMenuClick,
  subItem,
  tourHighlighted,
  traderResourcesTourMenuClick,
  traderResourcesTourAction,
  menuOpen
}) {
  const { data } = useQuery(getRandomTraderQuery, {
    variables: {
      filters: {
        disableContributorPage: false,
      }
    },
  });

  const items = get(data, 'randomTalent.items', []);
  const featuredItem = items[0];

  if (!featuredItem) {
    return null;
  }

  const title = get(featuredItem, 'title');
  const firstName = title?.split(' ')[0];

  return (
    <FeaturedComponent
      exploreMore={`Go to ${firstName}'s Page`}
      featuredItem={featuredItem}
      featuredTitle={title}
      featuredTalentTitle={featuredItem?.talentTitle}
      featuredImage={featuredItem?.talentThumbnail?.url}
      featuredSnippet={featuredItem?.bio}
      megaMenuClick={megaMenuClick}
      tourHighlighted={tourHighlighted}
      traderResourcesTourMenuClick={traderResourcesTourMenuClick}
      traderResourcesTourAction={traderResourcesTourAction}
      subItem={subItem}
      menuOpen={menuOpen}
    />
  )
};

export default RandomizedTrader;
