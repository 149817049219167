import React, { useState } from 'react';
import get from 'lodash.get';
import styled, { css } from 'styled-components';
import { ifNotProp, theme } from 'styled-tools';
import Box from '@material-ui/core/Box';
import BaseContainer from '@material-ui/core/Container'
import RichTextField from 'components/RichTextField';
import VideoPlayer from 'components/VideoPlayer';
import Icon from 'components/Icon';
import TastyStats from './TastyStats';
import videoHeroBlockShape from 'shapes/components/videoHeroBlockShape';
import { dataLayerPush } from 'utils/dataLayer';

const ABOUT_TASTYLIVE = '6066131776001';

const HeroContainer = styled.div`
  background-color: ${theme('colors.black')};
`;

const Container = styled(BaseContainer)`
  max-width: 1200px;
  position: relative;

  .vjs-control-bar {
    z-index: 5;
  }

  .vjs-big-play-button {
    display: none;
  }
`;

const PlayerWrapper = styled.div`
  padding-top: 1rem;
  position: relative;
  padding-top: 56.25%;

  width: 100%;

  img {
    width: 100%;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: ${theme('colors.white')};
  display: flex;
  flex-direction: column;
  height: 99%;
  justify-content: flex-end;
  padding: 2.75rem 2.75rem 1.5rem 2.75rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 3.75rem;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    > div {
      height: 100%;
    }
  }

  ${ifNotProp('$paused', css`
    display: none;
  `)}
`;

const VideoPlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PlaceholderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayContent = styled.div`
  max-width: 80%;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 60%;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const OverlayIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const UnmuteWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 0.4375rem solid white;
  border-radius: 50%;
  font-size: 7.2vw;

  svg {
    padding: 0.75rem;

    ${props => props.theme.breakpoints.up('md')} {
      padding: 1.5rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      padding: 2rem;
    }
  }
`;

VideoHero.PropTypes = {
  data: videoHeroBlockShape.isRequired,
};

function VideoHero({ data }) {
  const [staticVideoLoaded, setStaticVideoLoaded] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState();
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const { body, header, subheader} = data
  const videoDetails = get(data, 'featureVideo.videoSource');
  const { bcVideoFile, thumbnailImage, videoLink } = videoDetails;

  const mobileImage = thumbnailImage.url + '?format=pjpg&quality=50&width=387&disable=upscale';
  const desktopImage = thumbnailImage.url + '?format=pjpg&quality=50&width=1012&disable=upscale';
  const largeDesktopImage = thumbnailImage.url + '?format=pjpg&quality=50&width=1760&disable=upscale';

  const imgSrcSet = `${mobileImage} 600w, ${desktopImage} 1400w, ${largeDesktopImage} 2600w`;

  const onVideoPlayerLoaded = (success) => {
    setIsPlayerLoaded(true);
    setVideoPlayer(success.ref);
    togglePause();
  }

  const loadStaticVideo = () => {
    setStaticVideoLoaded(true);
  }

  const togglePause = () => {

    if (!videoPlayer) {
      setStaticVideoLoaded(true);
      setIsPaused(false);
    }

    if (videoPlayer) {
      if (isPaused) {
        videoPlayer.play();
        dataLayerPush(`'About us' video played`);
        setIsPaused(false);
      } else {
        videoPlayer.pause();
        dataLayerPush(`'About us' video paused`);
        setIsPaused(true);
      }
    }
  }


  return (
    <HeroContainer>
      <Container onClick={togglePause}>
        {bcVideoFile && (
          <PlayerWrapper>
            {staticVideoLoaded && (
              <VideoPlayerWrapper>
                <VideoPlayer
                  videoId={bcVideoFile}
                  onSuccess={onVideoPlayerLoaded}
                  autoplay={true}
                />
              </VideoPlayerWrapper>
            )}
            {!staticVideoLoaded && (
              <>
                {thumbnailImage && (
                  <PlaceholderImage
                    srcSet={imgSrcSet}
                    src={!imgSrcSet ? desktopImage : ''}
                    sizes='(max-width: 600px) 88vw, (max-width: 1400px) 88vw, (max-width: 2600px) 80vw'
                    alt='Video player placeholder'
                    onClick={loadStaticVideo}
                  />
                )}
              </>
            )}
          </PlayerWrapper>
        )}
        <Overlay $paused={isPaused}>
          <Box display='flex'>
            <OverlayContent>
              {subheader && <RichTextField richText={subheader} />}
              {header && <RichTextField richText={header} />}
              <RichTextField richText={body} />
            </OverlayContent>
            <OverlayIcon>
              <UnmuteWrapper>
                <Icon icon='play' />
              </UnmuteWrapper>
            </OverlayIcon>
          </Box>
          {bcVideoFile === ABOUT_TASTYLIVE && <TastyStats />}
        </Overlay>
      </Container>
    </HeroContainer>
  );
}

export default React.memo(VideoHero);
