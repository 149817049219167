import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import singleContentCardShape from 'shapes/singleContentCardShape';
import { autoOptimizeImage } from 'utils/functions';
import CardModal from './CardModal';

const FeedCardContainer = styled.div`
  cursor: pointer;
  min-height: 14rem;
  min-width: 18rem;
`;

const ImageWrapper = styled.div`
  height: 11rem;
  margin-bottom: 0.875rem;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const Header = styled(Typography)`
  margin-bottom: 0;
  font-weight: 600;
`;

FeedContentCard.propTypes = {
  data: singleContentCardShape,
  size: PropTypes.number,
  width: PropTypes.number,
};

function FeedContentCard({ data, size, width }) {
  const [modalOpen, setModalOpen] = useState(false);
  const { altImageText, cardImage, header, headerType } = data;

  const openCardModal = () => {
    setModalOpen(true);
  }

  return (
    <>
      <FeedCardContainer onClick={openCardModal}>
        <ImageWrapper>
          <img alt={altImageText} src={autoOptimizeImage(cardImage?.url)} />
        </ImageWrapper>
        {header && (
          <Header
            variant={(headerType && headerType.toLowerCase()) || 'h3'}
            size={size}
          >
            {header}
          </Header>
        )}
      </FeedCardContainer>

      <CardModal
        data={data}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size={size}
        width={width}
      />
    </>
  );
}

export default FeedContentCard;
