
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConceptOfTheDayBlockFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConceptOfTheDayBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"definition"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DefinitionFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"definitionCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"imageHeader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":251}};
    doc.loc.source = {"body":"#import \"../DefinitionFields.graphql\"\n#import \"../AssetFields.graphql\"\n\nfragment ConceptOfTheDayBlockFields on ConceptOfTheDayBlock {\n  header\n  definition {\n    ...DefinitionFields\n  }\n  definitionCta\n  imageHeader\n  image {\n    ...AssetFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../DefinitionFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));


      module.exports = doc;
    
