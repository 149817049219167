import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Fab from '@material-ui/core/Fab';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GlossarySection from './GlossarySection';
import { useAuth } from 'context/AuthContext';
import Icon from 'components/Icon';

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
`;

const ListWrapper = styled.div`
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 3.5rem 0;
  }
`;

const Title = styled(Typography)`
  font-size: 1.375rem;
  margin-top: 1.875rem;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 1.875rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const NavigateButton = styled(Fab)`
  bottom: 28px;
  height: 2.625rem;
  position: fixed;
  right: 56px;
  width: 2.625rem;
  z-index: 12;

  ${props => props.theme.breakpoints.up('md')} {
    bottom: 44px;
    height: 3.125rem;
    right: 44px;
    width: 3.125rem;
  }

  ${ifProp('$user', css`
    ${props => props.theme.breakpoints.down('sm')} {
      bottom: 60px;
    }
  `)}
`;

const propTypes = {
  glossaryHeader: PropTypes.string,
  loadingLetters: PropTypes.bool,
  mapByLetters: PropTypes.object,
};

function List({ glossaryHeader, loadingLetters, mapByLetters }) {
  const { user } = useAuth();
  const theme = useTheme();
  const listRef = useRef();
  const [scrollBottom, setScrollBottom] = useState(true);
  const [scrollTop, setScrollTop] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const checkScrollDirection = () => {
    if (window.pageYOffset > 800) {
      setScrollBottom(false);
      setScrollTop(true);
    } else if (window.pageYOffset <= 800) {
      setScrollBottom(true);
      setScrollTop(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollDirection)

    return () => {
      window.removeEventListener('scroll', checkScrollDirection);
    };
});

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  const scrollToBottom = () => {
    const footerOffset = isDesktop ? 580 : 800;

    window.scroll({
      top: listRef.current.offsetHeight - footerOffset,
      left: 0,
      behavior: 'smooth'
    });
  }

  if (loadingLetters) {
    return (
      <ListWrapper>
        {Array.from(new Array(8)).map((letter, index) => (
          <SkeletonWrapper key={`skeleton-${index}`}>
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
          </SkeletonWrapper>
        ))}
      </ListWrapper>
    );
  }

  return (
    <ListWrapper ref={listRef}>
      {glossaryHeader &&
        <Title variant='h1'>{glossaryHeader}</Title>
      }
      {Object.keys(mapByLetters).map((letter, index) => {
        const defsByLetter = mapByLetters[letter];

        return(
          <GlossarySection
            defsByLetter={defsByLetter}
            key={`letter-${index}`}
            letter={letter}
          />
        );
      })}
      <NavigateButton
        color='secondary'
        className='glossary-quick-scroll-btn'
        onClick={scrollBottom ? scrollToBottom : scrollToTop}
        $user={user}
      >
        <Icon icon={scrollBottom ? 'chevronDown' : 'chevronUp'} />
      </NavigateButton>
    </ListWrapper>
  );
}

List.propTypes = propTypes;

export default React.memo(List);
