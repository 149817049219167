import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SimpleDialog from 'components/SimpleDialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/Button';

ExpiredContentAlert.propTypes = {};

function ExpiredContentAlert() {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const search = location.search;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('expiredRedirect') && !isOpen) {
      setIsOpen(true);
    }
  }, [search, isOpen]);

  const handleClose = () => {
    setIsOpen(false);

    // Remove the query param and update current URL.
    const queryParams = new URLSearchParams(search);
    queryParams.delete('expiredRedirect');
    const newQueryString = queryParams.toString();

    history.replace(location.pathname + (newQueryString ? `?${newQueryString}` : ''));
  };

  if (!search || !(new URLSearchParams(search)).has('expiredRedirect')) {
    return null;
  }

  return (
    <SimpleDialog
      onClose={handleClose}
      open={isOpen}
      maxWidth='xs'
    >
      <DialogContent>
        <DialogContentText color='textPrimary'>
          We're sorry! The content you were looking for has been removed. Please enjoy this related content instead.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </SimpleDialog>
  );
}

export default ExpiredContentAlert;
