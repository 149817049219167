import styled from 'styled-components';
import PageLink from 'components/PageLink';
import { theme } from 'styled-tools';

export default styled(PageLink)`
  position: relative;
  display: flex;
  padding: .8125rem 1.25rem;
  align-items: center;
  color: ${theme('palette.common.white')};
  white-space: nowrap;
  transition: all 300ms cubic-bezier(.23, 1, .32, 1);
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${theme('typography.fontFamilySecondary')};

  &:hover,
  &:active {
    text-shadow: 0 0 .8px #fff, 0 0 .8px #fff;
    text-decoration: none;
  }
`;
