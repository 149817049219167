import React, { useState } from 'react';
import {dataLayerPush} from 'utils/dataLayer';
import episodeShape from 'shapes/episodeShape';
import { ActionItemContainer } from './index';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import addEpisodeToPlaylistsMutation from '../../graphql/mutations/addEpisodeToPlaylists.graphql';
import { useSnackbar } from 'notistack';
import { useAuth } from 'context/AuthContext';
import Tooltip from '@material-ui/core/Tooltip';
import updateHasSavedEpisodesToPlaylistsMutation from './AddToPlaylist/updateHasSavedEpisodesToPlaylists.graphql';
import {useDashboardContext} from '../../context/DashboardContext';
import { LabelWrapper } from './index';
import TTSnackbarMessage from '../TTSnackbar/Message';
import { ttSnackbarOptions } from '../TTSnackbar';
import ActionLabel from './ActionLabel';

WatchLaterActionIcon.propTypes = {
  episode: episodeShape.isRequired,
  showToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  preLabel: PropTypes.bool,
  postLabel: PropTypes.bool,
  label: PropTypes.string,
  fontSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
  expand: PropTypes.bool,
};

function WatchLaterActionIcon({
  episode,
  showToolTip = false,
  preLabel,
  label,
  postLabel,
  toolTipText = 'Watch Later',
  fontSize = 'default',
  expand = true,
}) {
  const [addEpisodeToPlaylists] = useMutation(addEpisodeToPlaylistsMutation);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [displayToolTip, setDisplayToolTip] = useState(false);
  const { state: dashboardState, updateDashboardLogs, setFirstTimeHelper, setSubsequentHelper, setHelperContentType } = useDashboardContext();
  const { hasSavedEpisodesToPlaylists } = dashboardState?.logs;
  const [updateHasSavedEpisodesToPlaylists] = useMutation(updateHasSavedEpisodesToPlaylistsMutation);

  if (!user) {
    return null;
  }

  async function handleSaveToWatchLaterClick() {
    setDisplayToolTip(false);
    await addEpisodeToPlaylists({
      variables: {
        input: {
          episodeId: episode.uid,
          playlistIds: ['watchLater'],
        },
      }
    })

    if (!hasSavedEpisodesToPlaylists) {
      await updateHasSavedEpisodesToPlaylists({ variables: { hasSavedEpisodesToPlaylists: true }});
      updateDashboardLogs();
      setFirstTimeHelper(true);
      setHelperContentType('episode');
    } else {
      setSubsequentHelper(true);
      enqueueSnackbar(<TTSnackbarMessage message='Saved to Watch Later' />, ttSnackbarOptions);
    }

    dataLayerPush(`Episode saved to 'Watch Later'`);
  }

  const labelText = label ? label : 'Save to Watch Later';
  const labelComponent = <LabelWrapper>{labelText}</LabelWrapper>

  return (
    <Tooltip title={showToolTip ? toolTipText : ''} open={displayToolTip}>
      <div onMouseEnter={() => setDisplayToolTip(true)} onMouseLeave={() => setDisplayToolTip(false)}>
        <ActionItemContainer onClick={handleSaveToWatchLaterClick}>
          {label && (
            <ActionLabel expand={expand} label={label}/>
          )}
          {preLabel && labelComponent}
          <AccessTimeIcon fontSize={fontSize} />
          {postLabel && labelComponent}
        </ActionItemContainer>
      </div>
    </Tooltip>
  )
}

export default WatchLaterActionIcon;
