import React from 'react';
import PropTypes from 'prop-types';
import { switchProp, ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import externalLinks from 'constants/externalLinks';

export const Disclaimer = styled(Typography)`
  font-family: 'Roboto Condensed', 'Roboto Condensed-fallback-italic', '"Franklin Gothic Bold"', 'sans-serif';

  ${switchProp('$placement', {
    definition: css`
      background: ${theme('palette.common.backgroundGray')};
      color: ${theme('colors.black')};
      font-family: ${theme('typography.fontFamilySecondary')};
      font-size: 0.5rem;
      font-style: italic;
      text-align: left;
      margin-top: -3rem;

      br {
        display: none;
      }

      ${ifProp('$marginBottom', css`
        margin-bottom: 0;
     `)}
    `,
    homepage: css`
      background: ${theme('palette.common.backgroundGray')};
      border-bottom: 0.0625rem solid ${theme('colors.mutedGray')};
      color: ${theme('colors.white')};
      font-family: ${theme('typography.fontFamilySecondary')};
      font-size: 0.5rem;
      font-style: italic;
      padding: 0.5rem;
      text-align: left;

      br {
        display: none;
      }

     ${ifProp('$marginBottom', css`
        margin-bottom: -2rem;
     `)}

    `,
    homepageLoggedOut: css`
      background: ${theme('palette.common.black')};
      color: ${theme('colors.white')};
      font-size: 0.5rem;
      font-style: italic;
      text-align: left;

      ${props => props.theme.breakpoints.down('sm')} {
        padding-left: 0.375rem;
      }

      ${props => props.theme.breakpoints.only('md')} {
        font-size: 0.4rem;
      }

      ${ifProp('$marginBottom', css`
        margin-bottom: -2rem;
     `)}
    `,
    episodes: css`
      color: ${theme('palette.text.hint')};
      font-size: .625rem;
      margin: 0.5rem;

      ${props => props.theme.breakpoints.up('sm')} {
        margin: 3rem 1.75rem 1rem;
      }

      ${props => props.theme.breakpoints.up('md')} {
        margin: 1.75rem 0 2.75rem;
      }
    `
  })}

  ${ifProp({$colorTheme: 'dark'}, css`
    color: ${theme('colors.white')};
  `)}

  a {
    font-weight: 700;
  }
`;

OptionsDisclaimer.propTypes = {
  intro: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placement: PropTypes.string,
  marginBottom: PropTypes.bool,
  colorTheme: PropTypes.oneOf(['light', 'dark']),
  style: PropTypes.object,
};

function OptionsDisclaimer({
  intro,
  placement,
  marginBottom = false,
  colorTheme = 'light',
  style
}) {
  return (
    <Disclaimer
      $placement={placement}
      $marginBottom={marginBottom}
      $colorTheme={colorTheme}
      style={style}
    >
      {intro}{' '}
      <br />
      Please read <Link href={externalLinks.optionsCharacteristicsRisks} color='inherit'>Characteristics and Risks of Standardized Options</Link>{' '}
      before deciding to invest in options.
    </Disclaimer>
  );
}

export default OptionsDisclaimer
