import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import Input from 'components/Input';
import { ButtonContainer, Title } from './SharedStyles';
import updateUserMutation from '../../../../graphql/mutations/updateUser.graphql';
import updateAuth0UserMutation from '../../../../graphql/mutations/updateAuth0User.graphql';
import { countryOptions } from 'constants/CountryOptions';
import { stateOptions } from 'constants/StateOptions';
import auth0UserShape from 'shapes/auth0UserShape';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const FormRow = styled.div`
  > .MuiFormControl-root {
    ${props => props.theme.breakpoints.down('xs')} {
      width: 100%;
    }

    &:first-child {
      ${props => props.theme.breakpoints.up('sm')} {
        padding-right: 0.5rem;
      }
    }

    &:last-child {
      ${props => props.theme.breakpoints.up('sm')} {
        padding-left: 0.5rem;
      }
    }
  }
`;

UpdateInfo.propTypes = {
  auth0Id: PropTypes.string,
  dbUser: PropTypes.object,
  handleClose: PropTypes.func,
  personalInfo: auth0UserShape,
  refetchAuth0: PropTypes.func,
  refetchDb: PropTypes.func,
  socialUser: PropTypes.bool,
  userId: PropTypes.string,
};

function UpdateInfo({
  auth0Id,
  dbUser,
  handleClose,
  personalInfo,
  refetchAuth0,
  refetchDb,
  socialUser,
  userId,
}) {
  const { user_metadata: metadata } = personalInfo
  const [firstName, setFirstName] = useState(get(dbUser, 'firstName', ''));
  const [lastName, setLastName] = useState(get(dbUser, 'lastName', ''));
  const [address, setAddress] = useState(get(metadata, 'address', ''));
  const [addressTwo, setAddressTwo] = useState(get(metadata, 'addressTwo', ''));
  const [state, setState] = useState(get(metadata, 'state', ''));
  const [zipCode, setZipCode] = useState(get(metadata, 'zipCode', ''));
  const [city, setCity] = useState(get(metadata, 'city', ''));
  const [country, setCountry] = useState(get(metadata, 'country', ''));
  const [phone, setPhone] = useState(get(metadata, 'phone', ''));

  const [updateUser] = useMutation(updateUserMutation);
  const [updateAuth0User] = useMutation(updateAuth0UserMutation);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleUpdateUser = (firstName, lastName) => {
    updateUser({
      variables: {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
      },
    })
  }

  const handleUpdateAuth0User = (auth0Id, address, addressTwo, state, zipCode, city, country, phone) => {
    updateAuth0User({
      variables: {
        auth0Id: auth0Id,
        input: {
          firstName: firstName,
          lastName: lastName,
          address: address,
          addressTwo: addressTwo,
          state: state,
          zipCode: zipCode,
          city: city,
          country: country,
          phone: phone,
        },
        type: 'meta'
      },
    });
  }

  const handleSubmit = () => {
    handleUpdateUser(firstName, lastName);
    handleUpdateAuth0User(auth0Id, address, addressTwo, state, zipCode, city, country, phone);
    refetchDb();
    refetchAuth0();
  }

  return (
    <div>
      <Title variant='h4'>Update Personal Info</Title>
      <form autoCopmlete='off' onSubmit={handleSubmit}>
        {!socialUser && (
          <>
            <Input
              defaultValue={get(dbUser, 'firstName', '')}
              id='firstName'
              label='First Name'
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              width='full'
            />
            <Input
              defaultValue={get(dbUser, 'lastName', '')}
              id='lastName'
              label='Last Name'
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              width='full'
            />
          </>
        )}
        <Input
          defaultValue={get(metadata, 'address', '')}
          id='address'
          label='Address'
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          width='full'
        />
        <Input
          defaultValue={get(metadata, 'addressTwo', '')}
          id='addressTwo'
          label='Address 2'
          onChange={(e) => setAddressTwo(e.target.value)}
          value={addressTwo}
          width='full'
        />
        <FormRow>
          <Input
            id='state'
            label='State'
            onChange={(e) => setState(e.target.value)}
            options={stateOptions}
            value={state}
            width='half'
            select
          />
          <Input
            defaultValue={get(metadata, 'zipCode', '')}
            id='zipCode'
            label='Zip Code'
            onChange={(e) => setZipCode(e.target.value)}
            value={zipCode}
            width='half'
          />
        </FormRow>
        <Input
          defaultValue={get(metadata, 'city', '')}
          id='city'
          label='City'
          onChange={(e) => setCity(e.target.value)}
          value={city}
          width='full'
        />
        <Input
          id='country'
          label='Country'
          onChange={(e) => setCountry(e.target.value)}
          options={countryOptions}
          value={country}
          width='full'
          select
        />
        <Input
          defaultValue={get(metadata, 'phone', '')}
          id='phone'
          label='Phone'
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          width='full'
          type={isMobile ? 'tel' : 'number'}
        />
        <ButtonContainer>
          <Button
            color='primary'
            type='submit'
            variant='contained'
          >
            Save Changes
          </Button>
          <Button onClick={handleClose} variant='outlined'>Cancel</Button>
        </ButtonContainer>
      </form>
    </div>
  );
}

export default React.memo(UpdateInfo);
