import React from 'react';
import PropTypes from 'prop-types';
import assetShape from 'shapes/assetShape';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Typography from '@material-ui/core/Typography';
import RichTextField from 'components/RichTextField';
import { autoOptimizeImage } from 'utils/functions';

const GridContainer = styled(Grid)`
  background-color: ${prop('theme.colors.backgroundGray')};
  border: 0.0625rem solid ${prop('theme.colors.borderGray')};

  ${ifProp('$hasImg', css`
    background-color: ${prop('theme.colors.white')};
  `)}
`;

const ImageContainer = styled.div`
  height: 100%;
  max-height: 40rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1.25rem;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 1.25rem 7.5rem;
   }

  ${props => props.theme.breakpoints.up('lg')} {
    padding: 1.25rem 9.5rem;
   }
`;

const CtaHeader = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2.125rem;
`;

const StartButton = styled(Button).attrs(props => ({
  as: (props.to && Link) || (props.href && 'a')
}))`
  border: 0.0625rem solid ${prop('theme.colors.black')};
  border-radius: 0.25rem;
  color: ${prop('theme.colors.black')};
  font-weight: 600;
  line-height: normal;
  margin-top: 2.125rem;
  padding: 0.625rem 3rem;
  width: fit-content;

  a& {
    text-decoration: none;
  }
`;

const LUCK_BOX_SIGN_UP_TITLE = 'Get luckbox!';
const LUCK_BOX_SIGN_UP_TEXT = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacinia augue vel nulla imperdiet lobortis.';

GetLuckbox.propTypes = {
  luckboxOptions: PropTypes.shape({
    signupTitle: PropTypes.string,
    signupText: PropTypes.string,
    signupImage: assetShape,
  }),
};

GetLuckbox.defaultProps = {
  luckboxOptions: {
    signupTitle: LUCK_BOX_SIGN_UP_TITLE,
    signupText: LUCK_BOX_SIGN_UP_TEXT
  }
};

function GetLuckbox({ luckboxOptions = {} }) {
  const { signupTitle, signupText, signupImage } = luckboxOptions;

  return (
    <>
      <GridContainer container $hasImg={!!signupImage}>
        <Grid item xs={12} md={6}>
          <ImageContainer>
            <img
              alt='stack of luckbox magazines'
              src={autoOptimizeImage(signupImage?.url)}
            />
          </ImageContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <CtaContainer>
            {signupTitle && <CtaHeader variant='h2'>{signupTitle}</CtaHeader>}
            {signupText &&
              <RichTextField richText={signupText || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacinia augue vel nulla imperdiet lobortis Test.'} />
            }
            <StartButton
              href='https://luckboxmagazine.com/subscribe/?utm_source=luckbox_HP_mast&utm_medium=LP&utm_campaign=luckbox'
              target='blank'
            >
              Get Started
            </StartButton>
          </CtaContainer>
        </Grid>
      </GridContainer>
    </>
  );
}

export default React.memo(GetLuckbox);
