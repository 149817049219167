import React from 'react';
import PropTypes from 'prop-types';
import { prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import Modal from 'components/Modal';
import ActionLabel from 'components/ActionIcons/ActionLabel';

const Trigger = styled.button.attrs(props => {
  return {'data-testid': props.testId};
})`
  align-items: center;
  display: flex;
  font-weight: 700;
  font-size: 0.875rem;

  ${switchProp('type', {
    button: css`
      padding: 0.5rem;
    `,
    link: css`
      background: none;
      border: none;
      color: ${prop('theme.colors.black')};
      padding: 0;
      text-transform: uppercase;

      &:hover {
        color: ${prop('theme.colors.ttRed')};
        cursor: pointer;
      }
    `
  })}

  ${switchProp('$iconPosition', {
    prefix: css`
      flex-direction: row-reverse;
    `,
    suffix: css`
      flex-direction: row;
    `
  })}

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  ariaDescribe: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  contentType: PropTypes.string,
  height: PropTypes.number,
  hideClose: PropTypes.bool,
  icon: PropTypes.element,
  iconPosition: PropTypes.string,
  padding: PropTypes.string,
  triggerText: PropTypes.string,
  triggerType: PropTypes.string,
  width: PropTypes.number,
  label: PropTypes.string,
  expand: PropTypes.bool,
  testId: PropTypes.string,
}

function ModalWithTrigger({
  ariaLabel,
  ariaDescribe,
  children,
  contentType,
  handleOpen,
  handleClose,
  height = 600,
  hideClose = false,
  icon,
  iconPosition = 'suffix',
  open,
  padding = 'narrow',
  triggerText,
  triggerType = 'button',
  width = 800,
  testId,
  label,
  expand
}) {
  return (
    <div>
      <Trigger
        $iconPosition={iconPosition}
        className='modal-trigger'
        onClick={handleOpen}
        type={triggerType}
        testId={testId}
        tabIndex={0}
      >
        {triggerText}
        {icon && icon}
        {label && (
          <ActionLabel $expand={expand} label={label} />
        )}
      </Trigger>
      <Modal
        open={open}
        onClose={handleClose}
        height={height}
        hideClose={hideClose}
        width={width}
        padding={padding}
        ariaLabel={ariaLabel}
        ariaDescribe={ariaDescribe}
        contentType={contentType}
      >
        {children}
      </Modal>
    </div>
  );
}

ModalWithTrigger.propTypes = propTypes;

export default ModalWithTrigger;
