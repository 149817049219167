import React from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { REPLAY_TOUR_STEPS } from 'constants/replayTourSteps';
import { useTourContext } from 'context/TourContext';
import { TraderResourcesStepKeys } from 'components/Tours/constants';

const GoButton = styled(Typography)`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
`;

NextButton.propTypes = {};

function NextButton() {
  const { state: { currentStep, startAt } } = useTourContext();
  const { stepOne } = REPLAY_TOUR_STEPS;
  const history = useHistory();

  const goToConceptsStrategies = () => {
    if (currentStep === TraderResourcesStepKeys.ConceptsColumns) {
      history.push('/concepts-strategies');
    }
  }

  return (
    <>
      {(currentStep === stepOne || (currentStep === null && startAt === stepOne)) ? (
        <GoButton>Let's Go</GoButton>
      ) : (
        <ArrowForwardIcon onClick={goToConceptsStrategies} />
      )}
    </>
  );
}

export default NextButton;
