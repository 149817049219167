import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const MegaMenuGroupHeader = styled.div`
  align-items: center;
  color: ${theme('palette.common.black')};
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: condensed;
  font-family: ${theme('typography.fontFamilySecondary')};
  line-height: 1.31;
  letter-spacing: 0.32px;
  margin-bottom: 0.5rem;
  text-decoration: none;

  &.mega-menu-group-header-link svg {
    align-self: flex-end;
    margin-bottom: 0.2rem;
  }

  // Only apply hover styles if the group header is also a link.
  a&:hover {
    color: ${theme('colors.ttRed')};
    text-decoration: none;
  }

  ${ifProp(
    '$tourDisabled',
    css`
      &:not(.tour-highlight):hover {
        color: ${theme('palette.common.black')};
        cursor: default;
      }
    `
  )}

  ${ifProp(
    '$subheader',
    css`
      font-size: 0.875rem;
    `
  )}

  // Add thin red border around the link when it's meant to be highlighted
  // as part of the Trader Resources tour.
  &.tour-highlight {
    border: 1px solid ${theme('colors.ttRed')};
    padding: 0.5rem;
    margin-left: -0.5rem;
    margin-bottom: 0;
  }

  svg {
    margin-left: 0.375rem;
  }
`;

export default MegaMenuGroupHeader;
