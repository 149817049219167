import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import { ifProp, theme } from 'styled-tools';

const CheckboxWrapper = styled(Checkbox)`
  position: absolute;
  left: -2.8rem;
  top: 0;
  bottom: 0;
  
  ${props => props.theme.breakpoints.down('xs')} {
    position: static;
    margin-right: 1rem;
  }
  
  ${ifProp({$isListItem: true}, css`
    margin-left: -1.5rem;
  `)};
  
  svg {
    fill: ${theme('colors.black')};
    
    ${ifProp({checked: true}, css`
      fill: ${theme('colors.ttRed')};
    `)};
  }
`;

const Row = styled.div`
  position: relative;
`;

const SelectAllWrapper = styled.span`
  font-size: 1rem;
  cursor: pointer;
`;

const DividerWrapper = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

SelectAllRow.propTypes = {
  handleSelectAll: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  isListItem: PropTypes.bool,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
}

function SelectAllRow({
  handleSelectAll,
  selectAllChecked,
  isListItem = false,
  top = null,
  right = null,
  bottom = null,
  left = null
}) {

  const text = selectAllChecked ? 'Deselect All' : 'Select All';

  return (
    <>
      <DividerWrapper />
      <Row>
        <CheckboxWrapper
          checked={selectAllChecked}
          onChange={handleSelectAll}
          $isListItem={isListItem}
        />
        <SelectAllWrapper onClick={handleSelectAll}>{text}</SelectAllWrapper>
      </Row>
      <DividerWrapper />
    </>
  )
}

export default React.memo(SelectAllRow);
