import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { ListItem } from 'components/EpisodeCard/ListItemCard/ListItem';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import EpisodeCardWrapper from 'components/EpisodeCard/EpisodeCardWrapper';
import UpcomingEpisodeImage from 'components/EpisodeCard/components/ImageDetails/UpcomingEpisodeImage';
import ViewSlidesActionIcon from 'components/ActionIcons/ViewSlides';
import WatchLaterActionIcon from 'components/ActionIcons/WatchLater';
import { ActionItemContainer } from 'components/ActionIcons';
import AbsoluteOverlay from 'components/AbsoluteOverlay';
import InfoBoxFlag from 'components/InfoBoxFlag';
import { dataLayerPush } from 'utils/dataLayer';
import { getEpisodeUrl } from 'utils/linkUtils';
import { COLOR_THEMES } from 'utils/constants';
import episodeShape from 'shapes/episodeShape';
import Icon from 'components/Icon'
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import EpisodeDisplayOptions from 'constants/EpisodeDisplayOptions';
import { useDashboardContext } from 'context/DashboardContext';
import { getShowThumbnail } from 'utils/showHelpers';
import { autoOptimizeImage } from 'utils/functions';
import placeholderImg from 'assets/images/img-placeholder.png';

const ActionIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  z-index: 11;
  text-transform: uppercase;
  cursor: pointer;
  color: ${theme('palette.common.white')};
  visibility: hidden;

  svg {
    fill: ${theme('palette.common.white')};
    width: 1rem;
    height: 1rem;

    & :hover,
    & :focus {
      fill: ${theme('colors.white')};
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    position: relative;
    z-index: 9;
    width: 100%;
    height: auto;
  }

  &:focus,
  &:focus-within {
    a {
      opacity: 1;
    }

    ${ActionIconContainer} {
      visibility: visible;
    }
  }

  ${ifProp({$edit: false}, css`
    ${ListItem}:hover &,
    ${EpisodeCardWrapper}:hover & {
      opacity: 1;
      color: ${theme('palette.common.white')};

      svg {
        visibility: visible;
      }

      a {
        opacity: 1;
      }
    }
  `)};

  .lazyload-wrapper {
    width: 100%;
  }
`;

const ImageLink = styled(RouterLink)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  padding-left: 0.25rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition-duration: 500ms;
  color: ${theme('palette.common.white')};
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 400;
`;

const ActionIconWrapper = styled.div`
  button {
    border-radius: 0;
    width: auto;
    height: 1rem;

    &:hover {
      background: transparent;

      svg {
        fill: ${theme('colors.white')};
      }
    }
  }
`


const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  align-items: center;

  ${ifProp({$expand: true}, css`
    span {
      font-size: 0.5rem;
      width: auto;
      height: 1rem;
    }
  `)};

  &:hover ${ActionIconWrapper}, &:hover span {
    right: 0;
    visibility: visible;
    background: transparent;
  }

  &:hover span:not(${ActionItemContainer}),
  &:hover svg {
    background-color: #00000085;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 0.25rem;
  background-color: ${theme('colors.ttRed')};
  width: ${prop('$percent')}%;
`;

ImageDetails.propTypes = {
  expirationDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onClick: PropTypes.func,
  episode: episodeShape,
  watchLaterList: PropTypes.bool,
  recommendation: PropTypes.string,
  playIconSize: PropTypes.number,
  linkUrlOverride: PropTypes.string,
  upcomingPlaceholder: PropTypes.bool,
  expand: PropTypes.bool,
  rollDown: PropTypes.bool,
  percentWatched: PropTypes.number,
  edit: PropTypes.bool,
  colorTheme: PropTypes.oneOf(Object.values(COLOR_THEMES)),
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func,
  active: PropTypes.bool,
  display: PropTypes.oneOf([
    EpisodeDisplayOptions.horizontal,
    EpisodeDisplayOptions.vertical,
    EpisodeDisplayOptions.listItem
  ]),
  setCardHeight: PropTypes.func,
};

function ImageDetails({
  display,
  expirationDate,
  onClick,
  episode,
  watchLaterList,
  recommendation = '',
  playIconSize,
  linkUrlOverride,
  upcomingPlaceholder = false,
  expand = true,
  rollDown = false,
  percentWatched = 0,
  edit = false,
  colorTheme = COLOR_THEMES.light,
  checked = false,
  onCheck = () => {},
  active,
}) {
  const theme = useTheme();
  const { preferences } = useGetUserDataSettings();
  const { state: dashboardState } = useDashboardContext();

  if (!episode && upcomingPlaceholder) {
    return <UpcomingEpisodeImage colorTheme={colorTheme} />
  }

  const episodeUrl = linkUrlOverride || getEpisodeUrl(episode);
  const { displayTitle, pdfSlides  } = episode;
  const accepted = !!preferences?.acceptedAt;


  const actionIcons = [
    {
      text: 'Watch Later',
      icon: <WatchLaterActionIcon
              episode={episode}
              label={'Watch Later'}
              expand={expand}
            />,
      willShow: accepted && !watchLaterList
    },
    {
      text: 'View Slides',
      icon: <ViewSlidesActionIcon
              episode={episode}
              iconSize={1}
              iconColor={theme.palette.common.white}
              label={'View Slides'}
              expand={expand}
              noHover
            />,
      willShow: pdfSlides?.length > 0
    }
  ];

  const { thumbnailImage, show } = episode || {};
  const thumbnail = thumbnailImage || getShowThumbnail(show);
  const url = (thumbnail && thumbnail.url) || placeholderImg;
  const thumbnailImageUrl = autoOptimizeImage(url, true, 493);

  const clickImage = () => {
    dataLayerPush(`${recommendation} Episode clicked`);
    onClick && onClick();
  }

  const Image = <img
    loading={'lazy'}
    src={thumbnailImageUrl}
    alt={displayTitle}
    width={493}
    height={276}
  />

  const placeholderSkeletonHeight = display === EpisodeDisplayOptions.listItem ? 74 : display === EpisodeDisplayOptions.horizontal ? 100 : 150
  const PlaceholderSkeleton = (
    <Skeleton variant='rect' width='100%' height={placeholderSkeletonHeight} />
  )

  const LazyLoadImage = (
    <LazyLoad debounce={true} height='100%' offset={100} placeholder={PlaceholderSkeleton}>
      {Image}
    </LazyLoad>
  )

  return (
    <ImageWrapper $edit={edit}>
      {expirationDate && (
        <InfoBoxFlag display={display} expirationDate={expirationDate} />
      )}
      {!active && (
        <ImageLink to={episodeUrl} onClick={clickImage}>
          <Icon icon='play' size={playIconSize} color={theme.palette.common.white} />
        </ImageLink>
      )}
      {dashboardState.isOpen
        ? Image
        : LazyLoadImage
      }
      {/*Action Icons that appear on the thumbnail overlay on hover*/}
      {!edit && (
        <>
          <ActionIconContainer $expand={expand}>
            {actionIcons.map((actionIcon, index) => (
              actionIcon.willShow &&
              <ActionWrapper
                key={`ActionIcon-${index}`}
                color='primary'
                aria-label='add an alarm'
                $expand={expand}
              >
                <ActionIconWrapper>{actionIcon.icon}</ActionIconWrapper>
              </ActionWrapper>
            ))}
          </ActionIconContainer>
          <ProgressBar $percent={percentWatched} />
        </>
      )}
      {edit && (
        <AbsoluteOverlay zIndex={9} opacity={0.75} background='#000' />
      )}
    </ImageWrapper>
  );
}

export default React.memo(ImageDetails);
