import IconButton from '@material-ui/core/IconButton';
import { autoOptimizeImage } from 'utils/functions';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import SearchIcon from '@material-ui/icons/Search';
import UserAvatar from 'components/Header/components/UserAvatar';
import Icon from 'components/Icon';

const MobileMenuButton = styled(IconButton)`
  height: 3.125rem;
  margin-right: 1rem;

  .MuiIconButton-label {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-height: 1rem;
  }
`
const MobileActionButtons = styled.div`
  display: flex;
  color: ${theme('palette.common.black')};
`;

const Logo = styled(RouterLink)`
  display: flex;
  align-items: center;
  width: 7.0875rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 1.25rem;
  }

  @media (min-width: 1120px) {
    margin-right: 3rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const HamburgerBar = styled.div`
  background-color: ${theme('palette.common.black')};
  border-radius: 0.625rem;
  height: 2px;
  position: relative;
  transform-origin: 3px;
  width: 1.625rem;

  ${switchProp('$bar', {
    first: css`
      transition: transform 0.3s linear;

      ${ifProp('$active', css`
        background-color: ${theme('colors.ttRed')};
        transform: rotate(45deg);
      `, css`
        transform: rotate(0);
      `)}
    `,
    second: css`
      transition: opacity 0.3s linear;

      ${ifProp('$active', css`
        background-color: ${theme('colors.ttRed')};
        opacity: 0;
        transform: translateX(0px);
      `, css`
        opacity: 1;
        transform: translateX(0px);
      `)}
    `,
    third: css`
      transition: transform 0.3s linear;

      ${ifProp('$active', css`
        background-color: ${theme('colors.ttRed')};
        transform: rotate(-45deg);
      `, css`
        transform: rotate(0);
      `)}
    `,
})}
`;

function MobileHeaderNavBar({ logo, mobileNavOpen, handleMobileDrawerToggle, handleMobileSuperNavToggle, handleOpenSearch, StyledAvatar, user }) {
  return (
    <>
      <MobileMenuButton
        $active={mobileNavOpen}
        color='inherit'
        className={mobileNavOpen ? 'active' : ''}
        disableRipple
        disableFocusRipple
        onClick={handleMobileDrawerToggle}
      >
        <HamburgerBar $active={mobileNavOpen} $bar='first' />
        <HamburgerBar $active={mobileNavOpen} $bar='second' />
        <HamburgerBar $active={mobileNavOpen} $bar='third' />
      </MobileMenuButton>

      {logo?.url && (
        <div>
          <Logo to='/'>
            <img
              src={autoOptimizeImage(logo.url)}
              alt='tastylive logo'
              width={113}
              height={42}
            />
          </Logo>
        </div>
      )}
      <MobileActionButtons>
        <IconButton aria-label='Search' color='inherit' onClick={handleOpenSearch}>
          <SearchIcon />
        </IconButton>
        <IconButton
          aria-label='Open profile and help menus'
          color='inherit'
          edge='end'
          onClick={handleMobileSuperNavToggle}
          data-testid='mobileSuperNavToggle'
        >
          {user ? (
            <UserAvatar component={StyledAvatar} id='userAvatar' />
          ) : (
            <Icon icon='login' />
          )}
        </IconButton>
      </MobileActionButtons>
    </>
  )
}

export default MobileHeaderNavBar;