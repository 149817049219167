import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { theme } from 'styled-tools';
import RegulatoryScopeField from 'components/DataPrivacyCapture/components/ScopesAndPoliciesForm/RegulatoryScopeField';
import PrivacyAcknowledgementField
  from 'components/DataPrivacyCapture/components/ScopesAndPoliciesForm/PrivacyAcknowledgementField';
import { FormFooter } from '../FormFooter';

const SectionHeader = styled.h3`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

ScopesAndPoliciesForm.propTypes = {
  privacyAcknowledgements: PropTypes.arrayOf(PropTypes.object),
  regulatoryScopes: PropTypes.arrayOf(PropTypes.object),
  dataPrivacySettings: PropTypes.object,
  handleSave: PropTypes.func,
}

function ScopesAndPoliciesForm({ privacyAcknowledgements, regulatoryScopes, dataPrivacySettings, handleSave }) {
  const initialValues = useMemo(() => {
    return {
      // We only set initial values for regulatory scopes that have been answered, so that we
      // don't pre-populate the radio buttons for those values.
      regulatoryScopes: (regulatoryScopes || []).reduce((scopeMap, definition) => {
        const hasScope = (dataPrivacySettings.regulatoryScopes || {})[definition.id];
        scopeMap[definition.id] = hasScope ? '1' : (hasScope === false ? '0' : null);

        return scopeMap;
      }, {}),
      // Privacy acknowledgements will default to false if version was not accepted.
      privacyAcknowledgements: (privacyAcknowledgements || []).reduce((itemMap, definition) => {
        const userItem = (dataPrivacySettings.privacyAcknowledgements || {})[definition.id];
        // Default privacy acks to false.
        if (!userItem) {
          itemMap[definition.id] = false;
          return itemMap;
        }

        // Privacy acks should be reset to false if new version.
        itemMap[definition.id] = !!userItem.acceptedAt && userItem.version === definition.version;

        return itemMap;
      }, {}),
    };
  }, [dataPrivacySettings, privacyAcknowledgements, regulatoryScopes]);

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors = {};

        // Validate policies.
        privacyAcknowledgements.forEach(definition => {
          if (!values.privacyAcknowledgements?.[definition.id]) {
            errors.privacyAcknowledgements = {
              ...(errors.privacyAcknowledgements || {}),
              [definition.id]: 'You must agree to the terms to continue.',
            };
          }
        });

        // Validate regulatory scopes.
        regulatoryScopes.forEach(definition => {
          const value = values.regulatoryScopes?.[definition.id];

          if (value !== '1' && value !== '0') {
            errors.regulatoryScopes = {
              ...(errors.regulatoryScopes || {}),
              [definition.id]: 'Please select an option.',
            };
          }
        });

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const inputData = {
          privacyAcknowledgements: Object.keys(values.privacyAcknowledgements).map(key => {
            const definition = privacyAcknowledgements.find(x => x.id === key);
            if (!definition) {
              throw new Error(`Invalid privacy acknowledgement id ${key}`);
            }

            // If user already accepted this version, we'll keep the original acceptedAt value.
            const existing = dataPrivacySettings?.privacyAcknowledgements?.[key];
            const acceptedAt = existing?.version === definition.version
              ? existing.acceptedAt
              : dayjs.utc().toISOString();

            return {
              id: key,
              version: definition.version,
              acceptedAt,
            };
          }),
          regulatoryScopes: Object.keys(values.regulatoryScopes).map(key => {
            return {
              id: key,
              hasScope: values.regulatoryScopes[key] === '1',
            }
          }),
        };

        await handleSave(inputData);
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting, errors, touched }) => (
        <>
          <DialogContent dividers>
            <Form id='dataPrivacyForm'>
              <SectionHeader>Privacy Policy &amp; Terms</SectionHeader>
              {(regulatoryScopes || []).length > 0 && (
                <DialogContentText color='textPrimary'>
                  {regulatoryScopes.map(regulatoryScope => (
                    <RegulatoryScopeField
                      key={regulatoryScope.id}
                      regulatoryScope={regulatoryScope}
                      touched={touched}
                      errors={errors}
                      disabled={isSubmitting}
                    />
                  ))}
                </DialogContentText>
              )}
              {(privacyAcknowledgements || []).length > 0 && (
                <div>
                  {privacyAcknowledgements.map(privacyAcknowledgement => (
                    <PrivacyAcknowledgementField
                      key={privacyAcknowledgement.id}
                      touched={touched}
                      privacyAcknowledgement={privacyAcknowledgement}
                      errors={errors}
                    />
                  ))}
                </div>
              )}
            </Form>
          </DialogContent>
          <FormFooter isSubmitting={isSubmitting} submitForm={submitForm} formType='dataPrivacyForm' />
        </>
      )}
    </Formik>
  );
}

export default ScopesAndPoliciesForm;
