import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';

const EPISODE = 'episode';

const StyledHeader = styled(Typography).attrs({ variant: 'h4' })`
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 0.8rem;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;

const StyledText = styled.span`
  padding-left: .3rem;
`;

ModalHeader.propTypes = {
  page: PropTypes.string,
};

function ModalHeader({ page }) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledHeader>
      {isTablet && page === EPISODE ? (
        <Icon icon='chevronLeft' />
      ) : (
        <PlaylistAddIcon />
      )}
       <StyledText>Save To Playlist</StyledText>
    </StyledHeader>
  );
}

export default ModalHeader;
