import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import BaseBox from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Box = styled(BaseBox)`
  margin-top: 0.5rem;

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: 1.5rem;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const Number = styled(Typography)`
  font-size: 3.25rem;
  padding-right: 0.5rem;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 3.75rem;
  }
`;

const Text = styled(Typography)`
  line-height: 1.25rem;
`;

function TastyStats() {
  return (
    <Box display='flex' justifyContent='space-between'>
      <Box alignItems='center' display='flex'>
        <Number variant='h2'>7</Number>
        <Text>Days of Live <br /> Programming</Text>
      </Box>
      <Box alignItems='center' display='flex'>
        <Number variant='h2'>100+</Number>
        <Text>Original <br /> Shows</Text>
      </Box>
      <Box alignItems='center' display='flex'>
        <Number variant='h2'>27M</Number>
        <Text>YouTube <br /> Views</Text>
      </Box>
    </Box>
  );
}

export default React.memo(TastyStats);
