import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import BaseContainer from '@material-ui/core/Container';
import RichTextField from 'components/RichTextField';
import fileListShape from 'shapes/components/fileListShape';

// Setting max-width here to match text block; might need to be updated later
const Container = styled(BaseContainer)`
  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1380px;
  }
`;

const FileLink = styled.a`
  display: block;
  text-decoration: none;
  margin-bottom: 1rem;
  color: ${theme('colors.black')};

  &:hover {
    color: ${theme('colors.ttRed')};
  }
`;

const FileDescription = styled.div`
  p {
    margin: 0;
  }
`;

FileList.propTypes = {
  data: fileListShape,
  className: PropTypes.string,
};

function FileList({ data, className }) {
  const { files } = data;

  return (
    <div className={className}>
      <Container maxWidth='xl'>
        {files.map((file, index) => {
          const { fileName, fileDescription, fileLink: { href }} = file;

          return(
            <FileLink href={href} key={`file-${index}`}>
              <Typography variant='h3'>{fileName}</Typography>
              <FileDescription>
                <RichTextField richText={fileDescription} />
              </FileDescription>
            </FileLink>
          );
        })}
      </Container>
    </div>
  );
}

export default React.memo(FileList);
