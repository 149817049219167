import React from 'react';
import styled from 'styled-components';
import { usePictureInPictureContext } from 'context/PictureInPictureContext';
import VideoPlayerPortal from 'components/VideoPlayer/VideoPlayerPortal';
import VideoPlayer from 'components/VideoPlayer';

const FloatingPipWrapper = styled.div`
  position: absolute;
  width: 6.25rem;
  height: 3.75rem;
  bottom: 0;
  left: -9999px;
`;

function VideoPlayerPortals() {
  const { state: pipState } = usePictureInPictureContext();

  if (pipState.videos.length === 0) {
    return null;
  }

  return (
    <>
      {pipState.videos.map(video => {
        const isPip = pipState.pipVideoId === video.mountId;

        return (
          <VideoPlayerPortal mountId={video.mountId} key={video.mountId} float={isPip && pipState.floatPipSource}>
            <VideoPlayer {...video.videoProps} mountId={video.mountId} />
          </VideoPlayerPortal>
        );
      })}
      <FloatingPipWrapper id='floating-pip-wrapper' />
    </>
  );
}

export default React.memo(VideoPlayerPortals);
