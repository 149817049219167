import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';
import VideoPlayerPortals from 'components/VideoPlayer/VideoPlayerPortals';
import TraderResourcesTour from 'components/Tours/TraderResourcesTour';
import ExpiredContentAlert from 'components/ExpiredContentAlert';
import DailyReplaysTour from 'components/Tours/DailyReplaysTour';
import DataPrivacyCapture from 'components/DataPrivacyCapture';
import { useAuth } from 'context/AuthContext';
import Header from 'components/Header';
import Alert from 'components/Alert';
import { useSiteSettings } from 'context/SiteSettingsContext';

const BodyWrapper = styled.div`
  padding-top: 6.25rem;
  overflow: clip;

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 3.75rem;
  }

  ${ifProp('$displayAlert', css`
    padding-top: 9.75rem;

    ${props => props.theme.breakpoints.down('sm')} {
      padding-top: 7.25rem;
    }

    ${props => props.theme.breakpoints.down('xs')} {
      padding-top: 12.1875rem;
    }

    .key-concepts-mobile-nav {
      top: 264px;
      height: calc(100vh - 264px);
    }
  `)}

  ${ifNotProp('$displayAlert', css`
    .glossary-nav {
      ${props => props.theme.breakpoints.down('sm')} {
        top: 78px;
      }
    }
  `)}

  ${ifProp('$stickyFooterAd', css`
    .glossary-quick-scroll-btn {
      bottom: 125px;

      /* Matching breakpoint specific to ad footer */
      @media (min-width: 729px) {
        bottom: 154px;
      }

      ${props => props.theme.breakpoints.up('md')} {
        bottom: 120px;
      }
    }
  `)}
`;

MainSection.propTypes = {
  header: PropTypes.object,
  siteAlerts: PropTypes.shape({
    isActive: PropTypes.bool,
    richTextEditor: PropTypes.string,
    alertLevel: PropTypes.string
  }),
  showStickyAd: PropTypes.bool,
};

function MainSection({ children, header, siteAlerts, showStickyAd }) {
  const { displayAlert } = useSiteSettings();
  const { user } = useAuth();

  return (
    <>
      {displayAlert && (
        <Alert
          siteAlerts={siteAlerts}
          sitewide={true}
        />
      )}
       {/*<BannerAd />*/}
      <Header data={header} displayAlert={displayAlert} />
      <BodyWrapper $displayAlert={displayAlert} $stickyFooterAd={showStickyAd}>
        {children}
      </BodyWrapper>
      <ExpiredContentAlert />
      {user && <DataPrivacyCapture/>}
      <VideoPlayerPortals />
      {typeof window !== 'undefined' && <DailyReplaysTour />}
      {typeof window !== 'undefined' && <TraderResourcesTour />}
    </>
  );
}

export default React.memo(MainSection);
