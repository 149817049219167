import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import Icon from 'components/Icon'
import IconButton from '@material-ui/core/IconButton';
import { theme } from 'styled-tools';
import { useDashboardContext } from 'context/DashboardContext';
import DeleteIcon from '@material-ui/icons/Delete';

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  height: fit-content;
  
  &:hover svg {
    fill: ${theme('colors.ttRed')};
  }
`;

RemoveItem.propTypes = {
  onDelete: PropTypes.func,
  text: PropTypes.string
};

function RemoveItem({ onDelete, text }) {
  const theme = useTheme();
  const { state: dashboardState } = useDashboardContext();

  return (
    <IconWrapper>
      <IconButton
        aria-label='Add to Playlist'
        aria-haspopup='true'
        onClick={onDelete}
        disableRipple
        size='small'
      >
        {dashboardState.isOpen
          ?
            <DeleteIcon />
          :
            <Icon
              icon='trashcan'
              className='remove'
              color={theme.palette.common.black}
              size={1}
            />
        }
      </IconButton>
      {text &&
        <span>{text}</span>
      }
    </IconWrapper>
  );
}

export default React.memo(RemoveItem);
