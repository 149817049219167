import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, theme } from 'styled-tools';
import Typography from '@material-ui/core/Typography';

const RawContentAlertText = styled.div`
  margin: 0;

  p {
    font-weight: 600;

    // clamping the text to allow for the ellipses effect in the design
    ${ifNotProp('$seeMore', css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    `)}
  }
`;

const RawContentAlertCta = styled.a`
  border: 1px solid ${theme('colors.white')};;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  min-width: fit-content;
  padding: 0.25rem 2rem;
  text-decoration: none !important;
  text-transform: uppercase;
  height: 2rem;
`;

RawContentAlert.propTypes = {
  rawContent: PropTypes.shape({
    alertText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string
  })
};

function RawContentAlert({ isMobile, rawContent, seeMore, textSectionRef }) {
  const { alertText, buttonText, buttonLink } = rawContent;

  return (
    <>
      <RawContentAlertText $seeMore={seeMore}>
        <p>{alertText}</p>
      </RawContentAlertText>
      <RawContentAlertCta href={buttonLink} target='_blank' rel='noopener'>
        {buttonText}
      </RawContentAlertCta>
    </>
  )
}

export default React.memo(RawContentAlert);
