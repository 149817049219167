import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import researchSlideShape from 'shapes/researchSlideShape';
import episodeShape from 'shapes/episodeShape';
import Button from 'components/Button';
import Icon from 'components/Icon';
import LoginSection from './LoginSection';
import ActionButtons from './ActionButtons';
import { useAuth } from 'context/AuthContext';
import addSavedSlideDeckMutation from '../../../../graphql/mutations/addSavedSlideDeck.graphql';
import removeSavedSlideDeckMutation from '../../../../graphql/mutations/removeSavedSlideDeck.graphql';
import { dataLayerPush } from 'utils/dataLayer';
import { useDashboardContext } from 'context/DashboardContext';
import updateHasSavedSlidesMutation from './updateHasSavedSlides.graphql';
import { ttSnackbarOptions } from 'components/TTSnackbar';
import TTSnackbarMessage from 'components/TTSnackbar/Message';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ifProp('$loggedIn', css`
    justify-content: space-between;
  `)}

  ${props => props.theme.breakpoints.up('md')} {
    border-top: 0.0625rem solid ${theme('colors.borderGray')};
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const ControlContainer = styled.div`
  display: flex;
`;

const ControlButton = styled(Button)`
  border-radius: 0;
  height: 100%;
  min-width: auto;
  padding: 0.875rem 0.75rem;

  ${props => props.theme.breakpoints.up('sm')} {
    min-width: 2.5rem;
    padding: 0.875rem 1rem;
  }

  .arrow-icon {
    fill: ${ifNotProp('disabled', theme('colors.black'), 'rgba(255, 255, 255, 0.26)')};
    height: 0.8em;
    width: 0.8em;
  }
`;

ActionSection.propTypes = {
  episode: episodeShape,
  fullDeck: researchSlideShape,
  isDesktop: PropTypes.bool,
  onArrowClick: PropTypes.func,
  page: PropTypes.string,
  searchHasSaved: PropTypes.bool,
  slideDeckPdf: PropTypes.object,
  slideDeckTitle: PropTypes.string,
  recheckForSavedDeck: PropTypes.func,
  onDelete: PropTypes.func,
};

function ActionSection({
  episode,
  fullDeck,
  isDesktop,
  onArrowClick,
  page,
  searchHasSaved,
  slideDeckPdf,
  slideDeckTitle,
  recheckForSavedDeck,
  onDelete
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const deckUid = get(fullDeck, 'uid');
  const hasSaved = get(fullDeck, 'hasSaved', searchHasSaved);
  const { state: dashboardState, updateDashboardLogs, setFirstTimeHelper, setSubsequentHelper, setHelperContentType } = useDashboardContext();
  const { hasSavedSlides } = dashboardState?.logs;
  const [ updateHasSavedSlides ] = useMutation(updateHasSavedSlidesMutation);

  const [addSavedSlideDeck, addSavedSlideDeckResult] = useMutation(addSavedSlideDeckMutation, {
    update(cache, { data: { addSavedSlideDeck } }) {
      cache.modify({
        id: cache.identify(fullDeck),
        fields: {
          hasSaved(existingHasSaved = false) {
            return true;
          }
        }
      });
    },
  });
  const [removeSavedSlideDeck, removeSavedSlideDeckResult] = useMutation(removeSavedSlideDeckMutation, {
    update(cache, { data: { addSavedSlideDeck } }) {
      cache.modify({
        id: cache.identify(fullDeck),
        fields: {
          hasSaved(existingHasSaved = false) {
            return false;
          }
        }
      });
    },
  });

  const handleToggle = saved => {
    const saveVariables = { episodeId: episode.uid, slideDeckId: fullDeck.uid };
    const removeVariables = { slideDeckId: fullDeck.uid };
    if (saved) {
      addSavedSlideDeck(
        { variables: saveVariables }
      ).then(() => {
        recheckForSavedDeck();
        dataLayerPush('Saved slide deck');
        handleLogs();
      });
    } else {
      if (onDelete) {
        onDelete();
      } else {
        removeSavedSlideDeck(
          { variables: removeVariables }
        ).then(() => {
          recheckForSavedDeck();
          dataLayerPush('Removed slide deck')
          enqueueSnackbar(<TTSnackbarMessage message='Removed Slide Deck from Dashboard' />, ttSnackbarOptions);
        });
      }
    }
  }

  async function handleLogs() {
    if (!hasSavedSlides) {
      await updateHasSavedSlides({ variables: { hasSavedSlides: true }});
      updateDashboardLogs();
      setFirstTimeHelper(true);
      setHelperContentType('slide show');
    } else {
      setSubsequentHelper(true);
      enqueueSnackbar(<TTSnackbarMessage message='Saved Slide Deck to Dashboard' />, ttSnackbarOptions);
    }
  }

  return (
    <Wrapper $loggedIn={user} $page={page}>
      {user &&
        <ActionButtons
          deckUid={deckUid}
          hasSaved={hasSaved}
          page={page}
          slideDeckPdf={slideDeckPdf}
          slideDeckTitle={slideDeckTitle}
          toggle={handleToggle}
        />
      }
      {!user &&
        <LoginSection />
      }
      {isDesktop && (
        <ControlContainer>
          <ControlButton direction='left' onClick={() => onArrowClick('left')}>
            <Icon className='arrow-icon' icon='chevronLeft' />
          </ControlButton>
          <ControlButton direction='right' onClick={() => onArrowClick('right')}>
            <Icon className='arrow-icon' icon='chevronRight' />
          </ControlButton>
        </ControlContainer>
      )}
    </Wrapper>
  );
}

export default ActionSection;
