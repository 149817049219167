import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

export const DetailsLink = styled.div`
  color: ${theme('palette.common.black')};
  text-decoration: none;

  &:hover {
    color: ${theme('palette.common.black')};
    text-decoration: none;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: 95%;
  }
  
  ${ifProp('$rollDown', css`
    width: 100%;
  `)}
`;
