
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CommunicationPreferencesFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CommunicationPreferences"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailPreferences"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"preferenceGroups"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"groupHeader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"emailSubscriptions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subscriptionName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subheader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]}]}}]}}]}}]}}],"loc":{"start":0,"end":268}};
    doc.loc.source = {"body":"fragment CommunicationPreferencesFields on CommunicationPreferences {\n  emailPreferences {\n    preferenceGroups {\n      groupHeader\n      emailSubscriptions {\n        id\n        status\n        subscriptionName\n        subheader\n        description\n      }\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
