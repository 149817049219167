import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`

const DashboardIconWrapper = styled(DashboardIcon)`
  margin-right: 1rem;
`;

TTSnackbarMessage.propTypes = {
  message: PropTypes.string | PropTypes.element
}

function TTSnackbarMessage({ message }) {

  return (
    <MessageContainer>
      <DashboardIconWrapper />
      {message}
    </MessageContainer>
  )
}

export default React.memo(TTSnackbarMessage);
