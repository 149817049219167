// Regular expression to determine if url is absolute or relative. Will match if url is absolute.
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { getSiteUrl } from 'utils/episodeHelpers';
import linkResolver from 'utils/linkResolver';

const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

export function isAbsoluteUrl(url) {
  return absoluteUrlRegex.test(url) || (url && url.startsWith('/api/'));
}

export function makeUserPlaylistUrl(playlistId, episodeId) {
  let url = `/user-playlists/${playlistId}`;

  if (episodeId) {
    url += `/episodes/${episodeId}`;
  }

  return url;
}

export function makeDailyReplayUrl(dayOfWeek, episodeId) {
  let url = `/daily-replays/${dayOfWeek.toLowerCase()}`;

  if (episodeId) {
    url += `/episodes/${episodeId}`;
  }

  return url;
}

export function getEpisodeUrl(episode, { playlist = null, dailyReplay = null } = {}) {
  if (!episode) {
    return null;
  }

  if (playlist) {
    return makeUserPlaylistUrl(playlist.id, episode.uid);
  }

  if (dailyReplay) {
    const dailyReplayDay = dailyReplay.date ? dayjs(dailyReplay.date).format('dddd') : dailyReplay.uid;
    return makeDailyReplayUrl(dailyReplayDay, episode.uid);
  }

  return linkResolver({ ...episode, contentTypeUid: 'episode' });
}

export function makeBlogContributorUrl(contributor, includeAnchor = true) {
  if (!contributor) {
    return null;
  }

  if (contributor.disableContributorPage) {
    return `/authors/${contributor.url.replace('/talent/', '')}`;
  }

  return contributor.url + (includeAnchor ? '#blog-posts' : '');
}

export function removeTrailingBackslash(url) {
  const location = typeof window !== 'undefined' ? window.location : null;
  const mainPath = location?.pathname;

  if (url.slice(-1) === '/') {
    const newUrl = url.slice(0, url.length - 1);
    return newUrl
  } else if (location?.search && mainPath?.slice(-1) === '/') {
    const updatedPath = mainPath.slice(0, mainPath.length - 1);

    return `${getSiteUrl()}${updatedPath}${location.search}`;
  }
  return url;
}
