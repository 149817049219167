import React from 'react';
import placeholderImg from 'assets/images/img-placeholder.png';

export const convertRem = (value) => {
  return value / 16;
};

export const strippedString = (string) => {
  return string.replace(/(<([^>]+)>)/gi, '');
};

export const createHeaderId = (string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const calcPercent = (value1, value2) => {
  const percent = (value1 / value2) * 100;
  return Math.round(percent);
};

export function findFieldError(errors, fieldName, currentValue) {
  return errors.find((x) => x.param === fieldName);
}

export function autoOptimizeImage(url, optimizeSize, defaultWidth) {
  if (url) {
    let optimizedImage = `${url}?format=pjpg&auto=webp&quality=50`;

    if (optimizeSize && defaultWidth) {
      optimizedImage += `&width=${defaultWidth}&disable=upscale`;
    }

    return optimizedImage;
  }

  return url;
}

export function buildComparisonRows(columns = [], headers = []) {
  const firstHeader = headers[0];
  const firstColumn = columns[0];

  const comparisonRows = [];
  columns?.slice(1)?.forEach((column, index) => {
    const { rows = [] } = column;

    comparisonRows.push({
      name: firstHeader.content,
      data: headers.slice(1)[index].content,
      header: true,
    });

    rows?.forEach((row, index) => {
      const title = firstColumn?.rows[index]?.content?.replace(
        /<[^>]*>?/gm,
        ''
      );
      const formattedContent = row?.content?.replace(/<[^>]*>?/gm, '');

      comparisonRows.push({ name: title, data: formattedContent });
    });
  });

  return comparisonRows;
}

export function buildBasicRows(columns) {
  if (columns.length < 1) {
    return [];
  }

  // Get the number of rows and assume all columns have same number of rows
  const numRows = columns[0]?.rows?.length;

  // Build empty array based on number of rows
  let emptyRows = [];
  for (let i = 0; i < numRows; i++) {
    emptyRows.push([]);
  }

  // Build out html rows to number of columns
  const htmlRows = columns.reduce((rows, column) => {
    for (let i = 0; i < column.rows.length; i++) {
      rows?.[i]?.push(column.rows[i]);
    }
    return rows;
  }, emptyRows);

  return htmlRows;
}

export function optimizeContentCardImage(thumbnail, size, imageSizes) {
  const url = (thumbnail && thumbnail.url) || placeholderImg;

  if (imageSizes) {
    return `${url}?width=${imageSizes[size].width}&format=pjpg&auto=webp`;
  }

  return url;
}

export function imageVsWindowWidth(width) {
  if (typeof window !== 'undefined' && width > window.innerWidth) {
    return true;
  } else {
    return false;
  }
}

export function matchRuleShort(str, rule) {
  let escapeRegex = (str) => str?.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');

  return new RegExp(
    '^' + rule.split('*').map(escapeRegex).join('.*') + '$'
  ).test(str);
}

export function extractLastSegment(url) {
  const parts = url.split('/');
  const lastSegment = parts[parts.length - 1];
  return lastSegment;
}

export function scrollToHash() {
  let currentHash = window.location.hash;
  if (currentHash) {
    let hashValue = currentHash.slice(1);
    
    //Prefixing 'scroll-' prevents Firefox's default scroll behavior
    let element = document.querySelector(`#scroll-${hashValue}`);
    if (element) {
      const topOffset = element.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  }
};

