import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FutureIcon from 'components/Icon/FutureIcon';
import { COLOR_THEMES } from 'utils/constants';

const ImageWrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  padding-top: 56.25%;
  border: 1px solid ${({ theme, $colorTheme }) => $colorTheme === COLOR_THEMES.dark ? theme.colors.mutedGray : theme.colors.borderGray};
`;

const InnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, $colorTheme }) => $colorTheme === COLOR_THEMES.dark ? theme.colors.upcomingPlaceholderTextDark : theme.colors.borderGray};
  font-size: 2.6875rem;
`;

UpcomingEpisodeImage.propTypes = {
  colorTheme: PropTypes.oneOf(Object.values(COLOR_THEMES)),
};

function UpcomingEpisodeImage({ colorTheme = COLOR_THEMES.light }) {
  return (
    <ImageWrapper $colorTheme={colorTheme}>
      <InnerWrap $colorTheme={colorTheme}>
        <FutureIcon fontSize='inherit' />
      </InnerWrap>
    </ImageWrapper>
  );
}

export default React.memo(UpcomingEpisodeImage);
