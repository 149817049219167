import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {formatStringForTestId} from "__testUtils__/functions";

const SmallCheck = styled(Checkbox)`
  padding-top: 0;
  padding-bottom: 0;

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const RemoveIcon = styled(RemoveCircleOutlineIcon)`
  font-size: 1rem;
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  right: 1.15rem;

  &:hover {
    color: ${theme('colors.ttRed')};
  }
`;

const Label = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  .MuiTypography-body1 {
    font-size: 0.8125rem;
  }

  .MuiFormControlLabel-label {
    border-bottom: solid 1px transparent;
    margin-right: 0.875rem;
  }

  &:hover {
    .MuiFormControlLabel-label {
      width: 100%;
      border-bottom: solid 1px ${theme('colors.lightGray')};
    }
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  padding-bottom: 9px;

  &:hover svg{
    visibility: visible;
  }
`;

function getCheckedValues(values) {
  return values.filter(v => v.checked).map(v => v.name);
}

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  displayInColumns: PropTypes.bool,
  smColCount: PropTypes.number,
  mdColCount: PropTypes.number
}

function CheckboxesGroup({
  values = [],
  onChange,
  onRemove,
  displayInColumns = false,
  smColCount,
  mdColCount
}) {
  const [checked, setChecked] = useState(() => getCheckedValues(values));

  useEffect(() => {
    setChecked(getCheckedValues(values));
  }, [values]);

  const handleCheck = (event) => {
    const value = event.target.name;
    const updatedChecked = checked.includes(value) ? checked.filter(c => c !== value) : [...checked, value];

    setChecked(updatedChecked);
    onChange && onChange(updatedChecked, value, !checked.includes(value));
  };

  const handleRemove = (e, value) => {
    e.preventDefault();
    onRemove(value)
  }

  return (
    <FormGroup>
      {displayInColumns ? (
        <Grid container>
          {values.map((value, index) => (
            <Grid item xs={12} sm={smColCount} md={mdColCount} key={index}>
              <LabelContainer>
                <Label
                  key={`check-${index}`}
                  data-testid={formatStringForTestId(value.label)}
                  control={
                    <SmallCheck
                      checked={checked.includes(value.name)}
                      onKeyPress={e => event.key === 'Enter' && handleCheck(e)}
                      onChange={e => handleCheck(e)}
                      name={value.name}
                    />
                  }
                  label={
                    <>
                      {value.label}
                      {onRemove && <RemoveIcon onClick={(e) => handleRemove(e, value)} />}
                    </>
                  }
                />
              </LabelContainer>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {values.map((value, index) => (
            <LabelContainer key={index}>
              <Label
                key={`check-${index}`}
                data-testid={formatStringForTestId(value.label)}
                control={
                  <SmallCheck
                    checked={checked.includes(value.name)}
                    onKeyPress={e => event.key === 'Enter' && handleCheck(e)}
                    onChange={e => handleCheck(e)}
                    name={value.name}
                  />
                }
                label={
                  <>
                    {value.label}
                    {onRemove && <RemoveIcon onClick={(e) => handleRemove(e, value)} />}
                  </>
                }
              />
            </LabelContainer>
          ))}
        </>
      )}
    </FormGroup>
  );
}

CheckboxesGroup.propTypes = propTypes;

export default React.memo(CheckboxesGroup);
