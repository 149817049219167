import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EmptyPage from '../EmptyPage';
import { useHistory } from 'react-router-dom';
import { useDashboardContext } from 'context/DashboardContext';

function EmptyShows() {
  const { state: dashboardState } = useDashboardContext();
  const { followedShowsCount } = dashboardState;

  const noEpisodesTitle = "Oh no! We don't have content for that!";
  const noEpisodesMessage = "There aren't currently any episodes available for what you’re following. For the latest content, explore our full lineup of original shows.";

  const noShowsTitle = "You haven't followed any shows yet.";
  const noShowsMessage = <span>Follow shows anywhere you see this icon. Just click 'Follow' and the latest episodes of your followed shows will
    appear here, in the My Shows section of your dashboard. You can find the follow icon on shows and episodes
    across tasty<em>live</em>.</span>

  const icon = <AddCircleOutlineIcon />;
  const history = useHistory();

  return (
    <EmptyPage
      title={followedShowsCount ? noEpisodesTitle : noShowsTitle}
      text={followedShowsCount ? noEpisodesMessage : noShowsMessage}
      icon={icon}
      buttonText={followedShowsCount ? 'Original Shows' : 'Discover Shows'}
      onClick={() => history.push('/on-demand')}
    />
  )
}

export default React.memo(EmptyShows);
