import styled, { css, keyframes } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const VerticalCardWrapper = styled.div`
  // Using min-width to allow for a grid of 5 cards across in large sizes.
  min-width: 13rem;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  position: relative;
  
  ${ifProp({$rollDown: true}, css`
    transition: padding-bottom 250ms;
    padding-bottom: 0;
    width: 100%;
    ${ifProp({$descriptionPresent: true}, css`
      position: absolute;
    `)}
  `)}
  
  ${ifProp('$autoWidth', css`
    min-width: auto;
  `)}

  ${ifProp('$singleShowPage', css`
    ${props => props.theme.breakpoints.down('sm')} {
      min-width: none;
    }
  `)}

  ${ifProp('$homepageSlider', css`
    ${props => props.theme.breakpoints.down('xs')} {
      min-width: 17rem;
    }
  `)}

  ${ifProp({$page: 'search'}, css`
    min-height: 15rem;
  `)};

  ${ifProp({$expand: true}, css `
    transition: transform 250ms, box-shadow 250ms;
  `)}

  &:hover {
    cursor: pointer;

    ${ifProp({$expand: true}, css `
      background-color: ${theme('colors.white')};
      box-shadow: 0px 10px 20px ${theme('colors.cardBoxShadow')};
      z-index: 12;
      position: absolute;
      width: 100%;
      transform: scale(1.25);
      border: solid 1px ${theme('colors.white')};

      ${props => props.theme.breakpoints.down('sm')} {
        transform: scale(1.15);
      }
    `)};
    
    ${ifProp({$rollDown: true}, css `
      box-shadow: 0px 3px 3px ${theme('colors.cardBoxShadow')};
      z-index: 12;
      background: ${theme('colors.white')};
      
      ${ifProp({$descriptionPresent: true}, css`
         padding-bottom: 3rem;
     
         ${props => props.theme.breakpoints.up('md')} {
            padding-bottom: 4rem;
         }
      `)}
    `)}; 
  }
`;

export default VerticalCardWrapper;
