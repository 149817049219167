import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import getLiveStreamQuery from '../../graphql/queries/liveStream.graphql';
import dayjs from 'dayjs';
import Helmet from 'react-helmet';
import StructuredData from 'components/StructuredData';
import {useAuth} from "context/AuthContext";

const liveShowThumbnailUrl = 'https://images.contentstack.io/v3/assets/blt40263f25ec36953f/blt6dccf225e4080605/5ff66bd8b529867fcec27fdf/tastytrade-Network-Podcast.png';

LiveShowStructuredData.propTypes = {};

function LiveShowStructuredData() {
  const { user } = useAuth();
  const { data } = useQuery(getLiveStreamQuery, {
    skip: !user
  });

  if (!data?.liveStream) {
    return null;
  }

  const { startDateTimeUtc, endDateTimeUtc } = data.liveStream.liveSchedule || {};

  const videoObjectData = {
    '@context': 'http://schema.org/',
    '@type': 'VideoObject',
    name: 'tastylive: A Financial Network for Options & Futures Trading',
    description: 'The tastylive network teaches investors innovative, simple ways to trade stocks, options, and futures, take advantage of market volatility and build a successful portfolio. Tom Sosnoff leads an irreverent and playful band of floor traders who are showing America a new way to quickly find low risk, high return strategies in bullish, bearish and sideways markets.',
    url: 'https://www.tastylive.com',
    uploadDate: dayjs().format('YYYY-MM-DD'),
    thumbnailUrl: liveShowThumbnailUrl,
    contentUrl: data.liveStream.streamSources?.liveHls,
    publication: {
      '@type': 'BroadcastEvent',
      isLiveBroadcast: true,
      startDate: startDateTimeUtc && dayjs(startDateTimeUtc).toISOString(),
      endDate: endDateTimeUtc && dayjs(endDateTimeUtc).toISOString(),
      publishedOn: {
        '@type': 'BroadcastService',
        'url': 'https://www.tastylive.com',
      },
    },
  };

  return <StructuredData data={videoObjectData} />;
}

export default React.memo(LiveShowStructuredData);
