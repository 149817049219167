/** @module tt/routes/Dashboard */

import React, {useEffect, useState} from 'react';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import TuneIcon from '@material-ui/icons/Tune';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MobileFilters from 'routes/Dashboard/MobileFilters';
import Hidden from '@material-ui/core/Hidden';
import SortIcon from '@material-ui/icons/Sort';
import MyShowsSidebar from 'routes/Dashboard/components/MyShowsSidebar';
import MySlidesSidebar from 'routes/Dashboard/components/MySlidesSidebar';
import MobileFilterList from 'routes/Dashboard/components/MobileFilterList';
import PlaylistsSidebar from 'routes/Dashboard/components/PlaylistsSidebar';
import MobileSortDialog from 'routes/Dashboard/components/MobileSortDialog';
import DailyReplaysSidebar from 'routes/Dashboard/components/DailyReplaysSidebar';
import DesktopSort from './components/DesktopSort';
import Playlists from './components/Playlists';
import { dataLayerPush } from 'utils/dataLayer';
import SettingsIcon from '@material-ui/icons/Settings';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDashboardContext } from 'context/DashboardContext';
import PropTypes from 'prop-types';

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme('colors.backgroundGray')};
`;

const DashboardToolbar = styled(Toolbar)`
  flex: 0 0 auto;
  background-color: ${theme('palette.common.white')};
  border-bottom: 0.0625rem solid ${prop('theme.colors.mediumGray')};
  display: flex;
  justify-content: space-between;
  min-height: 2.5rem;
  padding: 0 1.25rem;
  z-index: 1302; // z-index higher than mobile filters (1301).

  ${ifProp('$mobile', css`
    // If height is changed, "top" must be updated in routes/Dashboard/MobileFilters "Wrapper"
    height: 2.75rem;

    ${props => props.theme.breakpoints.up('md')} {
      display: none;
    }
  `, css`
    ${props => props.theme.breakpoints.down('sm')} {
      display: none;
    }
  `)}

  ${ifProp('$desktop', css`
    ${props => props.theme.breakpoints.up('sm')} {
      padding-left: 1.25rem;
    }
  `)}

  ${ifProp('$noBorder', css`
    border-bottom-color: transparent;

    ${ifProp('$desktop', css`
      background-color: ${theme('colors.backgroundGray')};
    `)}
  `)}
`;

const DashboardBody = styled.div`
  flex: 1 1 auto;
  padding: 1rem 1.25rem;
  overflow-y: auto;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 1rem 5.625rem;
  }
`;

const IconNav = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  
  && span {
    font-size: 0.8125rem;
    font-weight: 400;
  }
  
  svg {
    font-size: 1.25rem;
  }

  .standard-sort {
    margin-right: 0.875rem;
  }
  
  .down {
    font-size: 1.5rem;
    margin-left: 0.33rem;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0.3rem 0.75rem;
  border-radius: 0.4rem;
  
  &:hover {
    cursor: pointer;
    background-color: ${theme('colors.toolHoverBackgroundGray')};
    
    ${ifProp({$type: 'remove'}, css`
      color: white;
      background-color: ${theme('colors.ttRed')}
    `)};
  }
  
  .edit,
  .sort {
    font-size: 1rem;
    margin-right: 0.625rem;
  }
  
  .cancel {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
`;

const ToolbarText = styled.p`
  font-size: 0.8125rem;
  font-family: ${theme('typography.fontFamilySecondary')};
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
`;

const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: pointer;
`;

/**
 * Text labels shown in the dashboard toolbar in mobile.
 *
 * @type {{panelMySlides: string, panelPlaylists: string, panelMyShows: string, panelEpisodes: string}}
 */
const MOBILE_TOOLBAR_LABELS = {
  panelMyShows: 'Shows',
  panelMySlides: 'Slides',
  panelPlaylists: 'Playlists',
  panelEpisodes: 'History',
  panelDailyReplays: 'Daily Replays',
};

/**
 * React components to use for mobile filters slide-down panel per page.
 */
const MOBILE_FILTER_COMPONENTS = {
  panelMyShows: {
    filter: () => <MyShowsSidebar component={MobileFilterList} />,
    sort: () => <MyShowsSidebar component={MobileFilterList} />
  },
  panelMySlides: {
    filter: () => <MySlidesSidebar component={MobileFilterList} />,
    sort: () => <MySlidesSidebar component={MobileFilterList} />,
    removableContent: true
  },
  panelPlaylists: {
    filter: () => <PlaylistsSidebar component={MobileFilterList} on />,
    sort: false,
    removableContent: true
  },
  panelEpisodes: {
    sort: false,
  },
  panelDailyReplays: {
    filter: () => <DailyReplaysSidebar component={MobileFilterList} />,
    sort: false,
  }
};

Main.propTypes = {
  dashComponent: PropTypes.node,
}

function Main({ dashComponent }) {
  const { toggleDeleteSelectedItems, toggleEdit, state: dashboardState } = useDashboardContext();
  const { isEditing, expanded } = dashboardState
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [mobileSortDialogOpen, setMobileSortDialogOpen] = useState(false);
  const DashboardComponent = dashComponent;
  const FilterComponent = MOBILE_FILTER_COMPONENTS[expanded]?.filter;
  const SortComponent = MOBILE_FILTER_COMPONENTS[expanded]?.sort;
  const isContentRemovable = MOBILE_FILTER_COMPONENTS[expanded]?.removableContent;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const pageTitle = expanded && MOBILE_TOOLBAR_LABELS[expanded];
  const hideBorder = expanded === 'panelDailyReplays' || expanded === 'panelEpisodes';

  useEffect(() => {
    if (dashboardState.isEditing) {
      toggleEdit();
    }
  }, [dashComponent])

  const toggleMobileFilters = () => {
    setMobileFiltersOpen(!mobileFiltersOpen);
    dataLayerPush('Toggled mobile dashboard filters');
  }

  const toggleMobileSortDialog = () => {
    setMobileSortDialogOpen(!mobileSortDialogOpen);
    dataLayerPush('Toggled mobile dashboard sort dialog');
  }

  return (
    <MainContainer>
      {/*Desktop toolbar*/}
      <DashboardToolbar $desktop $noBorder={hideBorder}>
        <IconNav>
          <DesktopSort dashComponent={dashComponent} expanded={expanded} page='dashboard' />
        </IconNav>
        {isContentRemovable &&
          (
            isEditing
              ?
                <FlexDiv>
                  <IconContainer $type='remove' onClick={toggleDeleteSelectedItems}>
                    <DeleteIcon className='cancel' />
                    <ToolbarText>Remove</ToolbarText>
                  </IconContainer>

                  <IconContainer onClick={toggleEdit}>
                    <DoneIcon className='cancel' />
                    <ToolbarText>Okay, I'm Done</ToolbarText>
                  </IconContainer>
                </FlexDiv>
              :
                <IconContainer onClick={toggleEdit}>
                  <SettingsIcon className='edit' />
                  <ToolbarText>Manage My Stuff</ToolbarText>
                </IconContainer>
          )
        }
      </DashboardToolbar>

      {/*Mobile/Tablet toolbar*/}
        <DashboardToolbar $mobile $noBorder={mobileFiltersOpen}>
          {pageTitle && <ToolbarText as='h2'>{pageTitle}</ToolbarText>}
          <ToolbarActions>
            {SortComponent && (
              <IconButton
                aria-label='Sort options'
                color='inherit'
                onClick={toggleMobileSortDialog}
              >
                <SortIcon fontSize='small' />
              </IconButton>
            )}
            {FilterComponent &&
            <Hidden smUp>
              <IconButton
                aria-label='Filter options'
                color='inherit'
                onClick={toggleMobileFilters}
              >
                <TuneIcon fontSize='small'/>
              </IconButton>
            </Hidden>
            }

            {isContentRemovable &&
              <>
                {isEditing &&
                  <DeleteIconWrapper onClick={toggleDeleteSelectedItems} className='cancel' />
                }

                <IconButton
                  aria-label={isEditing ? 'Cancel bulk edit mode' : 'Bulk remove items'}
                  color='inherit'
                  edge='end'
                  onClick={toggleEdit}
                >
                  {isEditing ? (
                    <DoneIcon fontSize='small' className='cancel' />
                  ) : (
                    <SettingsIcon fontSize='small' className='edit' />
                  )}
                </IconButton>
              </>
            }
          </ToolbarActions>
        </DashboardToolbar>

      {/* Mobile Filters - shown on mobile only */}
      {FilterComponent && (
        <Hidden smUp implementation='css'>
          <MobileFilters open={mobileFiltersOpen} onClose={toggleMobileFilters}>
            <FilterComponent />
          </MobileFilters>
        </Hidden>
      )}

      {/* Mobile Sort dialog - shown on mobile/tablet */}
      <Hidden mdUp implementation='css'>
        <MobileSortDialog open={mobileSortDialogOpen} onClose={toggleMobileSortDialog} />
      </Hidden>

      {/* The "id" here is referenced by the infinite scrolling feature in the dashboard to target the scrollable container. */}
      <DashboardBody id='dashboardScrollContainer'>
        <DashboardComponent edit={isEditing} />
      </DashboardBody>
    </MainContainer>
  );
}

export default React.memo(Main);
