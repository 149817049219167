import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from 'components/Checkbox';
import { SUBSCRIPTION_STATUSES } from 'constants/communicationPreferences';
import preferenceGroupShape from 'shapes/preferenceGroupShape';
import updateEmailPreferencesMutation from './updateEmailPreferences.graphql';
import { useMutation } from '@apollo/client';
import ErrorAlert from 'components/ErrorAlert';

const Header = styled(Typography)`
  font-family: ${prop('theme.typography.fontFamilySecondary')};
  font-weight: 700;
  margin-bottom: 3rem;
`;

const GridRow = styled(Grid)`
  margin-bottom: 2.5rem;

  .MuiGrid-item {
    padding-bottom: 0;
    padding-top: 0;
  }

  .MuiFormControlLabel-root {
    margin-left: -0.5rem;
  }
`;

const GridHeader = styled(Typography)`
  font-family: ${prop('theme.typography.fontFamilySecondary')};
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  width: 100%;
`;

const ButtonPrimary = styled(Button)`
  border: 0.0625rem solid ${prop('theme.colors.ttRed')};
  border-radius: 0.3125rem;
  color: ${prop('theme.colors.white')};

  ${ifProp({ $type: 'primary'}, css`
    background-color: ${prop('theme.colors.ttMuted')};

    &:hover {
      background-color: ${prop('theme.colors.darkRed')};
    }
  `)}
  
  ${ifProp('disabled', css`
    border: inherit;
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  button {
    width: 100%;

    ${props => props.theme.breakpoints.up('sm')} {
      width: 50%;
    }

    &:first-of-type {
      margin-bottom: 1rem;

      ${props => props.theme.breakpoints.up('sm')} {
        margin-bottom: 0;
        margin-right: 0.5rem;
      }
    }

    &:last-of-type {
      ${props => props.theme.breakpoints.up('sm')} {
        margin-left: 0.5rem;
      }
    }
  }
`;

const Text = styled(Typography)`
  ${ifProp({ size: 'small'}, css`
    font-size: 0.75rem;
  `)}

  ${switchProp('type', {
    header: css`
      font-weight: 700;
    `,
    subheader: css`
      font-size: 0.8125rem;
      font-weight: 500;
    `
  })}
`;

UpdateEmailPrefs.propTypes = {
  data: PropTypes.arrayOf(preferenceGroupShape).isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string
};

function UpdateEmailPrefs({ data, onClose, title = 'Update Email Preferences' }) {
  const [updateEmailPreferences, { loading, error }] = useMutation(updateEmailPreferencesMutation);
  const [updatedState, setUpdatedState] = useState(data);
  const rendered = useRef(false);

  // If data is updated externally, update the state but skip first render since we already set it.
  useEffect(() => {
    if (rendered.current) {
      setUpdatedState(data);
    } else {
      rendered.current = true;
    }
  }, [data]);

  const handleCheckboxChange = (subscriptionId, newValue) => {
    const newData = updatedState.map(group => {
      return {
        ...group,
        emailSubscriptions: group.emailSubscriptions.map(subscription => {
          if (subscription.id === subscriptionId) {
            return {
              ...subscription,
              status: newValue ? SUBSCRIPTION_STATUSES.subscribed : SUBSCRIPTION_STATUSES.notSubscribed,
            };
          }

          return subscription;
        })
      };
    });

    setUpdatedState(newData);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    return updateEmailPreferences({
      variables: {
        input: {
          emailSubscriptions: updatedState.reduce((results, group) => {
            group.emailSubscriptions && group.emailSubscriptions.forEach(({ id, status }) => {
              results.push({ id, status });
            });

            return results;
          }, []),
        }
      },
    }).then(() => {
      onClose && onClose();
    });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Header variant='h4'>{title}</Header>

      {updatedState && updatedState.map((emailPref, index) => {
        const options = emailPref.emailSubscriptions;

        return (
          <GridRow container key={`email-row-${index}`} spacing={4}>
            <GridHeader variant='h4'>{emailPref.groupHeader}</GridHeader>
            {options && options.map((option, index) => (
              <Grid item key={`email-type-${option.id || index}`} sm={6} xs={12}>
                <Checkbox
                  value={option.status === SUBSCRIPTION_STATUSES.subscribed}
                  onChange={(event) => handleCheckboxChange(option.id, event.target.checked)}
                  label={option.subscriptionName}
                  labelPlace='end'
                  labelWeight='bold'
                />
                <Box pl={3}>
                  {option.subheader && (
                    <Text type='subheader'>{option.subheader}</Text>
                  )}
                  {option.description && (
                    <Text size='small'>
                      {option.description}
                    </Text>
                  )}
                </Box>
              </Grid>
            ))}
          </GridRow>
        )
      })}

      {error && (
        <Box my={3}>
          <ErrorAlert>An error occurred while saving your email preferences. Please try again in a minute.</ErrorAlert>
        </Box>
      )}

      <ButtonContainer>
        <ButtonPrimary type='submit' $type='primary' variant='contained' disabled={loading}>
          {loading ? 'Saving Changes...' : 'Save Changes'}
        </ButtonPrimary>
        <Button type='button' variant='outlined' onClick={onClose} disabled={loading}>Cancel</Button>
      </ButtonContainer>
    </form>
  );
}

export default React.memo(UpdateEmailPrefs);
