import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import EmptyPage from '../EmptyPage';
import { useHistory } from 'react-router-dom';

function EmptyWatchHistory() {

  const title = "You haven't watched any episodes yet";
  const message = "Any episodes you watch will be saved here for you to quickly find again!";
  const icon = <HistoryIcon />;
  const history = useHistory();

  return (
    <EmptyPage
      title={title}
      text={message}
      icon={icon}
      buttonText='Discover Shows'
      onClick={() => history.push('/on-demand')}
    />
  )
}

export default React.memo(EmptyWatchHistory);
