import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function SportsIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_495" data-name="Group 495" transform="translate(-1.518 0.538)">
          <path id="Path_261" data-name="Path 261" d="M14.657-42.568a.74.74,0,0,0-.848.026l-2.571-1.734a4.15,4.15,0,0,0,.248-3.206,5.905,5.905,0,0,0-2.412-3.144,6.07,6.07,0,0,0-3.313-1.038,4.208,4.208,0,0,0-3.6,1.787C.8-47.831,1.683-44.838,4.135-43.206a6.061,6.061,0,0,0,3.312,1.038,4.272,4.272,0,0,0,3.377-1.491L13.4-41.921a.743.743,0,0,0,.316.762l1.869,1.243a.74.74,0,0,0,1.029-.206l.115-.173a.741.741,0,0,0-.206-1.029Zm-7.628-.374a5.137,5.137,0,0,1-1.388-.329l.369-.555,1.113.741Zm.865-.3.66-.99,1.048.7a3.568,3.568,0,0,1-1.333.543Zm-.462-.308L6.318-44.29l.66-.992,1.113.741ZM2.338-46.939a3.577,3.577,0,0,1-.012-1.44l1.036.69L2.7-46.7Zm1.488-.442,1.113.741-.66.992-1.113-.741Zm2.336-3.509a5.118,5.118,0,0,1,1.391.324l-.367.55-1.113-.741ZM5.3-50.6l-.66.992L3.6-50.3a3.563,3.563,0,0,1,1.334-.543Zm.463.307,1.114.741-.66.992L5.1-49.3ZM6.68-48.25l.66-.992,1.115.741-.66.992Zm.805,1.2-.66.992L5.71-46.795l.66-.992ZM5.907-48.094l-.66.992-1.113-.741.66-.992ZM5.4-46.33l1.114.741-.66.992-1.114-.741Zm1.885.586.66-.992L9.06-46,8.4-45ZM8.256-47.2l.66-.992,1.113.741-.66.992Zm2.237.057.356.236a3.515,3.515,0,0,1,.017,1.443l-1.034-.687Zm-.051-.926-.1.154-1.113-.741L9.6-49.21a5.163,5.163,0,0,1,.846,1.141ZM9.184-49.6l-.422.633-1.114-.741.424-.638a5.843,5.843,0,0,1,.59.335A6.04,6.04,0,0,1,9.184-49.6Zm-6.025-.326,1.173.78-.66.992L2.5-48.933a3.085,3.085,0,0,1,.276-.532A2.981,2.981,0,0,1,3.159-49.924Zm-.4,4.148.1-.148,1.113.741-.372.562a5.166,5.166,0,0,1-.839-1.154Zm1.249,1.54.426-.64,1.114.741-.425.639a5.248,5.248,0,0,1-.576-.328A5.455,5.455,0,0,1,4.006-44.236Zm6.042.33-1.184-.789.66-.992,1.18.785a2.993,2.993,0,0,1-.271.536,3.132,3.132,0,0,1-.385.46Z" transform="translate(0 62.714)" fill="#e31b23"/>
          <path id="Path_262" data-name="Path 262" d="M52.8-57.047a5.7,5.7,0,0,0-.592,3.41,6,6,0,0,1,4.375,1.185,6,6,0,0,1,2.754,3.6,5.7,5.7,0,0,0,2.931-1.84,56.869,56.869,0,0,0-4.585-3.4A55.457,55.457,0,0,0,52.8-57.047Z" transform="translate(-38.101 66.765)" fill="#e31b23"/>
          <path id="Path_263" data-name="Path 263" d="M66.7-63.977a6,6,0,0,1-4.376-1.184,6,6,0,0,1-2.754-3.6A5.705,5.705,0,0,0,56.32-66.5a57.044,57.044,0,0,1,4.909,2.975,56.906,56.906,0,0,1,4.611,3.417,5.7,5.7,0,0,0,.865-3.871Z" transform="translate(-41.238 75.58)" fill="#e31b23"/>
          <path id="Path_264" data-name="Path 264" d="M56.766-39.472a5.358,5.358,0,0,0-3.817-1.078,5.71,5.71,0,0,0,2.34,3.279,5.7,5.7,0,0,0,3.921.921,5.372,5.372,0,0,0-2.444-3.123Z" transform="translate(-38.701 54.401)" fill="#e31b23"/>
          <path id="Path_265" data-name="Path 265" d="M74.845-66.348a5.372,5.372,0,0,0,3.816,1.079,5.708,5.708,0,0,0-2.338-3.279,5.715,5.715,0,0,0-3.921-.922,5.355,5.355,0,0,0,2.443,3.123Z" transform="translate(-53.339 76.149)" fill="#e31b23"/>
          <path id="Path_266" data-name="Path 266" d="M4.552-74.713a4.821,4.821,0,0,0-.3-2.388,4.842,4.842,0,0,0-1.4-1.958c-.807,1.123-1.147,2.2-.892,2.856s1.238,1.213,2.594,1.49Z" transform="translate(-0.269 83.328)" fill="#e31b23"/>
          <path id="Path_267" data-name="Path 267" d="M49.639-91.233c.808-1.123,1.149-2.2.893-2.855s-1.238-1.214-2.594-1.491a4.821,4.821,0,0,0,.3,2.388,4.838,4.838,0,0,0,1.4,1.958Z" transform="translate(-34.901 95.759)" fill="#e31b23"/>
          <path id="Path_268" data-name="Path 268" d="M9.3-90.352a5.524,5.524,0,0,1,.343,2.773,10.752,10.752,0,0,0,5.077-.674,10.736,10.736,0,0,0,4.19-2.952,5.562,5.562,0,0,1-1.631-2.266,5.529,5.529,0,0,1-.343-2.774,10.754,10.754,0,0,0-5.077.675,10.744,10.744,0,0,0-4.19,2.952A5.547,5.547,0,0,1,9.3-90.352Zm.665-3.484a.2.2,0,0,1,.269-.1,2.716,2.716,0,0,1,.939.806,7.61,7.61,0,0,1,1.139-.594,2.215,2.215,0,0,0-.611-.48.2.2,0,0,1-.1-.27.2.2,0,0,1,.269-.1,2.738,2.738,0,0,1,.844.692,7.5,7.5,0,0,1,1.329-.363,2.346,2.346,0,0,0-.707-.595.207.207,0,0,1-.1-.271.2.2,0,0,1,.269-.1,2.759,2.759,0,0,1,1.008.9,3.815,3.815,0,0,1,.912,0,.2.2,0,0,1,.164.236.2.2,0,0,1-.236.165,3.224,3.224,0,0,0-.607-.013,3.52,3.52,0,0,1,.332,1.064.2.2,0,0,1-.172.232.162.162,0,0,1-.031,0,.207.207,0,0,1-.2-.174,3,3,0,0,0-.373-1.082,7.046,7.046,0,0,0-1.339.338,3.379,3.379,0,0,1,.483,1.323.205.205,0,0,1-.172.232l-.028,0a.206.206,0,0,1-.2-.174,2.968,2.968,0,0,0-.468-1.237,7.174,7.174,0,0,0-1.174.605A3.442,3.442,0,0,1,11.8-91.58a.2.2,0,0,1-.172.232.15.15,0,0,1-.03,0,.205.205,0,0,1-.2-.175,3.047,3.047,0,0,0-.346-1.037,3.725,3.725,0,0,0-.635.575.206.206,0,0,1-.162.08.2.2,0,0,1-.123-.042.2.2,0,0,1-.04-.285,4.08,4.08,0,0,1,.737-.673,2.341,2.341,0,0,0-.769-.665.2.2,0,0,1-.1-.27Z" transform="translate(-4.625 96.31)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default SportsIcon;
