import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DateFormatter from 'components/DateFormatter';
import RichTextField from 'components/RichTextField';
import testimonialShape from 'shapes/testimonialShape';
import Grid from '@material-ui/core/Grid';

const Title = styled.span`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
`;

const BodyContent = styled(RichTextField)`
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${theme('colors.white')};
  box-shadow: 0.625rem 0.625rem 1.25rem 0.625rem #ececec;
  margin-top: 1rem;
  position: relative;
  
  p {
    font-size: 1rem;
    font-weight: 600;
  }
  
    &:after {
      content: "";
      position: absolute;
      box-shadow: 0.625rem 0.625rem 1.25rem #ececec;
      -moz-transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      top: -0.625rem;
      left: 1.5rem;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #FFF #FFF transparent;
    }
`;

const Person = styled.span`
  font-weight: 600;
`;

const AccountCircleIconWrapper = styled(AccountCircleIcon)`
  fill: ${theme('colors.ttRed')};
  height: 4rem;
  width: 4rem;
  margin-right: 0.5rem;
`;

const TitleGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

TestimonialCard.propTypes = {
  testimonial: testimonialShape.isRequired,
  testimonialStyles: PropTypes.shape({
    applyBorder: PropTypes.bool,
    applyBoxShadow: PropTypes.bool
  })
};

function TestimonialCard({ testimonial, testimonialStyles }) {
  const { applyBorder, applyBoxShadow } = testimonialStyles;

  const {
    testimonialAvatar,
    testimonialBody,
    testimonialDate,
    TestimonialLocation,
    testimonialPerson,
    title
  } = testimonial;

  return (
    <Grid container>
      <TitleGrid item xs={12}>
        <AccountCircleIconWrapper />
        <Title>
          <Person>{testimonialPerson}</Person>
          &nbsp;&nbsp;
          <DateFormatter date={testimonialDate} />
        </Title>
      </TitleGrid>
      <Grid item xs={12}>
        <BodyContent richText={testimonialBody} />
      </Grid>
    </Grid>
  );
}

export default TestimonialCard;
