import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MoneyCycleIcon(props) {
  return (
    <SvgIcon {...props}>
        <g id="np_finance_1063365_000000" transform="translate(0.082 0)">
          <path id="Path_232" data-name="Path 232" d="M31.748,36.575a.962.962,0,0,0-1.249-.715.99.99,0,0,0-.649,1.174,10.243,10.243,0,0,1-2.7,9.49,9.921,9.921,0,0,1-12.6,1.327l.75-.765a.6.6,0,0,0-.35-1.021l-3.7-.459a.587.587,0,0,0-.649.663l.5,3.725a.581.581,0,0,0,1,.357l1.05-1.071A11.749,11.749,0,0,0,28.5,47.9,12.169,12.169,0,0,0,31.748,36.575Z" transform="translate(-8.067 -27.499)" fill="#e31b23"/>
          <path id="Path_233" data-name="Path 233" d="M4.836,5.057A9.919,9.919,0,0,1,17.431,3.73l-.75.765a.6.6,0,0,0,.35,1.021l3.7.459a.587.587,0,0,0,.649-.663l-.5-3.725a.581.581,0,0,0-1-.357L18.83,2.3A11.876,11.876,0,0,0,3.436,3.628,12.272,12.272,0,0,0,.238,15a.962.962,0,0,0,1.249.715.99.99,0,0,0,.649-1.174,10.412,10.412,0,0,1,2.7-9.489Z" transform="translate(0 -0.075)" fill="#e31b23"/>
          <path id="Path_234" data-name="Path 234" d="M38.077,36.223V35.14a4,4,0,0,0,2.5-.886,2.711,2.711,0,0,0,.969-2.117,2.323,2.323,0,0,0-.816-1.969,6.313,6.313,0,0,0-2.6-1.083h-.051V26.327a5.2,5.2,0,0,1,2.194.837l1.021-1.378A6.4,6.4,0,0,0,38.128,24.7v-.739h-.969V24.7a3.82,3.82,0,0,0-2.4.886,2.639,2.639,0,0,0-.918,2.068,2.286,2.286,0,0,0,.816,1.92,6.1,6.1,0,0,0,2.551,1.034v2.952a5.748,5.748,0,0,1-2.653-1.28l-1.225,1.28a6.826,6.826,0,0,0,3.776,1.625v1.083h.969Zm0-5.268a3.315,3.315,0,0,1,1.275.591,1.168,1.168,0,0,1,.357.837,1.039,1.039,0,0,1-.408.837,2.066,2.066,0,0,1-1.174.394l0-2.66ZM35.934,28.3a1.02,1.02,0,0,1-.306-.787,1.113,1.113,0,0,1,.408-.837,2.11,2.11,0,0,1,1.071-.394v2.56a2.527,2.527,0,0,1-1.174-.541Z" transform="translate(-25.455 -18.114)" fill="#e31b23"/>
        </g>
    </SvgIcon>
  );
}

export default MoneyCycleIcon;
