export function convertNullValuesToUndefined(obj) {
  if (obj === null) {
    return undefined;

  }
  if (typeof obj !== 'object') {
    return obj;

  }
  if (obj instanceof Array) {
    return obj.map(convertNullValuesToUndefined);
  }

  return Object.keys(obj).reduce((result, key) => ({
    ...result,
    [key]: convertNullValuesToUndefined(obj[key]),
  }), {});
}
