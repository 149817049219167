import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { COLOR_THEMES } from 'utils/constants';

const PlaylistIndexIndicator = styled.div`${({ theme }) => css`
  flex: 0 0 auto;
  width: 1.625rem; // Using width instead of margin right for spacing so that if number is many digits, we don't break layout.
  align-self: flex-end;
  font-size: 0.8125rem;
  font-family: ${theme.typography.fontFamilySecondary};
  font-weight: bold;
  color: ${ifProp('$active', theme.colors.ttRed, theme.colors.mutedLightGray)};
  
    
  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: false }, css`
    color: ${ifProp('$active', theme.colors.white, theme.colors.whiteTransparent)};
  `)}
  
  ${ifProp({ $colorTheme: COLOR_THEMES.dark, $upcoming: true }, css`
    color: ${theme.colors.upcomingPlaceholderTextDark};
  `)}
    
  ${props => props.theme.breakpoints.down('md')} {
    align-self: flex-start;
  }
  
  ${props => props.theme.breakpoints.down('xs')} {
    align-self: flex-end;
  }
`}`;

export default PlaylistIndexIndicator;
