import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

const Alert = styled(BaseAlert)`
  a {
    color: inherit;
  }
`;

DashboardAlert.propTypes = {
  message: PropTypes.string.isRequired,
  mt: PropTypes.number,
  severity: PropTypes.oneOf(['error', 'info']).isRequired
}

function DashboardAlert({ message, mt = 3, severity }) {

  return (
    <Box mt={mt}>
      <Alert severity={severity}>
        {message}
      </Alert>
    </Box>
  );
};

export default React.memo(DashboardAlert);
