import React, { Fragment } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Leadership from './components/Leadership';
import TalentCluster from './components/TalentCluster';
import talentCardsShape from 'shapes/components/talentCardsShape';

const TalentCardsContainer = styled(Container)`
  max-width: 1380px;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

TalentCards.propTypes = {
  data: talentCardsShape.isRequired,
  className: PropTypes.string,
};

function TalentCards({ data, className }) {
  const allTalent = get(data, 'modularBlocks');

  return (
    <TalentCardsContainer>
      {allTalent.map((talent, index) => {
        const { leadershipFeatured, talentCluster } = talent;

        return (
          <Fragment key={`talent-${index}`}>
            {leadershipFeatured && <Leadership data={leadershipFeatured} />}
            {talentCluster && <TalentCluster data={talentCluster} />}
          </Fragment>
        );
      })}
    </TalentCardsContainer>
  );
}

export default React.memo(TalentCards);
