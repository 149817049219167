import React from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';
import { dataLayerPush } from 'utils/dataLayer';

const Divider = styled.hr`
  display: none;

  ${props => props.theme.breakpoints.down('xs')} {
    border-left: none;
    border-right: none;
    border-top: 0.0625rem solid ${theme('colors.borderGray')};
    display: block;
    margin: 0 auto;
    width: 95%;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  padding: 1rem 0 1rem 1.5rem;
  width: 100%;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 1.5rem 1rem;
  }
`;

const Link = styled.a`
  color: ${theme('colors.black')};
  display: flex;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: ${theme('colors.ttRed')};
  }
`;

const LinkText = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

const LoginIcon = styled(Icon)`
  height: 1.125rem;
  margin-right: 0.625rem;
  width: 1.25rem;
`;

function LoginSection() {
  return (
    <>
      <Divider />
      <LoginContainer>
        <Box alignItems='center' display='flex'>
          <Link href='/api/login' onClick={() => {
            dataLayerPush('Logged in from slides carousel')
          }}>
            <LoginIcon icon='login' />
            <LinkText>Login or Sign Up to Download</LinkText>
          </Link>
        </Box>
      </LoginContainer>
    </>
  );
}

export default LoginSection;
