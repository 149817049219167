import React from 'react';
import PropTypes from 'prop-types';
import {ifNotProp, switchProp} from 'styled-tools';
import styled, { css } from 'styled-components';
import AddToPlaylist from 'components/CardButtons/AddToPlaylist';
import episodeShape from 'shapes/episodeShape';
import { theme } from 'styled-tools';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:hover span svg {
    fill: ${theme('colors.ttRed')};
  }

  ${switchProp('display', {
    vertical: css`
      div {
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
    `,

    horizontal: css`
      margin-bottom: -0.375rem;
      flex-direction: row;

      ${props => props.theme.breakpoints.up('lg')} {
        flex-direction: column;
        justify-content: flex-start;

        ${ifNotProp({display: 'horizontal'}, css`
          ${props => props.theme.breakpoints.up('lg')} {
            margin-right: 1.875rem;
          }
        `)}
      }

      div {

      ${ifNotProp({display: 'horizontal'}, css`
        ${props => props.theme.breakpoints.up('lg')} {
          margin: 0.25rem 0 0.5rem;
        }
      `)}
        &:not(:first-child) {
          ${props => props.theme.breakpoints.down('md')} {
            margin-left: 0.5rem;
          }
        }
      }

      .remove {
        ${props => props.theme.breakpoints.up('md')} {
          display: none;
        }
      }
    `
  })}
`;

EpisodeIcons.propTypes = {
  display: PropTypes.string,
  episode: episodeShape.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  excludedActions: PropTypes.array,
  onUnfollowOverride: PropTypes.func
};

function EpisodeIcons({ display, episode, onDelete, onOpen, onClose, excludedActions, onUnfollowOverride }) {
  return (
    <IconContainer display={display}>
      <AddToPlaylist
        episode={episode}
        onOpen={onOpen}
        onClose={onClose}
        onDelete={onDelete}
        excludedActions={excludedActions}
        onUnfollowOverride={onUnfollowOverride}
      />
    </IconContainer>
  );
}

export default EpisodeIcons;
