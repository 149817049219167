import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import researchSlideShape from 'shapes/researchSlideShape';
import episodeShape from 'shapes/episodeShape';
import StandardCarousel from './StandardCarousel';
import SwipeableCarousel from './SwipeableCarousel';
import SlideshowFooter from './SlideshowFooter';
import SlideshowHeader from './SlideshowHeader';
import { dataLayerPush } from 'utils/dataLayer';

const LEFT = 'left';
const RIGHT = 'right';

const Body = styled.div`
  height: 100%;
  position: relative;

  > div {
    height: 100%;
    position: initial;
  }
`;

Slideshow.propTypes = {
  episode: episodeShape,
  onClose: PropTypes.func,
  page: PropTypes.string,
  slideDecks: PropTypes.arrayOf(researchSlideShape),
  onDelete: PropTypes.func,
};

function Slideshow({ episode, onClose, page, slideDecks, onDelete }) {
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const fullDeck = slideDecks && slideDecks[0];
  const slideDeckPdf = get(fullDeck, 'slideDeckPdf');
  const slideDeckTitle = get(fullDeck, 'title');
  const slideImages = get(fullDeck, 'slideImages', []) || [];

  if (!slideDecks) {
    return null;
  }

  const numSlides = slideImages.length;
  const onArrowClick = (direction) => {
    const increment = direction === LEFT ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;
    setIndex(newIndex);

    if (direction === RIGHT) {
      dataLayerPush('Slide next');
    } else {
      dataLayerPush('Slide prev');
    }
  }

  const mainRef = useRef(null);
  const imageRef = useRef(null);

  // Setting logic to determine the min-height of the carousel based on the
  // image size within it; otherwise the modal collapses down to just the footer
  // height when clicking through slides. This is setting the initial height
  // and then checking if there is a re-size and adjusting accordingly
  useEffect(() => {
    setTimeout(() => {
      if (mainRef.current) {
        setCarouselHeight(mainRef.current.offsetHeight);
      }
    }, 1000);

    function handleResize() {
      if (imageRef.current) {
        setCarouselHeight(imageRef.current.offsetHeight);
      }
    }

    window.addEventListener('resize', handleResize)
  }, [mainRef, imageRef]);

  return (
    <>
      <SlideshowHeader
        onClose={onClose}
        episode={episode}
      />
      <Body ref={mainRef}>
        {isDesktop && (
          <StandardCarousel
            carouselHeight={carouselHeight}
            carouselIndex={index}
            imageRef={imageRef}
            onArrowClick={onArrowClick}
            slideDecks={slideDecks}
            totalImages={slideImages}
            onDotClick={(i) => setIndex(i)}
          />
        )}
        {!isDesktop && (
          <SwipeableCarousel
            carouselHeight={carouselHeight}
            imageRef={imageRef}
            slideDecks={slideDecks}
            totalImages={slideImages}
          />
        )}
      </Body>
      <SlideshowFooter
        onClose={onClose}
        page={page}
        episode={episode}
        fullDeck={fullDeck}
        isDesktop={isDesktop}
        onArrowClick={onArrowClick}
        slideDecks={slideDecks}
        slideDeckPdf={slideDeckPdf}
        slideDeckTitle={slideDeckTitle}
        onDelete={onDelete}
      />
    </>
  );
}

export default Slideshow;
