import PropTypes from 'prop-types';
import ctaButtonShape from 'shapes/ctaButtonShape';
import headerTypeShape from 'shapes/headerTypeShape';
import imageAssetShape from '../imageAssetShape'

const { arrayOf, bool, shape, string } = PropTypes;

export default shape({
  body: string,
  buttonCta: arrayOf(ctaButtonShape),
  featureVideo: shape({
    displayKpis: bool,
    videoDisplayOptions: arrayOf(string),
    videoSource: shape({
      bcVideoFile: string,
      videoLink: shape({
        href: string,
        title: string,
      }),
    }),
  }),
  header: string,
  selectHeaderType: headerTypeShape,
  selectSubheaderType: headerTypeShape,
  subheader: string,
  thumbnailImage: imageAssetShape
});
