import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import talentShape from 'shapes/talentShape';
import TalentCard from 'components/TalentCard';

TalentCluster.propTypes = {
  data: PropTypes.shape({
    talent: PropTypes.arrayOf(talentShape)
  })
};

function TalentCluster({ data }) {
  const { talent } = data;

  return (
    <Grid container spacing={2}>
      {talent.map((singleTalent, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={`talent-${index}`}>
          <TalentCard key={`talent-${index}`} singleTalent={singleTalent} />
        </Grid>
      ))}
    </Grid>
  );
}

export default React.memo(TalentCluster);
