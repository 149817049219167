import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { switchProp, theme } from 'styled-tools';

const ExpireFlag = styled.div`
  background-color: ${theme('palette.common.white')};
  bottom: 10px;
  color: ${theme('palette.common.black')};
  font-size: 0.65rem;
  line-height: normal;
  padding: 0.25rem 0.5rem;
  position: absolute;
  right: 10px;
  text-transform: uppercase;
  z-index: 10;
  border-radius: 2px;

  ${switchProp('display', {
    vertical: css`
      font-size: 0.75rem;
    `,
    horizontal: css`
      font-size: 0.75rem;

      ${props => props.theme.breakpoints.up('md')} {
        bottom: 6px;
        font-size: 10px;
        padding: 0.25rem 0.25rem 0.125rem;
        right: 6px;
      }
    `
  })}
`;

InfoBoxFlag.propTypes = {
  display: PropTypes.string,
  expirationDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  slideCount: PropTypes.number,
};

function InfoBoxFlag({ display, expirationDate, slideCount }) {
  return (
    <>
      {expirationDate &&
        <ExpireFlag display={display}>
          Expires in {expirationDate}
        </ExpireFlag>
      }
      {slideCount &&
        <ExpireFlag display={display}>
          {slideCount} Slides
        </ExpireFlag>
      }
    </>
  );
}

export default React.memo(InfoBoxFlag);
