import React from 'react';
import BaseButton from '@material-ui/core/Button';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';

// Extending the material-ui Button component
// to allow for additional styling as needed.
// Note that the majority of the custom styles to
// the buttons should be managed through MUI overrides
// in the src/theme.js file.
// Styling for custom props or edge-cases can be done here.
const Button = styled(BaseButton)`
  
`;

export default Button;

