import React from 'react';
import PropTypes from 'prop-types';
import { switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import BaseFormControl from '@material-ui/core/FormControl';

const FormControl = styled(BaseFormControl)`
  margin-bottom: 0.875rem;
  ${switchProp('width', {
    full: css`
      width: 100%;
    `,
    half: css`
      width: 50%;
    `,
    third: css`
      width: 33.33%;
    `,
  })}
`;

const Label = styled(InputLabel)`
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 700;
  margin-bottom: 0.875rem;
  position: relative;
  text-transform: uppercase;
  transform: initial;
`;

const TextAreaInput = styled(TextField)`
  textarea {
    border: 0.0625rem solid ${theme('colors.inputGray')};
    padding: 0.25rem;
  }

  .MuiInput-underline {
    padding: 0;

    &:before {
      border: 0;
    }

    &:after {
      position: relative;
    }
  }
`;

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  type: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  error: PropTypes.bool,
}

function TextArea({
  defaultValue,
  label,
  multiline,
  id,
  onChange,
  rows,
  type,
  variant,
  value,
  width,
  error,
  ...props
}) {
  return (
    <FormControl width={width} error={error}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <TextAreaInput
        defaultValue={defaultValue}
        id={id}
        multiline={multiline}
        onChange={onChange}
        rows={rows}
        type={type}
        variant={variant}
        error={error}
        value={value}
        {...props}
      />
    </FormControl>
  );
}

export default React.memo(TextArea);
