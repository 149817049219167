import React from 'react';
import PropTypes from 'prop-types';
import episodeShape from 'shapes/episodeShape';
import EpisodeCardWrapper from 'components/EpisodeCard/EpisodeCardWrapper';
import EpisodeDisplayOptions from 'constants/EpisodeDisplayOptions';
import playlistShape from 'shapes/playlistShape';
import dailyReplayShape from 'shapes/dailyReplayShape';
import scheduleItemShape from 'shapes/scheduleItemShape';
import { formatStringForTestId } from '__testUtils__/functions';
import ListItemCard from './ListItemCard';
import VerticalCard from './VerticalCard';
import HorizontalCard from './HorizontalCard';

const displayComponents = {
  [EpisodeDisplayOptions.vertical]: VerticalCard,
  [EpisodeDisplayOptions.horizontal]: HorizontalCard,
  [EpisodeDisplayOptions.listItem]: ListItemCard,
};

EpisodeCard.propTypes = {
  display: PropTypes.oneOf([
    EpisodeDisplayOptions.horizontal,
    EpisodeDisplayOptions.vertical,
    EpisodeDisplayOptions.listItem
  ]),
  episode: episodeShape,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  episodeBlurb: PropTypes.bool,
  onClick: PropTypes.func,
  singleShowPage: PropTypes.bool,
  edit: PropTypes.func,
  autoWidth: PropTypes.bool,
  excludedActions: PropTypes.array,
  watchLaterList: PropTypes.bool,
  recommendation: PropTypes.string,
  playlist: playlistShape,
  playlistIndex: PropTypes.number,
  dailyReplay: dailyReplayShape,
  scheduleItem: scheduleItemShape,
  expand: PropTypes.bool,
  rollDown: PropTypes.bool,
  draggable: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  onUnfollowOverride: PropTypes.func,
  size: PropTypes.string,
  homepage: PropTypes.bool,
};

function EpisodeCard({
  display,
  episode,
  onDelete,
  allowDelete = false,
  loading = false,
  episodeBlurb = true,
  onClick,
  size,
  singleShowPage = false,
  autoWidth = false,
  excludedActions,
  watchLaterList,
  recommendation = '',
  playlist,
  playlistIndex,
  dailyReplay,
  scheduleItem,
  expand = true,
  rollDown = false,
  checked = false,
  onCheck,
  draggable = false,
  onUnfollowOverride,
  homepage = false,
  ...props
}) {
  const CardComponent = displayComponents[display] || VerticalCard;
  let percentWatched = 0;
  const { episodeDuration, playbackPosition } = episode?.userEpisodeDetails ?? {};

  if (episodeDuration && playbackPosition) {
    percentWatched = playbackPosition / episodeDuration * 100;
  }

  function handleClick() {
    onCheck && onCheck();
  }

  return (
    <EpisodeCardWrapper
      $size={size}
      onClick={handleClick}
      data-testid={episode?.displayTitle ? formatStringForTestId(episode?.displayTitle) : null}
    >
      <CardComponent
        display={display}
        episode={episode}
        loading={loading}
        allowDelete={allowDelete}
        onDelete={onDelete}
        episodeBlurb={episodeBlurb}
        singleShowPage={singleShowPage}
        autoWidth={autoWidth}
        onClick={onClick && (event => onClick(event, episode))}
        excludedActions={excludedActions}
        recommendation={recommendation}
        watchLaterList={watchLaterList}
        playlist={playlist}
        playlistIndex={playlistIndex}
        dailyReplay={dailyReplay}
        scheduleItem={scheduleItem}
        expand={expand}
        rollDown={rollDown}
        percentWatched={percentWatched}
        draggable={draggable}
        checked={checked}
        onCheck={onCheck ? onCheck : () => {}}
        onUnfollowOverride={onUnfollowOverride}
        homepage={homepage}
        {...props}
      />
    </EpisodeCardWrapper>
  );
}

export default React.memo(EpisodeCard);
