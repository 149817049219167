import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePictureInPictureContext } from 'context/PictureInPictureContext';

PipEnabledPlayer.propTypes = {
  props: PropTypes.shape({
    videoId: PropTypes.string.isRequired,
    live: PropTypes.string.isRequired,
    clearExistingPip: PropTypes.func,
    playbackPosition: PropTypes.number,
    episodeId: PropTypes.string
  })
};

function PipEnabledPlayer(props) {
  const { addVideo, removeVideo, updateVideo, setReturnPipId, setPipId, state: { pipReturnId, pipVideoId } } = usePictureInPictureContext();
  const firstRun = useRef(true);

  const mountId = props.videoId ? props.videoId : (props.live ? 'live-player' : 'default-player');

  // Handle case when we want to clear existing PIP when this video is loaded.
  useEffect(() => {
    if (props.clearExistingPip && pipVideoId && pipVideoId !== mountId) {
      setPipId(null, null);
      document.exitPictureInPicture()
        .then(() => {
          // removeVideo call must come after setPipId since we need to clear the pip video first.
          removeVideo(pipVideoId);
        })
        .catch(err => console.error(err));
    }
  }, [mountId, pipVideoId, props.clearExistingPip]);

  useEffect(() => {
    if (!firstRun.current) {
      updateVideo(mountId, props);
    }
  }, [props]);

  useEffect(() => {
    // Handle case when returning from PIP to this page, to restore the video.
    if (mountId === pipReturnId && typeof window !== 'undefined') {
      // Clear these after this render.
      setTimeout(() => {
        setReturnPipId(null);
        setPipId(null, null);
      }, 0);
    } else {
      // Add the video to render in the portal.
      addVideo(mountId, props);
    }

    firstRun.current = false;

    return () => {
      // Remove the video from portal on unmount.
      removeVideo(mountId);
      firstRun.current = true;
    };
  }, [mountId]);


  return (
    <div id={mountId} />
  );
}

export default React.memo(PipEnabledPlayer);
