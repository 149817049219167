import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth } from 'context/AuthContext';
import get from 'lodash.get';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import TagManager from 'react-gtm-module';
import { dataLayerPush, gtag } from 'utils/dataLayer';
import config from 'config';

const HUBSPOT_FINGERPRINT_COOKIE = 'tasty_hsfp';

/**
 * C0001 - Strictly Necessary
 * C0002 - Performance Cookies
 * C0003 - Functional Cookies
 * C0004 - Targeting Cookies
 * C0005 - Social Media Cookies
 */
const ONETRUST_CATEGORIES = {
  strictlyNecessary: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  socialMedia: 'C0005',
};

function getConsentState(category, groups) {
  return groups.includes(category) ? 'granted' : 'denied';
}

AnalyticsListener.propTypes = {
  children: PropTypes.node,
};

function AnalyticsListener({ children }) {
  const history = useHistory();
  const { user } = useAuth();
  const { marketing } = useGetUserDataSettings();
  const [cookies, setCookie] = useCookies([HUBSPOT_FINGERPRINT_COOKIE]);

  // initial page load hook
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = window.gtag || gtag;

      const checkConsentAndInitGTM = () => {
        const consentGroups = window.OnetrustActiveGroups || []; 
        const isConsentGranted = consentGroups.includes(ONETRUST_CATEGORIES.targeting) || consentGroups.includes(ONETRUST_CATEGORIES.performance);
        
        if (isConsentGranted) {
          initGTM();
        }
      };
      checkConsentAndInitGTM();

      window.addEventListener('OneTrustGroupsUpdated', event => {
        gtag('consent', 'default', {
          ad_storage: getConsentState(ONETRUST_CATEGORIES.targeting, event.detail),
          ad_user_data: getConsentState(ONETRUST_CATEGORIES.performance, event.detail),
          ad_personalization: getConsentState(ONETRUST_CATEGORIES.targeting, event.detail),
          analytics_storage: getConsentState(ONETRUST_CATEGORIES.performance, event.detail),
          functionality_storage: getConsentState(ONETRUST_CATEGORIES.functional, event.detail),
          personalization_storage: getConsentState(ONETRUST_CATEGORIES.strictlyNecessary, event.detail),
          security_storage: getConsentState(ONETRUST_CATEGORIES.strictlyNecessary, event.detail),
          'wait_for_update': 500,
        });

        initGTM();
      });
    }
  }, [])

  // when user data loads or changes, adjust tracking based on cookie consent
  useEffect(() => {
    initHubSpotListener();
  }, [user, marketing, typeof window !== 'undefined'])

  useEffect(() => {
    function sendPageView(location) {
      if (typeof window !== 'undefined') {
        let _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
      }
    }

    return history.listen(sendPageView);
  }, [history]);

  function initHubSpotListener() {
    if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
      let _hsq = window._hsq = window._hsq || [];

      if (marketing?.acceptedAt) {
        // call again w/ {track: true} removes the do not track cookie
        _hsq.push(['doNotTrack', {track: true}]);

        _hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {
          // Add the fingerprint to a cookie so it could be referenced when redirecting
          // to cross-domains (Auth0, Thinkific) from the server side.
          setCookie(HUBSPOT_FINGERPRINT_COOKIE, hsfp, { path: '/' });
        }]);

        _hsq.push(['identify', {
          email: get(user, 'email'),
          auth0_user_id: get(user, 'sub'),
        }]);

        dataLayerPush(user.sub, 'user_id');
        dataLayerPush(user.sub, 'userId');
        dataLayerPush(user.sub, 'tasty-user-id');

        // Identify the user with GA as well.
        if (user) {
          gtag('set', {'user_id': user.sub});
          gtag('set', {'tasty-user-id': user.sub});

          // Support universal tag too if that's available.
          if (typeof window.ga !== "function") {
            (function(e, t, i, r, n, a, s) {
                e.GoogleAnalyticsObject = n;
                e[n] = e[n] || function() {
                  (e[n].q = e[n].q || []).push(arguments)
                },
                  e[n].l = 1 * new Date;
                a = t.createElement(i),
                  s = t.getElementsByTagName(i)[0];
                a.async = 1;
                a.src = r;
                s.parentNode.insertBefore(a, s)
              }
            )(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            window.ga('create', config.analytics.gaTrackerId, 'auto');
          }
          window.ga('set', 'userId', user.sub); // Set the user ID using signed-in user_id.
        }
      } else {
        // prevent hubspot from sending any information for the user
        _hsq.push(['doNotTrack']);
      }
    }
  }

  function initGTM() {
    if (!config.analytics.gtmContainerId || window.tastyGTMInitalized) {
      return;
    }

    // declare config
    const tagManagerArgs = {
      gtmId: config.analytics.gtmContainerId,
    }

    // trigger gtm init
    TagManager.initialize(tagManagerArgs);
    window.tastyGTMInitalized = true;
  }

  return children;
}

export default AnalyticsListener;
