import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { GridList } from 'components/GridListSlider';
import Container from '@material-ui/core/Container';

const BlockContainer = styled(Container).attrs({ maxWidth: 'xl' })`
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${ifProp({page: 'homepage'}, css`
    max-width: none;
    margin-bottom: -1.25rem;
    margin-top: -1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &.modular-block-recommendedShows {
      padding-bottom: 4rem;
    }

    ${GridList} {
      padding-left: 0;
      padding-right: 0;
    }
  `)}

  ${ifProp('$carouselGutters', css`
    ${props => props.theme.breakpoints.up('md')} {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  `)}

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
`;

export default BlockContainer;
