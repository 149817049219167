
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RecommendedShowsBlockFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RecommendedShowsBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"headerType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shows"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentTypeUid"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":211}};
    doc.loc.source = {"body":"#import \"../BlockStyleFields.graphql\"\n\nfragment RecommendedShowsBlockFields on RecommendedShowsBlock {\n  header\n  headerType\n  shows {\n    uid\n    contentTypeUid\n  }\n  blockStyle {\n    ...BlockStyleFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../BlockStyleFields.graphql").definitions));


      module.exports = doc;
    
