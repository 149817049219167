import React from 'react';
import styled from 'styled-components';
import recommendedShowsBlockShape from 'shapes/components/recommendedShowsBlockShape';
import MuiGridList from '@material-ui/core/GridList';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';

const GridListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: hidden;
`;

export const GridList = styled(MuiGridList)`
  width: 100vw;
  flex-wrap: nowrap;
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: translateZ(0);

  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  // Overriding inline styles from material-ui; had to use !important
  .MuiGridListTile-root {
    height: 100% !important;
    width: ${prop('$tileWidth')} !important;
  }
`;

GridListSlider.propTypes = {
  children: recommendedShowsBlockShape.isRequired,
  tileWidth: PropTypes.string
};

function GridListSlider({ children, spacing = 13, tileWidth = '44%' }) {

  return (
    <GridListWrapper>
      <GridList cols={1.5} cellHeight={116} spacing={spacing} component='div' $tileWidth={tileWidth}>
        {children}
      </GridList>
    </GridListWrapper>
  );
}

export default GridListSlider;
