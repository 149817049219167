import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function BitcoinIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_500" data-name="Group 500" transform="translate(-9.199)">
          <path id="Path_276" data-name="Path 276" d="M48.464-47.771a4.378,4.378,0,0,0-2.411-.53H44.7v4.442h1.912c1.765,0,2.647-.677,2.647-2.089a2.006,2.006,0,0,0-.794-1.823Z" transform="translate(-25.059 60.8)" fill="#e31b23"/>
          <path id="Path_277" data-name="Path 277" d="M27.692-81.6A9.314,9.314,0,0,0,18.4-72.308a9.315,9.315,0,0,0,9.294,9.294,9.315,9.315,0,0,0,9.294-9.294A9.315,9.315,0,0,0,27.692-81.6Zm1.471,15.618v1.559H27.869V-65.9H27.1v1.529H25.811V-65.9h-3v-1.088l1.559-.353V-77.19l-1.559-.353v-1l3.352-.088h.06v-1.676h1.294v1.676h.8v-1.676H29.6v1.795a3.6,3.6,0,0,1,1.529.647,2.79,2.79,0,0,1,1.088,2.353,2.838,2.838,0,0,1-2.353,2.971,3.908,3.908,0,0,1,2.117,1.088,2.648,2.648,0,0,1,.677,1.882c-.059,2.117-1.205,3.294-3.5,3.588Z" transform="translate(-6.493 84.307)" fill="#e31b23"/>
          <path id="Path_278" data-name="Path 278" d="M47.913-62.99a1.744,1.744,0,0,0,.705-1.471,1.8,1.8,0,0,0-.588-1.471A2.315,2.315,0,0,0,46.5-66.4h-2v4h1.353a3.5,3.5,0,0,0,2.059-.588Z" transform="translate(-24.919 73.578)" fill="#e31b23"/>
          <path id="Path_279" data-name="Path 279" d="M21.2-90.8a12.013,12.013,0,0,0-12,12,12.013,12.013,0,0,0,12,12,12.013,12.013,0,0,0,12-12A12.013,12.013,0,0,0,21.2-90.8Zm0,22.411A10.43,10.43,0,0,1,10.788-78.8,10.43,10.43,0,0,1,21.2-89.212,10.43,10.43,0,0,1,31.61-78.8,10.43,10.43,0,0,1,21.2-68.39Z" transform="translate(0 90.801)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default BitcoinIcon;
