import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import episodeShape from 'shapes/episodeShape';
import Skeleton from '@material-ui/lab/Skeleton';
import ImageDetails from '../components/ImageDetails';
import EpisodeDetails from './EpisodeDetails';
import linkResolver from 'utils/linkResolver';
import { getEpisodeExpirationDays } from 'utils/episodeHelpers';
import NewContentFlag from 'components/NewContentFlag';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useElementSize from 'hooks/useElementSize';
import AbsoluteOverlay from '../../AbsoluteOverlay';
import CheckboxFloating from '../../CheckboxFloating';
import VerticalCardWrapper from './VerticalCardWrapper';

const SkeletonWrapper = styled.div`
  // Using min-width to allow for a grid of 5 cards across in large sizes.
  min-width: 13rem;

  ${ifProp(
    '$autoWidth',
    css`
      min-width: auto;
    `
  )}

  ${ifProp(
    '$singleShowPage',
    css`
      ${(props) => props.theme.breakpoints.down('sm')} {
        min-width: none;
      }
    `
  )}
`;

const VerticalCardLayout = styled.div`
  // Use dynamic min-height here to accommodate for absolutely positioned cards, preventing overlap.
  min-height: ${props => `${props.$height + 16}px`};
`;

const ImageContainer = styled.div`
  width: 100%;
`;

VerticalCard.propTypes = {
  display: PropTypes.string,
  episode: episodeShape,
  episodeBlurb: PropTypes.bool,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  showDescription: PropTypes.bool,
  onClick: PropTypes.func,
  singleShowPage: PropTypes.bool,
  autoWidth: PropTypes.bool,
  edit: PropTypes.bool,
  excludedActions: PropTypes.array,
  isNewContent: PropTypes.bool,
  homepageSlider: PropTypes.bool,
  recommendation: PropTypes.string,
  expand: PropTypes.bool,
  rollDown: PropTypes.bool,
  percentWatched: PropTypes.number,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  onUnfollowOverride: PropTypes.func,
  homepage: PropTypes.func,
};

function VerticalCard({
  display,
  episode,
  onDelete,
  allowDelete = false,
  loading = false,
  episodeBlurb = true,
  onClick,
  singleShowPage = false,
  autoWidth = false,
  edit,
  excludedActions,
  isNewContent = false,
  homepageSlider = false,
  recommendation = '',
  expand = true,
  rollDown = false,
  percentWatched = 0,
  checked = false,
  onCheck,
  onUnfollowOverride,
  homepage,
  ...props
}) {
  if (loading) {
    return (
      <SkeletonWrapper $autoWidth={autoWidth} $singleShowPage={singleShowPage}>
        <Skeleton variant='rect' height={125} />
        <Skeleton height={12} width='30%' />
        <Skeleton height={13} width='60%' />
        <Skeleton height={14} />
        <Skeleton height={14} />
        <Skeleton height={14} />
      </SkeletonWrapper>
    );
  }

  if (!episode) {
    return null;
  }

  const {
    description,
    publishedAt,
    displayTitle,
    shortDescription,
    show,
  } = episode;

  const episodeUrl = linkResolver({ ...episode, contentTypeUid: 'episode' });
  const expirationDate = getEpisodeExpirationDays(episode);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const willExpand = edit ? false : isDesktop ? expand : false;
  const imageRef = useRef(null);
  const [cardHeight, setCardHeight] = useState({ imageHeight: 0, detailsHeight: 0 });
  const totalCardHeight = cardHeight.detailsHeight + cardHeight.imageHeight;
  const { height: imageHeight } = useElementSize(imageRef)

  useEffect(() => {
    setCardHeight(prevHeight => ({
      ...prevHeight,
      imageHeight
    }))
  }, [imageHeight]);

  return (
    <VerticalCardLayout $height={totalCardHeight}>
      <VerticalCardWrapper
        $autoWidth={autoWidth}
        $homepageSlider={homepageSlider}
        $singleShowPage={singleShowPage}
        $expand={willExpand}
        $rollDown={rollDown}
        $descriptionPresent={!!episodeBlurb && !!shortDescription && !edit}
        $page={props?.page}
      >
        <ImageContainer ref={imageRef}>
          <ImageDetails
            expirationDate={expirationDate}
            onClick={onClick}
            episode={episode}
            recommendation={recommendation}
            expand={willExpand}
            rollDown={rollDown}
            percentWatched={percentWatched}
            edit={edit}
            checked={checked}
            onCheck={onCheck}
          />
        </ImageContainer>
        {isNewContent && (
          <NewContentFlag height={1.625} text='New' type='short' width={2.5} />
        )}
        <EpisodeDetails
          description={description}
          display={display}
          displayTitle={displayTitle}
          publishedAt={publishedAt}
          shortDescription={shortDescription}
          show={show}
          episode={episode}
          allowDelete={allowDelete}
          onDelete={onDelete}
          episodeUrl={episodeUrl}
          episodeBlurb={episodeBlurb}
          onClick={onClick}
          singleShowPage={singleShowPage}
          recommendation={recommendation}
          edit={edit}
          excludedActions={excludedActions}
          expand={willExpand}
          rollDown={rollDown}
          onUnfollowOverride={onUnfollowOverride}
          homepage={homepage}
          setCardHeight={setCardHeight}
        />
      </VerticalCardWrapper>
      {edit && (
        <>
          <CheckboxFloating checked={checked} onCheck={onCheck} />
          <AbsoluteOverlay zIndex={10} />
        </>
      )}
    </VerticalCardLayout>
  );
}

export default VerticalCard;
