import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DOMPurify from 'isomorphic-dompurify';
import { Field, ErrorMessage } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { ifProp, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Text = styled(Typography)`
  ${ifProp({ size: 'small'}, css`
    font-size: 0.75rem;
  `)}

  ${switchProp('type', {
    header: css`
        font-weight: 700;
      `,
    subheader: css`
        font-size: 0.8125rem;
        font-weight: 500;
      `
  })}
`;

DataUsageItemField.propTypes = {
  touched: PropTypes.any,
  dataUsageDefinition: PropTypes.any,
  errors: PropTypes.any,
};

function DataUsageItemField({ errors, dataUsageDefinition, touched }) {
  return (
    <FormControl
      component='fieldset'
      margin='normal'
      fullWidth
      error={touched.dataUsageItems?.[dataUsageDefinition.id] &&
      errors.dataUsageItems?.[dataUsageDefinition.id]}
    >
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={`dataUsageItems.${dataUsageDefinition.id}`}
        Label={{ label: <Text component='span' type='subheader'>{dataUsageDefinition.dataDescription}</Text> }}
      />
      <Box pl={4}>
        {dataUsageDefinition.dataPurpose && (
          <Text
            size='small'
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataUsageDefinition.dataPurpose) }}
          />
        )}
        <ErrorMessage
          name={`dataUsageItems.${dataUsageDefinition.id}`}
          component={FormHelperText}
        />
      </Box>
    </FormControl>
  );
}

export default DataUsageItemField;
