export const COLUMN_SPAN_OPTS = {
  fullWidth: 'full width',
  halfWidth: 'half width',
  twoThirds: '2/3 width',
  oneThird: '1/3 width',
  oneFourth: '1/4 width',
  threeFourth: '3/4 width',
  oneFifth: '1/5 width',
  twoFifths: '2/5 width',
  threeFifths: '3/5 width',
  fourFifths: '4/5 width',
}

export default {
  [COLUMN_SPAN_OPTS.fullWidth]: 12,
  [COLUMN_SPAN_OPTS.halfWidth]: 6,
  [COLUMN_SPAN_OPTS.twoThirds]: 8,
  [COLUMN_SPAN_OPTS.oneThird]: 4,
  [COLUMN_SPAN_OPTS.oneFourth]: 3,
  [COLUMN_SPAN_OPTS.threeFourth]: 9,
  [COLUMN_SPAN_OPTS.oneFifth]: 2,
  [COLUMN_SPAN_OPTS.twoFifths]: 4,
  [COLUMN_SPAN_OPTS.threeFifths]: 6,
  [COLUMN_SPAN_OPTS.fourFifths]: 8,
};
