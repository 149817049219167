import React  from 'react';
import PropTypes from 'prop-types';
import placeholderImg from 'assets/images/img-placeholder.png';
import SimpleDialog from 'components/SimpleDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FollowShowActionIcon from 'components/ActionIcons/FollowShow';
import { autoOptimizeImage } from 'utils/functions';
import { dataLayerPush } from 'utils/dataLayer';
import showShape from 'shapes/showShape';

MobileModal.propTypes = {
  history: PropTypes.object,
  shortDescription: PropTypes.string,
  showDetailsModal: PropTypes.bool,
  setShowDetailsModal: PropTypes.func,
  showUrl: PropTypes.string,
  show: showShape,
  teaser: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
};

function MobileModal({
  history,
  shortDescription,
  showDetailsModal,
  setShowDetailsModal,
  showUrl,
  show,
  teaser,
  title,
  user
}) {

  const handleAction = (actionName, event) => {
    const action = mobileActionItems.find(v => v.value === actionName);

    if (action?.callback) {
      action?.callback(event);
    }
    setShowDetailsModal(false);
    dataLayerPush('Mobile show card modal closed');
  }

  const mobileActionItems = [
    {
      value: 0,
      label: <strong>Explore</strong>,
      icon: <ArrowForwardIcon style={{ marginLeft: '-0.25rem' }} />, // Negative margin-left to left-align play arrow icon with others.
      callback: () => {
        history.push(showUrl);
        dataLayerPush(`'Explore' show link clicked`);
      }
    },
    {
      icon: <FollowShowActionIcon show={show} postLabel={true}  />,
      hide: !user
    }
  ];

  return (
    <SimpleDialog
      onClose={handleAction}
      open={showDetailsModal}
      options={mobileActionItems}
      headerImage={autoOptimizeImage(show.image?.url || placeholderImg)}
    >
      <DialogContent>
        <DialogContentText color='textPrimary'>
          <h3 style={{marginBottom: '0.5rem'}}>{title}</h3>
          {
            teaser ? <>{teaser}</> :
            shortDescription ? <div dangerouslySetInnerHTML={{ __html: shortDescription }} /> :
            <>{''}</>
          }
        </DialogContentText>
      </DialogContent>
    </SimpleDialog>
  );
}

export default MobileModal;
