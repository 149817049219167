import React  from 'react';
import { useQuery } from '@apollo/client';
import followedShowsQuery from '../../../graphql/queries/followedShows.graphql';
import {useAuth} from "../../AuthContext";

export function useGetFollowedShows() {
  const { user } = useAuth();
  const { data, loading, error, refetch } = useQuery(followedShowsQuery, {
    fetchPolicy: 'no-cache',
    skip: !user || typeof window === 'undefined',
  });

  return {data, loading, error, refetch}
}
