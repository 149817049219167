import React, { useEffect, useState } from 'react';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import showShape from 'shapes/showShape';
import { dataLayerPush } from 'utils/dataLayer';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import { useDashboardContext } from 'context/DashboardContext';
import updateHasFollowedShowsMutation from './updateHasFollowedShows.graphql';
import { ActionItemContainer, LabelWrapper } from 'components/ActionIcons';
import { ttSnackbarOptions } from 'components/TTSnackbar';
import TTSnackbarMessage from 'components/TTSnackbar/Message';
import { useFollowedShowsContext } from 'context/Dashboard/FollowedShows';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-weight: bold;
  font-size: 1.15rem;
`;

FollowShowActionIcon.propTypes = {
  show: showShape.isRequired,
  showToolTip: PropTypes.bool,
  preLabel: PropTypes.bool,
  postLabel: PropTypes.bool,
  labelOverride: PropTypes.string,
  fontSize: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
  toggleFollowOverride: PropTypes.bool,
  onUnfollowOverride: PropTypes.func,
};

function FollowShowActionIcon({
  show,
  showToolTip = false,
  fontSize = 'default',
  preLabel,
  postLabel,
  labelOverride,
  toggleFollowOverride,
  onUnfollowOverride,
}) {
  const { preferences } = useGetUserDataSettings();
  const { state: dashboardState, updateDashboardLogs, setFirstTimeHelper, setSubsequentHelper, setHelperContentType } = useDashboardContext();
  const { showFirstTimeHelper } = dashboardState;
  const { hasFollowedShows } = dashboardState?.logs;
  const [ updateHasFollowedShows ] = useMutation(updateHasFollowedShowsMutation)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackId, setSnackId] = useState();
  const followedShowsContext = useFollowedShowsContext();
  const [displayToolTip, setDisplayToolTip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { followedShows, followShow, unfollowShow } = followedShowsContext;

  const isFollowing = followedShows?.items?.find(v => v.uid === show.uid) || false;

  useEffect(() => {
    if(toggleFollowOverride) {
      handleFollowToggle();
    }
  }, [toggleFollowOverride])

  useEffect(() => {
    if (showFirstTimeHelper) {
      closeSnackbar(snackId);
    }
  }, [showFirstTimeHelper]);

  function handleFollowToggle() {
    if (!isFollowing) {
      follow();
    } else {
      unfollow();
    }
  }

  function setFollowMessage(show, error) {
    if (error) {
      return (
        <div>
          <StyledSpan>Oops! You have reached the maximum shows you can follow at one time.</StyledSpan><br/>
          <>For optimal performance and personalization, you can follow a maximum of 45 shows.</>
          <br/>
          <>Simply unfollow a show from your current list to follow this show.</>
        </div>
      );
    }

    return `"${show.title}" Followed`;
  }

  async function follow() {
    const error = await followShow(show);

    if (!hasFollowedShows) {
      await updateHasFollowedShows({ variables: { hasFollowedShows: true }});
      updateDashboardLogs();
      setFirstTimeHelper(true);
      setHelperContentType('show');
    } else {
      setSubsequentHelper(true);
      setSnackId(enqueueSnackbar(<TTSnackbarMessage message={setFollowMessage(show, error)}/>, ttSnackbarOptions));
    }
  }

  async function unfollow() {
    setFirstTimeHelper(false);
    await unfollowShow(show);

    if (onUnfollowOverride) {
      onUnfollowOverride();
    } else {
      enqueueSnackbar(<TTSnackbarMessage message={`No longer following "${show.title}"`} />, ttSnackbarOptions);
    }
  }

  const ActionIcon = () => (
    isFollowing
      ? <RemoveCircleOutlineIcon fontSize={fontSize} />
      : <AddCircleOutlineIcon fontSize={fontSize} />
  )

  const toolTipText = isFollowing
    ? 'Unfollow'
    : 'Follow'

  const labelText = isFollowing
    ? 'Unfollow this show'
    : 'Follow this show';

  const label = (
    <LabelWrapper>
      {labelOverride ?? labelText}
    </LabelWrapper>
  )

  if (!preferences?.acceptedAt) {
    return null;
  }

  async function handleFollowShowClick(){
    setDisplayToolTip(false);
    const action = toggleFollowOverride ?? handleFollowToggle();
    await dataLayerPush(`${action}`);
    setShowModal(!showModal);
  }

  return (
    <>
      <Tooltip title={showToolTip ? toolTipText : ''} open={displayToolTip}>
        <div onMouseEnter={() => setDisplayToolTip(true)} onMouseLeave={() => setDisplayToolTip(false)}>
          <ActionItemContainer onClick={handleFollowShowClick} data-testid={`${toolTipText}ShowButton`}>
            {preLabel && label}
            <ActionIcon />
            {postLabel && label}
          </ActionItemContainer>
        </div>
      </Tooltip>
    </>
  )
}

export default FollowShowActionIcon;
