import React from 'react';
import { prop, theme } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import YouTubeIcon from '@material-ui/icons/YouTube';
import RestoreIcon from '@material-ui/icons/Restore';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import ReplayIcon from '@material-ui/icons/Replay';
import AccordionGroupItem from 'components/AccordionGroup/AccordionGroupItem';
import MyShows from 'routes/Dashboard/components/MyShows';
import MySlides from 'routes/Dashboard/components/MySlides';
import Playlists from 'routes/Dashboard/components/Playlists';
import Episodes from 'routes/Dashboard/components/Episodes';
import MyShowsSidebar from 'routes/Dashboard/components/MyShowsSidebar';
import MySlidesSidebar from 'routes/Dashboard/components/MySlidesSidebar';
import PlaylistsSidebar from 'routes/Dashboard/components/PlaylistsSidebar';
import Icon from 'components/Icon';
import DailyReplays from 'routes/Dashboard/components/DailyReplays';
import DailyReplaysSidebar from 'routes/Dashboard/components/DailyReplaysSidebar';
import config from 'config';
import PropTypes from 'prop-types';

const SidebarContainer = styled.div`
  height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;
  background-color: ${prop('theme.colors.white')};
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('sm')} {
    border-right: 0.0625rem solid ${prop('theme.colors.mediumGray')};
  }

  .MuiFormControlLabel-root > .MuiCheckbox-root {
    padding-top: 0.05rem;
  }
`;

const SectionTitle = styled.div`
  align-items: center;
  color: ${prop('theme.colors.black')};
  display: flex;
  min-height: 2.5rem;
  padding: 0 1.25rem;

  p {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const HelpContainer = styled.div`
  margin-top: auto;
  margin-left: 1rem;
  padding-top: 1rem;

  a {
    text-decoration: none;
    color: ${theme('colors.black')};
    cursor: pointer;
    font-size: 0.8125rem;

    & :hover {
      color: ${theme('colors.ttRed')};
    }
  }
`;

Sidebar.propTypes = {
  expanded: PropTypes.bool,
  handleChange: PropTypes.func,
  activeComponent: PropTypes.bool,
};

function Sidebar({ expanded, handleChange, activeComponent }) {
  const itemProps = {
    expanded,
    handleChange,
    activeComponent,
    type: 'sidebar',
  };

  return (
    <SidebarContainer>
      <SectionTitle>
        <Typography>Followed</Typography>
      </SectionTitle>
      <AccordionGroupItem
        id={'MyShows'}
        // count={2} TODO: show count when new episodes arrive.
        title='My Shows'
        icon={<YouTubeIcon />}
        dash={MyShows}
        active={activeComponent === MyShows}
        {...itemProps}
      >
        <MyShowsSidebar />
      </AccordionGroupItem>

      <SectionTitle>
        <Typography>Saved</Typography>
      </SectionTitle>
      <AccordionGroupItem
        id={'MySlides'}
        title='My Slides'
        icon={<Icon icon='slidesOutline' />}
        dash={MySlides}
        active={activeComponent === MySlides}
        {...itemProps}
      >
        <MySlidesSidebar />
      </AccordionGroupItem>
      <AccordionGroupItem
        id={'Playlists'}
        title='Playlists'
        icon={<PlaylistPlayIcon />}
        dash={Playlists}
        active={activeComponent === Playlists}
        {...itemProps}
      >
        <PlaylistsSidebar />
      </AccordionGroupItem>
      <AccordionGroupItem
        id={'Episodes'}
        title='Watch History'
        icon={<RestoreIcon />}
        dash={Episodes}
        active={activeComponent === Episodes}
        {...itemProps}
      >
        {/* TODO: allow filtering history by shows */}
        {/*<CheckboxList values={shows} />*/}
      </AccordionGroupItem>
      <AccordionGroupItem
        id={'DailyReplays'}
        title='Daily Replays'
        icon={<ReplayIcon />}
        dash={DailyReplays}
        active={activeComponent === DailyReplays}
        {...itemProps}
      >
        <DailyReplaysSidebar />
      </AccordionGroupItem>
      <HelpContainer>
        <a href={config.freshDesk.articles.dashboard} target='blank'>
          Need Help?
        </a>
      </HelpContainer>
    </SidebarContainer>
  );
}

export default React.memo(Sidebar);
