import fetch from 'cross-fetch';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import config from 'config';

function offsetLimitPagination(keyArgs) {
  if (typeof keyArgs === 'undefined') {
    keyArgs = false;
  }

  return {
    // Cache separate results based on the episode filters.
    keyArgs,
    // Concatenate the incoming list items with
    // the existing list items.
    merge(existing, incoming, { args: { pagination: { skip = 0 } = {}}}) {
      // Slicing is necessary because the existing data is
      // immutable, and frozen in development.
      const merged = existing && existing.items ? existing.items.slice(0) : [];
      for (let i = 0; i < incoming.items.length; ++i) {
        merged[skip + i] = incoming.items[i];
      }

      return {
        ...incoming,
        items: merged,
      };
    },
  };
}

export function buildServerSideApolloClient(req) {
  const apolloHeaders = {
    cookie: req.header('Cookie'),
  };

  return createApolloClient(
    { ssrMode: true },
    apolloHeaders,
    undefined,
    config.gatewayApi.baseUrlSSR + '/graphql',
  );
}

export default function createApolloClient(options, headers, initialState, uriOverride) {
  // Allow defining separate api url for SSR vs. browser
  const uri = uriOverride || process.env.RAZZLE_GRAPHQL_API_PROXY;

  // Remember that this is the interface the SSR server will use to connect to the
  // API server, so we need to ensure it isn't firewalled, etc
  const httpLink = createHttpLink({
    uri,
    credentials: 'same-origin',
    headers,
    fetch,
  });

  // TO DO: Add 'useGETForHashedQueries: true' - currently user data is not being
  // returned properly when switching to GET requests; will revisit with the
  // elimination of proxying graphql requests through the client
  const persistedQueriesLink = createPersistedQueryLink({
    sha256,
  });

  const client = new ApolloClient({
    ...options,
    cache: new InMemoryCache({
      // See: https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
      typePolicies: {
        Show: {
          keyFields: ['uid'],
        },
        Episode: {
          keyFields: ['uid'],
        },
        Page: {
          keyFields: ['uid'],
        },
        UserWatchEvent: {
          keyFields: ['userId', 'eventTime'],
        },
        ResearchSlide: {
          keyFields: ['uid'],
        },
        Query: {
          fields: {
            episodes: offsetLimitPagination(['filters', 'sort']),
            followedShowsEpisodes: offsetLimitPagination(['filters', 'sort']),
            watchHistory: offsetLimitPagination(['filters', 'sort']),
            playlistEpisodes: offsetLimitPagination(['playlistId', 'sort']),
            blogPosts: offsetLimitPagination(['filters', 'sort']),
          },
        },
      },
    }).restore(initialState),
    link: process.env.NODE_ENV !== 'test' ? persistedQueriesLink.concat(httpLink) : httpLink,
  });

  return client;
}
