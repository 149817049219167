import React, { useCallback, useMemo, useState } from 'react'
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useDashboardContext } from 'context/DashboardContext';
import savedSlideDecksEpisodesQuery from './savedSlideDecksEpisodes.graphql';
import SortOptions from 'constants/SortOptions';
import MySlidesLoading from './Loading';
import MySlidesBody from './Body';
import DashboardAlert from '../DashboardAlert';
import DashboardPageHeader from '../DashboardPageHeader';
import usePagination from 'hooks/usePagination';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import OptInAlert from '../OptInAlert';
import { dataLayerPush } from 'utils/dataLayer';
import { useDashboardSlidesContext } from 'context/Dashboard/Slides';

const PER_PAGE_LIMIT = 24;

MySlides.propTypes = {
  edit: PropTypes.bool,
};

function MySlides({ edit }) {
  const { state: dashboardState } = useDashboardContext();
  const [slides, setSlides] = useState([]);
  const [totalItems, setTotalItems] = useState(slides.length);
  const { page, hasNextPage, nextPage, setPage } = usePagination(totalItems, PER_PAGE_LIMIT);
  const { preferences } = useGetUserDataSettings();
  const [previousSlides, setPreviousSlides] = useState([]);
  const { shows } = useDashboardSlidesContext();

  const filters = useMemo(() => {
    return { showUids: shows.selected };
  }, [shows]);

  const sort = useMemo(() => {
    return {
      sortBy: 'published_at',
      sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
    };
  }, [dashboardState.sortBy]);

  const { loading, error, refetch } = useQuery(savedSlideDecksEpisodesQuery, {
    variables: {
      filters,
      sort,
      pagination: {
        skip: 0,
        limit: PER_PAGE_LIMIT,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setPage(0);
      setSlides(data?.savedSlideDecksEpisodes?.items);
      setTotalItems(data?.savedSlideDecksEpisodes?.count);
    }
  });

  const fetchNextPage = useCallback(() => {
    refetch({
      filters,
      sort,
      pagination: {
        skip: (page + 1) * PER_PAGE_LIMIT,
        limit: PER_PAGE_LIMIT
      }
    }).then(({ data }) => {
      setSlides([...slides, ...get(data, 'savedSlideDecksEpisodes.items', [])]);
      dataLayerPush(`'My Slides' infinite scroll pagination loaded`);
      nextPage();
    });
  }, [dashboardState, page, slides]);

  // fetches more slides
  const fetchMoreItems = useCallback((removedSlides) => {
    setPreviousSlides(slides);
    const updateItemList = slides.filter(slide => !removedSlides.find(removedSlide => slide.pdfSlides[0].uid === removedSlide.pdfSlides[0].uid))

    return refetch({
      filters,
      sort,
      pagination: {
        skip: ((page + 1) * PER_PAGE_LIMIT),
        limit: removedSlides.length
      }
    }).then(({ data }) => {
      setTotalItems(data?.savedSlideDecksEpisodes?.count);
      setSlides([...updateItemList, ...data?.savedSlideDecksEpisodes?.items])
    });
  }, [dashboardState, page, slides, previousSlides]);

  if (!preferences?.acceptedAt) {
    return <OptInAlert loading={loading} accepted={false} />
  }

  const onUndoDeleteSlides = () => {
    setSlides(previousSlides)
  }

  return (
    <>
      {slides.length > 0 &&
        <DashboardPageHeader>My Slides</DashboardPageHeader>
      }
      {loading && <MySlidesLoading />}
      {error && (
        <DashboardAlert
          message='Failed to load your slides, please reload to try again.'
          severity='error'
        />
      )}
      {!loading && !error && (
        <MySlidesBody
          edit={edit}
          slidesList={slides}
          hasMore={hasNextPage}
          fetchNextPage={fetchNextPage}
          fetchMoreItems={fetchMoreItems}
          onUndoDeleteSlides={onUndoDeleteSlides}
        />
      )}
    </>
  );
}

export default React.memo(MySlides);
