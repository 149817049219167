import styled from 'styled-components';
import DropdownList from './DropdownList';

export default styled.div`
  height: inherit;
  position: relative;

  &:hover {
    ${DropdownList} {
      display: block;
      opacity: 1;
    }
  }
`;
