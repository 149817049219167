import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HeadphonesIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_496" data-name="Group 496" transform="translate(-19.063 2.521)">
          <path id="Path_269" data-name="Path 269" d="M47.355-39.23h2.061v-8.477H47.355Z" transform="translate(-17.319 58.058)" fill="#e31b23"/>
          <path id="Path_270" data-name="Path 270" d="M56.188-37.7h2.061v-6.359H56.188Z" transform="translate(-22.725 55.827)" fill="#e31b23"/>
          <path id="Path_271" data-name="Path 271" d="M38.5-37.7H40.56v-6.359H38.5Z" transform="translate(-11.898 55.827)" fill="#e31b23"/>
          <path id="Path_272" data-name="Path 272" d="M40.711-64.125v-.735A9.528,9.528,0,0,0,37.923-71.6a9.526,9.526,0,0,0-6.739-2.792h-.242A9.526,9.526,0,0,0,24.2-71.6a9.527,9.527,0,0,0-2.788,6.742v.735a2.821,2.821,0,0,0-2.351,2.748v3.111a2.828,2.828,0,0,0,2.82,2.829h3.847V-64.2h-2.2v-.694a7.417,7.417,0,0,1,2.17-5.243,7.416,7.416,0,0,1,5.241-2.176h.436a7.407,7.407,0,0,1,5.115,2.238,7.414,7.414,0,0,1,2.1,5.171v.7H36.4v8.768h3.847a2.828,2.828,0,0,0,2.82-2.829v-3.111a2.821,2.821,0,0,0-2.351-2.748Zm-17.1,2.045v4.526l-1.73,0a.705.705,0,0,1-.5-.208.71.71,0,0,1-.2-.5v-3.111a.7.7,0,0,1,.7-.7Zm17.333,3.814a.711.711,0,0,1-.2.5.705.705,0,0,1-.5.208h-1.73v-4.526h1.729l0,0a.7.7,0,0,1,.5.206.7.7,0,0,1,.206.5Z" transform="translate(0 74.395)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default React.memo(HeadphonesIcon);
