import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseModal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { convertRem } from 'utils/functions';
import Slide from '@material-ui/core/Slide';

const StyledModal = styled(BaseModal)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 1.25rem;
  width: 100%;
`;

const ModalBody = styled.div`
  background-color: ${prop('theme.colors.white')};
  border-radius: 0.625rem;
  max-width: ${prop('width')}rem;
  overflow-y: auto;
  position: relative;
  width: 100%;

  ${ifProp('$bgColor', css`
    background-color: ${prop('$bgColor')};
  `)};

  ${props => props.theme.breakpoints.down('md')} {
    max-height: 95vh;
  }

  ${switchProp('$padding', {
    none: css`
      padding: 0;
    `,
    thin: css`
      padding: 1.875rem;
    `,
    narrow: css`
      padding: 2.5rem 2.75rem;
    `,
    medium: css`
      padding: 2rem;

      ${props => props.theme.breakpoints.up('sm')} {
        padding: 2.875rem 3.375rem;
      }
    `,
    wide: css`
      padding: 3rem 3.75rem;
    `
  })}

  ${switchProp('$alignment', {
    left: css`
      text-align: left;
    `,
    center: css`
          text-align: center;
        `,
    right: css`
          text-align: right;
        `,
  },css `
    text-align: auto;
  `)}

  &:focus {
    outline: none;
  }
`;

const CloseContainer = styled.button`
  background-color: transparent;
  border: none;
  width: fit-content;

  &:focus {
    svg {
      color: ${prop('theme.colors.ttRed')};
    }
  }

  ${ifProp({ $contentType: 'content-cards' }, css`
    align-items: center;
    background-color: ${prop('theme.colors.white')};
    border-radius: 50%;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;

    &:hover {
      background-color: ${prop('theme.colors.lightGray')};
    }
  `)}
`;

const Close = styled(CloseIcon)`
  cursor: pointer;
  z-index: 5;

  ${props => props.theme.breakpoints.up('sm')} {
    right: 28px;
    top: 32px;

    ${ifProp({ $contentType: 'slides' }, css`
      right: 12px;
      top: 12px;
    `)}
  }

  ${switchProp('$contentType', {
    'slides': css`
      right: 12px;
      top: 12px;

      ${props => props.theme.breakpoints.down('sm')} {
        display: none;
      }
    `,
    'content-cards': css`
      position: inherit;

      && {
        right: auto;
        top: auto;
      }
    `
  })}

  &:hover {
    color: #e31b23;
  }
`;

const propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  ariaDescribe: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  contentType: PropTypes.string,
  height: PropTypes.number,
  icon: PropTypes.element,
  padding: PropTypes.oneOf(['none', 'thin', 'narrow', 'medium', 'wide']),
  triggerText: PropTypes.string,
  triggerType: PropTypes.string,
  width: PropTypes.number,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  bgColor: PropTypes.string,
  hideClose: PropTypes.bool
}

function Modal({
  contentType,
  open,
  onClose,
  ariaLabel,
  ariaDescribe,
  children,
  height = 600,
  padding = 'narrow',
  page,
  width = 800,
  alignment,
  bgColor,
  hideClose = false,
  ...props
}) {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescribe}
      {...props}
    >
      <Slide direction={'up'} in={open}>
        <ModalBody
          height={convertRem(height)}
          $padding={padding}
          width={convertRem(width)}
          $alignment={alignment}
          $bgColor={bgColor}
          data-testid='styledModalDisplayed'
        >
          {!hideClose &&
            <CloseContainer $contentType={contentType} onClick={onClose}>
              <Close $contentType={contentType} $page={page} />
            </CloseContainer>
          }
          {children}
        </ModalBody>
      </Slide>
    </StyledModal>
  );
}

Modal.propTypes = propTypes;

export default Modal;
