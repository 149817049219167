import React from 'react';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import placeholderImg from 'assets/images/img-placeholder.png';
import { dataLayerPush } from 'utils/dataLayer';

const IFRAME = 'iframe';
const IMG = 'img';
const pxThreshold = 30; // set for now because of tasty icon width

export function trackBlogFilters(tempBlogFilters) {
  if (tempBlogFilters?.contributorUids?.length > 0) {
    dataLayerPush('Filtered Blog List page by Author');
  } else if (tempBlogFilters?.blogCategoryUids?.length > 0) {
    dataLayerPush('Filtered Blog List page by Category');
  }
}

export function getOptimizedBlogImage(thumbnail, size, imageSizes) {
  const url = (thumbnail && thumbnail.url) || placeholderImg;

  if (imageSizes) {
    return `${url}?height=${imageSizes[size].height}&format=pjpg&auto=webp`;
  }

  return url;
}

// Check to see if the react component type is an iframe -- if an iframe is
// found wrap it in the responsive .video-wrapper class. Otherwise just return
// the original data unchanged. Also, check for images and update the src to
// optimize for next-gen image formats (pjpg/webp). Last, if not a media related
// child element, just return the original data unchanged.
export function checkForMedia(data) {
  if (data?.type === IFRAME) {
    return <div className='video-wrapper'>{data}</div>;
  } else if (data?.type === IMG) {
    const imgSrc = data?.props?.src;
    const imgWidth = data?.props?.width ? data?.props?.width : 1000;
    const imgHeight = data?.props?.height;
    const queryParams = `?format=pjpg&auto=webp&quality=50&width=${imgWidth}&disable=upscale`;
    const mobileQueryParams =
      '?format=pjpg&auto=webp&quality=50&width=480&disable=upscale';
    const responsiveSizes = '(max-width: 600px) 480px, 1000px';
    const optimizedImgSrc = imgSrc + queryParams;
    const optimizedMobileImgSrc = imgSrc + mobileQueryParams;
    const imgSrcSet = `${optimizedMobileImgSrc} 480w, ${optimizedImgSrc} 1000w`;

    const imgData = React.cloneElement(data, {
      src: imgSrc + queryParams,
      srcSet: imgSrcSet,
      sizes: responsiveSizes,
      loading: 'lazy'
    });

    return imgData
  } else {
    return data;
  }
}

// Recursively map through a tree of React elements; Run the elements through
// the helper function to check the data for whatever is needed.
export function recursiveMap(children, checkData) {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child?.props?.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, checkData),
      });
    }

    return checkData(child);
  });
}

// This logic is no longer actively being used; leaving in the codebase
// for now in case there is a use for it later
function pxCompare (stringWidth, operator, width) {
  if (!stringWidth) {
    return;
  }

  const stripValue = stringWidth?.replace(/[^0-9\.]+/g, '');
  const stringWidthNumber = stripValue && parseInt(stripValue);

  switch (operator) {
    case ">":
      return stringWidthNumber > width;
      break
    case ">=":
      return stringWidthNumber >= width;
      break
    case "<":
      return stringWidthNumber < width;
      break
    case "<=":
      return stringWidthNumber <= width;
      break
    case "=":
      return stringWidthNumber === width;
      break
    default:
      console.error(`Invalid operator ${operator}`);
      return;
  }
}
