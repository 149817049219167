import React from 'react';
import Button from 'components/Button';
import PropTypes from 'prop-types';

ButtonPrimary.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
}

function ButtonPrimary({ onClick, children }) {
  return (
    <Button
      type='submit'
      onClick={onClick}
      variant='contained'
      color='primary'
    >
      {children}
    </Button>
  )
}

export default React.memo(ButtonPrimary);
