import IconButton from '@material-ui/core/IconButton';
import { autoOptimizeImage } from 'utils/functions';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import MainNavLink from '../MainNavLink';

const Logo = styled(RouterLink)`
  display: flex;
  align-items: center;
  width: 7.0875rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 1.25rem;
  }

  @media (min-width: 1120px) {
    margin-right: 3rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const MainNavLinks = styled.div`
  align-self: stretch;
  display: flex;
  position: relative; // relative positioning required for mega menu placement.
  justify-content: initial !important;
  width: 100% !important;
`;

function MainHeaderNavBar({ logo, handleOpenSearch, menuItems, displayAlert }) {
  return (
    <>
      {logo?.url && (
        <div>
          <Logo to='/'>
            <img
              src={autoOptimizeImage(logo.url)}
              alt='tastylive logo'
              width={113}
              height={42}
            />
          </Logo>
        </div>
      )}
      {menuItems && (
        <MainNavLinks style={{ width: '566px', justifyContent: 'space-between' }}>
          {menuItems.map((menuItem, i) => (
            <MainNavLink
              displayAlert={displayAlert}
              menuItem={menuItem}
              key={i}
            />
          ))}
        </MainNavLinks>
      )}
      <IconButton aria-label='Search' color='inherit' onClick={handleOpenSearch}>
        <SearchIcon />
      </IconButton>
    </>
  )
}

export default MainHeaderNavBar;
