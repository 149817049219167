import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import VisibilityWrapper from 'components/VisibilityWrapper';

const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ifProp('$headerImage', css`
    background-image: url("${prop('$headerImage')}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 25rem;

    button {
      position: absolute;
      top: 0;
      right: 0.75rem;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      height: 300px;
    }

    ${props => props.theme.breakpoints.down('xs')} {
      height: 200px;
    }
  `)}

  // If close button is absolutely positioned, add extra padding on right so title does not conflict.
  ${ifProp('$absoluteClose', css`
    padding-right: 3rem;
  `)}

  ${ifProp('$disablePadding', css`
    padding-top: 0;
    padding-bottom: 0;
  `)}
`;

const CloseButton = styled(IconButton)`${({ theme }) => css`
  color: ${theme.palette.grey[500]};

  ${ifProp('$absolute', css`
    position: absolute;
    right: 0.75rem;
    top: 0;
  `)}
`}`;

const ListItem = styled(MuiListItem)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: .8125rem;
  text-transform: uppercase;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: 600;

  &:hover {
    color: ${prop('theme.colors.ttRed')};
  }

  .MuiListItemIcon-root {
    min-width: 2.1rem;
    color: ${theme.palette.common.black};
  }

  span {
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: .8125rem;
    font-weight: 600;
  }
`}`;

const ContentDivider = styled(Divider)`
  margin: 0 1.25rem 0.5rem;
`;

// Custom DialogTitle to include a close button.
const DialogTitle = ({ children, onClose, absolutePosClose, disablePadding, headerImage, ...other }) => {
  return (
    <StyledMuiDialogTitle
      disableTypography
      $disablePadding={disablePadding}
      $absoluteClose={onClose && absolutePosClose}
      $headerImage={headerImage}
      {...other}
    >
      <div>{children}</div>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose} edge='end' $absolute={absolutePosClose}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

SimpleDialog.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.node.isRequired,
    icon: PropTypes.node,
  })),
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  title: PropTypes.node,
  headerImage: PropTypes.string,
  children: PropTypes.node,
  highlightSelected: PropTypes.bool,
  absolutePosClose: PropTypes.bool,
  disableTitlePadding: PropTypes.bool,
  mobileSort: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', false]),
  disableBackdropClick: PropTypes.bool
};

function SimpleDialog({
  options,
  onClose,
  open,
  selectedValue,
  title,
  headerImage,
  children,
  highlightSelected = false,
  absolutePosClose = false,
  disableTitlePadding = false,
  maxWidth = 'sm',
  mobileSort = false,
  disableBackdropClick = false,
  ...props
}) {

  const handleClose = (event) => {
    onClose(selectedValue, event);
  };

  const handleListItemClick = (value, event) => {
    onClose(value, event);
  };

  return (
    <Dialog
      onClose={onClose && handleClose}
      aria-labelledby={title ? 'simple-dialog-title' : undefined}
      open={open}
      paperFullWidth
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
      {...props}
    >
      {(title || headerImage) && (
        <DialogTitle
          id="simple-dialog-title"
          onClose={onClose && handleClose}
          absolutePosClose={absolutePosClose}
          disablePadding={disableTitlePadding}
          headerImage={headerImage}
        >
          {title}
        </DialogTitle>
      )}
      {children}
      {children && options?.length > 0 ? <ContentDivider /> : null}
      {options?.length > 0 && (
        <List>
          {options.map((option, index) => !option.hide && (
            <VisibilityWrapper key={index} displayCallback={option.displayCallback} visibility={option.display}>
              <ListItem
                button
                $mobileSort={mobileSort}
                onClick={(event) => handleListItemClick(option.value, event)}
                selected={highlightSelected && selectedValue === option.value}
              >
                {option.icon && option.label && <ListItemIcon>{option.icon}</ListItemIcon>}
                {option.label
                    ? <ListItemText primary={option.label} disableTypography />
                    : <ListItemText primary={option.icon} disableTypography />
                }
              </ListItem>
            </VisibilityWrapper>
          ))}
        </List>
      )}
    </Dialog>
  );
}

export default React.memo(SimpleDialog);
