import { theme } from 'styled-tools';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import BaseContainer from '@material-ui/core/Container';

export const Container = styled(BaseContainer)`
  border-bottom: ${props => props.hasBorder ? '0.0625rem solid ' + theme('colors.borderGray') : 'none'};
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  flex-wrap: nowrap;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  width: 70%;

  .MuiButton-label > * {
    font-size: 0.875rem;
  }
  
  ${props => props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    width: 100%;
  }
`;
