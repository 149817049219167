import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import { autoOptimizeImage } from 'utils/functions';
import { hoverAnimations } from './const';

const Container = styled.div`
   ${ifProp('$expand', css`
     ${props => props.theme.breakpoints.up('md')} {
       min-height: 11rem;
     }

     ${props => props.theme.breakpoints.up('lg')} {
       min-height: 14rem;
     }
   `)}
 }
`;

const CardImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

ExpandImageContainer.propTypes = {
  altImageText: PropTypes.string,
  cardImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  expand: PropTypes.bool,
};

function ExpandImageContainer({ altImageText, cardImage, expand }) {
  const imgUrl = get(cardImage, 'url');
  const icon = imgUrl && imgUrl.endsWith('.svg');

  return (
    <>
      {cardImage && (
        <Container $expand={expand}>
          <CardImage alt={altImageText} src={autoOptimizeImage(imgUrl)} />
        </Container>
      )}
    </>
  );
}

export default ExpandImageContainer;
