import React, { useReducer, useContext, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { dataLayerPush } from 'utils/dataLayer';
import { useQuery } from '@apollo/client';
import getUserDashboardLogs from '../../graphql/queries/getUserDashboardLogs.graphql';
import { useWillShowFooterStickyAd } from 'hooks/useWillShowFooterStickyAd';
import myPlaylistsQuery from '../../graphql/queries/myPlaylists.graphql';
import MyShows from 'routes/Dashboard/components/MyShows';
import SortOptions from 'constants/SortOptions';
import { useAuth } from '../AuthContext';

function getDefaultState(history, willShowStickyFooterAd) {
  const DEFAULT_STATE = {
    isOpen: false,
    expanded: 'panelMyShows',
    component: MyShows,
    isEditing: false,
    sortBy: SortOptions.newest,
    myShowsFilters: {
      showUids: [],
      notShowUids: [],
    },
    mySlidesFilters: {
      slideDecksUids: [],
    },
    followedShowsCount: 0,
    currentPlaylist: null,
    watchLaterPlaylist: null,
    currentDailyReplay: null,
    showFirstTimeHelper: false,
    showSubsequentHelper: false,
    helperContentType: null,
    playlistsData: [],
    history,
    logs: {},
    willShowStickyFooterAd: willShowStickyFooterAd ?? false,
    deleteItems: false
  };

  let storedState = {};

  if (typeof localStorage !== "undefined") {
    // TODO: load stored state of dashboard from localStorage
  }

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_TOGGLE_DASHBOARD = 'toggleDashboard';
const ACTION_OPEN_DASHBOARD = 'openDashboard';
const ACTION_CLOSE_DASHBOARD = 'closeDashboard';
const ACTION_TOGGLE_EDIT = 'toggleEdit';
const ACTION_SET_SELECTED_PAGE = 'setSelectedPage';
const ACTION_SET_MY_SHOWS_FILTERS = 'setMyShowsFilters';
const ACTION_SET_SELECTED_PLAYLIST = 'setSelectedPlaylist';
const ACTION_SET_WATCH_LATER_PLAYLIST = 'watchLaterPlaylist';
const ACTION_SET_SORT_BY = 'setSortBy';
const ACTION_SET_MY_SLIDES_FILTERS = 'setMySlidesFilters';
const ACTION_SET_SELECTED_DAILY_REPLAY = 'setSelectedDailyReplay';
const ACTION_SET_FOLLOWED_SHOWS_COUNT = 'setFollowedShowsCount';
const ACTION_UPDATE_LOGS = 'updateDashboardLogs';
const ACTION_TOGGLE_FIRST_TIME_HELPER = 'setFirstTimeHelper';
const ACTION_TOGGLE_SUBSEQUENT_HELPER = 'setSubsequentHelper';
const ACTION_SET_HELPER_CONTENT_TYPE = 'helperContentType';
const ACTION_SET_SHOW_STICKY_FOOTER_AD = 'setShowStickyFooterAd';
const ACTION_TOGGLE_DELETE_SELECTED_ITEMS = 'toggleDeleteSelectedItems';
const ACTION_UPDATE_PLAYLISTS = 'updatePlaylists';

function dashboardReducer(state, action) {
  switch (action.type) {
    case ACTION_TOGGLE_DASHBOARD:
      state.history && state.history.push({ ...location, hash: state.isOpen ? '' : '#dashboard' });

      const additionalUpdates = state.isOpen ? {
        expanded: 'panelMyShows',
        component: MyShows,
      } : {};

      return {
        ...state,
        isOpen: !state.isOpen,
        isEditing: false,
        ...additionalUpdates,
      };
    case ACTION_OPEN_DASHBOARD:
      // Return same ref if no changes to make.
      if (state.isOpen === true) {
        return state;
      }

      return {
        ...state,
        isEditing: false,
        isOpen: true,
      };
    case ACTION_CLOSE_DASHBOARD:
      // Return same ref if no changes to make.
      if (state.isOpen === false) {
        return state;
      }

      return {
        ...state,
        isOpen: false,
        isEditing: false,
        expanded: 'panelMyShows',
        component: MyShows,
      };
    case ACTION_TOGGLE_EDIT:
      return {
        ...state,
        isEditing: !state.isEditing,
      };
    case ACTION_SET_SELECTED_PAGE:
      return {
        ...state,
        expanded: action.payload.expanded,
        component: action.payload.component,
      };
    case ACTION_SET_MY_SHOWS_FILTERS:
      return {
        ...state,
        myShowsFilters: {
          ...state.myShowsFilters,
          ...action.payload,
        }
      };
    case ACTION_SET_SELECTED_PLAYLIST:
      return {
        ...state,
        currentPlaylist: action.payload || null,
      };
    case ACTION_SET_WATCH_LATER_PLAYLIST:
      return {
        ...state,
        watchLaterPlaylist: action.payload || null
      }
    case ACTION_SET_FOLLOWED_SHOWS_COUNT:
      return {
        ...state,
        followedShowsCount: action.payload || null,
      };
    case ACTION_SET_SELECTED_DAILY_REPLAY:
      return {
        ...state,
        currentDailyReplay: action.payload || null,
      };
    case ACTION_SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case ACTION_SET_MY_SLIDES_FILTERS:
      return {
        ...state,
        mySlidesFilters: {
          ...state.mySlidesFilters,
          ...action.payload,
        }
      };
    case ACTION_TOGGLE_FIRST_TIME_HELPER:
      return {
        ...state,
        showFirstTimeHelper: action.payload
      }
    case ACTION_TOGGLE_SUBSEQUENT_HELPER:
      return {
        ...state,
        showSubsequentHelper: action.payload
      }
    case ACTION_SET_HELPER_CONTENT_TYPE:
      return {
        ...state,
        helperContentType: action.payload
      }
    case ACTION_UPDATE_LOGS:
      return {
        ...state,
        logs: action.payload.getUserDashboardLogs
      }
    case ACTION_SET_SHOW_STICKY_FOOTER_AD:
      return {
        ...state,
        willShowStickyFooterAd: action.payload
      }
    case ACTION_TOGGLE_DELETE_SELECTED_ITEMS:
      return {
        ...state,
        deleteItems: !state.deleteItems,
        isEditing: false
      }
    case ACTION_UPDATE_PLAYLISTS:
      return {
        ...state,
        playlistsData: action.payload
      }
    default:
      return state;
  }
}

const DashboardContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  toggleDashboard: () => {},
  openDashboard: () => {},
  closeDashboard: () => {},
  toggleEdit: () => {},
  setSelectedPage: (expanded, component) => {},
  setMyShowsFilters: (filters) => {},
  setFollowedShowsCount: (followedShowsCount) => {},
  setCurrentPlaylist: (playlist) => {},
  setWatchLaterPlaylist: (playlist) => {},
  setCurrentDailyReplay: (dailyReplay) => {},
  setSortBy: (sortBy) => {},
  setMySlidesFilters: (filters) => {},
  toggleHelpers: () => {},
  setFirstTimeHelper: (show) => {},
  setSubsequentHelper: (show) => {},
  setHelperContentType: (type) => {},
  updateDashboardLogs: () => {},
  setShowStickyFooterAd: (show) => {},
  toggleDeleteSelectedItems: () => {},
  refetchPlaylists: () => {}
});

export const DashboardProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const willShowStickyFooterAd = useWillShowFooterStickyAd();
  const { user } = useAuth();

  const [state, dispatch] = useReducer(
    dashboardReducer,
    getDefaultState(history, willShowStickyFooterAd)
  );

  const { data: logs, loading, refetch } = useQuery(getUserDashboardLogs, {
    fetchPolicy: 'no-cache',
    skip: !user || typeof window === 'undefined',
  });

  const { data: playlists, loading: playlistsLoading, error: playlistsError, refetch: playlistsRefetch } = useQuery(myPlaylistsQuery, {
    fetchPolicy: 'no-cache',
    skip: !state?.isOpen
  });

  useEffect(() => {
    if (!loading && logs) {
      dispatch({ type: ACTION_UPDATE_LOGS, payload: logs})
    }
  }, [logs])

  useEffect(() => {
    if (!playlistsLoading && playlists) {
      dispatch({ type: ACTION_UPDATE_PLAYLISTS, payload: {
        playlists: playlists?.myPlaylists?.items,
        error: playlistsError,
        loading: playlistsLoading
      }})
    }
  }, [playlists, playlistsError])

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    toggleDashboard: () => dispatch({ type: ACTION_TOGGLE_DASHBOARD }),
    openDashboard: () => dispatch({ type: ACTION_OPEN_DASHBOARD }),
    closeDashboard: () => dispatch({ type: ACTION_CLOSE_DASHBOARD }),
    toggleEdit: () => dispatch({ type: ACTION_TOGGLE_EDIT }),
    setSelectedPage: (expanded, component) => dispatch({
      type: ACTION_SET_SELECTED_PAGE,
      payload: { expanded, component }
    }),
    setMyShowsFilters: (filters) => dispatch({
      type: ACTION_SET_MY_SHOWS_FILTERS,
      payload: filters,
    }),
    setCurrentPlaylist: (playlist) => dispatch({
      type: ACTION_SET_SELECTED_PLAYLIST,
      payload: playlist,
    }),
    setWatchLaterPlaylist: (playlist) => dispatch({
      type: ACTION_SET_WATCH_LATER_PLAYLIST,
      payload: playlist
    }),
    setFollowedShowsCount: (followedShowsCount) => dispatch({
      type: ACTION_SET_FOLLOWED_SHOWS_COUNT,
      payload: followedShowsCount
    }),
    setCurrentDailyReplay: (dailyReplay) => dispatch({
      type: ACTION_SET_SELECTED_DAILY_REPLAY,
      payload: dailyReplay,
    }),
    setSortBy: (sortBy) => dispatch({ type: ACTION_SET_SORT_BY, payload: sortBy }),
    setMySlidesFilters: (filters) => dispatch({
      type: ACTION_SET_MY_SLIDES_FILTERS,
      payload: filters,
    }),
    setFirstTimeHelper: (show) => {
      if (show) {
        dispatch({ type: ACTION_TOGGLE_FIRST_TIME_HELPER, payload: false });
      }
      dispatch({ type: ACTION_TOGGLE_FIRST_TIME_HELPER, payload: show });
    },
    setSubsequentHelper: show => {
      if (show) {
        // If a user starts to do a new interaction the "bewm" message should hide
        dispatch({ type: ACTION_TOGGLE_FIRST_TIME_HELPER, payload: false });
        dispatch({ type: ACTION_TOGGLE_SUBSEQUENT_HELPER, payload: false });
      }
      dispatch({ type: ACTION_TOGGLE_SUBSEQUENT_HELPER, payload: show });
    },
    setHelperContentType: type => dispatch({ type: ACTION_SET_HELPER_CONTENT_TYPE, payload: type }),
    updateDashboardLogs: () => {
      refetch();
    },
    setShowStickyFooterAd: show => dispatch({ type: ACTION_SET_SHOW_STICKY_FOOTER_AD, payload: show}),
    toggleDeleteSelectedItems:  () => dispatch({ type: ACTION_TOGGLE_DELETE_SELECTED_ITEMS }),
    refetchPlaylists: () => {
      playlistsRefetch();
    }
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      logs,
      state
    }));
  }, [state]);

  useEffect(() => {
    if (location.hash && location.hash === '#dashboard') {
      if (!contextValue.state.isOpen) {
        contextValue.openDashboard();
        dataLayerPush('Dashboard opened');
      }
    } else if (contextValue.state.isOpen) {
      contextValue.closeDashboard();
      dataLayerPush('Dashboard closed');
    }
  }, [location]);

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
