import React, { useState } from 'react';
import PropTypes from 'prop-types';
import linkShape from 'shapes/linkShape';
import { isAbsoluteUrl } from 'utils/linkUtils';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from 'components/Button'
import linkResolver from 'utils/linkResolver';
import { dataLayerPush } from 'utils/dataLayer';
import LeavingModal from './LeavingModal';

PageLink.propTypes = {
  link: linkShape,
  button: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
  componentProps: PropTypes.object,
  absoluteComponent: PropTypes.elementType,
};

function PageLink({
  link,
  button,
  component: CustomComponent,
  children,
  absoluteComponent = undefined,
  componentProps = {},
  ...props
}) {
  const url = linkResolver(link);

  const MainComponent = CustomComponent || (button ? Button : Link);
  const [openNavModal, setOpenNavModal] = useState(false);
  const [enrichedUrl, setEnrichedUrl] = useState(url)

  if (!link) {
    // If there's no link, let's just render the component out.
    return <MainComponent {...props}>{children}</MainComponent>;
  }

  const isAbsolute = isAbsoluteUrl(url);
  const { openInNewWindow, showExternalLinkPopup } = link;

  const handleOpen = (event) => {
    if (showExternalLinkPopup) {
      event.preventDefault()
      dataLayerPush(`Leaving site modal opened for '${url}'`);
      setEnrichedUrl(event?.target?.href || url)
      setOpenNavModal(true);
    }
  };

  const handleClose = () => {
    setOpenNavModal(false);
  };

  // If link is absolute, we can't render it with react-router Link component,
  // and we need to use href instead of to.
  // If the link should open in a new window, we can't render it with react-router either,
  // because it does not support setting the target prop.
  const renderComponent = isAbsolute ? absoluteComponent : RouterLink;
  const linkComponentProps = (isAbsolute || openInNewWindow)
    ? { href: url, target: openInNewWindow && '_blank', rel: openInNewWindow && isAbsolute && 'noopener' }
    : { to: url };

  return (
    <>
      <MainComponent onClick={handleOpen} component={renderComponent} {...linkComponentProps} {...props} {...componentProps}>
        {children}
      </MainComponent>
      <LeavingModal
        onClose={handleClose}
        openInNewWindow={openInNewWindow}
        open={openNavModal}
        padding='narrow'
        url={url}
        testId='externalLinkModal'
        enrichedUrl={enrichedUrl}
      />
    </>
  );
}

export default React.memo(PageLink);
