import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import PlaylistsLoading from './Loading';
import PlaylistsBody from './Body';
import DashboardAlert from '../DashboardAlert';
import usePagination from 'hooks/usePagination';
import SortOptions from 'constants/SortOptions';
import DashboardPageHeader from '../DashboardPageHeader';
import { useDashboardContext } from 'context/DashboardContext';
import getPlaylistEpisodesQuery from './playlistEpisodes.graphql';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import OptInAlert from '../OptInAlert';
import { dataLayerPush } from 'utils/dataLayer';
import { Link as RouterLink } from 'react-router-dom';
import EmptyPlaylists from './Empty';
import DashboardButton from 'components/DashboardButton';

const PER_PAGE_LIMIT = 12;

Playlists.propTypes = {
  edit: PropTypes.bool,
};

function Playlists({ edit }) {
  const { state: dashboardState, setCurrentPlaylist } = useDashboardContext();
  const { currentPlaylist } = dashboardState;
  const playlists = dashboardState.playlistsData.playlists;
  const [episodes, setEpisodes] = useState([]);
  const [previousData, setPreviousData] = useState({ episodes: [], page: 0, hasNextPage: false });
  const [episodesCount, setEpisodesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { page, nextPage, setPage } = usePagination(episodesCount, PER_PAGE_LIMIT);
  const playlistName = currentPlaylist && !currentPlaylist.isDefault ? currentPlaylist.name : 'Watch Later';
  const { preferences } = useGetUserDataSettings();
  const { error, refetch } = useQuery(getPlaylistEpisodesQuery, {
    variables: {
      // Use custom playlist if selected, otherwise default to Watch Later playlist.
      playlistId: currentPlaylist?.id,
      sort: {
        sortBy: 'published_at',
        sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
      },
      pagination: {
        skip: 0,
        limit: PER_PAGE_LIMIT
      }
    },
    skip: !currentPlaylist,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setPage(0);
      setEpisodes(data?.playlistEpisodes?.items ?? []);
      setEpisodesCount(data?.playlistEpisodes?.count);
      setLoading(false);
      setHasNextPage(data?.playlistEpisodes?.count === PER_PAGE_LIMIT);
    }
  });

  useEffect(() => {
    if (playlists.length && !currentPlaylist) {
      setCurrentPlaylist(playlists[0])
    }
  }, [playlists])

  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0,0);
    }
    setEpisodesCount(0);
    setLoading(true);
  }, [playlistName]);

  const fetchNextPage = useCallback(() => {
    refetch({
      skip: !currentPlaylist,
      // Use custom playlist if selected, otherwise default to Watch Later playlist.
      playlistId: currentPlaylist?.id,
      sort: {
        sortBy: 'published_at',
        sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
      },
      pagination: {
        skip: (page + 1) * PER_PAGE_LIMIT,
        limit: PER_PAGE_LIMIT
      }
    }).then(({ data }) => {
      setEpisodes([...episodes, ...data?.playlistEpisodes?.items]);
      setEpisodesCount(episodesCount + data?.playlistEpisodes?.count);
      setHasNextPage(data?.playlistEpisodes?.count === PER_PAGE_LIMIT);
      dataLayerPush('Playlists infinite scroll pagination loaded');
      nextPage();
    });
  }, [currentPlaylist, dashboardState, page, episodes]);

  // fetches n episodes
  const fetchMoreEpisodes = useCallback((removedEpisodes) => {
    const updatedEpisodeList = episodes.filter(episode => !removedEpisodes.find(removedEpisode => removedEpisode === episode.uid));
    setPreviousData({  episodes, hasNextPage, page })

    if (hasNextPage) {
      refetch({
        // Use custom playlist if selected, otherwise default to Watch Later playlist.
        playlistId: currentPlaylist && currentPlaylist.id,
        sort: {
          sortBy: 'published_at',
          sortDirection: dashboardState.sortBy === SortOptions.oldest ? 'ascending' : 'descending',
        },
        pagination: {
          skip: ((page + 1) * PER_PAGE_LIMIT),
          limit: removedEpisodes.length
        }
      }).then(({ data }) =>  {
        setEpisodes([...updatedEpisodeList, ...data?.playlistEpisodes?.items])
      });
    } else {
      setEpisodes(updatedEpisodeList);
    }

  }, [currentPlaylist, dashboardState, page, episodes, setPage, previousData, hasNextPage]);

  async function handleUndoDelete() {
    setEpisodes(previousData.episodes ?? episodes)
    setHasNextPage(previousData.hasNextPage);
  }

  if (!preferences?.acceptedAt) {
    return <OptInAlert loading={loading} accepted={false} />
  }

  return (
    <>
      {loading && <PlaylistsLoading />}
      {episodes?.length > 0 && (
          <DashboardPageHeader
            edit={edit}
            mb={0}
            capitalize={false}
            actions={currentPlaylist && (
              <DashboardButton
                component={RouterLink}
                variant='outlined'
                color='primary'
                to={`/user-playlists/${currentPlaylist.id}`}
              >
                Play All
              </DashboardButton>
            )}
          >
            {playlistName}
          </DashboardPageHeader>
        )}
      {error && (
        <DashboardAlert
          message='Failed to load your playlists, please reload to try again.'
          severity='error'
        />
      )}
      {!error && episodes.length === 0 &&
        <EmptyPlaylists
          title={(!playlists.length && !episodes.length) ? 'You haven\'t created any playlists yet.' : undefined}
        />
      }
      {!loading && !error && (
        <PlaylistsBody
          episodes={episodes}
          edit={edit}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          fetchMoreEpisodes={fetchMoreEpisodes}
          playlistId={currentPlaylist?.id}
          onUndoDeleteEpisodes={handleUndoDelete}
        />
      )}
    </>
  );
}

export default React.memo(Playlists);
