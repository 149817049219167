import React from 'react';
import styled from 'styled-components';
import { ifNotProp, theme } from 'styled-tools';
import { useLivePlayerContext } from 'context/LivePlayerContext';
import { dataLayerPush } from 'utils/dataLayer';
import Button from 'components/Button';
import Icon from 'components/Icon';

const ControlButton = styled(Button)`
  color: ${theme('colors.white')};
  font-size: 0.75rem;
  text-transform: uppercase;
  height: 100%;
  justify-content: center;
  border-radius: 0;
  transition: all .5s ease-out;
  opacity: 0.5;

  svg {
    font-size: 1rem;
    fill: ${theme('colors.white')};
    margin-right: 0.5rem;
  }

  &.schedule {
    background: linear-gradient(to left, ${theme('colors.mutedGray')} 50%, ${theme('colors.black')} 50%);
    background-position: left bottom;
    background-size: 200% 100%;

    &.is-active {
      background-position: right bottom;
    }
  }

  &.tradefeed {
    background: linear-gradient(to right, ${theme('colors.mutedGray')} 50%, ${theme('colors.black')} 50%);
    background-position: right bottom;
    background-size: 200% 100%;

    &.is-active {
      background-position: left bottom;
    }
  }

  &.audio {
    &.is-active {
      background-color: ${theme('colors.mutedGray')};
    }
  }

  &:hover {
    opacity: 1;

    svg{
      fill: ${theme('colors.white')};
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  &.MuiButton-text:hover {
    color: ${theme('colors.white')};
  }

  .video-icon {
    fill: ${ifNotProp('disabled', theme('colors.white'), 'rgba(255, 255, 255, 0.26)')};

    &.video-icon-audio {
      font-size: 1.3rem;
    }

    &.focus {
      fill: ${theme('colors.white')};
    }
  }

  &.Mui-disabled {
    svg {
      fill: rgba(255, 255, 255, 0.26);
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    min-width: 0rem;
  }
`;

const AudioOnlyIcon = styled(Icon)`
  fill: ${theme('colors.white')};
`;

function AudioOnlyButton() {
  const { state: { selectedEpisode, isVideoMode }, toggleVideoMode } = useLivePlayerContext();

  const toggleAudioOnly = () => {
    const dataLayerMessage = isVideoMode ? 'Live Player audio-only enabled' : 'Live Player audio-only disabled';
    dataLayerPush(dataLayerMessage);
    toggleVideoMode();
  };

  if (!selectedEpisode) {
    return (
      <ControlButton
        onClick={toggleAudioOnly}
        title="Audio Only"
        className={!isVideoMode ? 'is-active audio' : 'audio'}
      >
        <AudioOnlyIcon
          className="video-icon video-icon-audio"
          icon="headset"
          $active={!isVideoMode}
        />
        <div>Audio Only</div>
      </ControlButton>
    );
  }

  return null;
}

export default React.memo(AudioOnlyButton);