import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink } from 'react-router-dom';
import GridListTile from '@material-ui/core/GridListTile';
import showThreeColumnShape from 'shapes/components/showThreeColumnShape';
import ShowCard, { SHOW_CARD_SIZES } from 'components/ShowCard';
import GridListSlider from 'components/GridListSlider';

const ShowThreeColumnContainer = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const GridListTileContainer = styled(GridListTile)`
  height: auto !important;
  // appropriate use of important tag as this component is an exception where we need to override mui styling
`;

const propTypes = {
  data: showThreeColumnShape,
  className: PropTypes.string,
};

function ShowThreeColumn({ data, className }) {
  const { leftColumnShow, middleColumnShow, rightColumnShow, isMobileSlider, shouldLazyLoad } = data;

  if (!leftColumnShow && !middleColumnShow && !rightColumnShow) {
    return null;
  }
  return (
    <ShowThreeColumnContainer className={className}>

      {/* Tablet/Desktop shows list */}
      <Hidden xsDown={isMobileSlider}>
        <Grid container spacing={2}>
          {[leftColumnShow, middleColumnShow, rightColumnShow].map((show, i) => (
            <Grid item xs={12} sm={4} key={i}>
              {show && <ShowCard show={show} size={SHOW_CARD_SIZES.featured} shouldLazyLoad={shouldLazyLoad} />}
            </Grid>
          ))}
        </Grid>
      </Hidden>

      {/* Mobile-only shows list */}
      <Hidden xsDown={!isMobileSlider} smUp>
        <GridListSlider spacing={16} tileWidth='78%'>
          {[leftColumnShow, middleColumnShow, rightColumnShow].map((show, i) => (
            <GridListTileContainer key={i} component='div'>
              {show && <ShowCard show={show} size={SHOW_CARD_SIZES.featured} shouldLazyLoad={shouldLazyLoad}/>}
            </GridListTileContainer>
          ))}
        </GridListSlider>
      </Hidden>

    </ShowThreeColumnContainer>
  );
}

ShowThreeColumn.propTypes = propTypes;

export default ShowThreeColumn;
