import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useAuth } from 'context/AuthContext';
import savedSlideDecksShowsQuery
  from '../../../routes/Dashboard/components/MySlidesSidebar/savedSlideDecksShows.graphql';

const DashboardSlidesContext = createContext(undefined);

const defaultState = {
  shows: {
    error: null,
    loading: true,
    data: {
      items: [],
      count: 0
    },
    selected: []
  },
  loading: true
}

export const DashboardSlidesProvider = ({ children }) => {
  // default state
  const [state, setState] = useState(defaultState);
  const { user } = useAuth();

  /* INTERNAL FUNCTIONS */
  //side deck shows
  const { data: showsData, loading: showsLoading, error: showsError, refetch } = useQuery(savedSlideDecksShowsQuery, {
    variables: {
      filters: {},
    },
    fetchPolicy: 'network-only',
    skip: !user,
  });

  // update show data for component handling of loading and error states
  useEffect(() => {
    const shows = {
      ...state.shows,
      error: showsError,
      loading: showsLoading,
      data: showsData?.savedSlideDecksShows ?? state.shows,
    }

    setState( { ...state, shows })
  }, [showsData, showsError, showsLoading]);
  /* END INTERNAL FUNCTIONS */

  /* CONTEXT CALLABLE FUNCTIONS */
  const setSelectedSlides = (selected) => setState({ ...state, slides: {...state.slides, selected} });
  const setSelectedShows = (selected) => setState({ ...state, shows: {...state.shows, selected} });
  const refetchShows = () => refetch();
  /* END CONTEXT CALLABLE FUNCTIONS */

  // actual context data
  const contextData = {
    ...state,
    setSelectedSlides,
    setSelectedShows,
    refetchShows
  }

  return (
    <DashboardSlidesContext.Provider value={contextData}>
      {children}
    </DashboardSlidesContext.Provider>
  )
}

export const useDashboardSlidesContext = () => {
  const context = useContext(DashboardSlidesContext);

  if (context === undefined) {
    console.error("The component calling useDashboardSlidesContext is not wrapped inside the DashboardSlidesContextProvider");
    return defaultState;
  }

  return context;
};
