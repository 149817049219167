import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseCarousel from 'react-material-ui-carousel';
import researchSlideShape from 'shapes/researchSlideShape';
import { autoOptimizeImage } from 'utils/functions';

const Carousel = styled(BaseCarousel)`
  height: 100%;
  min-height: ${prop('$minHeight')}px;

  .CarouselItem {
    height: 100%;
    min-height: inherit;

    & > div {
      display: flex;
      height: 100%;
      min-height: inherit;
    }
  }

  .indicators-container {
    position: absolute;
    bottom: -40px;
    right: 120px;
    width: fit-content;
  }
`;

const SlideImage = styled.div`
  display: flex;
  min-height: fit-content;

  img {
    height: 100%;
    width: 100%;
  }
`;

StandardCarousel.propTypes = {
  carouselHeight: PropTypes.number,
  carouselIndex: PropTypes.number,
  imageRef: PropTypes.object,
  onArrowClick: PropTypes.func,
  onDotClick: PropTypes.func,
  slideDecks: PropTypes.arrayOf(researchSlideShape),
  totalImages: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
  }))
};

function StandardCarousel({
  carouselHeight,
  carouselIndex,
  imageRef,
  onArrowClick,
  onDotClick,
  slideDecks,
  totalImages
}) {

  const debounceChange = useCallback(debounce((cur) => onDotClick(cur), 500), []);

  return (
    <>
      {slideDecks.map((slideDeck, deckIndex) => (
        <Carousel
          key={`slideDeck-${deckIndex}`}
          indicators={true}
          index={carouselIndex}
          animation='slide'
          autoPlay={false}
          next={onArrowClick}
          prev={onArrowClick}
          swipe={false}
          $minHeight={carouselHeight}
          navButtonsAlwaysInvisible
          activeIndicatorIconButtonProps={{ style: { backgroundColor: 'black' } }}
          indicatorContainerProps={{ className: 'indicators-container' }}
          onChange={debounceChange}
        >
          {totalImages.map((slideImage, slideIndex) => (
            <SlideImage
              key={`slide-image-${slideIndex}`}
              ref={imageRef}
            >
              <img alt={`slide ${slideImage.title}`} src={autoOptimizeImage(slideImage.url)} />
            </SlideImage>
          ))}
        </Carousel>
      ))}
    </>
  );
}

export default StandardCarousel;
