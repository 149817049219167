import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box'
import styled, { css } from 'styled-components';
import { switchProp, theme } from 'styled-tools'
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import SocialLinks, { SocialContainer } from 'routes/TalentPage/components/SocialLinks';
import { socialGroupShape } from 'shapes/talentShape';
import Button from 'components/Button';
import Icon from 'components/Icon';
import {
  ButtonOverlay,
  ImageOverlayHover,
  Divider,
  FollowButton,
  Name,
  Bio
} from '../SharedStyles';

const TALENT = 'talent';
const LEADERSHIP = 'leadership';

OverlayHover.propTypes = {
  bio: PropTypes.string,
  card: PropTypes.string,
  cardsInRow: PropTypes.string,
  socialGroup: socialGroupShape,
  title: PropTypes.string,
  talentTitle: PropTypes.string,
};

function OverlayHover({ bio, card, cardsInRow, socialGroup, title, talentTitle }) {
  const theme = useTheme();
  const firstName = title?.split(' ')[0];

  return (
    <>
      <ImageOverlayHover $card={card} $cardsInRow={cardsInRow}>
        <Box>
          <Name
            $card={card}
            $cardsInRow={cardsInRow}
            variant={card === TALENT ? 'h4' : 'h2'}
          >
            {title}
          </Name>
          <Typography>{talentTitle}</Typography>
          <Divider />
          <Bio $card={card} $cardsInRow={cardsInRow}>{bio}</Bio>
        </Box>
      </ImageOverlayHover>
      <ButtonOverlay $card={card} $cardsInRow={cardsInRow}>
        {/*<FollowButton
          $card={card}
          color='secondary'
          variant='text'
          endIcon={<Icon icon='chevronRight' color={theme.palette.common.white} size={0.75} />}
        >
          Follow Trades by {firstName}
        </FollowButton>*/}
        <SocialLinks socialGroup={socialGroup} fill={theme.palette.common.white} />
      </ButtonOverlay>
    </>
  );
}

export default OverlayHover;
