import styled, { css }  from 'styled-components';
import { theme, ifProp } from 'styled-tools';

export const ActionItemContainer = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  color: ${theme('colors.black')};
  border-radius: 0;
  padding: 0;

  ${props => props.theme.breakpoints.up('lg')} {
    justify-content: center;
  }

  &:hover {
    color: ${theme('colors.ttRed')};
  }

  ${ifProp('$loading', css`
    cursor: default;

    & {
      color: ${theme('colors.mutedLightGray')}
    }

    &:hover {
      && span {
        color: ${theme('colors.ttRed')};
      }
    }

    && svg {
      fill: ${theme('colors.mutedLightGray')};
    }
  `)}
`;

export const LabelWrapper = styled.span`
  font-size: .65rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 0.6rem;

  ${props => props.theme.breakpoints.down('xs')} {
    margin-left: 0.85rem;
  }

  &:hover {
    color: ${theme('colors.black')};
  }
`;
