import styled from 'styled-components';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import ComputerChipIcon from 'components/Icon/ComputerChipIcon';
import MoneyCycleIcon from 'components/Icon/MoneyCycleIcon';
import MegaphoneIcon from 'components/Icon/MegaphoneIcon';
import SportsIcon from 'components/Icon/Sports';
import HeadphonesIcon from 'components/Icon/HeadphonesIcon';
import MartiniGlassIcon from 'components/Icon/MartiniGlassIcon';
import PlugChordIcon from 'components/Icon/PlugChordIcon';
import BitcoinIcon from 'components/Icon/BitcoinIcon';

const DumbbellIcon = styled(FitnessCenterIcon)`
  transform: scaleX(-1);
`;

export const iconReference = {
  'dumbbell': DumbbellIcon,
  'computer-chip': ComputerChipIcon,
  'money-cycle': MoneyCycleIcon,
  'megaphone': MegaphoneIcon,
  'sports': SportsIcon,
  'headphones': HeadphonesIcon,
  'martini-glass': MartiniGlassIcon,
  'plug-chord': PlugChordIcon,
  'bitcoin': BitcoinIcon
}