import PropTypes from 'prop-types';
import assetShape from 'shapes/assetShape';

const { number, string, shape } = PropTypes;

export default shape({
  imageAsset: assetShape,
  altImageText: string,
  objectFit: string,
  overflowDirection: string,
  overflowPercent: number,
  maskDirection: string,
});
