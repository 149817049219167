import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { theme } from 'styled-tools';
import talentShape from 'shapes/talentShape';
import {
  ImageContainer,
  Overlay,
  ImageOverlay,
  Wrapper,
} from 'components/LeadershipCard/components/SharedStyles';
import OverlayHover from 'components/LeadershipCard/components/OverlayHover';
import { autoOptimizeImage } from 'utils/functions';
import { formatStringForTestId } from '__testUtils__/functions';

const CardWrapper = styled(Wrapper)`
  position: relative;
  padding-top: 78%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  border-radius: 1rem;
  border: 0;

  @media (min-width: 300px) {
    padding-top: 307px;
  }
`;

const TalentImageContainer = styled(ImageContainer)`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: ${theme('colors.white')};
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
`;

const Link = styled(RouterLink)`
  position: static;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
  }
`;

const Name = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const Title = styled(Typography)`
  font-size: 1.125rem;
`;

TalentCard.propTypes = {
  singleTalent: talentShape.isRequired,
};

function TalentCard({ singleTalent }) {
  const { bio, title, url, talentTitle, talentFeatureImage, socialGroup } =
    singleTalent;

  if (!title ?? !url) {
    return null;
  }

  const formattedTitle = title?.replace(/\s?-/, ',');
  const mobileImage = talentFeatureImage
    ? talentFeatureImage.url +
      '?format=pjpg&quality=50&width=250&disable=upscale'
    : '';
  const desktopImage = talentFeatureImage
    ? talentFeatureImage.url +
      '?format=pjpg&quality=50&width=400&disable=upscale'
    : '';

  const imgSrcSet = `${mobileImage} 600w, ${desktopImage} 1400w`;

  return (
    <CardWrapper>
      <TalentImageContainer $card='talent'>
        {talentFeatureImage && (
          <img
            alt={title}
            srcset={imgSrcSet}
            src={!imgSrcSet ? desktopImage : ''}
            sizes='(max-width: 600px) 88vw, (max-width: 1400px) 30vw'
          />
        )}
        <Overlay />
        <ImageOverlay $card='talent'>
          <Name variant='h4'>{formattedTitle}</Name>
          <Typography>{talentTitle}</Typography>
        </ImageOverlay>
        <OverlayHover
          bio={bio}
          card='talent'
          socialGroup={socialGroup}
          title={formattedTitle}
          talentTitle={talentTitle}
        />
        <Link to={url} />
      </TalentImageContainer>
    </CardWrapper>
  );
}

export default TalentCard;
