import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: ${(props) => props.$minHeight};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
  }

  ${ifProp(
    '$noTopMargin',
    css`
      padding-top: 0;
    `
  )};

  ${ifProp(
    '$noTopMarginMobile',
    css`
      ${(props) => props.theme.breakpoints.down('sm')} {
        padding-top: 0;
      }
    `
  )};
`;

PageWrapper.propTypes = {
  noTopMargin: PropTypes.bool,
  noTopMarginMobile: PropTypes.bool,
  fullHeight: PropTypes.bool,
  children: PropTypes.node,
};

function PageWrapper({ noTopMargin, noTopMarginMobile, children, ...props }) {
  const [minHeight, setMinHeight] = useState('100vh');
  const contentRef = useRef();

  const adjustHeight = () => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const viewportHeight = window.innerHeight;
      if (contentHeight > 0 && contentHeight < viewportHeight) {
        setMinHeight('40rem');
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [children]);

  return (
    <Wrapper
      $noTopMargin={noTopMargin}
      $noTopMarginMobile={noTopMarginMobile}
      $minHeight={minHeight}
      {...props}
    >
      <div ref={contentRef}>
        {children}
      </div>
    </Wrapper>
  );
}

export default React.memo(PageWrapper);
