import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';
import get from 'lodash.get';
import { ifProp, prop } from 'styled-tools';
import defaultTo from 'lodash.defaultto';
import getEpisodesQuery from '../../../graphql/queries/getEpisodes.graphql';
import episodeFeedBlockShape from 'shapes/components/episodeFeedBlockShape';
import Grid from '@material-ui/core/Grid';
import BaseStackHeader from 'components/StackHeader';
import RichTextField from 'components/RichTextField';
import Typography from '@material-ui/core/Typography';
import BlockContainer from 'components/BlockContainer';
import config from 'config';
import useInterval from 'hooks/useInterval';
import EpisodeSection from 'components/EpisodeSection';
import Button from 'components/Button';
import EpisodeTabbedFeedNav from './components/EpisodeTabbedFeedNav';
import EpisodeFeed from 'components/EpisodeFeed';

const ROLLDOWN_EPISODES_HEADER = 'Recent Episodes';

const StackHeader = styled(BaseStackHeader)`
  ${({ theme, $isHomepage }) => css`
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.5rem;

    ${$isHomepage &&
    `
      border-bottom: 3px solid #e21f26;
      padding-bottom: .33rem;
      font-size: 1.25rem;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 2rem;

      ${theme.breakpoints.down('sm')} {
        margin-bottom: 1.5rem;
      }
    `}
  `}
`;

const EpisodeFeedBlockContainer = styled(BlockContainer)`
  ${ifProp('$homepage', css`
    padding:  0 5rem 5rem 5rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 3rem 1rem;
    }
  `)}
`;

const StyledButton = styled(Button)`
  color: ${prop('theme.colors.ttRed')};
  background-color: ${prop('theme.colors.white')};
  padding: .5rem 1.5rem;
  font-size: 1rem;
  border: .15rem solid ${prop('theme.colors.ttRed')};
  border-radius: 0.125rem;

  &:hover {
    background-color: ${prop('theme.colors.ttRed')};
    color: ${prop('theme.colors.white')};
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 4rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const MobileRolldownSection = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const DesktopRolldownSection = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const episodeSortTypeMap = {
  most_recent: {
    sortBy: 'published_at',
    sortDirection: 'descending',
  },
  oldest_to_newest: {
    sortBy: 'published_at',
    sortDirection: 'ascending',
  },
  trending: {
    sortBy: 'trending',
    sortDirection: 'descending',
  }
};

EpisodeFeedBlock.propTypes = {
  data: episodeFeedBlockShape.isRequired,
  className: PropTypes.string,
};

function EpisodeFeedBlock({ data, className, ...props }) {
  const { page } = props;
  const isHomepage = (page === 'homepage');
  const [displayedEpisodes, setDisplayedEpisodes] = useState(4);
  const [showMore, setShowMore] = useState(true);
  const [selectedEpisodeTabbedIndex, setSelectedEpisodeTabbedIndex] = useState(0);

  if (!data) {
    return null;
  }

  const currentFilters = data?.episodeTabbedFeedOptions?.[selectedEpisodeTabbedIndex]?.episodeFeed?.filters || {};
  const {
    header,
    headerType = 'H2',
    description,
    episodeCount,
    enableTabbedFeed,
    displayAsRolldown,
    episodeTabbedFeedOptions,
    episodeFeed: {
      sortType = 'most_recent',
      liveUpdate = {},
      filters = {},
    } = {},
  } = data;

  const isRecentEpisodes = header === ROLLDOWN_EPISODES_HEADER;

  // Prepare query variables from the CS block data.
  const sort = episodeSortTypeMap[sortType] || {};
  const pagination = { limit: episodeCount || 20 };

  const {
    shows,
    topics,
    products,
    formats,
    talent,
    subjectMatter,
    subjectMatterFields = 'Both',
  } = enableTabbedFeed ? currentFilters : filters;

  const queryFilters = {
    showUids: defaultTo(shows, []).map(v => v.uid),
    topicUids: defaultTo(topics, []).map(v => v.uid),
    productUids: defaultTo(products, []).map(v => v.uid),
    formatUids: defaultTo(formats, []).map(v => v.uid),
    talentUids: defaultTo(talent, []).map(v => v.uid),
    subjectMatterUids: defaultTo(subjectMatter, []).map(({ uid, contentTypeUid }) => ({ uid, contentTypeUid })),
    subjectMatterFields,
  };

  const { data: episodesData, loading, error, refetch } = useQuery(getEpisodesQuery, {
    skip: typeof window === 'undefined',
    variables: {
      filters: queryFilters,
      pagination,
      sort,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    pollInterval: config.episodeFeeds.pollInterval
  });

  // Setup interval that tracks current time and updates recent episode feed.
  useInterval(() => {
    if (liveUpdate?.willUpdate) {
      refetch();
    }
  }, liveUpdate?.updateInterval ?? config.episodeFeeds.pollInterval);

  useEffect(() => {
    setDisplayedEpisodes(episodeIncrement)
    setShowMore(true);
  },[selectedEpisodeTabbedIndex])

  const totalEpisodes = get(episodesData, 'episodes.items', [])
  const animatedEpisodes = get(episodesData, 'episodes.items', []).slice(0, displayedEpisodes);
  const maxEpisodes = 16;
  const episodeIncrement = 4;

  const loadNextEpisodes = () => {
    if (displayedEpisodes === maxEpisodes) {
      setDisplayedEpisodes(episodeIncrement);
      setShowMore(true);
    } else {
      const episodeCount = Math.min(displayedEpisodes + episodeIncrement, maxEpisodes);
      setDisplayedEpisodes(episodeCount);
      setShowMore(episodeCount < maxEpisodes);
    }

    if (!showMore) {
      const middlePosition = window.innerHeight / 0.7;
      const scrollOffset = 300;
      window.scrollTo({ top: middlePosition + scrollOffset, behavior: 'smooth' });
    }
  };

  return (
    <EpisodeFeedBlockContainer $carouselGutters maxWidth='xl' $homepage={isHomepage} className={className} {...props}>
      {/* Header and description, if set */}
      {(header || description) && (
        <Grid container>
          <Grid item xs={12}>
            {header && (
              <StackHeader headerType={headerType} defaultHeaderType='H2' $isHomepage={isHomepage} gutterBottom>
                {header}
              </StackHeader>
            )}
            {description && <RichTextField richText={description} />}
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          {/* Episode feed */}
          <>
            {enableTabbedFeed && (
              <EpisodeTabbedFeedNav
                value={selectedEpisodeTabbedIndex}
                setValue={setSelectedEpisodeTabbedIndex}
                data={episodeTabbedFeedOptions}
              />
            )}
            {displayAsRolldown ? (
              <>
                <MobileRolldownSection>
                  {(loading || (totalEpisodes && totalEpisodes.length > 0)) && (
                    <EpisodeFeed
                      episodes={totalEpisodes}
                      loading={loading}
                      feedName={header}
                      page={page}
                      rollDown={true}
                    />
                  )}
                </MobileRolldownSection>
                <DesktopRolldownSection>
                  {(loading || (animatedEpisodes && animatedEpisodes.length > 0)) && (
                    <EpisodeSection
                      episodes={animatedEpisodes}
                      homepage={isHomepage}
                      rollDown={true}
                    />
                  )}
                  <ButtonContainer>
                    <StyledButton onClick={loadNextEpisodes}>
                      {showMore ? 'Load More' : 'Show Less'}
                    </StyledButton>
                  </ButtonContainer>
                </DesktopRolldownSection>
              </>
            ) : (
              <>
                {(loading || (totalEpisodes && totalEpisodes.length > 0)) && (
                  <EpisodeFeed episodes={totalEpisodes} loading={loading} feedName={header} page={page} />
                )}
              </>
            )}
          </>
          {/* Message if no episodes found */}
          {!loading && !error && totalEpisodes && totalEpisodes.length === 0 && (
            <Typography>No episodes available at this time. Check back later!</Typography>
          )}
        </Grid>
      </Grid>
    </EpisodeFeedBlockContainer>
  );
}

export default EpisodeFeedBlock;
