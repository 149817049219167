import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';
import Input from 'components/Input';
import { ButtonContainer, Title } from './SharedStyles';
import updateAuth0UserMutation from '../../../../graphql/mutations/updateAuth0User.graphql';
import { theme } from 'styled-tools';
import { validateEmail } from 'utils/commongRegex';

const ExistingData = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled(Typography)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 0.8125rem;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
`}`;

const SubmissionError = styled.div`
  color: ${theme('colors.ttRed')};
`;

UpdateEmail.propTypes = {
  auth0Id: PropTypes.string,
  email: PropTypes.string,
  handleClose: PropTypes.func,
  refetchAuth0: PropTypes.func,
};

function UpdateEmail({ auth0Id, email, handleClose, refetchAuth0 }) {
  const [newEmail, setNewEmail] = useState({ value: '', error: false });
  const [confirmNewEmail, setConfirmNewEmail] = useState({ value: '', error: false });
  const [password, setPassword] = useState({ value: '', error: false });
  const [submissionError, setSubmissionError] = useState();

  const [updateAuth0User] = useMutation(updateAuth0UserMutation, {errorPolicy: 'all'});
  const handleUpdateAuth0User = (auth0Id, newEmail, password) => {
    updateAuth0User({
      variables: {
        auth0Id: auth0Id,
        input: {
          email,
          newEmail,
          password
        },
        type: 'email'
      }
    }).then((res) => {
      if (res.errors) {
        const statusCode = res.errors[0].message.substr(-3);
        if (statusCode === '429') {
          setSubmissionError('Too many attempts, please try again later.')
        } else if (statusCode === '403') {
          setPassword({ value: password, error: 'Incorrect password.'})
        } else {
          setSubmissionError('Something went wrong.')
        }
      } else {
        refetchAuth0();
        handleClose();
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let emailError = false;
    let confirmEmailError = false;

    // check that email is valid
    if (!validateEmail(newEmail.value)) {
      emailError = 'Invalid email';
    }
    setNewEmail({...newEmail, error: emailError});

    // check that emails match
    if (newEmail.value.length && confirmNewEmail.value.length && newEmail.value.toLowerCase() !== confirmNewEmail.value.toLowerCase()) {
      confirmEmailError = 'Emails do no match';
    }
    setConfirmNewEmail({...confirmNewEmail, error: confirmEmailError})

    // if form is valid, submit request
    if (!emailError && !confirmEmailError) {
      handleUpdateAuth0User(auth0Id, newEmail.value, password.value);
    }
  }

  const disableForm = !(newEmail.value.length && confirmNewEmail.value.length && password.value.length);
  return (
    <div>
      <Title variant='h4'>Change Email Address</Title>
      <form onSubmit={handleSubmit}>
        <ExistingData>
          <Label>Current Email</Label>
          <Typography>{email}</Typography>
        </ExistingData>
        <Input
          id='newEmail'
          label='New Email'
          onChange={(e) => setNewEmail({error: false, value: e.target.value})}
          value={newEmail.value}
          width='full'
          error={newEmail.error}
          helperText={newEmail.error}
        />
        <Input
          id='confirmNewEmail'
          label='Confirm New Email'
          onChange={(e) => setConfirmNewEmail({error: false, value: e.target.value})}
          value={confirmNewEmail.value}
          width='full'
          error={confirmNewEmail.error}
          helperText={confirmNewEmail.error}
        />
        <Input
          id='password'
          label='For Security, Please Confirm Your Password'
          onChange={(e) => setPassword({error: false, value: e.target.value})}
          type='password'
          value={password.value}
          width='full'
          error={password.error}
          helperText={password.error}
        />
        {submissionError && <SubmissionError>{submissionError}</SubmissionError>}
        <ButtonContainer>
          <Button
            color='primary'
            variant='contained'
            type='submit'
            disabled={disableForm}
          >
            Save Changes
          </Button>
          <Button onClick={handleClose} variant='outlined'>Cancel</Button>
        </ButtonContainer>
      </form>
    </div>
  );
}

export default React.memo(UpdateEmail);
