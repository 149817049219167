import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { ifProp, theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import VisibilityWrapper from 'components/VisibilityWrapper';
import menuItemShape from 'shapes/menuItemShape';
import { dataLayerPush } from 'utils/dataLayer';
import PageLink from 'components/PageLink';
import MegaMenu from '../MegaMenu';
import { useTourContext } from 'context/TourContext';
import {
  Tours,
  TRADER_RESOURCES_MENU_CLASS,
  TraderResourcesStepKeys,
} from 'components/Tours/constants';

const NavLinkWrapper = styled.div`
  position: relative;
`;

const StyledNavLink = styled(Link)`
  display: flex;
  height: 100%;
  margin-bottom: -1px;
  padding-right: 12px;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-stretch: condensed;
  text-transform: uppercase;
  font-family: ${theme('typography.fontFamilySecondary')};
  color: ${theme('palette.common.black')};
  text-decoration: none;
  cursor: pointer;

  &:hover{
    text-shadow: 0 0 .5px #000, 0 0 .5px #000;
  }

  @media (min-width: 1120px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  ${NavLinkWrapper}:hover & {
    text-decoration: none;
    background-color: ${theme('palette.common.white')};
    color: ${theme('palette.common.black')};
  }
`;

const NavItemHoverUnderline = styled.span`
  padding: 0.4375rem 0.0625rem;
  border-bottom: 1px solid transparent;

  ${NavLinkWrapper}:hover & {
    border-bottom-color: ${theme('colors.lightRed')};
  }

  ${ifProp(
    'active',
    css`
      border-bottom-color: ${theme('colors.lightRed')};
    `
  )}
`;

MainNavLink.propTypes = {
  displayAlert: PropTypes.bool,
  menuItem: menuItemShape,
};

function MainNavLink({ displayAlert, menuItem }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTimer, setMenuTimer] = useState(null);
  const { link, linkText, menuColumns, submenu, visibility, cssClassName } =
    menuItem || {};
  const history = useHistory();
  const {
    state: { activeTour, currentStep },
    updateCurrentStep,
  } = useTourContext();

  const isTraderResources = cssClassName === TRADER_RESOURCES_MENU_CLASS;

  function handleKeyPress() {
    if (menuColumns?.length) {
      setMenuOpen(!menuOpen);
    }
  }

  const openMegaMenu = () => {
    setMenuTimer(
      setTimeout(function() {
        dataLayerPush('megamenu opened');

        setMenuOpen(true);
      }, 250)
    );

    // TraderResourcesTour - move to next step if on first step.
    if (
      isTraderResources &&
      activeTour === Tours.TraderResources &&
      currentStep === TraderResourcesStepKeys.MenuItem
    ) {
      updateCurrentStep(currentStep + 1);
    }
  };

  const closeMegaMenu = () => {
    dataLayerPush('megamenu closed');
    setMenuOpen(false);
    clearTimeout(menuTimer);
    setMenuTimer(null);
  };

  // TraderResourcesTour - force menu item to remain open if trader resources tour on menu open step.
  const forceOpenForTour =
    isTraderResources &&
    activeTour === Tours.TraderResources &&
    (currentStep === TraderResourcesStepKeys.MenuOpen ||
      currentStep === TraderResourcesStepKeys.ConceptsColumns);
  const isMenuOpen = menuOpen || forceOpenForTour;

  return (
    <VisibilityWrapper visibility={visibility}>
      <NavLinkWrapper
        onMouseEnter={openMegaMenu}
        onMouseLeave={closeMegaMenu}
        className={cssClassName}
      >
        <PageLink
          component={StyledNavLink}
          link={link}
          tabIndex={0}
          onKeyPress={e => {
            // Spacebar opens/closes megamenu for ADA. Enter key goes to the link.
            if (e.key === ' ') {
              e.preventDefault();
              handleKeyPress();
            }
          }}
        >
          <NavItemHoverUnderline>{linkText}</NavItemHoverUnderline>
        </PageLink>
        {menuColumns && menuColumns.length > 0 && (
          <MegaMenu
            displayAlert={displayAlert}
            menuItems={menuColumns}
            menuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
          />
        )}
      </NavLinkWrapper>
    </VisibilityWrapper>
  );
}

export default React.memo(MainNavLink);
