import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDashboardContext } from 'context/DashboardContext';
import UndoSnackBar from 'components/UndoSnackBar';
import EpisodeCard from 'components/EpisodeCard';
import episodeShape from 'shapes/episodeShape';
import { ACTION_FOLLOW_SHOW, ACTION_WATCH_LATER } from 'components/CardButtons/AddToPlaylist';
import removeEpisodesFromPlaylistsMutation from '../../../../graphql/mutations/removeEpisodesFromPlaylists.graphql';
import { dataLayerPush } from 'utils/dataLayer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import updatePlaylistEpisodesOrderMutation from '../../../../graphql/mutations/updatePlaylistEpisodesOrder.graphql';
import styled  from 'styled-components';
import SelectAllRow from '../SelectAllRow';
import ButtonPrimary from 'components/ButtonPrimary';
import deletePlaylistMutation from '../PlaylistsSidebar/deletePlaylist.graphql';
import PlaylistsLoading from './Loading';
import EpisodeDisplayOptions from 'constants/EpisodeDisplayOptions';

const WATCH_LATER = 'Watch Later';

const GridWrapper = styled(Grid)`
  position: relative;
`;

const InfiniteScrollWrapper = styled(InfiniteScroll) `
  margin-top: 1rem;
`;

const ListItems = styled.div`
  width: 100%;
`;

const DeletePlaylistContainer = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const DeletePlaylistButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  
  button {
    margin: 0 0.5rem;
  }
`;

PlaylistsBody.propTypes = {
  edit: PropTypes.bool,
  episodes: PropTypes.arrayOf(episodeShape),
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  fetchMoreEpisodes: PropTypes.func.isRequired,
  onUndoDeleteEpisodes: PropTypes.func.isRequired,
};

function PlaylistsBody({ edit, episodes, fetchNextPage, hasNextPage, fetchMoreEpisodes, onUndoDeleteEpisodes }) {
  const [episodeList, setEpisodeList] = useState({current: [], previous: []});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [selectedPlaylistEpisodes, setSelectedPlaylistEpisodes] = useState([]);
  const { state: dashboardState, setCurrentPlaylist, refetchPlaylists } = useDashboardContext();
  const { currentPlaylist, deleteItems, watchLaterPlaylist } = dashboardState;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [removeEpisodesFromPlaylists] = useMutation(removeEpisodesFromPlaylistsMutation);
  const [updatePlaylistEpisodesOrder] = useMutation(updatePlaylistEpisodesOrderMutation);
  const playlistName = currentPlaylist && !currentPlaylist.isDefault ? currentPlaylist.name : 'Watch Later';
  const [showDeletePlaylistComponent, setShowDeletePlaylistComponent] = useState(false);
  const [deletePlaylist] = useMutation(deletePlaylistMutation);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => handleDeleteEpisodes(toDeleteRef.current);
  }, [])

  useEffect(() => {
    setEpisodeList({...episodeList, current: episodes});
  }, [episodes]);

  useEffect(() => {
    if (selectAllChecked && episodes?.length > selectedPlaylistEpisodes.length) {
      setSelectedPlaylistEpisodes(episodeList.current.map(ep => ep.uid));
    }
  }, [episodeList.current?.length])

  async function handleClickDelete(selectedEpisodes) {
    await setShowSnackBar(false)

    // if we delete multiple episodes in rapid succession we need to delete the old one before replacing the state prop with the new one
    handleDeleteEpisodes(toDelete);

    // set the next episodes to be deleted
    setToDelete(selectedEpisodes);
    setShowSnackBar(true);

    // set loading state while we make ui and data changes
    setLoading(true);

    if (selectedEpisodes.length === episodeList.current.length && !currentPlaylist?.isDefault && !hasNextPage) {
      setShowDeletePlaylistComponent(true)
    } else {
      // update the parent components episode list
      await fetchMoreEpisodes(selectedEpisodes);
    }

    // now that updates have been made, remove loading state
    setLoading(false);
  }

  function handleAllowTimeout() {
    handleDeleteEpisodes(toDelete);
    setShowSnackBar(false);
  }

  async function handleDeleteEpisodes(episodes) {
    currentPlaylist && episodes?.length &&
    await removeEpisodesFromPlaylists({
      variables: {
        input: { episodeIds: episodes, playlistIds: [currentPlaylist.id] }
      }
    });
    dataLayerPush(`Episode(s) removed from ${playlistName} Playlist`);
  }

  function handleUndoDeletePlaylist() {
    if (toDeleteRef.current.length === episodeList.current.length && !currentPlaylist?.isDefault) {
      setShowDeletePlaylistComponent(false)
    } else {
      onUndoDeleteEpisodes();
    }
    setShowSnackBar(false);
    setToDelete([]);
  }

  async function handleOnDragEnd(reorderedList) {
    const items = Array.from(episodeList.current);
    const [reorderedItem] = items.splice(reorderedList.source.index, 1);
    items.splice(reorderedList.destination?.index, 0, reorderedItem);
    await setEpisodeList({...episodeList, current: items})
    updateOrder(items);
  }

  async function updateOrder(items) {
    await updatePlaylistEpisodesOrder({
      variables: {
        input: {
          playlistId: currentPlaylist.id,
          episodeIds: items.map(episode => episode.uid)
        },
      }
    })
  }
  function handleCheck(uid) {
    const updatedSelected = selectedPlaylistEpisodes.includes(uid) ? selectedPlaylistEpisodes.filter(c => c !== uid) : [...selectedPlaylistEpisodes, uid];
    updatedSelected.sort();
    const allEpisodeUids = episodeList.current.map(ep => ep.uid).sort();

    if (allEpisodeUids.length === updatedSelected.length) {
      setSelectAllChecked(true)
    } else {
      setSelectAllChecked(false);
    }

    setSelectedPlaylistEpisodes(updatedSelected);
  }

  useEffect(() => {
    if (selectedPlaylistEpisodes.length) {
      handleClickDelete(selectedPlaylistEpisodes);
    }
  }, [deleteItems])

  function handleSelectAll() {
    if (selectAllChecked) {
      setSelectAllChecked(false);
      setSelectedPlaylistEpisodes([])
    } else {
      setSelectAllChecked(true);
      setSelectedPlaylistEpisodes(episodeList.current.map(ep => ep.uid));
    }
  }

  async function handleDeletePlaylist() {
    setLoading(true);
    setShowDeletePlaylistComponent(false)

    if (!currentPlaylist.isDefault && currentPlaylist?.id) {
      await deletePlaylist({variables: {playlistId: currentPlaylist?.id}});
      await refetchPlaylists();
      setCurrentPlaylist(watchLaterPlaylist)
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <PlaylistsLoading />
    )
  }

  return (
    <>
      {showDeletePlaylistComponent
        ?
          <DeletePlaylistContainer>
            There are no episodes remaining in this playlist.
            Would you like to delete this playlist?
            <DeletePlaylistButtons>
              <ButtonPrimary onClick={() => handleDeletePlaylist()}>Delete Playlist</ButtonPrimary>
            </DeletePlaylistButtons>
          </DeletePlaylistContainer>
        :
          <>
            {edit &&
              <SelectAllRow handleSelectAll={handleSelectAll} selectAllChecked={selectAllChecked} isListItem={isMobile}  />
            }
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="list-items">
                {(provided) => (
                  <InfiniteScrollWrapper
                    dataLength={episodeList.current.length}
                    next={fetchNextPage}
                    hasMore={!showSnackBar && hasNextPage}
                    loader={<h4>Loading...</h4>}
                    className='MuiList-root MuiGrid-container'
                    scrollableTarget='dashboardScrollContainer'
                    style={{ overflow: 'unset' }}
                  >
                    <ListItems className='list-items' {...provided.droppableProps} ref={provided.innerRef}>
                      {episodeList.current.map((episode, index) => (
                        <Draggable
                          key={`item-${index}`}
                          draggableId={String(index)}
                          index={index}
                          isDragDisabled={edit}
                        >
                          {(provided) => (
                            <GridWrapper
                              key={index}
                              item
                              xs={12}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <EpisodeCard
                                display={isMobile ? EpisodeDisplayOptions.listItem : EpisodeDisplayOptions.horizontal}
                                episode={episode}
                                allowDelete={edit}
                                edit={edit}
                                draggable={!edit}
                                onDelete={() => handleClickDelete([episode.uid])}
                                watchLaterList={playlistName === WATCH_LATER}
                                excludedActions={[ACTION_WATCH_LATER, ACTION_FOLLOW_SHOW]}
                                playlist={currentPlaylist}
                                playlistIndex={index + 1}
                                checked={selectedPlaylistEpisodes.includes(episode.uid)}
                                onCheck={() => handleCheck(episode.uid)}
                              />
                            </GridWrapper>
                          )}
                        </Draggable>
                      ))}
                    </ListItems>
                    {provided.placeholder}
                  </InfiniteScrollWrapper>
                )}
              </Droppable>
            </DragDropContext>
          </>
      }
      {showSnackBar &&
        <UndoSnackBar
          episode={toDelete}
          onAllowTimeOut={handleAllowTimeout}
          onUndo={handleUndoDeletePlaylist}
          message='You removed an episode.'
        />
      }
    </>
  );
};

export default React.memo(PlaylistsBody);
