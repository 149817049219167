import React from 'react';
import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { ifProp, switchProp, theme } from 'styled-tools';
import { SocialContainer } from 'routes/TalentPage/components/SocialLinks';
import Button from 'components/Button';

export const Wrapper = styled.div`
  border: 0.0625rem solid ${theme('colors.borderGray')};
  height: 100%;
  min-height: 16rem;

  ${SocialContainer} {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 8rem;

  ${switchProp('$card', {
    leadership: css``,
    talent: css`
      max-height: 19.1875rem; // Normalizing images since leadership are bigger
    `,
  })}

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`;

export const Overlay = styled.div`
  background: rgba(255, 255, 255, 0) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(14px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding-top: 56.25%;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 1;
  width: 100%;

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

export const ImageOverlay = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: ${theme('colors.white')};
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;

  ${switchProp('$card', {
    leadership: css`
      ${switchProp('$cardsInRow', {
        'four plus': css`
          padding: 2rem 3.5rem;

          ${(props) => props.theme.breakpoints.up('lg')} {
            padding: 1.25rem 2.5rem;
          }
        `,
        'three under': css`
          padding: 2rem 3.5rem;
        `,
      })}
    `,
    talent: css`
      padding: 1.25rem 2.5rem;
    `,
  })}

  ${Wrapper}:hover & {
    display: none;
  }
`;

export const ImageOverlayHover = styled.div`
  background: rgba(0, 0, 0, 0.8) 0% 0% no-repeat padding-box;
  color: ${theme('colors.white')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);

  ${switchProp('$card', {
    leadership: css`
      ${switchProp('$cardsInRow', {
        'four plus': css`
          padding: 3.25rem 3rem 3.75rem 3rem;

          ${(props) => props.theme.breakpoints.up('lg')} {
            padding: 2.5rem 2.5rem 2rem 2.5rem;
          }
        `,
        'three under': css`
          padding: 3.25rem 3rem 3.75rem 3rem;
        `,
      })}
    `,
    talent: css`
      padding: 2.5rem 2.5rem 2rem 2.5rem;
    `,
  })}

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

export const Bio = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;

  ${switchProp('$card', {
    leadership: css`
      ${switchProp('$cardsInRow', {
        'four plus': css`
          -webkit-line-clamp: 8;

          ${(props) => props.theme.breakpoints.up('lg')} {
            -webkit-line-clamp: 4;
          }
        `,
        'three under': css`
          -webkit-line-clamp: 8;
        `,
      })}
    `,
    talent: css`
      -webkit-line-clamp: 4;

      ${(props) => props.theme.breakpoints.up('sm')} {
        -webkit-line-clamp: 3;
      }
    `,
  })}
`;

export const ButtonOverlay = styled(Box)`
  bottom: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 4;

  ${switchProp('$card', {
    leadership: css`
      ${switchProp('$cardsInRow', {
        'four plus': css`
          padding: 0 3rem 0.75rem 3rem;

          ${(props) => props.theme.breakpoints.up('lg')} {
            padding: 0 2.5rem 1rem 2.5rem;
          }
        `,
        'three under': css`
          padding: 0 3rem 3.75rem 3rem;
        `,
      })}
    `,
    talent: css`
      padding: 0 2.5rem 1rem 2.5rem;
    `,
  })}

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

export const Divider = styled.div`
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  margin: 1rem 0;
`;

export const FollowButton = styled(Button)`
  font-size: 0.875rem;
  padding: 0;

  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: 1rem;
  }

  ${switchProp('$card', {
    leadership: css`
      margin-bottom: 1.5rem;
    `,
    talent: css`
      margin-bottom: 1rem;
    `,
  })}
`;

export const Name = styled(Typography)`
  font-weight: 400;

  ${switchProp('$card', {
    leadership: css`
      ${ifProp(
        { $cardsInRow: 'four plus' },
        css`
          ${(props) => props.theme.breakpoints.up('lg')} {
            font-size: 2.25rem;
          }
        `
      )}
    `,
    talent: css`
      font-size: 1.5rem;
    `,
  })}
`;
