import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${ifProp({ edit: false }, css`
    padding-top: 0.875rem;
  `)}
  
  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const SectionTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom:  ${prop('mb')}rem;
  align-self: center;
  
  ${ifProp({ capitalize: true }, css`
    text-transform: uppercase;
  `)}
`;

const SectionActions = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 1rem 0;
  }
`;

DashboardPageHeader.propTypes = {
  edit: PropTypes.bool,
  children: PropTypes.node,
  mb: PropTypes.number,
  actions: PropTypes.node,
  capitalize: PropTypes.bool,
};

function DashboardPageHeader({ edit, children, mb = 2.5, actions, capitalize = true }) {

  return (
    <SectionHeader edit={false}> {/* When we add the select all / delete all toolbar, replace <false> with <edit> prop */}
      <SectionTitle variant='h4' mb={mb} capitalize={capitalize}>
        {children}
      </SectionTitle>
      {actions && <SectionActions>{actions}</SectionActions>}
    </SectionHeader>
  );
};

export default React.memo(DashboardPageHeader);
