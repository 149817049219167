import React from 'react';
import showShape from 'shapes/showShape';
import styled from 'styled-components';
import FollowShowActionIcon from 'components/ActionIcons/FollowShow';
import { theme } from 'styled-tools';

const FollowShowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.875rem;

  svg {
    margin-left: 0.25rem;
    width: 1.6875rem;
    height: 1.6875rem;
    color: ${theme('colors.white')};;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`

const FollowShowTextWrapper = styled.span`
  display: none;

  ${FollowShowWrapper}:hover & {
    display: block;
  }
`;

FollowShowToggle.propTypes = {
  show: showShape.isRequired,
};

function FollowShowToggle({ show }) {
  const text = show.isFollowing ? 'Unfollow' : 'Follow';

  return (
    <FollowShowWrapper>
      <FollowShowTextWrapper>{text}</FollowShowTextWrapper>
      <FollowShowActionIcon show={show} />
    </FollowShowWrapper>
  );
}

export default React.memo(FollowShowToggle);
