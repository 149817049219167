import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import fullBleedCtaBannerShape from 'shapes/components/fullBleedCtaBannerShape';
import VisibilityWrapper from 'components/VisibilityWrapper'
import { flattenBlockStyle } from 'utils/blockStyleUtils';
import { autoOptimizeImage } from 'utils/functions';
import BannerContent from './BannerContent';

const BannerContainer = styled.div`
  padding: 0;
  background-color: ${prop('$bgColor')};
  background-image: url("${prop('$mobileUrl')}");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;

  ${ifProp({$bgSizingMobile: 'Stay'}, css`
    background-size: cover;
  `)}

  ${props => props.theme.breakpoints.up('md')} {
    background-image: linear-gradient(89deg, #000, transparent), url("${prop('$url')}");
    background-position: right;
    background-size: cover;
    background-color: ${theme('colors.black')};

    ${ifProp({$bgSizing: 'Stay'}, css`
      background-size: cover;
      background-position: center;
    `)}
  }

  ${ifProp('$paddingBottom', css`
    padding-bottom: ${prop('$paddingBottom')}rem;
  `)}

  ${ifProp('$paddingTop', css`
    padding-top: ${prop('$paddingTop')}rem;
  `)}
`;

const Banner = styled.div`
  display: flex;
  padding: 3rem;
  background-color: ${prop('$bgColor')}${prop('$opacityOverlay')};
  height: 100%;
  min-height: 42rem;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 4.75rem;
    min-height: 50rem;

    ${ifProp({$bgSizingMobile: 'Stay'}, css`
      min-height: auto;
    `)}
  }

  ${props => props.theme.breakpoints.up('md')} {
    align-items: flex-end;
    min-height: ${prop('$tabletBannerHeight')};
    padding: 4rem;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    min-height: ${prop('$bannerHeight')};
    padding: 8rem 8.75rem;
  }

  ${ifProp('$marginBottom', css`
    margin-bottom: ${prop('$marginBottom')}rem;
  `)}

  ${ifProp('$marginTop', css`
    margin-top: ${prop('$marginTop')}rem;
  `)}

  ${ifProp('$jumbotron', css`
    min-height: 50rem;
  `)}

  &.image-only {
    ${props => props.theme.breakpoints.down('sm')} {
      min-height: 24rem;
    }

    @media (max-width: ${theme('breakpoints.custom.mobileLg')}) {
      min-height: 14rem;
    }

    // custom css classes to allow easy mockup matching
    &.btn-mt-sm-12r {
      display: none;

      &.MuiButtonBase-root {
        margin-top: 12rem;
      }
    }
  }
`;

const OuterContainer = styled.div`
  min-height: 672px;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 800px;
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-height: ${prop('$tabletBannerHeight')};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    min-height: ${prop('$bannerHeight')};
  }
`;

FullBleedCtaBanner.propTypes = {
  data: fullBleedCtaBannerShape,
  className: PropTypes.string,
};

function FullBleedCtaBanner({ className, data, ...props }) {
  const blockData = data.blockReference;
  const { additionalOptions, backgroundImage, modularBlocks, additionalStyles } = blockData;
  const { blockStyle } = additionalStyles || {};
  const {
    backgroundColor,
    backgroundImage: bgImage,
    backgroundImageSizing,
    backgroundImageMobile: bgImageMobile,
    backgroundImageSizingMobile,
    overlayOpacity
  } = backgroundImage;
  const { cssClassName, marginBottom, marginTop, paddingBottom, paddingTop } = flattenBlockStyle(blockStyle);
  const { isJumbotron, minimumHeight, desktopBannerHeight, campaignId } = additionalOptions;
  const opacity = overlayOpacity ? (overlayOpacity < 10 ? '0' + overlayOpacity : overlayOpacity) : '00';
  const hideBlock = data.hideIfLoggedIn || blockData.hideIfLoggedIn;

  if (!modularBlocks) {
    return null;
  }

  const baseBannerHeight = desktopBannerHeight ? desktopBannerHeight : '350px';
  const bannerHeight = (minimumHeight && minimumHeight != '0') ? minimumHeight : baseBannerHeight;
  const tabletBannerHeight = (minimumHeight && minimumHeight != '0') ? minimumHeight : `calc(${bannerHeight} - 144px)`

  return (
    <VisibilityWrapper visibility={hideBlock ? 'hide_if_logged_in' : 'always_visible'}>
      <OuterContainer
        $tabletBannerHeight={tabletBannerHeight}
        $bannerHeight={bannerHeight}
      >
        <BannerContainer
          className={className}
          {...props}
          $bgColor={backgroundColor}
          $url={bgImage ? autoOptimizeImage(bgImage.url) : null}
          $mobileUrl={bgImageMobile ? autoOptimizeImage(bgImageMobile.url) : null}
          $bgSizing={backgroundImageSizing}
          $bgSizingMobile={backgroundImageSizingMobile}
          $height={minimumHeight}
        >
          <Banner
            className={cssClassName}
            $bgColor={backgroundColor}
            $minimumHeight={minimumHeight}
            $marginTop={marginTop}
            $marginBottom={marginBottom}
            $paddingTop={paddingTop}
            $paddingBottom={paddingBottom}
            $jumbotron={isJumbotron}
            $opacityOverlay={opacity}
            $bgColor={backgroundColor ?? '#000000'}
            $bgSizing={backgroundImageSizing}
            $bgSizingMobile={backgroundImageSizingMobile}
            $bannerHeight={bannerHeight}
            $tabletBannerHeight={tabletBannerHeight}
          >
            {modularBlocks.length > 0 && (
              <BannerContent blocks={modularBlocks} campaignId={campaignId} />
            )}
          </Banner>
        </BannerContainer>
      </OuterContainer>
    </VisibilityWrapper>
  )
}

export default FullBleedCtaBanner;
