import React, { Fragment } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import RichTextField from 'components/RichTextField';
import { COLUMN_SPAN_OPTS } from 'constants/columnSpanMap';
import { autoOptimizeImage, imageVsWindowWidth } from 'utils/functions';
import { blocksShape } from 'shapes/components/fullBleedCtaBannerShape';
import Hidden from '@material-ui/core/Hidden';
import CtaButton from 'components/CtaButton';
import { dataLayerPush } from 'utils/dataLayer';

const BannerBlocks = styled.div`
  display: flex;
  width: 100%;
`;

const CommentaryContainer = styled.div`
  color: ${theme('colors.white')};
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;

  ${switchProp('$color', {
    'black': css`
      color: ${theme('colors.black')};
    `,
    'white': css`
      color: ${theme('colors.white')};
    `,
    'red': css`
      color: ${theme('colors.ttRed')};
    `,
    'gray': css`
      color: ${theme('colors.mutedGray')};
    `
  })}

  ${switchProp('$span', {
    [COLUMN_SPAN_OPTS.twoFifths]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 40%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.halfWidth]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 50%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.threeFifths]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 60%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.oneThird]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 33.334%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.twoThirds]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 66.667%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.threeFourth]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 75%;
        width: 100%;
      }
    `,
    [COLUMN_SPAN_OPTS.fullWidth]: css`
      ${props => props.theme.breakpoints.up('md')} {
        max-width: 100%;
        width: 100%;
      }
    `,
  })}

  ${switchProp('$alignment', {
    'center': css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `,
    'right': css`
      text-align: right;
      margin-left: auto;
    `,
  })}

  ${switchProp('$imagePosition', {
    'left': css`
      flex-direction: row-reverse;
    `,
    'right': css`
      flex-direction: row;
    `,
    'top': css`
      flex-direction: column;
    `,
  })}

  h1, h2, h3, h4, h5 {
    line-height: 1em;
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiBox-root {
    margin-bottom: 1.75rem;
    margin-top: 0;

    ${props => props.theme.breakpoints.down('md')} {
      margin-bottom: 1.25rem;
    }
  }
`;

const Header = styled.div`
  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }

  h2 {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
  }

  .condensed-font {
    font-family: ${theme('typography.fontFamilySecondary')};
  }
`;

const Subheader = styled.div`
  margin-bottom: 1.75rem;

  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: 1.25rem;
  }

  .condensed-font {
    font-family: ${theme('typography.fontFamilySecondary')};
  }
`;

const Description = styled.div`
  margin-bottom: 1.75rem;

  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: 1.25rem;
  }
`;

const ButtonContainer = styled.div`
  a,
  button {
    font-size: 0.8125rem;
    margin-right: .5rem;

    &:first-child {
      margin-bottom: 0.75rem;

      ${props => props.theme.breakpoints.up('md')} {
        margin-bottom: 0;
      }
    }
  }
`;

const CommentaryWrapper = styled.div`
  ${switchProp('$imagePosition', {
    'left': css`
      margin-bottom: 1rem;
      padding-left: 1rem;
    `,
    'right': css`
      margin-bottom: 1rem;
      padding-right: 1rem;
    `,
  })}
`;

const FeaturedContainer = styled.div`
  ${switchProp('$imagePosition', {
    'top': css`
      margin-bottom: 1rem;
    `,
  })}
`;

const FeatureImage = styled.img`
  width: 100%;

  ${ifProp('$height', css`
    height: ${prop('$height')}px;
  `)}

  ${ifProp('$width', css`
    width: ${prop('$width')}px;
  `)}

  ${ifProp('$fullWidth', css`
    height: auto;
    width: 100%;
  `)}

  ${ifProp('$hasMobileImage', css`
    ${props => props.theme.breakpoints.down('xs')} {
      display: none;
    }
  `)}
`;

BannerContent.propTypes = {
  blocks: PropTypes.arrayOf(blocksShape),
  campaignId: String
};

function BannerContent({ blocks, campaignId }) {
  const commentary = blocks.find(block => block.ctaCommentary);
  const featured = blocks.find(block => block.featureImage);
  const imagePosition = get(featured, 'featureImage.featureImage.imagePosition');
  const imageUrl = get(featured, 'featureImage.featureImage.featureImage.url');
  const mobileImageUrl = get(featured, 'featureImage.featureImage.featureImageMobile.url', true);
  const imageHeight = get(featured, 'featureImage.featureImage.imageHeight');
  const imageWidth = get(featured, 'featureImage.featureImage.imageWidth', 'auto');

  const optimizeWithDimensions = (imageUrl) => {
    let optimizedImage = autoOptimizeImage(imageUrl);

    if (imageHeight && imageWidth) {
      return optimizedImage + `&height=${imageHeight}&width=${imageWidth}`;
    } else if (imageHeight) {
      return optimizedImage + `&height=${imageHeight}`;
    } else if (imageWidth) {
      return optimizedImage + `&width=${imageWidth}`;
    }

    return optimizedImage;
  }

  return (
    <CommentaryContainer
      $color={get(commentary, 'ctaCommentary.selectTextColor')}
      $span={get(commentary, 'ctaCommentary.contentSpan')}
      $alignment={get(commentary, 'ctaCommentary.contentAlignment')}
      $imagePosition={imagePosition}
    >
      {commentary && (
        <CommentaryWrapper $imagePosition={imagePosition}>
          {get(commentary, 'ctaCommentary.ctaHeader') && (
            <Header>
              <RichTextField richText={commentary.ctaCommentary.ctaHeader} />
            </Header>
          )}
          {get(commentary, 'ctaCommentary.ctaSubheader') && (
            <Subheader>
              <RichTextField richText={commentary.ctaCommentary.ctaSubheader} />
            </Subheader>
          )}
          {get(commentary, 'ctaCommentary.ctaDescription') && (
            <Description>
              <RichTextField richText={commentary.ctaCommentary.ctaDescription} />
            </Description>
          )}
          {commentary?.ctaCommentary?.ctaButtons.length >= 1 && (
            <ButtonContainer>
              {commentary.ctaCommentary.ctaButtons.map((button, index) => (
                <div onClick={() => {
                  if (campaignId) {
                    dataLayerPush(campaignId, 'campaign_id')}
                  }
                }>
                  <CtaButton
                    data={button}
                    key={`button-${index}`}
                    icon={button.buttonIcon}
                    iconColor={commentary.ctaCommentary.selectTextColor}
                  />
                </div>
              ))}
            </ButtonContainer>
          )}
        </CommentaryWrapper>
      )}
      {featured && (
        <FeaturedContainer $imagePosition={imagePosition}>
          <FeatureImage
            alt={get(featured, 'featureImage.featureImage.altImageText')}
            $fullWidth={imageVsWindowWidth(imageWidth)}
            $height={imageHeight}
            $hasMobileImage={mobileImageUrl}
            src={optimizeWithDimensions(imageUrl)}
            $width={imageWidth}
          />
          {mobileImageUrl && (
            <Hidden smUp implementation='css'>
              <FeatureImage
                alt={get(featured, 'featureImage.featureImage.altImageText')}
                $fullWidth={imageVsWindowWidth(imageWidth)}
                $height={imageHeight}
                src={optimizeWithDimensions(imageUrl)}
                $width={imageWidth}
              />
            </Hidden>
          )}
        </FeaturedContainer>
      )}
    </CommentaryContainer>
  )
}

export default BannerContent;
