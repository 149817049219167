import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop, theme, switchProp } from 'styled-tools';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import twoBlockContentShape from 'shapes/components/twoBlockContentShape';
import { autoOptimizeImage } from 'utils/functions';
import Block from './Block';
import { flattenBlockStyle } from 'utils/blockStyleUtils';

const BlockContainer = styled(Container)`
  max-width: 1380px;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  border-radius: 1rem;
  
  ${props => props.theme.breakpoints.up('md')} {
     min-height: 16rem;
  }
   
  ${props => props.theme.breakpoints.down('sm')} {
     min-height: 12rem;
  }

  ${props => props.theme.breakpoints.up('xl')} {
    max-width: 1380px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    h4 {
      font-size: 1.5rem;
    }
  }

  ${ifProp('$fullWidth', css`
    ${props => props.theme.breakpoints.up('sm')} {
      max-width: 100%;
      padding-left: 4rem;
      padding-right: 4rem;
    }

   ${props => props.theme.breakpoints.up('md')} {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      padding-left: 5.75rem;
      padding-right: 5.75rem;
    }
  `)}

  ${ifProp({page: 'trader-resources'}, css`
    background-color: ${prop('$backgroundColor')};
    padding-left: 0;
    padding-right: 0;

    ${props => props.theme.breakpoints.up('md')} {
      padding-left: 1rem;
      padding-right: 4rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      max-width: 100%;
      padding-left: 3.75rem;
      padding-right: 8.75rem;
    }

    @media (min-width: 1820px) {
      padding-left: 6.75rem;
      padding-right: 10.75rem;
    }
  `)}

  ${ifProp('$marginTop', css`
    margin-top: ${prop('$marginTop')}rem;
  `)}

  ${ifProp('$marginBottom', css`
    margin-bottom: ${prop('$marginBottom')}rem;
  `)}

  ${ifProp('$marginRight', css`
    margin-right: ${prop('$marginRight')}rem;
  `)}

  ${ifProp('$marginLeft', css`
    margin-left: ${prop('$marginLeft')}rem;
  `)}

  ${ifProp('$paddingTop', css`
    padding-top: ${prop('$paddingTop')}rem;
  `)}

  ${ifProp('$paddingBottom', css`
    padding-bottom: ${prop('$paddingBottom')}rem;
  `)}

  ${ifProp('$paddingRight', css`
    padding-right: ${prop('$paddingRight')}rem;
  `)}

  ${ifProp('$paddingLeft', css`
    padding-left: ${prop('$paddingLeft')}rem;
  `)}

  ${ifProp('$backgroundColor', css`
    background-color: $backgroundColor;
  `)}
`;

const GridContainer = styled(Grid)`
  border-radius: 1rem;

  ${ifProp('$border', css`
    border: 0.0625rem solid ${theme('colors.borderGray')};
  `)}

  ${ifProp('$boxShadow', css`
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  `)}

  ${ifProp('$flipOnCollapse', css`
    ${props => props.theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;
    }
  `)}

  ${ifProp('$backgroundColor', css`
     background-color: ${prop('$backgroundColor')};
  `)}

  ${ifProp('$maskContent', css`
    overflow: hidden;
  `)}
`;

const GridCol = styled(Grid)`
  align-items: center;
  display: flex;
  width: 100%;
  border-radius: 1rem;

  ${ifNotProp('$url', css`
    align-items: flex-start;
    padding: 1.5rem;

    ${props => props.theme.breakpoints.up('md')} {
      padding: 3rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      padding: 5rem;
    }
  `)}

  ${ifProp('$backgroundColor', css`
    background-color: ${prop('$backgroundColor')};

    ${ifProp({page: 'trader-resources'}, css`
      ${props => props.theme.breakpoints.down('sm')} {
        background-color: ${theme('colors.white')};
      }
    `)}
  `)}

  ${switchProp('$textColor', {
    Black: css`
      color: ${theme('colors.black')};
    `,
    Red: css`
      color: ${theme('colors.ttRed')};
    `,
    White: css`
      color: ${theme('colors.white')};
    `,
    Gray: css`
      color: ${theme('colors.gray')};
    `
  })}

  ${switchProp('$maskDirection', {
    top: css`
      background-color: transparent;
      margin-bottom: -${prop('$maskPercent')}%;
    `,
    bottom: css`
      background-color: transparent;
      margin-top: -${prop('$maskPercent')}%;
    `,
    both: css`
      margin-bottom: -${prop('$maskPercent')}%;
      margin-top: -${prop('$maskPercent')}%;
    `,
  })}

  ${ifProp({$overflowDirection: 'both'}, css`
    background-color: transparent;
    margin-bottom: -${prop('$maskPercent')}%;
    margin-top: -${prop('$maskPercent')}%;
  `)}

  ${ifProp({ sm: 6 }, css`
    &:last-child {
      ${ifProp('$border', css`
        border-left: 0.0625rem solid ${theme('colors.borderGray')};
      `)}
    }
  `)}

  .MuiBox-root {
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: 0.875rem;
    }
  }
`;

const BlockImage = styled.img`
  position: absolute;
  top: 0;
  object-fit: contain;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0;
  }

  ${switchProp('$objectFit', {
    cover: css`
      height: 100%;
      object-fit: cover;
      width: 100%;
    `,
    contain: css`
      height: 100%;
      object-fit: contain;
      width: 100%;
    `,
    overflow: css`
      object-fit: contain;
      width: 100%;

      ${ifProp({$overflowDirection: 'top'}, css`
        margin-top: -${prop('$overflowMargin')}%;
        object-position: ${ifProp({ $order: 'first' }, 'top right', 'top left')};
      `)}

      ${ifProp({$overflowDirection: 'bottom'}, css`
        margin-bottom: -${prop('$overflowMargin')}%;
        object-position: ${ifProp({ $order: 'first' }, 'bottom right', 'bottom left')};
      `)}

      ${ifProp({$overflowDirection: 'both'}, css`
        margin-bottom: -${prop('$overflowMargin')}%;
        margin-top: -${prop('$overflowMargin')}%;
        object-position: center;
      `)}
    `,
  })}

  ${switchProp('$maskDirection', {
    top: css`
      margin-bottom: -${prop('$overflowMargin')}%;
      margin-top: -${prop('$overflowMargin')}%;
      object-fit: cover;
      object-position: ${ifProp({ $order: 'first' }, 'bottom right', 'bottom left')};
    `,
    bottom: css`
      margin-bottom: -${prop('$overflowMargin')}%;
      margin-top: -${prop('$overflowMargin')}%;
      object-fit: cover;
      object-position: ${ifProp({ $order: 'first' }, 'top right', 'top left')};
    `,
    both: css`
      margin-bottom: -${prop('$overflowMargin')}%;
      margin-top: -${prop('$overflowMargin')}%;
      object-fit: cover;
      object-position: center;
    `,
  })}
`;

const SkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlockImageWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 72.12%;
`;

const StyledSkeleton = styled(Skeleton)`
  position: absolute;
`;

TwoBlockContent.propTypes = {
  data: twoBlockContentShape.isRequired,
  className: PropTypes.string,
};

function TwoBlockContent({ data, className, ...props }) {
  const baseBlockStyle = data.blockReference?.blockStyle?.[0];
  const {
    applyBorder,
    applyBoxShadow,
    additionalStyles,
    singleColumnDetails,
  } = get(data, 'blockReference', {});
  const { blockStyle } = additionalStyles || {};

  const {
    flipOrientation,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    backgroundColor
  } = flattenBlockStyle(blockStyle);
  const fullWidth = baseBlockStyle?.width === 'full';
  const colCount = 12 / singleColumnDetails.length;
  const maskContent = singleColumnDetails.some(el => el.imageAsset.maskDirection === 'both');

  return (
    <BlockContainer
      className={className}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $marginRight={marginRight}
      $marginLeft={marginLeft}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $paddingRight={paddingRight}
      $paddingLeft={paddingLeft}
      $backgroundColor={backgroundColor}
      $fullWidth={fullWidth}
      {...props}
    >
      <GridContainer
        container
        $border={applyBorder}
        $boxShadow={applyBoxShadow}
        $flipOnCollapse={flipOrientation}
        $backgroundColor={backgroundColor}
        $maskContent={maskContent}
      >
        {singleColumnDetails.map((column, index ) => {
          const imageUrl = get(column, 'imageAsset.imageAsset.url');
          const altImageText = get(column, 'imageAsset.altImageText');
          const overflowPercent = get(column, 'imageAsset.overflowPercent');
          const overflowDirection = get(column, 'imageAsset.overflowDirection');
          const overflowMargin = overflowPercent ? overflowPercent - 1 : 0;
          const maskDirection = get(column, 'imageAsset.maskDirection');
          const maskPercent = overflowPercent / 3.5;

          const mobileImage = imageUrl + '?format=pjpg&auto=webp&quality=50&width=500&disable=upscale';
          const desktopImage = imageUrl + '?format=pjpg&auto=webp&quality=50&disable=upscale';

          const imgSrcSet = `${mobileImage} 600w, ${desktopImage} 1400w`;

          return (
            <GridCol
              item
              xs={12}
              sm={12}
              md={colCount}
              $border={applyBorder}
              key={`block-${index}`}
              $url={imageUrl}
              $backgroundColor={column.backgroundColor}
              $textColor={column.selectTextColor}
              $overflowDirection={overflowDirection}
              $maskDirection={maskDirection}
              $maskPercent={maskPercent}
              {...props}
            >
              {imageUrl && (
                <BlockImageWrapper>
                  <LazyLoad
                    offset={300}
                    height='100%'
                    width='100%'
                    placeholder={
                      <SkeletonWrapper>
                        <StyledSkeleton variant='rect' />
                      </SkeletonWrapper>
                    }
                  >
                    <BlockImage
                      $aspectRatio={get(column, 'imageAsset.aspectRatio')}
                      $objectFit={get(column, 'imageAsset.objectFit')}
                      $overflowDirection={overflowDirection}
                      $overflowMargin={overflowMargin}
                      $maskDirection={maskDirection}
                      $order={index === 0 ? 'first' : 'second'}
                      alt={altImageText}
                      srcSet={imgSrcSet}
                      src={!imgSrcSet ? desktopHero : ''}
                      sizes='(max-width: 600px) 50vw, (max-width: 1400px) 33vw'
                    />
                  </LazyLoad>
                </BlockImageWrapper>
              )}
              <Block data={column} url={autoOptimizeImage(imageUrl, true)} />
            </GridCol>
          );
        })}
      </GridContainer>
    </BlockContainer>
  );
}

export default React.memo(TwoBlockContent);
