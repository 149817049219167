import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
};

function DateFormatter({ date, format = 'MMM D, YYYY' }) {
  return dayjs(date).format(format);
}

export default React.memo(DateFormatter);
