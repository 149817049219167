import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'context/AuthContext';

VisibilityWrapper.propTypes = {
  visibility: PropTypes.oneOf(['always_visible', 'show_if_logged_in', 'hide_if_logged_in']),
  children: PropTypes.node,
  displayCallback: PropTypes.func,
};

function VisibilityWrapper({ visibility, children, displayCallback }) {
  const { user } = useAuth();

  const authenticated = !!user;

  if (displayCallback && !displayCallback()) {
    return null;
  }

  if (visibility === 'show_if_logged_in' && !authenticated) {
    return null;
  }

  if (visibility === 'hide_if_logged_in' && authenticated) {
    return null;
  }

  return (
    <>{children}</>
  );
}

export default VisibilityWrapper;
