import get from 'lodash.get';
import { transparentize, lighten } from 'polished';
import placeholderImg from 'assets/images/img-placeholder.png';
import { DEFAULT_GRADIENT_COLOR, showImageSizes } from 'utils/constants';
import { dataLayerPush } from 'utils/dataLayer';

export function getShowThumbnailUrl(show, portrait = false, size) {
  const thumbnail = getShowThumbnail(show, portrait);
  const optimizedThumbnail = getOptimizedImage(thumbnail, size, showImageSizes);

  return optimizedThumbnail;
}

export function getShowThumbnail(show, portrait = false) {
  const { image, portraitImage } = show || {};

  return portrait ? portraitImage || image : image || portraitImage;
}

export function getOptimizedImage(thumbnail, size, imageSizes) {
  const url = (thumbnail && thumbnail.url) || placeholderImg;

  if (imageSizes) {
    return `${url}?width=${imageSizes[size].width}&height=${imageSizes[size].height}&format=pjpg&auto=webp`;
  }

  return url;
}

export function makeOverlayGradient(
  showOverlayColor,
  startPercent = '72%',
  endPercent = '-70%',
  angle = '244deg'
) {
  const baseColor = showOverlayColor || DEFAULT_GRADIENT_COLOR;
  const endColor = transparentize(1, lighten(0.2, baseColor));
  const startColor = transparentize(0.08, baseColor);

  return `linear-gradient(${angle}, ${endColor} ${endPercent}, ${startColor} ${startPercent})`;
}

export function trackFilters(tempShowFilters) {
  if (get(tempShowFilters, 'quickFilters').length > 0) {
    dataLayerPush('Filtered On Demand page by Product');
  } else if (get(tempShowFilters, 'topicFilters').length > 0) {
    dataLayerPush('Filtered On Demand page by Topic');
  } else if (get(tempShowFilters, 'formatFilters').length > 0) {
    dataLayerPush('Filtered On Demand page by Format');
  }
}
