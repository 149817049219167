import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MegaMenuGroupHeader from '../MegaMenuGroupHeader';
import DateFormatter from 'components/DateFormatter';
import { autoOptimizeImage } from 'utils/functions';
import MegaMenuImageLink from '../MegaMenuImageLink';
import MegaMenuImage from '../MegaMenuImage';
import MegaMenuDate from '../MegaMenuDate';
import {
  MegaMenuSnippetLink,
  MegaMenuSnippet,
} from 'components/Header/components/MegaMenu';
import Icon from 'components/Icon';
import MegaMenuImageWrapper from '../MegaMenuImageWrapper';

const FeaturedWrapper = styled.div`
  height: 100%;
`;

const ExploreLink = styled(MegaMenuGroupHeader)`
  text-transform: uppercase;
`;

FeaturedComponent.propTypes = {
  exploreMore: PropTypes.string,
  featuredDate: PropTypes.string,
  featuredItem: PropTypes.object,
  featuredSnippet: PropTypes.string,
  featuredImage: PropTypes.string,
  menuOpen: PropTypes.bool,
  featuredTalentTitle: PropTypes.string,
  featuredTitle: PropTypes.string,
  megaMenuClick: PropTypes.func.isRequired,
  subItem: PropTypes.object,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.func,
  traderResourcesTourAction: PropTypes.bool,
};

function FeaturedComponent({
  exploreMore = '',
  featuredDate = '',
  featuredItem = {},
  featuredSnippet = '',
  featuredImage = '',
  featuredTalentTitle = '',
  featuredTitle = '',
  megaMenuClick,
  menuOpen,
  subItem = {},
  tourHighlighted,
  traderResourcesTourMenuClick,
  traderResourcesTourAction,
}) {
  const isTalent = get(featuredItem, '__typename') === 'Talent';

  return (
    <>
      <FeaturedWrapper>
        {menuOpen && (
          <MegaMenuImageLink
            as={Link}
            to={featuredItem.url}
            onClick={
              tourHighlighted ? traderResourcesTourMenuClick : megaMenuClick
            }
            $tourDisabled={traderResourcesTourAction && !tourHighlighted}
          >
            {featuredImage ? (
              <MegaMenuImageWrapper>
                <MegaMenuImage
                  alt={featuredTitle ?? 'uploaded image'}
                  src={
                    autoOptimizeImage(featuredImage) +
                    `&width=${isTalent ? 80 : 500}`
                  }
                  $type={subItem.menuFeatured?.featuredType}
                  $trader={isTalent}
                />
              </MegaMenuImageWrapper>
            ) : (
              <AccountCircleIcon className='avatar-icon' />
            )}
          </MegaMenuImageLink>
        )}
        <MegaMenuGroupHeader
          as={Link}
          to={featuredItem.url}
          onClick={megaMenuClick}
        >
          {featuredTitle}
        </MegaMenuGroupHeader>
        {featuredTalentTitle && (
          <MegaMenuGroupHeader
            as={Link}
            to={featuredItem?.url}
            onClick={megaMenuClick}
            $subheader
          >
            {featuredTalentTitle}
          </MegaMenuGroupHeader>
        )}
        {featuredDate && (
          <MegaMenuDate>
            <DateFormatter date={featuredDate} />
          </MegaMenuDate>
        )}
        {featuredSnippet && (
          <MegaMenuSnippetLink
            as={Link}
            to={featuredItem.url}
            onClick={
              tourHighlighted ? traderResourcesTourMenuClick : megaMenuClick
            }
            $tourDisabled={traderResourcesTourAction && !tourHighlighted}
          >
            <MegaMenuSnippet
              richText={featuredSnippet}
              $tourDisabled={traderResourcesTourAction && !tourHighlighted}
            />
          </MegaMenuSnippetLink>
        )}
      </FeaturedWrapper>
    </>
  );
}

export default FeaturedComponent;
