import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

export const CloseIconWrapper = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: transform 0.25s;

  &:hover,
  &:focus {
    background-color: ${theme('colors.lightGray')};

    svg {
      fill: ${theme('colors.ttRed')};
    }
  }
}
`;

export default CloseIconWrapper;
