import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { DetailsLink } from '../DetailsLink';
import DateFormatter from 'components/DateFormatter';
import EpisodeCardWrapper from 'components/EpisodeCard/EpisodeCardWrapper';
import { dataLayerPush } from 'utils/dataLayer';
import AbsoluteOverlay from '../../AbsoluteOverlay';

const EpisodeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderField = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  line-height: 1.31;

  &.upcoming {
    color: ${theme('colors.upcomingPlaceholderText')};
  }
`;

const ShowTitle = styled(HeaderField)`
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;

  ${EpisodeCardWrapper}:hover & {
    color: ${theme('colors.black')};

    &.upcoming {
      color: ${theme('colors.upcomingPlaceholderText')};
    }
  }
`;

const EpisodeTitle = styled(HeaderField)`
  font-size: 1rem;
  font-weight: 600;
  margin: 0.25rem 0;

  ${EpisodeCardWrapper}:hover &:not(.upcoming) {
    color: ${theme('colors.black')};

    &.upcoming {
      color: ${theme('colors.upcomingPlaceholderText')};
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;

const EpisodeDate = styled(HeaderField)`
  font-size: 0.8rem;
  font-weight: 400;
`;

EpisodeDetails.propTypes = {
  episodeUrl: PropTypes.string,
  displayTitle: PropTypes.string,
  publishedAt: PropTypes.string,
  recommendation: PropTypes.string,
  show: PropTypes.object,
  upcomingPlaceholder: PropTypes.bool,
  edit: PropTypes.bool
};

function EpisodeDetails({ episodeUrl, displayTitle, publishedAt, recommendation, show, upcomingPlaceholder, edit }) {
  const clickLink = () => {
    dataLayerPush(`${recommendation} Episode clicked`);
  }

  const episodeTitleAndDate = (
    <>
      <EpisodeTitle className={upcomingPlaceholder && 'upcoming'}>{displayTitle}</EpisodeTitle>
      <EpisodeDate className={upcomingPlaceholder && 'upcoming'}>
        <DateFormatter date={publishedAt} format='MMM DD, YYYY' />
      </EpisodeDate>
    </>
  );

  return (
    <EpisodeWrapper>
      <TitleContainer>
        {show &&
          <DetailsLink to={show.url} onClick={clickLink}>
            <ShowTitle variant='h4' className={upcomingPlaceholder && 'upcoming'}>{get(show, 'title')}</ShowTitle>
          </DetailsLink>
        }
        {episodeUrl ? (
          <DetailsLink to={episodeUrl}>
            {episodeTitleAndDate}
          </DetailsLink>
        ) : episodeTitleAndDate}
      </TitleContainer>
      {edit &&
        <AbsoluteOverlay />
      }
    </EpisodeWrapper>
  );
}

export default React.memo(EpisodeDetails);
