import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDashboardContext } from 'context/DashboardContext';
import styled, { css } from 'styled-components';
import { theme, ifProp, switchProp } from 'styled-tools';
import CloseIcon from '@material-ui/icons/Close';

const FirstTimeHelperDialog = styled.div`
  background-color: ${theme('colors.ttRed')};
  color: ${theme('colors.white')};
  position: absolute;
  right: 0;
  z-index: 1302;
  width: 0;
  height 0;
  transition: all .3s ease;
  border-radius: 1rem;
  
  ${switchProp('$direction', {
    up: css`
      margin-top: 1rem;
    `,
    down: css`
      margin-bottom: 5rem;
      bottom: 0;
    `,
  })}
  
  p {
    overflow: hidden;
    font-size: 1rem;
    font-weight: 400;
    transition: all .3s ease;
    display: none;
  }
  
  &:after {
    content: "";
    position: absolute;
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    border-width: 0.625rem;
    border-style: solid;
    display: none;
    
    ${switchProp('$direction', {
      up: css`
        border-color: transparent ${theme('colors.ttRed')} ${theme('colors.ttRed')} transparent;
        top: -0.625rem;
      `,
      down: css`
        border-color: ${theme('colors.ttRed')} transparent transparent ${theme('colors.ttRed')};
        bottom: -0.625rem;
      `,
    })}
    
    ${switchProp('$position', {
      left: css`
        left: 3.25rem;
      `,
      center: css`
        left: 9.375rem;
      `,
      right: css`
        right: 3.25rem;
      `
    })}
  }
    
  ${ifProp('$firstTimeHelper', css`
    padding: 1.5rem;
    width: 18.75rem;
    height: auto;
    
    p {
      display: block;
    }
    
    &:after {
      display: block;
    }
  `)};
  
  ${ifProp('$dashboardIsOpen', css`
    z-index: 1;
  `)}
`

const HelperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  
  & svg {
    cursor: pointer;
  }
`;

const HelperHeaderText = styled.h3`
  margin: 0;
`;

FirstTimeDashboardHelper.propTypes = {
  arrowDirection: PropTypes.oneOf(['up', 'down']),
  arrowPosition: PropTypes.oneOf(['left', 'center', 'right'])
};

function FirstTimeDashboardHelper({arrowDirection = 'up', arrowPosition = 'right'}) {
  const { state: dashboardState, setFirstTimeHelper } = useDashboardContext();
  const { showFirstTimeHelper } = dashboardState;
  const timerRef = useRef(null)

  useEffect(() => {
    clearTimeout(timerRef.current);

    if (showFirstTimeHelper) {
      timerRef.current = setTimeout(() => {
        setFirstTimeHelper(false);
      }, 6000);
    }

  }, [showFirstTimeHelper]);

  function handleClickClose(e) {
    e.stopPropagation();
    setFirstTimeHelper(false);
  }

  return (
      <FirstTimeHelperDialog
        $firstTimeHelper={showFirstTimeHelper}
        $direction={arrowDirection}
        $position={arrowPosition}
        $dashboardIsOpen={dashboardState.isOpen}
      >
        <HelperHeader>
          <HelperHeaderText>Bewm!</HelperHeaderText>
          <CloseIcon onClick={handleClickClose} />
        </HelperHeader>
        <p>
          You just added your first {dashboardState.helperContentType} to your dashboard!
        </p>
      </FirstTimeHelperDialog>
  );
}

export default FirstTimeDashboardHelper;
