import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import episodeShape from 'shapes/episodeShape';
import Icon from 'components/Icon';
import CloseIconWrapper from 'components/CloseButton';
import IconButton from '@material-ui/core/IconButton';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0 0.375rem 1.875rem;
  min-height: 4rem;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0.375rem 0 0.375rem 1rem;
  }
`;

const Title = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

const TitleIcon = styled(Icon)`
  height: 1.125rem;
  margin-right: 0.625rem;
  width: 1.25rem;
`;

// MATT example of refactoring
const CloseIconWrapperButton = styled(CloseIconWrapper)`
  &:hover {
    background-color: ${theme('colors.iconHoverBackgroundGray')};

    svg{
      fill: ${theme('colors.ttRed')};
    }
  }
`;

SlideshowHeader.propTypes = {
  episode: episodeShape,
  onClose: PropTypes.func,
};

function SlideshowHeader({
  episode,
  onClose,
}) {
  const theme = useTheme();
  const episodeTitle = get(episode, 'displayTitle');

  return (
    <Header>
      <Box alignItems='center' display='flex'>
        <TitleIcon icon='slidesOutline' />
        <Title>{episodeTitle}</Title>
      </Box>
      <CloseIconWrapperButton onClick={onClose}>
        <CloseIcon style={{ color: theme.palette.common.black }} />
      </CloseIconWrapperButton>
    </Header>
  );
}

export default SlideshowHeader;
