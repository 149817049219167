import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PlugChordIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_504" data-name="Group 504" transform="translate(-6.759)">
          <path id="Path_275" data-name="Path 275" d="M28.333-93.179H26.742v-3.36a.959.959,0,0,0-.96-.96.959.959,0,0,0-.96.96v3.36H21.488v-3.36a.959.959,0,0,0-.96-.96.959.959,0,0,0-.96.96v3.36H17.976a.959.959,0,0,0-.96.96.959.959,0,0,0,.96.96h.253v1.768a4.924,4.924,0,0,0,3.284,4.648v1.263a.572.572,0,0,0,.581.581h.126v5a2.534,2.534,0,0,1-2.526,2.526A2.534,2.534,0,0,1,17.167-78v-3.209A4.479,4.479,0,0,0,12.7-85.676a4.5,4.5,0,0,0-4.5,4.471v6.316a.959.959,0,0,0,.96.96.959.959,0,0,0,.96-.96v-6.316a2.534,2.534,0,0,1,2.526-2.526,2.534,2.534,0,0,1,2.526,2.526v3.234A4.479,4.479,0,0,0,19.643-73.5a4.463,4.463,0,0,0,4.471-4.471v-5h.126a.572.572,0,0,0,.581-.581v-1.263a4.925,4.925,0,0,0,3.284-4.648v-1.768h.253a.959.959,0,0,0,.96-.96.986.986,0,0,0-.986-.985Z" transform="translate(0 97.5)" fill="#e31b23" fill-rule="evenodd"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default React.memo(PlugChordIcon);
