import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import Skeleton from '@material-ui/lab/Skeleton';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import Link from '@material-ui/core/Link';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const ListContainer = styled.div`
  width: 100%;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
`;

const ListItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 0.8125rem;
  min-height: 2.5rem;
  padding-left: 2.25rem;

  ${props => props.theme.breakpoints.down('xs')} {
    padding-right: 1rem;
    position: relative;

    ${ifProp('$mobileBehavior', css`
      padding-right: 2.125rem;
    `)}
  }

`;

const HoverWrap = styled.span`
  display: none;

  ${ifProp('$mobileBehavior', css`
    ${props => props.theme.breakpoints.down('xs')} {
      display: none;
    }
  `)}

  svg {
    font-size: 1rem;
    fill: ${prop('theme.colors.black')};

    ${ifProp('$mobileBehavior', css`
      ${props => props.theme.breakpoints.down('xs')} {
        font-size: 1.125rem;
      }
    `)}

    &:hover {
      fill: ${prop('theme.colors.ttRed')};
    }
  }

  ${ifProp('start', css`
    position: absolute;
    left: 1.25rem;
  `)}

  ${ListItem}:hover & {
    ${props => props.theme.breakpoints.up('sm')} {
      display: block;
    }
  }
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
`;

const TitleContainer = styled.div`
  width:90%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItemLink = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 0.8125rem;
  min-height: 2.5rem;
  width: 100%;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${prop('theme.colors.ttRed')};

    ${TitleContainer} {
      border-bottom: solid 1px ${theme('colors.lightGray')};
    }
  }

  ${ifProp('active', css`
    color: ${prop('theme.colors.ttRed')};
  `)}
`;

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.node,
    value: PropTypes.any,
    active: PropTypes.bool,
  })),
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
  mobile: PropTypes.bool,
  hoverComponentEnd: PropTypes.elementType,
  onUpdateOrder: PropTypes.func
}

function IconList({
  values = [],
  onSelect,
  hoverComponentStart: HoverStart,
  hoverComponentEnd: HoverEnd,
  loading = false,
  mobile = false,
  onUpdateOrder
}) {

  function handleOnDragEnd(reorderedList) {
    const items = Array.from(values);
    const [reorderedItem] = items.splice(reorderedList.source.index, 1);
    items.splice(reorderedList.destination?.index, 0, reorderedItem);
    onUpdateOrder(items);
  }

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ListContainer>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list-items">
          {(provided) => (
            <List className="list-items" {...provided.droppableProps} ref={provided.innerRef}>
              {values.map((item, index) => (
                  <Draggable
                    key={`item-${index}`}
                    draggableId={String(index)}
                    index={index}
                    isDragDisabled={!isDesktop || (!item.sortable || !onUpdateOrder)}
                    // ^ if no function to handle the updated order is passed, or an item has sortable = false, the drag will be disabled
                  >
                    {(provided) => (
                      <ListItem
                        active={item.active}
                        $mobileBehavior={mobile}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {HoverStart && <HoverWrap $mobileBehavior={mobile} start><HoverStart item={item} /></HoverWrap>}
                        {/* TODO: wrap this in a button or a tag for ADA */}
                        <ListItemLink
                          color='inherit'
                          onClick={() => onSelect && onSelect(item.value)}
                          active={item.active}
                        >
                          <IconContainer>
                            {item.icon}
                          </IconContainer>
                          <TitleContainer>
                            {item.title}
                          </TitleContainer>
                        </ListItemLink>
                        {HoverEnd &&
                        <HoverWrap $mobileBehavior={mobile}>
                          <HoverEnd item={item} />
                        </HoverWrap>
                        }
                      </ListItem>
                    )}
                  </Draggable>
                )
              )}
              {loading && Array.from(new Array(4)).map((item, index) => (
                <ListItem
                  key={`item-${index}`}
                >
                  <IconContainer>
                    <PlaylistPlayIcon />
                  </IconContainer>
                  <Skeleton width='70%' />
                </ListItem>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </ListContainer>
  );
}

IconList.propTypes = propTypes;

export default IconList;