import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

StructuredData.propTypes = {
  children: PropTypes.string,
  data: PropTypes.object,
};

function StructuredData({ children, data }) {
  return (
    <Helmet>
      <script type='application/ld+json'>
        {data ? JSON.stringify(data) : children}
      </script>
    </Helmet>
  );
}

export default StructuredData;
