import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ShowCard, { SHOW_CARD_SIZES } from 'components/ShowCard';
import showShape from 'shapes/showShape';

ShowsColumn.propTypes = {
  shows: PropTypes.arrayOf(showShape),
  shouldLazyLoad: PropTypes.bool
};

function ShowsColumn({ shows, shouldLazyLoad = true }) {
  if (!shows || shows.length === 0) {
    return null;
  }

  // If there's only one show selected, we will render the show card.
  if (shows.length === 1) {
    return <ShowCard show={shows[0]} size={SHOW_CARD_SIZES.md} shouldLazyLoad={ shouldLazyLoad } />;
  }

  return (
    <Grid container spacing={2}>
      {shows.map((show, i) => (
        <Grid key={i} item xs={6}>
          <ShowCard show={show} size={SHOW_CARD_SIZES.sm} shouldLazyLoad={ shouldLazyLoad ? shouldLazyLoad : i > 0 } />
        </Grid>
      ))}
    </Grid>
  );
}

export default ShowsColumn;
