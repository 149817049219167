import React from 'react';
import { ifProp, theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import { useTheme } from '@material-ui/core/styles';
import RichTextField from 'components/RichTextField';
import BlockContainer from 'components/BlockContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import conceptOfTheDayShape from 'shapes/components/conceptOfTheDayShape';
import { autoOptimizeImage } from 'utils/functions';
import Box from '@material-ui/core/Box';
import {
  FeatureCardWrapper,
  FeatureCardContentWrapper,
  FeatureCardDetailsWrapper,
  FeatureCardDetails,
  FeatureCardImageWrapper,
  FeatureCardImage,
  ConceptsButtonWrapper,
  FeatureCardTag,
} from 'components/GlobalStyles/FeatureCardStyles';

const ConceptBlockContainer = styled(BlockContainer)`
  ${ifProp(
    '$isKeyConcept',
    css`
      max-width: none;
      padding-top: 0;
      width: 88%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0;
      padding-left: 0;
    `
  )}
`;

const DefinitionHeader = styled.h1`
  font-size: 2.14rem;
  font-weight: 600;
  margin: 0;

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 2.5rem;
  }
`;

const StyledContentWrapper = styled(FeatureCardContentWrapper)`
  margin-top: 0.25rem;
`;

const StyledImageWrapper = styled(FeatureCardImageWrapper)`
  background-color: ${theme('colors.iconHoverBackgroundGray')};
`;

const StyledImage = styled(FeatureCardImage)`
  object-fit: contain;
`;

const GridTitle = styled.h2`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamilySecondary};
  `}
`;

const ConceptButton = styled.a`
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 2rem;
  }
`;

const DefinitionContainer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    p {
      font-size: 1.25rem;
    }
  }
`;

ConceptOfTheDay.propTypes = {
  data: conceptOfTheDayShape,
};

function ConceptOfTheDay({ data, ...props }) {
  const { page } = props;
  const theme = useTheme();
  const history = useHistory();
  const { header, imageHeader, image, definitionCta } = data;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { setActiveTerm } = useKeyConceptsContext();
  const definition = data?.definition[0];

  const cta = (
    <ConceptButton
      as={Button}
      color='primary'
      href={definition.url}
      variant='contained'
      onClick={handleClickCta}
    >
      {definitionCta}
    </ConceptButton>
  );

  function handleClickCta() {
    event.preventDefault();
    history.push(definition?.url);
  }

  return (
    <ConceptBlockContainer
      $isKeyConcept={page.includes('concepts')}
      maxWidth='xl'
      $carouselGutters
    >
      <FeatureCardWrapper>
        <StyledContentWrapper>
          <FeatureCardTag>{header}</FeatureCardTag>
          <DefinitionHeader>{definition?.title}</DefinitionHeader>
          <FeatureCardDetailsWrapper>
            <FeatureCardDetails>
              <Box>
                <GridTitle>Definition</GridTitle>
                <DefinitionContainer>
                  <RichTextField richText={definition.definition} />
                </DefinitionContainer>
              </Box>
            </FeatureCardDetails>
          </FeatureCardDetailsWrapper>
          <ConceptsButtonWrapper>
            {definitionCta && isDesktop && cta}
            {definitionCta && !isDesktop && cta}
          </ConceptsButtonWrapper>
        </StyledContentWrapper>
        <StyledImageWrapper>
          <StyledImage src={autoOptimizeImage(image?.url)} />
        </StyledImageWrapper>
      </FeatureCardWrapper>
    </ConceptBlockContainer>
  );
}

export default ConceptOfTheDay;
