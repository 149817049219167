import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonWrapper = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
`;

function HorizontalSkeleton() {
  return (
    <SkeletonWrapper>
      <Skeleton variant='rect' height={125} width='40%' />
      <Box width='60%'>
        <Skeleton height={9} width='40%' />
        <Skeleton height={10} width='40%' />
        <Skeleton height={11} width='60%' />
        <Skeleton height={11} width='60%' />
        <Skeleton height={12} width='60%' />
        <Skeleton height={12} width='80%' />
        <Skeleton height={12}  width='80%'/>
        <Skeleton height={12}  width='80%'/>
        <Skeleton height={12} />
        <Skeleton height={12} />
        <Skeleton height={12} />
      </Box>
    </SkeletonWrapper>
  );
}

export default React.memo(HorizontalSkeleton);
