import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AccordionWrapper from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Icon from 'components/Icon';

const StyledAccordion = styled(AccordionWrapper)`
  .MuiAccordionDetails-root {
    padding-bottom: 0;
    padding-top: 0;
    flex-direction: column;
  }
`;

const AccordionHeader = styled(AccordionSummary)`
  color: ${theme('colors.white')};
  padding: 0;

  .MuiTypography-h4 {
    font-family: ${prop('theme.typography.fontFamilySecondary')};
    font-weight: 700;
    font-size: 1.25rem;
  }

  .MuiAccordionSummary-expandIcon {
    color: ${theme('colors.white')};
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }

  .MuiAccordionSummary-content {
    padding-left: 1rem;
  }

  .MuiButtonBase-root {
    padding-right: 2rem;
  }
`;

const HeaderCopy = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
`;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

function BlogAccordion({ children, className, id, title }) {
  const [expanded, setExpanded] = useState(id);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={className}>
      <StyledAccordion
        square
        expanded={expanded === `${id}`}
        onChange={handleChange(`${id}`)}
      >
        <AccordionHeader
          expandIcon={<Icon icon={expanded === `${id}` ? 'remove' : 'plus'} size={0.65} />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <HeaderCopy>{title}</HeaderCopy>
        </AccordionHeader>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}

BlogAccordion.propTypes = propTypes;

export default React.memo(BlogAccordion);