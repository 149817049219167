import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DashboardAlert from '../DashboardAlert';
import DataPrivacyCapture from 'components/DataPrivacyCapture';

const Event = styled.span`
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;

OptInAlert.propTypes = {
  loading: PropTypes.bool,
  accepted: PropTypes.bool,
}

function OptInAlert({loading, accepted}) {
  const [showPrivacySettings, setShowPrivacySettings] = useState(false);

  if (loading || accepted) {
    return null;
  }

  return (
    <>
      <DashboardAlert
        message={
          <>
            You have opted out of storing this type of data. Change this setting
            &nbsp;
            <Event onClick={() => setShowPrivacySettings(true)}>here</Event>.
          </>
        }
        severity='info'
      />
      {showPrivacySettings &&
        <DataPrivacyCapture form='FORM_DATA_USAGE_CONSENT' onSave={() => setShowPrivacySettings(false)} />
      }
    </>
  )
}

export default React.memo(OptInAlert);
