export function dataLayerPush(message, dataKey = 'event') {
  window.dataLayer?.push({ [dataKey]: `${message}` });
}

export function gtag() {
  window.dataLayer?.push(arguments);
}

export function videoAnalyticsEventHandler(event) {
  // Send to dataLayer (GTM)
  gtag({
    ...event,
    event: 'videoPlayerEvent',
  });
}
