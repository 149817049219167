import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';

const CardContainer = styled.div`
  background-color: ${prop('background')};
  color: ${prop('textColor')};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: ${prop('$width')}px;
  align-items: center;
  border-radius: 1rem;
  padding: 2rem 1rem 0;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0;
  }

  ${ifProp('flip', css`
    flex-direction: column-reverse;
  `)}

  ${ifProp('marginRight', css`
    margin-right: ${prop('marginRight')}rem;
  `)}

  ${ifProp('marginTop', css`
    margin-top: ${prop('marginTop')}rem;
  `)}

  ${ifProp('boxShadow', css`
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  `)}

  ${ifProp('border', css`
    border: solid 1px ${theme('colors.lightGray')};
  `)}

  ${ifProp('paddingTop', css`
    padding-top: ${prop('paddingTop')}rem;
  `)}

  ${ifProp({ size: 4 }, css`
    padding: 0;

    img {
      border-radius: 1rem 1rem 0 0;
    }
  `)}

  ${ifProp({ size: 12 }, css`
    ${ifProp('flip', css`
      flex-direction: column;
    `)}

    // custom media query instead of mui break point to single row content card purposes
    @media(min-width: 650px) {
      flex-direction: row-reverse;

      ${ifProp('flip', css`
        flex-direction: row;
      `)}
    }
  `)}

  ${ifProp({$hover: 'expand'}, css`
    transition: transform 250ms, box-shadow 250ms;

    &:hover {
      cursor: pointer;
      height: fit-content;
      height: -moz-fit-content;
      z-index: 12;

      ${props => props.theme.breakpoints.up('md')} {
        background-color: ${theme('colors.white')};
        box-shadow: 0px 10px 20px #00000029;
        transform: scale(1.15);
      }
    }
  `)}
`;

export default CardContainer;
