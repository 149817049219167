import React from 'react';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { dataLayerPush } from 'utils/dataLayer';

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-between;
`;

FormFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func,
  formType: PropTypes.oneOf(['dataUsageConsentForm', 'dataPrivacyForm'])
}

export function FormFooter({ isSubmitting, submitForm, formType }) {
  return (
    <DialogActions>
      <Button
        type='button'
        disabled={isSubmitting}
        href='/api/logout'
      >
        Logout
      </Button>
      <Button
        type='submit'
        form={formType}
        disabled={isSubmitting}
        onClick={submitForm}
        variant='contained'
        color='primary'
      >
        Save
      </Button>
    </DialogActions>
  )
}