import PropTypes from 'prop-types';

const { string, shape, bool } = PropTypes;

export default shape({
  url: string,
  linkUrl: shape({
    href: string,
    title: string,
  }),
  uid: string.isRequired,
  contentTypeUid: string.isRequired,
  openInNewWindow: bool,
  showExternalLinkPopup: bool.isRequired,
});
