import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import MegaMenuImageLink from './components/MegaMenuImageLink';
import MegaMenuGroupHeader from './components/MegaMenuGroupHeader';
import MegaMenuDate from './components/MegaMenuDate';
import MegaMenuImage from './components/MegaMenuImage';
import RichTextField from 'components/RichTextField';
import DateFormatter from 'components/DateFormatter';
import menuItemShape from 'shapes/menuItemShape';
import { dataLayerPush } from 'utils/dataLayer';
import MostRecentArticle from './components/MostRecentArticle';
import MostRecentEpisode from './components/MostRecentEpisode';
import RandomizedShow from './components/RandomizedShow';
import RandomizedTrader from './components/RandomizedTrader';
import RandomizedConcept from './components/RandomizedConcept';
import RandomizedArticle from './components/RandomizedArticle';
import { autoOptimizeImage } from 'utils/functions';
import { useTourContext } from 'context/TourContext';
import PageLink from 'components/PageLink';
import Icon from 'components/Icon';
import {
  Tours,
  TRADER_RESOURCES_CONCEPTS_COL_CLASS,
  TraderResourcesStepKeys,
} from 'components/Tours/constants';
import HelpIcon from '@material-ui/icons/Help';
import MegaMenuImageWrapper from './components/MegaMenuImageWrapper';

const panelMinHeight = '25.875rem';

const TourTriggerWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 1rem 0.5rem 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const TriggerTour = styled(HelpIcon)`
  cursor: pointer;
  font-size: 1rem;
`;

export const MegaMenuContainer = styled.div`
  position: fixed;
  left: 0;
  top: ${ifProp('$displayAlert', '9.75rem', '6.25rem')};
  right: 0;
  bottom: auto;
  z-index: 1099;
  min-width: 56rem;
  margin-right: auto;
  margin-bottom: -1px;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  background-color: ${theme('palette.common.white')};
  box-shadow: 12px 20px 16px 6px rgba(0, 0, 0, 0.16);
  // Lazyload won't work as 'display: none' means the element won't be rendered to the DOM at all. So visibility: hidden is used instead.
  opacity: 0;
  visibility: hidden;
  width: 100vw;

  ${(props) => props.theme.breakpoints.up('sm')} {
    max-height: calc(100vh - 154px);
    overflow: scroll;
  }

  ${ifProp(
    'open',
    css`
      opacity: 1;
      visibility: visible;
    `
  )}
`;

const MenuMenuPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 3rem 0;
  width: 95%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }

  @media (min-width: 1440px) {
    max-width: 1380px;
    width: 88%;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    max-width: 1580px;
  }
`;

const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${ifProp(
    '$width',
    css`
      width: ${prop('$width')}%;
    `
  )}

  &:not(:last-child) {
    padding-right: 2.5rem;
  }

  &:not(:first-child) {
    padding-left: 1.5rem;
    border-left: 1px solid ${theme('colors.iconHoverBackgroundGray')};
  }
`;

const MenuSubcolumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  img {
    max-height: 6.25rem;
  }

  ${ifProp(
    '$endLink',
    css`
      justify-content: flex-end;
      // the endLink fontsize is slightly smaller than the column links
      a {
        font-size: 0.875rem;
      }
    `
  )}

  ${ifProp(
    '$hideItem',
    css`
      display: none;
    `
  )}
`;

export const MegaMenuSnippetLink = styled(PageLink)`
  color: ${theme('palette.common.black')};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${ifProp(
    '$tourDisabled',
    css`
      &:hover {
        cursor: default;
      }
    `
  )}
`;

export const MegaMenuSnippet = styled(RichTextField)`
  font-size: 0.875rem;
  margin-top: 0;
  max-width: 31.75rem;

  h3,
  h5 {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1rem;
    margin-top: 0.75rem;
  }

  h5 {
    margin-top: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    margin-top: 0;
  }

  a {
    text-decoration: none;
  }

  ${ifProp(
    '$tourDisabled',
    css`
      a {
        &:hover {
          color: ${theme('palette.common.black')};
          cursor: default;
        }
      }
    `
  )}
`;

const MegaMenuGroupList = styled.div`
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1.75rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const MegaMenuGroupListItem = styled.div`
  margin-bottom: 0.625rem;
  margin-right: 1.5rem;
  font-family: ${theme('typography.fontFamily')};
  flex: 0 1 auto;

  ${ifProp(
    '$hideItem',
    css`
      display: none;
    `
  )}
`;

const MegaMenuGroupLink = styled(PageLink)`
  font-size: 0.875rem;
  font-weight: 400;
  font-stretch: condensed;
  text-decoration: none;
  letter-spacing: 0.32px;
  line-height: 1.31;
  color: ${theme('palette.common.black')};

  &:hover {
    color: ${theme('colors.ttRed')};
    text-decoration: none;
  }

  ${ifProp(
    '$tourDisabled',
    css`
      &:hover {
        color: ${theme('palette.common.black')};
        font-weight: normal;
        cursor: default;
      }
    `
  )}

  // CSS trick so that the bold-on-hover does not shift the columns due to size changing.
  // Reference: https://css-tricks.com/bold-on-hover-without-the-layout-shift/
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &:after {
    content: attr(data-text);
    content: attr(data-text) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }
`;

const featuredComponents = {
  'most recent article': MostRecentArticle,
  'most recent episode': MostRecentEpisode,
  'randomized show': RandomizedShow,
  'randomized trader': RandomizedTrader,
  'randomized concept': RandomizedConcept,
  'randomized article': RandomizedArticle,
};

MegaMenu.propTypes = {
  displayAlert: PropTypes.bool,
  menuItems: PropTypes.arrayOf(menuItemShape),
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
};

function MegaMenu({ displayAlert, menuItems, menuOpen, setMenuOpen }) {
  const {
    state: { activeTour, currentStep },
    updateCurrentStep,
    enableTour,
    setWillShowTour,
  } = useTourContext();

  const traderResourcesTourAction =
    activeTour === Tours.TraderResources &&
    currentStep === TraderResourcesStepKeys.ConceptsColumns;

  const megaMenuClick = (event) => {
    // TraderResourcesTour - disable click event when tour step for featured concept is active.
    if (traderResourcesTourAction) {
      event.preventDefault();
      return false;
    }

    setMenuOpen(false);
    dataLayerPush('Mega Menu Link Clicked');
  };

  const traderResourcesTourMenuClick = () => {
    updateCurrentStep(currentStep + 1);
    setMenuOpen(false);
  };

  const manuallyStartTour = useCallback(() => {
    if (!activeTour) {
      setWillShowTour(true);
      enableTour(Tours.TraderResources);
    }
  }, [setWillShowTour, enableTour, activeTour]);

  return (
    <MegaMenuContainer
      $displayAlert={displayAlert}
      open={menuOpen}
      className='mega-menu-container'
    >
      <MenuMenuPanel>
        {menuItems.map((column, index) => {
          const conceptsStrategiesColumn =
            column.cssClassName === TRADER_RESOURCES_CONCEPTS_COL_CLASS;

          return (
            <MenuColumn
              key={index}
              $width={column.columnWidth}
              className={column.cssClassName}
            >
              {column?.submenu.map((subItem, index) => {
                const snippet = subItem.snippet;
                const menuImageAltText = subItem?.imageAsset?.altImageText
                  ? subItem.imageAsset.altImageText
                  : 'uploaded image';
                const menuImage = subItem.imageAsset?.imageAsset;
                const featuredItem = subItem.menuFeatured?.featuredItem;
                const featuredImage =
                  featuredItem?.metadata?.thumbnailImage?.url;
                const tourHighlighted =
                  conceptsStrategiesColumn &&
                  traderResourcesTourAction &&
                  subItem?.submenu.length === 0;
                const featuredType = subItem.menuFeatured?.featuredType;
                const FeaturedComponent = featuredComponents[featuredType];

                return (
                  <MenuSubcolumn
                    $endLink={subItem.linkIcon}
                    $hideItem={subItem.deviceVisibility === 'mobile_only'}
                    key={index}
                  >
                    <>
                      {subItem.linkText &&
                        (subItem.link ? (
                          <MegaMenuGroupHeader
                            as={PageLink}
                            link={subItem.link}
                            onClick={
                              tourHighlighted
                                ? traderResourcesTourMenuClick
                                : megaMenuClick
                            }
                            className={`mega-menu-group-header-link${
                              tourHighlighted ? ' tour-highlight' : ''
                            }`}
                            $tourDisabled={
                              traderResourcesTourAction && !tourHighlighted
                            }
                          >
                            {subItem.linkText}
                            {subItem.linkIcon && (
                              <Icon icon={subItem.linkIcon} />
                            )}
                          </MegaMenuGroupHeader>
                        ) : (
                          <MegaMenuGroupHeader>
                            {subItem.linkText}
                          </MegaMenuGroupHeader>
                        ))}
                      {featuredItem && (
                        <>
                          <MegaMenuImageLink
                            as={Link}
                            to={featuredItem.url}
                            onClick={
                              tourHighlighted
                                ? traderResourcesTourMenuClick
                                : megaMenuClick
                            }
                            $tourDisabled={
                              traderResourcesTourAction && !tourHighlighted
                            }
                          >
                            <MegaMenuImage
                              alt={
                                featuredItem.metadata?.thumbnailAltText ??
                                'uploaded image'
                              }
                              src={
                                autoOptimizeImage(featuredImage) + '&width=500'
                              }
                              $type={subItem.menuFeatured?.featuredType}
                            />
                          </MegaMenuImageLink>
                          <MegaMenuGroupHeader
                            as={Link}
                            to={featuredItem.url}
                            onClick={megaMenuClick}
                          >
                            {featuredItem.title}
                          </MegaMenuGroupHeader>
                          {featuredItem.postDate && (
                            <MegaMenuDate>
                              <DateFormatter date={featuredItem.postDate} />
                            </MegaMenuDate>
                          )}
                        </>
                      )}
                      {FeaturedComponent && !featuredItem && (
                        <FeaturedComponent
                          tourHighlighted={tourHighlighted}
                          menuOpen={menuOpen}
                          megaMenuClick={megaMenuClick}
                          traderResourcesTourMenuClick={
                            traderResourcesTourMenuClick
                          }
                          traderResourcesTourAction={traderResourcesTourAction}
                          subItem={subItem}
                        />
                      )}
                      {!FeaturedComponent && menuImage && menuOpen && (
                        <MegaMenuImageLink
                          as={PageLink}
                          link={subItem.link}
                          onClick={
                            tourHighlighted
                              ? traderResourcesTourMenuClick
                              : megaMenuClick
                          }
                          $tourDisabled={
                            traderResourcesTourAction && !tourHighlighted
                          }
                          $maxWidth={subItem.imageAsset.maxWidth}
                        >
                          <MegaMenuImageWrapper>
                            <MegaMenuImage
                              alt={menuImageAltText}
                              $maxWidth={subItem.imageAsset.maxWidth}
                              src={
                                autoOptimizeImage(menuImage.url) + '&width=500'
                              }
                            />
                          </MegaMenuImageWrapper>
                        </MegaMenuImageLink>
                      )}
                      {!FeaturedComponent && snippet && menuOpen && (
                        <MegaMenuSnippetLink
                          link={subItem.link}
                          onClick={
                            tourHighlighted
                              ? traderResourcesTourMenuClick
                              : megaMenuClick
                          }
                          $tourDisabled={
                            traderResourcesTourAction && !tourHighlighted
                          }
                        >
                          <MegaMenuSnippet
                            richText={snippet}
                            $tourDisabled={
                              traderResourcesTourAction && !tourHighlighted
                            }
                          />
                        </MegaMenuSnippetLink>
                      )}
                    </>
                    {subItem?.submenu.length > 0 && (
                      <MegaMenuGroupList>
                        {subItem.submenu.map((item, index) => (
                          <MegaMenuGroupListItem
                            $hideItem={item.deviceVisibility === 'mobile_only'}
                            key={index}
                          >
                            <MegaMenuGroupLink
                              link={item.link}
                              data-text={item.linkText}
                              onClick={megaMenuClick}
                              $tourDisabled={traderResourcesTourAction}
                            >
                              {item.linkText}
                            </MegaMenuGroupLink>
                          </MegaMenuGroupListItem>
                        ))}
                      </MegaMenuGroupList>
                    )}
                  </MenuSubcolumn>
                );
              })}
            </MenuColumn>
          );
        })}
      </MenuMenuPanel>
      <TourTriggerWrapper>
        <TriggerTour onClick={manuallyStartTour} />
      </TourTriggerWrapper>
    </MegaMenuContainer>
  );
}

export default MegaMenu;
