import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import showTwoColumnShape from 'shapes/components/showTwoColumnShape';
import ShowsColumn from 'components/ModularBlocks/ShowTwoColumn/ShowsColumn';

const GridItem = styled(Grid)`
  .lazyload-wrapper,
  .skeleton-card {
    height: 100%;
  }
`;

ShowTwoColumn.propTypes = {
  data: showTwoColumnShape,
};

function ShowTwoColumn({ data }) {
  const { leftColumnShows, rightColumnShows, shouldLazyLoad } = data;

  const hasLeftCol = leftColumnShows && leftColumnShows.length > 0;
  const hasRightCol = rightColumnShows && rightColumnShows.length > 0;

  if (!hasLeftCol && !hasRightCol) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <GridItem item xs={12} sm={6}>
        {hasLeftCol && <ShowsColumn shows={leftColumnShows} shouldLazyLoad={shouldLazyLoad} />}
      </GridItem>
      <GridItem item xs={12} sm={6}>
        {hasRightCol && <ShowsColumn shows={rightColumnShows} shouldLazyLoad={shouldLazyLoad} />}
      </GridItem>
    </Grid>
  );
}

export default React.memo(ShowTwoColumn);
