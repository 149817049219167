import React from 'react';
import Grid from '@material-ui/core/Grid';
import EpisodeCard from 'components/EpisodeCard';

function DailyReplaysLoading() {
  return Array.from(new Array(5)).map((episode, index) => (
    <Grid key={index} item xs={12}>
      <EpisodeCard
        display='horizontal'
        episode={episode}
        loading={true}
      />
    </Grid>
  ));
}

export default React.memo(DailyReplaysLoading);
