import PropTypes from 'prop-types';
import linkShape from 'shapes/linkShape';

const { shape, oneOf, string } = PropTypes;

export default shape({
  buttonLink: linkShape,
  buttonType: oneOf([
    'default',
    'primary',
    'secondary',
    'link',
    'default-outlined',
    'primary-outlined',
    'secondary-outlined'
  ]),
  buttonCtaMobileName: string,
  buttonCtaName: string,
  buttonIcon: string,
  buttonAction: string,
});
