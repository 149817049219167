import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import CheckboxGroup from '../CheckboxGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const ListContainer = styled.div`
  padding-left: 2rem;
  width: 100%;

`;

const ListControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0.5rem;
`;

const ControlContainer = styled.div`
  align-items: center;
  display: flex;

  a {
    font-size: 0.5rem;
    text-decoration: underline;

    &:hover {
      color: ${prop('theme.colors.ttRed')};
      cursor: pointer;
    }
  }

  p {
    font-size: 0.625rem;
    font-weight: 500;
    margin: 0 1.25rem 0 0;
    text-transform: uppercase;
  }
`;

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
  })),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
}

function CheckboxList({ values = [], onChange, onRemove, loading = false }) {
  const checked = values.find(x => x.checked);

  return (
    <ListContainer>
      <ListControls>
        <ControlContainer>
          <p>Filter</p>
          {checked && <a onClick={() => onChange([])}>Clear</a>}
        </ControlContainer>
      </ListControls>
      {loading ? Array.from(new Array(6)).map((item, index) => (
        <Box display='flex' alignItems='center' key={index}>
          <Box mr={1}><Skeleton width='1rem' /></Box>
          <Skeleton width='70%' />
        </Box>
      )) : (
        <CheckboxGroup values={values} onChange={onChange} onRemove={onRemove} />
      )}
    </ListContainer>
  );
}

CheckboxList.propTypes = propTypes;

export default CheckboxList;
