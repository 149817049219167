import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDashboardContext } from 'context/DashboardContext';
import EpisodeCard from 'components/EpisodeCard';
import DashboardAlert from '../DashboardAlert';
import scheduleItemShape from 'shapes/scheduleItemShape';
import styled  from 'styled-components';

const DailyReplaysContainer = styled.div`
  margin-top: 1rem;
  border-top: 0.0625rem solid #cccccc;
`;

DailyReplaysBody.propTypes = {
  scheduleItems: PropTypes.arrayOf(scheduleItemShape),
};

function DailyReplaysBody({ scheduleItems }) {
  const { state: dashboardState } = useDashboardContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { currentDailyReplay } = dashboardState;

  return (
    <DailyReplaysContainer>
      {scheduleItems.map((scheduleItem, index) => (
        <Grid key={index} item xs={12}>
          <EpisodeCard
            display={isMobile ? 'listItem' : 'horizontal'}
            episode={scheduleItem.episode}
            allowDelete={false}
            edit={false}
            dailyReplay={currentDailyReplay}
            playlistIndex={index + 1}
            scheduleItem={scheduleItem}
          />
        </Grid>
      ))}
      {scheduleItems.length === 0 &&
        <DashboardAlert
          severity='info'
          message={<>No Daily Replay available yet for this date.</>}
        />
      }
    </DailyReplaysContainer>
  );
}

export default React.memo(DailyReplaysBody);
