import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const ActionIconText = styled.div`
  position: relative;
  width: 6rem;
  overflow: hidden;
  height: 1rem;

  ${props => props.theme.breakpoints.down('xs')} {
    width: 5.25rem;
  }

  ${ifProp('$expand', css`
    font-size: 0.55rem;
    width: 4.75rem;

    ${props => props.theme.breakpoints.down('xs')} {
      width: 4rem;
    }
  `)};
  }
`;

const ActionTextWrapper = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  transition: 0.33s;
  position: absolute;
  top: 0;
  right: -4rem;
  height: 1rem;
  color: ${theme('palette.common.white')};
  font-family: ${theme('typography.fontFamily')};
  font-weight: 400;

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 0.6875rem;
  }
`;

ActionLabel.propTypes = {
  label: PropTypes.string.isRequired,
  expand: PropTypes.bool,
}

function ActionLabel({ expand, label }) {
  return (
    <ActionIconText $expand={expand}>
      <ActionTextWrapper>{label}</ActionTextWrapper>
    </ActionIconText>
  );
}

export default ActionLabel;
