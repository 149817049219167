import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import CtaButton from 'components/CtaButton';
import HubspotForm from 'components/HubspotForm';
import BlockVideoPlayer from './BlockVideoPlayer';
import RichTextField, { RichTextBox } from 'components/RichTextField';
import twoBlockContentShape from 'shapes/components/twoBlockContentShape';
import CustomIconList from 'components/CustomIconList';
import StandardBlockHeader from 'components/StandardBlockHeader';

const BlockContent = styled.div`
  ${ifNotProp('$url', css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  `)}
`;

const Body = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 28rem;
  }

  ${RichTextBox} {
    .responsive-grid {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;

      ${props => props.theme.breakpoints.down('xs')} {
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    .brand-list {
      font-size: 1rem;
      list-style: none;
      padding-left: 0;

      li {
        font-family: ${theme('typography.fontFamilySecondary')};
        font-size: 1.25rem;
        font-weight: 400;

        &:before {
          content: "\\2022";  /* \\2022 is the CSS Code/unicode for a bullet */
          color: ${theme('colors.ttRed')};
          display: inline-block;
          font-weight: 600;
          font-size: 1em;
          width: 1em;
        }
      }
    }

    h5 {
      font-family: ${theme('typography.fontFamilySecondary')};
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1.75rem;

  a,
  button {
    font-size: 0.8125rem;
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
  }

  ${ifProp({$buttonType: 'link'}, css`
    a {
      justify-content: flex-start;
    }
  `)}
`;

Block.propTypes = {
  data: twoBlockContentShape.isRequired,
  url: PropTypes.string,
};

function Block({ data, url }) {
  const {
    body,
    ebookSignUp,
    header,
    leadSource,
    optionalCta,
    subscriptionId,
    subheader,
    videoSource,
    customIconList
  } = data;
  const bcVideoId = get(videoSource, 'bcVideoFile');
  const livePlayer = get(videoSource, 'livePlayer');
  const videoOnly = (bcVideoId || livePlayer);
  const buttonType = get(optionalCta[0], 'buttonType', null);

  return (
    <>
      {videoOnly && (
        <BlockVideoPlayer videoData={videoSource} />
      )}
      {!videoOnly && (
        <BlockContent $url={url}>
          <div>
            <StandardBlockHeader header={header} subheader={subheader} />
            {body && (
              <Body><RichTextField richText={body} /></Body>
            )}
            {customIconList?.length > 0 && (
              <CustomIconList data={customIconList} />
            )}
            {subscriptionId && (
              <HubspotForm ebookSignUp={ebookSignUp} leadSource={leadSource} subscriptionId={subscriptionId} />
            )}
          </div>
          {optionalCta.length > 0 && (
            <ButtonContainer $buttonType={buttonType}>
              {optionalCta.map((cta, index) => (
                <CtaButton data={cta} icon={get(cta, 'buttonIcon')} key={`button-${index}`} />
              ))}
            </ButtonContainer>
          )}
        </BlockContent>
      )}
    </>
  );
}

export default React.memo(Block);
