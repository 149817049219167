import React from 'react';
import styled from 'styled-components';
import menuItemShape from 'shapes/menuItemShape';
import VisibilityWrapper from 'components/VisibilityWrapper';
import Link from '@material-ui/core/Link';
import SpecialBehaviorLink from 'components/Header/components/SpecialBehaviorLink';
import { theme } from 'styled-tools';
import DropdownList from './DropdownList';
import DropdownLinkWrapper from './DropdownLinkWrapper';
import SubMenuItem from './SubMenuItem';
import HelpOutline from '@material-ui/icons/HelpOutline';

const StyledNavLink = styled(Link)`
  align-items: center;
  color: ${theme('palette.common.white')};
  cursor: pointer;
  display: inline-flex;
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-stretch: condensed;
  font-weight: normal;
  height: 100%;
  padding: 0 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms cubic-bezier(.23, 1, .32, 1);

  &:hover,
  &:active {
    font-weight: bold;
    // replaces the BOLD hover state so as to not shift the text/divs
    p {
      text-shadow: 0 0 .8px #fff, 0 0 .8px #fff;
    }
  }

  ${DropdownLinkWrapper}:hover & {
    background-color: ${theme('colors.mutedGray')};
    text-decoration: none;
  }

  p {
    font-size: inherit;
    font-family: ${theme('typography.fontFamilySecondary')};
    margin: 0;
  }

  .MuiSvgIcon-root {
    margin-right: 0.375rem;
    max-height: 1.5rem;
    width: auto;
  }
`;

SuperNavLink.propTypes = {
  menuItem: menuItemShape.isRequired,
};

function SuperNavLink({ menuItem }) {
  const { link, linkText, submenu, visibility, specialBehavior } = menuItem || {};
  
  return (
    <VisibilityWrapper visibility={visibility}>
      <DropdownLinkWrapper>
        {specialBehavior ? (
          <SpecialBehaviorLink menuItem={menuItem} component={StyledNavLink} />
        ) : (
          <StyledNavLink component={link ? StyledNavLink : 'div'} link={link}>
            {linkText === 'Help' ? <HelpOutline/> : linkText}
          </StyledNavLink>
        )}
        {submenu && submenu.length > 0 && ( 
          <DropdownList>
            {submenu.map((item, i) => (
              <VisibilityWrapper key={i} visibility={item.visibility}>
                <SubMenuItem link={item.link}>
                  {item.linkText}
                </SubMenuItem>
              </VisibilityWrapper>
            ))}
          </DropdownList>
        )}
      </DropdownLinkWrapper>
    </VisibilityWrapper>
  );
}

export default SuperNavLink;
