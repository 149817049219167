/** @module tt/videojs */

const VJS_EVENTS = {
  loadStart: 'loadstart',
  loadedMetadata: 'loadedmetadata',
  timeUpdate: 'timeupdate',
  ended: 'ended',
  play: 'play',
  pause: 'pause',
  volumeChange: 'volumechange',
  resize: 'resize',
  error: 'error',
  fullscreenChange: 'fullscreenchange',
  enterPictureInPicture: 'enterpictureinpicture',
  leavePictureInPicture: 'leavepictureinpicture',
  rateChange: 'ratechange',
  dispose: 'dispose',
};

const ANALYTICS_EVENTS = {
  loaded: 'loaded',
  percentsPlayed: 'percentsPlayed',
  start: 'start',
  end: 'end',
  seek: 'seek',
  play: 'play',
  pause: 'pause',
  resize: 'resize',
  volumeChange: 'volumeChange',
  error: 'error',
  fullscreen: 'fullscreen',
  pictureInPicture: 'pictureInPicture',
  heartbeat: 'heartbeat',
  rateChange: 'rateChange',
};

const DEFAULT_EVENTS_TO_TRACK = [
  ANALYTICS_EVENTS.loaded,
  ANALYTICS_EVENTS.percentsPlayed,
  ANALYTICS_EVENTS.start,
  ANALYTICS_EVENTS.end,
  ANALYTICS_EVENTS.seek,
  ANALYTICS_EVENTS.play,
  ANALYTICS_EVENTS.pause,
  ANALYTICS_EVENTS.resize,
  ANALYTICS_EVENTS.volumeChange,
  ANALYTICS_EVENTS.error,
  ANALYTICS_EVENTS.fullscreen,
  ANALYTICS_EVENTS.pictureInPicture,
  ANALYTICS_EVENTS.heartbeat,
  ANALYTICS_EVENTS.rateChange,
];

const DEFAULT_PERCENT_PLAYED_INTERVAL = 10;
const DEFAULT_EVENT_CATEGORY = 'Video';
const DEFAULT_HEARTBEAT_INTERVAL = 300000; // 5 minutes, in milliseconds
const LOG_TAG = '[tastytradeAnalytics]';

/**
 * Adapted from videojs-ga
 *
 * @see https://github.com/mickey/videojs-ga
 *
 * @constructor
 */
function TastytradeAnalytics(options = {}) {
  // Initial config options.
  const eventsToTrack = options.eventsToTrack || DEFAULT_EVENTS_TO_TRACK;
  const percentsPlayedInterval = options.percentsPlayedInterval || DEFAULT_PERCENT_PLAYED_INTERVAL;
  const eventCategory = options.eventCategory || DEFAULT_EVENT_CATEGORY;
  const heartbeatInterval = options.heartbeatInterval || DEFAULT_HEARTBEAT_INTERVAL;

  // if you didn't specify a name, it will be 'guessed' from the video src after metadata is loaded
  let eventLabel = options.eventLabel;
  let gaTrackerId = options.gaTrackerId;
  options.debug = options.debug || false;

  // init a few variables.
  const percentsAlreadyTracked = [];
  let seekStart = 0;
  let seekEnd = 0;
  let seeking = false;
  let heartbeatIntervalCb = null;

  // initialize google analytics, if tracker ID is provided.
  if (gaTrackerId) {
    initGoogleAnalytics();
  }

  function loadStart() {
    // Set the eventLabel if it's not set.
    if (!eventLabel) {
      // Check if brightcove mediainfo object is available.
      if (this.mediainfo) {
        const customFields = this.mediainfo.customFields || {};
        if (customFields.tastytrade_media_id) {
          eventLabel = customFields.tastytrade_media_id;
        } else if (this.mediainfo.name) {
          eventLabel = this.mediainfo.name;
        } else {
          eventLabel = this.mediainfo.id;
        }

        if (this.mediainfo.id) {
          eventLabel += ` (${this.mediainfo.id})`;
        }
      } else {
        eventLabel = this.currentSrc().split("/").slice(-1)[0].replace(/\.(\w{3,4})(\?.*)?$/i,'');
      }
    }
  }

  function loaded() {
    if (isTracked(ANALYTICS_EVENTS.loaded)) {
      sendEvent('loadedmetadata', true);
    }

    // Set up heartbeat to send event on interval if video is playing unmuted.
    if (isTracked(ANALYTICS_EVENTS.heartbeat)) {
      heartbeatIntervalCb = setInterval(heartbeat.bind(this), heartbeatInterval);
    }
  }

  function dispose() {
    // Clear the heartbeat interval if set up.
    if (heartbeatIntervalCb) {
      clearInterval(heartbeatIntervalCb);
    }
  }

  function heartbeat() {
    // In case the interval is not cleaned up properly when the video is disposed of.
    if (!this) {
      return;
    }

    const volume = this.muted() === true ? 0 : this.volume();
    const isPlaying = !this.paused();
    const currentTime = Math.round(this.currentTime());

    // If video is playing and not muted, send heartbeat.
    if (volume > 0 && isPlaying) {
      sendEvent('heartbeat', false, currentTime);
    } else if (options.debug) {
      console.log(
        LOG_TAG,
        'heartbeat not sent since video not playing or is muted',
        'playing: ' + isPlaying,
        'volume: ' + volume
      );
    }
  }

  function timeUpdate() {
    const currentTime = Math.round(this.currentTime());
    const duration = Math.round(this.duration());
    const percentPlayed = Math.round(currentTime / duration * 100);

    for (let percent = 0; percent < 100; percent += percentsPlayedInterval) {
      if (percentPlayed >= percent && !percentsAlreadyTracked.includes(percent)) {
        if (isTracked(ANALYTICS_EVENTS.start) && percent === 0 && percentPlayed > 0) {
          sendEvent(ANALYTICS_EVENTS.start, true);
        } else if (isTracked(ANALYTICS_EVENTS.percentsPlayed) && percentPlayed !== 0) {
          sendEvent('percent played', true, percent);
        }

        if (percentPlayed > 0) {
          percentsAlreadyTracked.push(percent);
        }
      }
    }

    if (isTracked(ANALYTICS_EVENTS.seek)) {
      seekStart = seekEnd;
      seekEnd = currentTime;

      // if the difference between the start and the end are greater than 1 it's a seek.
      if (Math.abs(seekStart - seekEnd) > 1) {
        seeking = true;
        sendEvent('seek start', false, seekStart);
        sendEvent('seek end', false, seekEnd);
      }
    }
  }

  function end() {
    sendEvent('end', true);
  }

  function play() {
    const currentTime = Math.round(this.currentTime());
    sendEvent('play', true, currentTime);
    seeking = false;
  }

  function pause() {
    const currentTime = Math.round(this.currentTime());
    const duration = Math.round(this.duration());

    if (currentTime !== duration && !seeking) {
      sendEvent('pause', false, currentTime);
    }
  }

  function volumeChange() {
    const volume = this.muted() === true ? 0 : this.volume();
    sendEvent('volume change', false, volume);
  }

  function resize() {
    sendEvent('resize - ' + this.currentWidth() + '*' + this.currentHeight(), true);
  }

  function error() {
    const currentTime = Math.round(this.currentTime());
    sendEvent('error', true, currentTime);
  }

  function fullscreen() {
    const currentTime = Math.round(this.currentTime());
    if ((this.isFullscreen && this.isFullscreen()) || (this.isFullScreen && this.isFullScreen())) {
      sendEvent('enter fullscreen', false, currentTime);
    } else {
      sendEvent('exit fullscreen', false, currentTime);
    }
  }

  function enterPip() {
    const currentTime = Math.round(this.currentTime());
    sendEvent('enter pictureinpicture', false, currentTime);
  }

  function leavePip() {
    const currentTime = Math.round(this.currentTime());
    sendEvent('exit pictureinpicture', false, currentTime);
  }

  function rateChange() {
    sendEvent('playback rate change', false, this.playbackRate());
  }

  function isTracked(action) {
    return eventsToTrack.includes(action);
  }

  function getTrackerId() {
    return gaTrackerId;
  }

  function initGoogleAnalytics() {
    let scriptSrc = "https://www.google-analytics.com/analytics.js";
    if (options.debug) {
      scriptSrc = "https://www.google-analytics.com/analytics_debug.js";
      window.ga_debug = {
        trace: true
      };
    }
    if (typeof window.ga !== "function") {
      (function(e, t, i, r, n, a, s) {
          e.GoogleAnalyticsObject = n;
          e[n] = e[n] || function() {
            (e[n].q = e[n].q || []).push(arguments)
          },
            e[n].l = 1 * new Date;
          a = t.createElement(i),
            s = t.getElementsByTagName(i)[0];
          a.async = 1;
          a.src = r;
          s.parentNode.insertBefore(a, s)
        }
      )(window, document, "script", scriptSrc, "ga");
    }
    window.ga("create", getTrackerId(), "auto", "tastyGaTracker");
  }

  function sendEvent(action, nonInteraction = false, value) {
    if (options.debug) {
      console.log(LOG_TAG, 'event: ', action, ' ', eventLabel, ' ', nonInteraction, ' ', value);
    }

    // Send event to custom event handler if passed in.
    if (options.eventHandler && typeof options.eventHandler === 'function') {
      options.eventHandler({
        hitType: 'event',
        eventCategory,
        eventAction: action,
        eventLabel,
        eventValue: value,
        nonInteraction,
      });
    }

    // If no custom eventHandler passed in, or GA tracker specifically passed in, send GA event.
    if (!options.eventHandler || options.gaTrackerId) {
      if (window.ga) {
        ga('tastyGaTracker.send', {
          hitType: 'event',
          eventCategory,
          eventAction: action,
          eventLabel,
          eventValue: value,
          nonInteraction,
        });
      } else if (window._gaq) {
        _gaq.push(['_trackEvent', eventCategory, action, eventLabel, value, nonInteraction]);
      } else if (options.debug) {
        console.log(LOG_TAG, 'Google Analytics not detected');
      }
    }
  }

  // Bind the videojs events.
  this.on(VJS_EVENTS.loadStart, loadStart);
  this.on(VJS_EVENTS.loadedMetadata, loaded);
  this.on(VJS_EVENTS.timeUpdate, timeUpdate);
  this.on(VJS_EVENTS.dispose, dispose);

  if (isTracked(ANALYTICS_EVENTS.end)) {
    this.on(VJS_EVENTS.ended, end);
  }

  if (isTracked(ANALYTICS_EVENTS.play)) {
    this.on(VJS_EVENTS.play, play);
  }

  if (isTracked(ANALYTICS_EVENTS.pause)) {
    this.on(VJS_EVENTS.pause, pause);
  }

  if (isTracked(ANALYTICS_EVENTS.volumeChange)) {
    this.on(VJS_EVENTS.volumeChange, volumeChange);
  }

  if (isTracked(ANALYTICS_EVENTS.resize)) {
    this.on(VJS_EVENTS.resize, resize);
  }

  if (isTracked(ANALYTICS_EVENTS.error)) {
    this.on(VJS_EVENTS.error, error);
  }

  if (isTracked(ANALYTICS_EVENTS.fullscreen)) {
    this.on(VJS_EVENTS.fullscreenChange, fullscreen);
  }

  if (isTracked(ANALYTICS_EVENTS.pictureInPicture)) {
    this.on(VJS_EVENTS.enterPictureInPicture, enterPip);
    this.on(VJS_EVENTS.leavePictureInPicture, leavePip);
  }

  if (isTracked(ANALYTICS_EVENTS.rateChange)) {
    this.on(VJS_EVENTS.rateChange, rateChange);
  }
}

export default TastytradeAnalytics;
