import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import config from 'config';
import { dataLayerPush } from 'utils/dataLayer';

const OverlayContainer = styled.div`
  height: 100%;
`;

const VideoOverlay = styled.div`
  background-color: rgba(24, 24, 24, 0.49);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: ${theme('palette.common.white')};
`;

const UnmuteWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 0.275rem solid white;
  border-radius: 50%;
  font-size: 6vw;

  ${ifProp('$hero', css`
    border: 0.4375rem solid white;
    font-size: 7.2vw;
  `)}
`;

UnmuteOverlay.propTypes = {
  hero: PropTypes.bool,
  isMuted: PropTypes.bool,
  setIsMuted: PropTypes.func,
  videoPlayer: PropTypes.object,
};

function UnmuteOverlay({ hero, isMuted, setIsMuted, setStaticVideoLoaded, videoPlayer }) {
  if (videoPlayer && isMuted) {
    videoPlayer.muted(true);
  }

  const unmuteVideo = () => {
    setStaticVideoLoaded(true);

    if (videoPlayer) {
      // Unmute the player.
      videoPlayer.muted(false);
      dataLayerPush('Unmuted logged out homepage masthead video');
      videoPlayer.volume(config.livePlayer.defaultVolume);
    }

    setIsMuted(false);
  }

  return (
    <VideoOverlay $audio={isMuted}>
      <OverlayContainer>
        <IconContainer onClick={unmuteVideo}>
          <UnmuteWrapper $hero={hero}>
            <Icon icon='listen' />
          </UnmuteWrapper>
        </IconContainer>
      </OverlayContainer>
    </VideoOverlay>
  );
}

export default UnmuteOverlay;
