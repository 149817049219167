import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LeadershipCard from 'components/LeadershipCard';
import talentShape from 'shapes/talentShape';

Leadership.propTypes = {
  data: PropTypes.shape({
    leadershipLeft: talentShape,
    leadershipCenter: talentShape,
    leadershipRight: talentShape,
  })
};

function Leadership({ data }) {
  const { leadershipLeft, leadershipCenter, leadershipCenterMulti, leadershipRight } = data;
  const leadershipCenterFinal = leadershipCenterMulti?.length > 0
    ? leadershipCenterMulti
    : [leadershipCenter];

  if (!leadershipLeft && !leadershipCenter && !leadershipRight) {
    return null;
  }

  const leadershipArray = [leadershipLeft, ...leadershipCenterFinal, leadershipRight];
  const leaderCount = leadershipArray?.length;

  return (
    <Grid container spacing={2}>
      {leadershipArray.map((leadership, index) => (
        <Grid item xs={12} sm={6} lg={12 / leaderCount} key={`leader-${index}`}>
          {leadership && (
            <LeadershipCard cardCount={leaderCount} leadership={leadership} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default React.memo(Leadership);
