import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme, switchProp } from 'styled-tools';
import Container from '@material-ui/core/Container';
import StackHeader from 'components/StackHeader';
import TextColumn from 'components/ModularBlocks/TextBlock/TextColumn';
import columnSpanMap from 'constants/columnSpanMap';
import textBlockShape from 'shapes/components/textBlockShape';
import { flattenBlockStyle } from 'utils/blockStyleUtils';

const TextBlockContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1380px;
  }

  // Add specificity here to avoid custom CS styles from being overridden.
  // Reference: https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&& {
    ${ifProp('$marginBottom', css`
      margin-bottom: ${prop('$marginBottom')}rem;
    `)}

    ${ifProp('$marginTop', css`
      margin-top: ${prop('$marginTop')}rem;
    `)}

    ${ifProp('$paddingLeft', css`
      padding-left: ${prop('$paddingLeft')}rem;
    `)}

    ${ifProp('$paddingRight', css`
      padding-right: ${prop('$paddingRight')}rem;
    `)}

    ${ifProp('$paddingTop', css`
      padding-top: ${prop('$paddingTop')}rem;
    `)}

    ${ifProp('$paddingBottom', css`
      padding-bottom: ${prop('$paddingBottom')}rem;
    `)}

    ${ifProp('$background', css`
      background-color: ${prop('$background')};
    `)}
  }

  h1 {
    margin-bottom: 2rem;
  }
`;

const StyledGrid = styled(Grid)`
  ${switchProp('$columnAlignment', {
    center: css`
      justify-content: center;
    `,
  })}
`;

const propTypes = {
  data: textBlockShape.isRequired,
  className: PropTypes.string,
};

function TextBlock({ data, className }) {
  const {
    subheader,
    subheaderType,
    header,
    headerType,
    contentAlignment,
    columnAlignment,
    contentSpan,
    textColumns,
    additionalStyles,
  } = data;
  const { blockStyle } = additionalStyles || {};

  const {
    backgroundColor,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    cssClassName,
    marginBottom,
    marginTop,
  } = flattenBlockStyle(blockStyle);

  const colCount = (contentSpan && columnSpanMap[contentSpan]) || 'auto';

  return (
    <TextBlockContainer
      $background={backgroundColor}
      className={`${className} ${cssClassName || ''}`.trim()}
      maxWidth='xl'
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $paddingLeft={paddingLeft}
      $paddingRight={paddingRight}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
    >
      {(subheader || header) && (
        <Grid container>
          <Grid item xs={12} md={colCount}>
            <Box textAlign={contentAlignment}>
              {subheader && <StackHeader headerType={subheaderType} defaultHeaderType='H4'>{subheader}</StackHeader>}
              {header && <StackHeader headerType={headerType} defaultHeaderType='H2'>{header}</StackHeader>}
            </Box>
          </Grid>
        </Grid>
      )}
      {textColumns.length >= 1 &&
        <StyledGrid container spacing={4} $columnAlignment={columnAlignment}>
          {textColumns.map((textColumn, i) => (
            <TextColumn data={textColumn} key={i} />
          ))}
        </StyledGrid>
      }
    </TextBlockContainer>
  );
}

TextBlock.propTypes = propTypes;

export default React.memo(TextBlock);
