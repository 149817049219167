import React from 'react';
import PropTypes from 'prop-types';
import icons from 'assets/icons';

function Icon({ size, color, icon, className, style, alt }) {
  const IconComponent = icons[icon];

  return (
    <IconComponent
      height={`${size}em`}
      width={`${size}em`}
      className={className}
      style={style}
      fill={color}
      alt={alt}
    />
  );
}

Icon.defaultProps = {
  size: 1,
  color: 'currentColor',
  className: "",
  style: {}
}

Icon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.oneOf([
    'add',
    'addToDash',
    'addToPlaylist',
    'addToSlides',
    'alert',
    'alertTag',
    'alertTagLong',
    'alertTagShort',
    'apple',
    'arrowRight',
    'arrowForward',
    'barChart',
    'bookmarkOutline',
    'bookmarkFill',
    'calendar',
    'checkmark',
    'checkmarkBold',
    'chevronDown',
    'chevronLeft',
    'chevronRight',
    'chevronUp',
    'close',
    'createPlaylist',
    'dashboard',
    'data',
    'download',
    'edit',
    'explore',
    'facebook',
    'filter',
    'filterSlider',
    'followFill',
    'followOutline',
    'gaugeFast',
    'googlePlay',
    'grid',
    'headset',
    'home',
    'instagram',
    'lisbyn',
    'listen',
    'linkedin',
    'login',
    'play',
    'playlist',
    'plus',
    'popOut',
    'removeFill',
    'removeOutline',
    'reorder',
    'replay',
    'rss',
    'schedule',
    'search',
    'selectAll',
    'share',
    'shareV2',
    'show',
    'slidesFill',
    'slidesOutline',
    'spotify',
    'trade',
    'twitter',
    'undo',
    'upArrow',
    'watchLaterFill',
    'watchLaterOutline',
    'videoTrade',
    'youtube',
    'playCircle',
    'remove'
  ]).isRequired
}

export default React.memo(Icon);
