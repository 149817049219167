import React from 'react';
import styled from 'styled-components';
import FeaturedShow from './components/FeaturedShow';
import AutoPlayCarousel from 'components/Carousel/AutoPlayCarousel';
import CarouselCard from 'components/Carousel/CarouselCard';
import CtaButton from 'components/CtaButton';

const ShowsFeedContainer = styled.div`
  height: 100%;
  margin-bottom: 3rem;
`;

const CtaButtonContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding: 0 1rem;
`;

function ShowsFeed({data}){
  const { header, featuredShow, shows, buttonCta } = data;

  return (
    <ShowsFeedContainer>
      <FeaturedShow header={header} data={featuredShow[0]}/>
      <AutoPlayCarousel>
        {shows?.map((showData) => (
          <CarouselCard
            key={showData.id}
            image={showData.image}
            title={showData.title}
            body={showData.description}
            url={showData.url}
            buttonText={'EXPLORE'}
            gtmEvent='Show feed card clicked'
          />
        ))}
      </AutoPlayCarousel>
      <CtaButtonContainer>
        <CtaButton data={buttonCta}/>
      </CtaButtonContainer>
    </ShowsFeedContainer>
  )
}

export default React.memo(ShowsFeed);
