import React from 'react';
import PropTypes from 'prop-types';
import BlockStyle from './BlockStyle';

const ONE_COLUMN = 'showOneColumn';
const TWO_COLUMN = 'showTwoColumn';
const THREE_COLUMN = 'showThreeColumn';

BlockWrapper.propTypes = {
  blockType: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.node,
};

function BlockWrapper({ blockType, data, children, ...props }) {
  const columnArray = [ONE_COLUMN, TWO_COLUMN, THREE_COLUMN];
  const columnBlock = columnArray.includes(blockType);

  // Some blocks are a reference field to separate content type that may also have some base block styles.
  // If that's the case, it's referenced via a blockReference field, and we want any blockStyle directly on data to overwrite
  // and conflicting block style properties.
  const refBlockStyle = data.blockReference?.blockStyle || [];
  const blockStyles = [...refBlockStyle, ...(data.blockStyle || [])];

  return (
    <BlockStyle blockStyle={blockStyles} my={columnBlock ? 1 : 2.5} {...props}>
      {children}
    </BlockStyle>
  );
}

export default React.memo(BlockWrapper);
