import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';

const AbsoluteOverlayElement = styled.div`
  position: absolute;
  top: ${prop('$top')};
  right: ${prop('$right')};
  bottom: ${prop('$bottom')};
  left: ${prop('$left')};
  opacity: ${prop('$opacity')};
  background: ${prop('$background')};
  ${ifProp('$zIndex', css`
    z-index: ${prop('$zIndex')};
  `)};
`;

AbsoluteOverlay.propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  background: PropTypes.string,
  opacity: PropTypes.number,
  zIndex: PropTypes.number,
  children: PropTypes.node
}

function AbsoluteOverlay({
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  background = 'transparent',
  opacity = 0,
  zIndex,
  children = null
}) {

  return (
    <AbsoluteOverlayElement
      $top={top}
      $right={right}
      $bottom={bottom}
      $left={left}
      $background={background}
      $opacity={opacity}
      $zIndex={zIndex}
    >
      {children}
    </AbsoluteOverlayElement>
  )
}

export default React.memo(AbsoluteOverlay);
