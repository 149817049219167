import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

ErrorAlert.propTypes = {
  children: PropTypes.node,
};

function ErrorAlert({ children, ...props }) {
  return (
    <Alert severity='error' {...props}>
      {children}
      <br />
      If you continue seeing this error, please contact support at <a href='mailto:support@tastylive.com'>support@tastylive.com</a>.
    </Alert>
  );
}

export default ErrorAlert;
