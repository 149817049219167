
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BlogPostListFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BlogPost"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"metadata"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlogPostMetadataListFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"seo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SEOFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":349}};
    doc.loc.source = {"body":"#import \"graphql-fragments/content/AssetFields.graphql\"\n#import \"graphql-fragments/content/BlogPostMetadataListFields.graphql\"\n#import \"graphql-fragments/content/SEOFields.graphql\"\n\nfragment BlogPostListFields on BlogPost {\n  uid\n  url\n  title\n  postDate\n  updatedAt\n  metadata {\n    ...BlogPostMetadataListFields\n  }\n  seo {\n    ...SEOFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("graphql-fragments/content/AssetFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("graphql-fragments/content/BlogPostMetadataListFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("graphql-fragments/content/SEOFields.graphql").definitions));


      module.exports = doc;
    
