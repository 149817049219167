import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { theme } from 'styled-tools';
import DataUsageItemField from 'components/DataPrivacyCapture/components/DataUsageConsentForm/DataUsageItemField';
import { FormFooter } from '../FormFooter';
import { dataStoreItems } from '../../const';
import VerifyForm from './verifyForm';

const SectionHeader = styled.h3`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

DataUsageConsentForm.propTypes = {
  dataUsageDefinitions: PropTypes.arrayOf(PropTypes.object),
  dataPrivacySettings: PropTypes.object,
  handleSave: PropTypes.func,
};

function DataUsageConsentForm({ dataUsageDefinitions, dataPrivacySettings, handleSave }) {
  const [inputData, setInputData] = useState();
  const [showVerify, setShowVerify] = useState(false);
  const [rejectedItems, setRejectedItems] = useState([]);

  const initialValues = useMemo(() => {
    return {
      // Data usage items will default to true, unless user previously refused the item.
      dataUsageItems: (dataUsageDefinitions || []).reduce((itemMap, definition) => {
        const userItem = (dataPrivacySettings.dataUsageItems || {})[definition.id];
        // Default data usage items to true, so we check if it hasn't been refused.
        if (!userItem) {
          itemMap[definition.id] = true;
          return itemMap;
        }

        itemMap[definition.id] = !userItem.rejectedAt;
        return itemMap;
      }, {}),
    };
  }, [dataPrivacySettings, dataUsageDefinitions]);

  function handleSubmitForm(data) {
    setInputData(data);
    const rejected = [];
    const itemsToWarn = Object.keys(dataStoreItems);

    data.dataUsageItems.forEach(x => {
      if (x.rejectedAt && itemsToWarn.includes(x.id)) {
        rejected.push(x.id)
      }
    });

    setRejectedItems(rejected);

    if (rejected.length > 0) {
      setShowVerify(true);
    } else {
      handleSave(data);
    }
    
  }
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const inputData = {
          dataUsageItems: Object.keys(values.dataUsageItems).map(key => {
            const definition = dataUsageDefinitions.find(x => x.id === key);
            if (!definition) {
              throw new Error(`Invalid data usage item id ${key}`);
            }

            const newValue = values.dataUsageItems[key];

            // If user already accepted or rejected this version, we'll keep the original value.
            const existing = dataPrivacySettings?.dataUsageItems?.[key];

            const consentField = newValue ? 'acceptedAt' : 'rejectedAt';
            const clearField = newValue ? 'rejectedAt' : 'acceptedAt';

            const keepOriginal = existing && existing.version === definition.version && !!existing[consentField];
            if (keepOriginal) {
              return {
                id: key,
                version: existing.version,
                acceptedAt: existing.acceptedAt,
                rejectedAt: existing.rejectedAt,
              };
            }

            return {
              id: key,
              version: definition.version,
              [consentField]: dayjs.utc().toISOString(),
              [clearField]: null,
            };
          }),
        };

        await handleSubmitForm(inputData);
        setSubmitting(false);
      }}
    >
      {({ submitForm, isSubmitting, errors, touched }) => (
        <>
          {showVerify ? (
              <VerifyForm
                clickBack={() => setShowVerify(false)}
                clickSave={() => handleSave(inputData)}
                rejectedItems={rejectedItems}
              />
            ) : (
              <>
                <DialogContent dividers>
                  <Form id='dataUsageConsentForm'>
                    <SectionHeader>Data Usage Consent</SectionHeader>
                    {(dataUsageDefinitions || []).length > 0 && (
                      <DialogContentText color='textPrimary'>
                        {dataUsageDefinitions.map(definition => (
                          <DataUsageItemField
                            key={definition.id}
                            dataUsageDefinition={definition}
                            touched={touched}
                            errors={errors}
                            disabled={isSubmitting}
                          />
                        ))}
                      </DialogContentText>
                    )}
                  </Form>
                </DialogContent>
                <FormFooter isSubmitting={isSubmitting} formType='dataUsageConsentForm' />
              </>
            )
          }
        </>
      )}
    </Formik>
  );
}

export default DataUsageConsentForm;
