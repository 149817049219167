import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useAuth } from 'context/AuthContext';
import { useMutation, useQuery } from '@apollo/client';
import dataPrivacyDefinitionsQuery from './dataPrivacyDefinitions.graphql';
import updateDataPrivacySettingsMutation from './updateDataPrivacySettings.graphql';
import ScopesAndPoliciesForm from 'components/DataPrivacyCapture/components/ScopesAndPoliciesForm';
import DataUsageConsentForm from 'components/DataPrivacyCapture/components/DataUsageConsentForm';
import { DATA_PRIVACY_CLAIM } from 'utils/constants';
import SimpleDialog from 'components/SimpleDialog';

const ModalTitle = styled.h2`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0;
`;

DataPrivacyCapture.propTypes = {
  form: PropTypes.oneOf(['FORM_SCOPES_AND_POLICIES', 'FORM_DATA_USAGE_CONSENT']),
  onSave: PropTypes.func
};

const FORM_SCOPES_AND_POLICIES = 'FORM_SCOPES_AND_POLICIES';
const FORM_DATA_USAGE_CONSENT = 'FORM_DATA_USAGE_CONSENT';

function DataPrivacyCapture({ form, onSave }) {
  const { user, refetchUser } = useAuth();
  const { data } = useQuery(dataPrivacyDefinitionsQuery, {
    skip: !user || typeof window === 'undefined',
  });

  const [updateDataPrivacySettings] = useMutation(updateDataPrivacySettingsMutation);
  const [open, setOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(FORM_SCOPES_AND_POLICIES);
  const dataPrivacySettings = user && user[DATA_PRIVACY_CLAIM] || {};
  const { regulatoryScopes: baseRegulatoryScopes = [], dataUsageItems } = data || {};
  const { privacyAcknowledgements = [], dataUsageDefinitions = [] } = dataUsageItems || {};
  // Filter out any disabled regulatory scopes.
  const regulatoryScopes = baseRegulatoryScopes.filter(x => x.enabled);

  const {
    regulatoryScopesToAsk,
    privacyAcknowledgementsToAsk,
    dataUsageItemsToAsk,
    usageConsentUpdates
  } = useMemo(() => {
    if (!user || !data) {
      return {}
    }

    const regulatoryScopesToAsk = regulatoryScopes.filter(({ id }) => {
      const userItem = dataPrivacySettings.regulatoryScopes?.[id];
      return typeof userItem === 'undefined' || userItem === null;
    });

    const privacyAcknowledgementsToAsk = privacyAcknowledgements.filter(({ id, version }) => {
      const userItem = dataPrivacySettings.privacyAcknowledgements?.[id];
      return !userItem || userItem.version !== version;
    });

    const dataUsageItemsToAsk = dataUsageDefinitions.filter(({ id }) => {
      const userItem = dataPrivacySettings.dataUsageItems?.[id];
      return !userItem || (!userItem.acceptedAt && !userItem.rejectedAt);
    });

    return {
      regulatoryScopesToAsk,
      privacyAcknowledgementsToAsk,
      dataUsageItemsToAsk,
    }
  }, [user, data])

  useEffect(() => {
    if (form) {
      setCurrentForm(form);
      setOpen(true);
    } else if (regulatoryScopesToAsk && privacyAcknowledgementsToAsk && dataUsageItemsToAsk) {
      // Determine if there are data privacy acknowledges needed from the user.
      // TODO: compare the user's dataPrivacySettings data that's saved and find
      // out if they are missing any optins/questions, or if their accepted privacy policy
      // was an outdated version (just check version !== version basically).
      // TEMP: For now we only check privacy.
      const requiresUpdates = regulatoryScopesToAsk.length > 0 ||
        privacyAcknowledgementsToAsk.length > 0 ||
        dataUsageItemsToAsk.length > 0;

      // If we only have dataUsageItems to ask, we should jump to the FORM_DATA_USAGE_CONSENT page.
      if (requiresUpdates && regulatoryScopesToAsk.length === 0 && privacyAcknowledgementsToAsk.length === 0) {
        setCurrentForm(FORM_DATA_USAGE_CONSENT);
      }

      setOpen(requiresUpdates);
    }
  }, [regulatoryScopesToAsk, privacyAcknowledgementsToAsk, dataUsageItemsToAsk]);

  const handleSave = useCallback(async (inputData) => {
    await updateDataPrivacySettings({
      variables: {
        input: inputData
      }
    });

    // Refetch the user object with the updated settings reflected.
    // Otherwise Move on to the data usage form, or close modal if this is the data usage form.
    await refetchUser();

    // If being called on command with a form prop, close on save
    if (form) {
      await onSave();
      setOpen(false)
    } else if (FORM_SCOPES_AND_POLICIES === currentForm && dataUsageItemsToAsk > 0) {
      setCurrentForm(FORM_DATA_USAGE_CONSENT);
    } else {
      setOpen(false);
    }
  }, [updateDataPrivacySettings, refetchUser, currentForm]);

  if (!user || !data) {
    return null;
  }

  return (
    <SimpleDialog
      open={open}
      title={<ModalTitle>Data Privacy Settings</ModalTitle>}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {FORM_SCOPES_AND_POLICIES === currentForm && (
        <ScopesAndPoliciesForm
          handleSave={handleSave}
          regulatoryScopes={regulatoryScopes}
          dataPrivacySettings={dataPrivacySettings}
          privacyAcknowledgements={privacyAcknowledgements}
        />
      )}
      {FORM_DATA_USAGE_CONSENT === currentForm && (
        <DataUsageConsentForm
          handleSave={handleSave}
          dataUsageDefinitions={dataUsageDefinitions}
          dataPrivacySettings={dataPrivacySettings}
        />
      )}
    </SimpleDialog>
  );
}

export default DataPrivacyCapture;
