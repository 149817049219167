import React from 'react';
import PageWrapper from 'components/PageWrapper';

LoadingPage.propTypes = {};

function LoadingPage() {
  return (
    <PageWrapper data-testid='emptyLoadingPage'>

    </PageWrapper>
  );
}

export default React.memo(LoadingPage);
