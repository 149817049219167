import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from 'components/Footer';
import Disclaimer from 'components/Disclaimer';
import MainSection from './MainSection';
import layoutQuery from 'components/Layout/getLayout.graphql';
import { useSiteSettings } from 'context/SiteSettingsContext';
import StickyFooterAd from 'components/Ads/StickyFooterAd';
import LoadingPage from 'components/LoadingPage';
import { DFPSlotsProvider } from 'react-dfp';

function Layout({
  children,
  title = 'tastylive | a real financial network | financial news and entertainment'
}) {
  const [showStickyAd, setShowStickyAd] = useState(true);
  const { loading, error, data } = useQuery(layoutQuery);
  const { siteSettings } = useSiteSettings();

  if (loading) {
    return <LoadingPage />;
  }

  const { header, footer } = (data && data.layout) || {};
  const { siteAlerts } = siteSettings || {};

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{title}</title>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7988198.js"></script>
        <script async src="https://siteimproveanalytics.com/js/siteanalyze_6275188.js"></script>
      </Helmet>
      <DFPSlotsProvider dfpNetworkId={process.env.RAZZLE_DFP_NETWORK_ID || '27309309'} collapseEmptyDivs>
        <MainSection
          children={children}
          header={header}
          siteAlerts={siteAlerts}
          showStickyAd={showStickyAd}
        />
        <>
          {footer && <Footer data={footer} />}
          {footer && footer.disclaimers && <Disclaimer disclaimers={footer.disclaimers} />}
        </>
      </DFPSlotsProvider>
    </>
  );
}

export default React.memo(Layout);
