import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import linkResolver from 'utils/linkResolver';
import getDefinitionsQuery from '../../../../graphql/queries/getDefinitions.graphql';

const Card = styled(Link)`
  background: ${theme('colors.white')};
  border: 1px solid ${theme('colors.black')};
  border-radius: 4px;
  color: ${theme('colors.black')};
  display: flex;
  margin-bottom: 1.25rem;
  padding: 1rem;
  text-decoration: none;

  &:hover {
    background: ${theme('colors.ttRed')};
    color: ${theme('colors.white')};
  }
`;

CategoryContainer.propTypes = {
  categoryId: PropTypes.string
};

function CategoryContainer({ categoryId }) {
  const { data, error, loading, fetchMore } = useQuery(getDefinitionsQuery, {
    variables: {
      filters: {
        limit: 50,
        displayLearn: true,
        categoryFilters: categoryId
      },
    },
    fetchPolicy: 'network-only',
  });

  const definitions = get(data, 'definitions.items', []);

  return (
    <>
      {definitions.map((definition, index) => {
        const { title, url } = definition;
        return (
          <Card to={url}>
            {title}
          </Card>
        );
      })}
    </>
  );
}

export default CategoryContainer;
