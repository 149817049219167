/** @module tt/context/AuthContext */

import React, { useReducer, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { dataLayerPush } from 'utils/dataLayer';

function getDefaultState(preloadedState = {}) {
  const DEFAULT_STATE = {
    user: undefined,
    isAuthenticating: false,
    error: undefined,
  };

  return {
    ...DEFAULT_STATE,
    ...preloadedState
  };
}

const ACTION_SET_USER = 'setUser';
const ACTION_LOADING_USER = 'loadingUser';
const ACTION_AUTH_ERROR = 'authError';

function authReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticating: false,
      };
    case ACTION_LOADING_USER:
      return {
        ...state,
        user: null,
        isAuthenticating: true,
      };
    case ACTION_AUTH_ERROR:
      return {
        ...state,
        isAuthenticating: false,
        error: action.payload,
        user: null,
      };
    default:
      return state;
  }
}

const AuthContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  refetchUser: () => {},
});

export const AuthProvider = ({ children, value }) => {
  const [state, dispatch] = useReducer(
    authReducer,
    getDefaultState(value)
  );
  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
  });

  const fetchUser = async (refetch = false) => {
    if (state.user !== undefined && !refetch) {
      return state.user;
    }
    const params = {};
    if (refetch) {
      params.refetch = true;
    }
    const res = await axios.get('/api/me', { params });
    return res && res.data ? res.data : null;
  };

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    let isMounted = true;

    setContextValue((contextValue) => ({
      ...contextValue,
      state,
      refetchUser: () => {
        return fetchUser(true).then(user => {
          // Only set the user if the component is still mounted
          if (isMounted) {
            dispatch({ type: ACTION_SET_USER, payload: user });
          }
        }).catch(error => {
          console.error('FAILED REFETCH: ', error);
          dispatch({ type: ACTION_AUTH_ERROR, payload: error });
        });
      }
    }));

    return () => {
      isMounted = false;
    };
  }, [state]);

  useEffect(() => {
    if (state.user !== undefined) {
      sessionStorage.setItem(config.localStorageKeys.showStickyFooterAd, 'true');
      return;
    }
    let isMounted = true;

    dispatch({ type: ACTION_LOADING_USER });
    fetchUser().then(user => {
      // Only set the user if the component is still mounted
      if (isMounted) {
        dispatch({ type: ACTION_SET_USER, payload: user });
      }
    }).catch(error => dispatch({ type: ACTION_AUTH_ERROR, payload: error }));
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let status_exists = false;
    window?.dataLayer?.forEach((item) => { //if there's already a user status, ignore
      if (item['user_status']) {
        status_exists = true;
      }
    })
    if (!status_exists) {
      dataLayerPush(state.user ? 'logged in' : 'logged out', 'user_status')
    }
  }, [state])


  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

/**
 * The main API for useAuth
 *
 * @return {function} isAuthenticated is current user authenticated
 * @return {boolean} isAuthenticating currently running authentication
 * @return {function} isAuthorized check if current user is authenticated and matches list of roles
 * @return {object} user current user
 * @return {string} userId current user's identifier
 * @return {object} authResult raw authentication result object from auth provider
 * @return {function} login start the login process
 * @return {function} signup same as login, passes { mode: "signUp", screen_hint: "signup" } to Auth0
 * @return {function} logout start the logout process
 * @return {function} handleAuthentication function to call on your callback page
 */
export function useAuth() {
  const { state, refetchUser } = useAuthContext();

  const isAuthenticated = () => {
    return !!state.user;
  };

  return {
    isAuthenticating: state.isAuthenticating,
    isAuthenticated,
    user: state.user,
    userId: state.user ? state.user.sub : null,
    refetchUser,
  };
}
