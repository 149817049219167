import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

const StyledForm = styled.form`
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
`

const StyledFullWidthSearchBar = styled.input`
  border: none;
  width: 100%;
  height: inherit;
  min-height: 3.75rem;
  font-size: 1rem;

  &:focus {
    border: 0;
    outline: none;
  }
`;

FullWidthSearchBar.propTypes = {
  onRequestSearch: PropTypes.func,
};

function FullWidthSearchBar({ onRequestSearch, setShowSearchBar, ...props }) {
  const [searchValue, setSearchValue] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    onRequestSearch(encodeURIComponent(searchValue));
    setShowSearchBar(false)
    setSearchValue('')
  }

  return (
    <>
      <SearchIcon onClick={handleSubmit} />
      <StyledForm onSubmit={handleSubmit}>
        <StyledFullWidthSearchBar
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder='Search for anything'
          {...props}
        />
      </StyledForm>
    </>

  );
}

export default FullWidthSearchBar;
