import React from 'react';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import BaseContainer from '@material-ui/core/Container';
import getDefinitionsQuery from '../../../../graphql/queries/getDefinitions.graphql';
import { alphaLetters } from 'constants/alphabet';
import LetterNav from './LetterNav';
import List from './List';

const Container = styled(BaseContainer)`
  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
`;

const propTypes = {
  glossaryHeader: PropTypes.string,
  textBody: PropTypes.string,
};

function ListContainer({ glossaryHeader, textBody }) {
  const { data, error, loading, fetchMore } = useQuery(getDefinitionsQuery, {
    variables: {
      filters: {
        limit: 50,
        displayGlossary: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const definitions = get(data, 'definitions.items', []);

  if (!definitions) {
    return null;
  }

  const letterArray = [];

  // Looping through the array of the alphabet and then looping through the list
  // of definitions. Then a new key/value pair is added to the definition object
  // which adds the letter than each definition starts with as an available value.
  alphaLetters.forEach((letter) => {
    const letterCompare = get(letter, 'letter');

    definitions.forEach((definition) => {
      if (definition.title.startsWith(letterCompare.toUpperCase())) {
        letterArray.push({
          letterCategory: letterCompare,
          ...definition
        });
      }
    })
  });

  // Creating a new object that is grouping each of the definitions by the
  // category; the category is the letter each definition starts with so this
  // is grouping the definitions by their alphabet letter
  const mapByLetters = groupBy(letterArray, 'letterCategory');
  const loadingLetters = Object.keys(mapByLetters).length === 0;

  // Changing the status for letters that have definition from 'inactive' to
  // 'active'; if a letter does not have terms in the glossary then its status
  // will remain inactive for proper mapping
  Object.keys(mapByLetters).forEach((mapLetter) => {
    alphaLetters.forEach((letter) => {
      if (mapLetter === letter.letter) {
        letter.status = 'active';
      }
    });
  });

  return (
    <>
      <LetterNav
        alphaLetters={alphaLetters}
        glossaryHeader={glossaryHeader}
        textBody={textBody}
      />
      <Container maxWidth='xl'>
        <List
          glossaryHeader={glossaryHeader}
          loadingLetters={loadingLetters}
          mapByLetters={mapByLetters}
        />
      </Container>
    </>
  );
}

ListContainer.propTypes = propTypes;

export default ListContainer;
