import React from 'react';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Typography from '@material-ui/core/Typography';

const Header = styled(Typography)`
  font-family: ${prop('theme.typography.fontFamilySecondary')};
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const Subheader = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;

  ${props => props.theme.breakpoints.up('lg')} {
    font-size: 1.5rem;
  }
`;

const List = styled.ul`
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
  padding-left: 1.5rem;

  ${props => props.theme.breakpoints.up('sm')} {
    margin: 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    font-size: 1.5rem;
  }
`;

const ListItem = styled.li`
  font-weight: 500;
`;

const GridContainer = styled(Grid)`
  margin-bottom: 5.625rem;
`;

const ButtonPrimary = styled(Button)`
  border-radius: 0.3125rem;
  color: ${prop('theme.colors.white')};
  margin-bottom: 2.5rem;
  width: 100%;

  ${ifProp({ type: 'primary'}, css`
    background-color: ${prop('theme.colors.ttRed')};

    &:hover {
      background-color: ${prop('theme.colors.darkRed')};
    }
  `)}
`;

const ButtonLink = styled(Button)`
  width: 100%;
`;

function CancelAccount({ onClose }) {
  return (
    <div>
      <Header variant='h4'>Cancel Your Account</Header>
      <Box mb={5}>
        <Subheader variant='h4'>
          Are you sure? By canceling your tasty<em>live</em> account, you will no longer
          be able to:
        </Subheader>
      </Box>
      <GridContainer container>
        <Grid item xs={12}>
          <List>
            <ListItem>Get trade ideas delivered to your inbox</ListItem>
            <ListItem>Download research studies to boost your trading</ListItem>
            <ListItem>Create playlists of your favorite content</ListItem>
            <ListItem>Follow real-time trades placed by industry leaders</ListItem>
          </List>
        </Grid>
      </GridContainer>
      <ButtonPrimary type='primary' variant='contained' onClick={onClose}>
        Keep My Account
      </ButtonPrimary>
      <ButtonLink href='mailto:support@tastylive.com?subject=Cancel my tastylive account'>Cancel My Account</ButtonLink>
    </div>
  );
}

export default React.memo(CancelAccount);
