import React, { useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Panel, InfoRow, Label, LinkContainer } from './SharedStyles';
import updateAuth0UserMutation from '../../../../graphql/mutations/updateAuth0User.graphql';
import auth0UserShape from 'shapes/auth0UserShape';
import { theme } from 'styled-tools';
import { dataLayerPush } from 'utils/dataLayer';

const UpdateLink = styled.button`
  font-size: 0.875rem;
  padding: 0;
  background-color: ${theme('colors.white')};
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  
  &:hover {
    color: ${theme('colors.ttRed')};
  }
  
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

PasswordPanel.propTypes = {
  auth0Id: PropTypes.string,
  personalInfo: auth0UserShape,
  socialUser: PropTypes.bool,
};

function PasswordPanel({ auth0Id, personalInfo, socialUser }) {
  const [success, setSuccess] = useState(false);
  const [updateAuth0User] = useMutation(updateAuth0UserMutation);
  const { email } = personalInfo;

  const handleUpdateAuth0User = (auth0Id, email) => {
    updateAuth0User({
      variables: {
        auth0Id: auth0Id,
        input: {
          email: email,
        },
        type: 'password'
      },
    }).then(response => {
      setSuccess(get(response, 'data.updateMyAuth0User.success'));
      dataLayerPush(`Click to 'Update Password'`);
    });
  }

  return (
    <Panel item xs={12} sm={12} md={4}>
      <InfoRow>
        <div>
          <Label>Password</Label>
          <Typography>•••••••••••••••••••</Typography>
        </div>
        {!socialUser && (
          <LinkContainer>
            <UpdateLink onClick={() => handleUpdateAuth0User(auth0Id, email)}>
              Update Password
              <ChevronRightIcon />
            </UpdateLink>
          </LinkContainer>
        )}
      </InfoRow>
      {success &&
        <Alert severity='success'>
          Check your email for a message with password reset instructions!
        </Alert>
      }
    </Panel>
  );
}

export default React.memo(PasswordPanel);
