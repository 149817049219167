import React from 'react';
import PropTypes from 'prop-types';
import menuItemShape from 'shapes/menuItemShape';
import UserLogin from './UserLogin';
import UserSignUp from './UserSignUp';
import UserAccountMenu from './UserAccountMenu';
import UserDashboard from './UserDashboard';


const specialBehaviorComponents = {
  user_account_menu: UserAccountMenu,
  user_sign_up: UserSignUp,
  user_login: UserLogin,
  user_dashboard: UserDashboard,
};

SpecialBehaviorLink.propTypes = {
  menuItem: menuItemShape.isRequired,
  component: PropTypes.elementType,
  mobile: PropTypes.bool,
};

function SpecialBehaviorLink({ menuItem, component, mobile = false, ...props }) {
  const LinkComponent = specialBehaviorComponents[menuItem.specialBehavior];

  if (!LinkComponent) {
    console.error(`No component found for special behavior link type "${menuItem.specialBehavior}"`);

    return null;
  }

  return <LinkComponent menuItem={menuItem} component={component} mobile={mobile} {...props} />;

}


export default React.memo(SpecialBehaviorLink);
