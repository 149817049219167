import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import CheckboxList from 'components/AccordionGroup/CheckboxList';
import { dataLayerPush } from 'utils/dataLayer';
import { useDashboardSlidesContext } from 'context/Dashboard/Slides';

MySlidesSidebar.propTypes = {
  component: PropTypes.elementType,
};

function MySlidesSidebar({ component: Component = CheckboxList }) {
  const { shows: slideDecksShows, setSelectedShows, refetchShows } = useDashboardSlidesContext();
  const { loading, error, selected } = slideDecksShows;

  useEffect(() => {
    refetchShows();
    return () => setSelectedShows([])
  }, [])

  if (error) {
    return <Alert severity='error'>Failed to load your shows with slides.</Alert>
  }

  const shows = slideDecksShows.data?.items?.map(show => ({
    name: show.uid,
    label: show.title,
    checked: selected.includes(show.uid)
  }));

  function filterSlides(showUids) {
    setSelectedShows(showUids);
    dataLayerPush(`Clicked to filter 'My Slides' on the Dashboard`);
  }

  return (
    <>
      {shows?.length > 0 && (
        <Component
          values={shows}
          loading={loading}
          onChange={(showUids) => filterSlides(showUids)}
        />
      )}
    </>
  );
}

export default React.memo(MySlidesSidebar);
