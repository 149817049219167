import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/Button';
import styled from 'styled-components';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { dataStoreItems} from '../../const';
import PropTypes from 'prop-types';

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-between;
`;

VerifyForm.propTypes = {
  clickBack: PropTypes.func.isRequired,
  clickSave: PropTypes.func.isRequired,
  rejectedItems: PropTypes.array.isRequired
};

function VerifyForm({ clickBack, clickSave, rejectedItems }) {
  const [submitting, setSubmitting] = useState(false);

  function handleClickSave() {
    setSubmitting(true);
    clickSave();
  }

  return (
    <>
      <DialogContent>
        <p>
          Warning!
          If you’re an existing user, any of the following items you have saved will be permanently deleted by opting out of data storage
        </p>
        { rejectedItems.map((rejectedItem, key) => {
          const willDelete = dataStoreItems[rejectedItem];

          return (
            <div key={`rejectedItem-${key}`}>
              <b>{willDelete.data}</b>
              <ul>
                {willDelete.deletedItems.map((willDeleteItem, key) => (
                  <li key={`willDeleteItem-${key}`}>{willDeleteItem}</li>
                ))}
              </ul>
            </div>
          )
        })}

      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          onClick={clickBack}
        >
          Back
        </Button>
        <Button
          type='button'
          onClick={handleClickSave}
          disabled={submitting}
          variant='contained'
          color='primary'
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default VerifyForm;