import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EpisodeCard from 'components/EpisodeCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import UndoSnackBar from 'components/UndoSnackBar';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { useDashboardContext } from 'context/DashboardContext';

const InfiniteScrollContainer = styled.div`
  ${ifProp({$edit: true}, css`
    cursor: pointer;
  `)};

  &&& {
    .infinite-scroll-component {
      overflow: visible !important;
    }
  }
`;

ContentList.propTypes = {
  edit: PropTypes.bool,
  items: PropTypes.array,
  display: PropTypes.oneOf(['listItem', 'vertical', 'horizontal']),
  fetchNextPage: PropTypes.func,
  hasMore: PropTypes.bool,
  deletedMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onDeleteItems: PropTypes.func.isRequired,
  excludedActions: PropTypes.array,
  lastViewedDate: PropTypes.object,
  onCheck: PropTypes.func,
  selectedItems: PropTypes.array,
  fetchMoreItems: PropTypes.func.isRequired,
  onUndoRemoveEpisodes: PropTypes.func.isRequired,
  onUnfollowOverride: PropTypes.func
};

function ContentList({
  edit,
  items,
  display,
  fetchNextPage,
  hasMore,
  deletedMessage = 'Item removed from dashboard',
  onDeleteItems,
  excludedActions,
  lastViewedDate,
  onCheck,
  selectedItems = [],
  fetchMoreItems,
  onUndoRemoveEpisodes,
  onUnfollowOverride
}) {
  const [itemList, setItemList] = useState({current: [], previous: []})
  const [toDelete, setToDelete] = useState(null);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const { state: dashboardState } = useDashboardContext();
  const { deleteItems } = dashboardState;

  useEffect(() => {
    //when navigating away from the page or refreshing, instantly delete whatever is still set to delete
    return () => onDeleteItems && onDeleteItems(toDeleteRef.current);
    }, [])

  useEffect(() => {
    setItemList({...toDelete, current: items});
  }, [items])

  useEffect(() => {
    if(selectedItems.length) {
      handleClickDelete(selectedItems)
    }
  }, [deleteItems])
  // this function must take an ARRAY of items
  async function handleClickDelete(removedItems) {
    await setShowSnackBar(false)
    //if we delete multiple items in rapid succession we need to delete the old one before replacing the state prop with the new one
    onDeleteItems && onDeleteItems(toDeleteRef.current);

    // set the next item to be deleted
    setToDelete(removedItems);
    setShowSnackBar(true);

    if (fetchMoreItems) {
      fetchMoreItems(removedItems)
    }
  }

  function handleAllowTimeout() {
    onDeleteItems && onDeleteItems(toDeleteRef.current);
    setShowSnackBar(false);
  }

  function handleUndoDeleteItem() {
    onUndoRemoveEpisodes(toDeleteRef.current);
    setShowSnackBar(false);
    setToDelete(null);
  }

  function handleClickItem(item) {
    if (edit) {
      onCheck(item)
    }
  }

  return (
    <InfiniteScrollContainer $edit={edit}>
      <InfiniteScroll
        dataLength={itemList.current?.length} // This is important field to render the next data
        next={fetchNextPage}
        hasMore={showSnackBar ? false : hasMore}
        loader={<h4>Loading...</h4>}
        className='MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2'
        scrollableTarget='dashboardScrollContainer'
      >
        {itemList.current?.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={display === 'horizontal' ? 12 : 6}
            lg={display === 'horizontal' ? 12 : 3}
          >
            <EpisodeCard
              display={display}
              episode={item}
              edit={edit}
              onDelete={() => handleClickDelete(item)}
              excludedActions={excludedActions}
              isNewContent={lastViewedDate && dayjs(item.publishedAt).isAfter(dayjs(lastViewedDate))}
              checked={selectedItems.includes(item.uid)}
              onCheck={() => handleClickItem(item)}
              onUnfollowOverride={onUnfollowOverride ? () => handleClickDelete(item.show) : undefined}
            />
          </Grid>
        ))}
      </InfiniteScroll>
      {showSnackBar &&
        <UndoSnackBar
          episode={toDelete}
          onAllowTimeOut={handleAllowTimeout}
          onUndo={handleUndoDeleteItem}
          message={deletedMessage}
        />
      }
    </InfiniteScrollContainer>
  );
};

export default React.memo(ContentList);
