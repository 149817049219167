import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';

const MegaMenuImageWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  padding-top: 56.25%;
  position: relative;
`;

export default MegaMenuImageWrapper;
