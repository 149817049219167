import React, { useEffect } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import BaseContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CategoryContainer from './CategoryContainer';
import getDefinitionCategories from '../../../../graphql/queries/getDefinitionCategories.graphql';

// Matching max-width for header here
const Container = styled(BaseContainer)`
  max-width: 1644px;

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

const Title = styled(Typography)`
  ${switchProp('$level', {
    primary: css`
      margin-bottom: 1.5rem;
    `,
    secondary: css`
      font-size: 1.375rem;
      margin-bottom: 1rem;

      ${props => props.theme.breakpoints.up('lg')} {
        font-size: 1.5rem;
      }
    `
  })}
`;

const CategorySection = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

LearnTermsContainer.propTypes = {
  glossaryHeader: PropTypes.string,
  textBody: PropTypes.string,
};

function LearnTermsContainer({ glossaryHeader, textBody }) {
  const { data, error, loading, fetchMore } = useQuery(getDefinitionCategories);

  useEffect(() => {
    configureAnchors({ offset: -162, offscrollDuration: 50 });
  });

  const categories = get(data, 'definitionCategories.items', []);

  return (
    <ScrollableAnchor id='supplemental-terms'>
      <Container maxWidth='xl'>
        <Title variant='h2' $level='primary'>{glossaryHeader}</Title>
        <Grid container spacing={4}>
          {categories.map((category, index) => {
            const { uid, url, title } = category;

            return(
              <CategorySection item xs={12} sm={6} md={3} lg={3}>
                <Title variant='h3' $level='secondary'>{title}</Title>
                <CategoryContainer categoryId={uid} />
              </CategorySection>
            )
          })}
        </Grid>
      </Container>
    </ScrollableAnchor>
  );
}

export default LearnTermsContainer;
