import React from 'react';
import Grid from '@material-ui/core/Grid';
import SlidesCard from 'components/SlidesCard';

function MySlidesLoading() {

  return (
    <Grid container spacing={2}>
      {Array.from(new Array(8)).map((episode, index) => (
        <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
          <SlidesCard loading={true} />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(MySlidesLoading);
