export function getRawText(text) {
  let rawText = text.replace(/<[^>]*>/g," "); //Replaces <[^>]*> with an empty string
  rawText = rawText.replace(/\s+/g, ' '); //Replaces \s+ with an empty string
  rawText = rawText.replace(/&nbsp;/g, ' '); //Replaces &nbsp; with a space
  rawText = rawText.replace(/&#x27;/g, "'");   //Replaces &#x27; with apostrophe
  rawText = rawText.replace(/%3A/g, ":"); // Replaces %3A with :
  rawText = rawText.trim();
  return rawText;
}

export function getRawTextWordCount(text) {
  const rawText = getRawText(text);
  return rawText.split(" ").length;
}