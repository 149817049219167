import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { prop, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import adSlotBlockShape from 'shapes/components/adSlotBlockShape';
import AdSlot from './AdSlot';

const AdBlockContainer = styled(Container)`
  display: flex;
  justify-content: center;
`;

const AdSlotContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @media (min-width: 786px) {
    min-height: ${prop('$height')}px;
    padding: 1rem;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 2rem;
  }

  @media (max-width: ${theme('breakpoints.custom.mobileLg')}) {
    box-shadow: none;
  }
  .adunitContainer {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  }
  .mask-in-screenshot {
    @media (min-width: 786px) {
      min-height: inherit;
    }
  }
`;

export const AdWrapper = styled.div`
  display: flex;
  min-height: 5.625rem;
  justify-content: center;
  align-items: center;

  .adBox {
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  }
`;

AdSlotBlock.propTypes = {
  data: adSlotBlockShape,
  className: PropTypes.string,
};

function AdSlotBlock({ data, className, ...props }) {
  const { page } = props;
  const conceptsPage = page?.includes('concepts');
  const desktopAd = get(data, 'blockReference.adUnitDesktop');
  const mobileAd = get(data, 'blockReference.adUnitMobile');

  if (!desktopAd) {
    return null;
  }

  const desktopAdHeight = get(desktopAd, '[0].adSlotConfig.sizes.[0].height');
  const desktopAdWidth = get(desktopAd, '[0].adSlotConfig.sizes.[0].width');
  const mediaQueryWidth = desktopAdWidth - 1;
  const isMobile = useMediaQuery(`@media (max-width: ${mediaQueryWidth}px)`);

  return (
    <AdBlockContainer className={className} maxWidth='xl'>
      <AdSlotContainer $height={!isMobile ? desktopAdHeight : 'undefined'}>
        {isMobile ? (
          <AdWrapper className='mask-in-screenshot'>
            <AdSlot adUnits={mobileAd} />
          </AdWrapper>
        ) : (
          <AdSlot adUnits={desktopAd} />
        )}
      </AdSlotContainer>
    </AdBlockContainer>
  );
}

export default AdSlotBlock;
