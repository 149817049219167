import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { Header } from 'components/ModularBlocks/CalloutBlock';
import { columnShape } from 'shapes/calloutShape';
import Icon from 'components/Icon';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  ${ifProp(
    '$height',
    css`
      min-height: ${prop('$height')}%;
    `
  )}

  &:not(:last-child) {
    border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  }
`;

const Col = styled.div`
  align-items: center;
  display: flex;

  ${switchProp('$type', {
    content: css`
      padding: 1rem 1rem 1rem 3.125rem;
      width: 100%;

      ${ifProp(
        '$width',
        css`
          width: ${prop('$width')}%;
        `
      )}
    `,
    icon: css`
      padding: 1rem 1.5rem;

      svg {
        font-size: 1.5rem;
      }
    `,
  })}

  &:first-child {
    border-right: 0.0625rem solid ${theme('colors.borderGray')};
    padding-right: 2rem;
    justify-content: flex-start;
  }
`;

const ColContent = styled(Typography)`
  font-weight: 600;
`;

CalloutTable.propTypes = {
  rowCount: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      columns: PropTypes.arrayOf(columnShape),
    })
  ),
};

function CalloutTable({ rowCount, rows }) {
  return (
    <Table>
      {rows.map((row, rowIndex) => (
        <Row key={rowIndex} $height={100 / rowCount}>
          {row?.columns.map((column, colIndex) => {
            const columnWidth = rows[0].columns[colIndex].columnWidth;
            return (
              <Fragment key={colIndex}>
                {column?.content && (
                  <Col $type='content' $width={columnWidth}>
                    <ColContent>{column.content}</ColContent>
                  </Col>
                )}
                {column?.icon && (
                  <Col $type='icon'>
                    <Icon icon={column.icon} color='red' />
                  </Col>
                )}
              </Fragment>
            );
          })}
        </Row>
      ))}
    </Table>
  );
}

export default CalloutTable;
