import React from 'react';
import PropTypes from 'prop-types';
import menuItemShape from 'shapes/menuItemShape';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Typography from '@material-ui/core/Typography';
import { useDashboardContext } from 'context/DashboardContext';
import styled, { css } from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import CloseIcon from '@material-ui/icons/Close';
import FirstTimeDashboardHelper from '../FirstTimeDashboardHelper';

const SubsequentHelperContainer = styled.div`
  width: 100%;
  height: 100%;

  ${ifProp('$firstTimeHelper', css`
    background-color: ${theme('colors.ttRed')};
  `)}
  
  ${ifProp('$subSequentHelper', css`
    background-image: linear-gradient(to right, transparent 50%, ${theme('colors.ttRed')} 50%);
    background-size: 200% 100%;
    transition: background-position 1s;
    animation: slide-right linear 1s alternate;
    animation-iteration-count: 2;
  
    svg {
      animation: shake linear 1s;
      animation-iteration-count: 2;
    }
    
    @keyframes shake {
      25% {-webkit-transform: rotate(-10deg);}
      50% {-webkit-transform: rotate(20deg);}
      75% {-webkit-transform: rotate(-5deg);}
    }
  
    @keyframes slide-right {
      from { background-position: 0% 0%; } 
      to { background-position: -200% 0%; }
    }
  `)}
`

SubsequentDashboardHelper.propTypes = {
  children: PropTypes.element
};

function SubsequentDashboardHelper({ children }) {
  const { state: dashboardState } = useDashboardContext();
  const { showFirstTimeHelper, showSubsequentHelper } = dashboardState;

  return (
    <SubsequentHelperContainer
      $firstTimeHelper={showFirstTimeHelper}
      $subSequentHelper={showSubsequentHelper}
    >
      {children}
    </SubsequentHelperContainer>
  );
}

export default SubsequentDashboardHelper;