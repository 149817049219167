import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { useGetUserDataSettings } from 'hooks/useGetUserDataSettings';
import { dataLayerPush } from 'utils/dataLayer';
import DeleteIcon from '@material-ui/icons/Delete';

const ActionsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 1rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    align-items: center;
    flex-direction: row;
    padding: 0.875rem 0 0.875rem 1.5rem;
    width: 100%;
  }

  ${ifProp({$page: 'episode'}, css`
    ${props => props.theme.breakpoints.down('sm')} {
      padding: 1rem 1.5rem
    }

    ${props => props.theme.breakpoints.down('xs')} {
      padding: 1.5rem 1rem;
    }
  `)}
`;

const Divider = styled.hr`
  display: none;

  ${props => props.theme.breakpoints.down('sm')} {
    border-left: none;
    border-right: none;
    border-top: 0.0625rem solid ${theme('colors.borderGray')};
    display: block;
    margin: 0 auto;
    width: 95%;
  }
`;

const SaveButton = styled(Button)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1rem;
  padding: 0;

  &:focus {
    border-radius: 0.25rem;
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin-bottom: 1rem;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 0.8125rem;
  }

  ${ifProp('$hasSaved', css`
    svg {
      margin-left: -4px;
    }
  `)}

  ${props => props.theme.breakpoints.up('md')} {
    margin-right: 2.625rem;
  }

  svg {
    margin-right: 0.5rem;
    min-height: 1.5rem;
    width: 1.2rem;
  }
`;

const IconContainer = styled.a`
  align-items: center;
  color: ${theme('colors.black')};
  display: flex;
  text-decoration: none;

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 0.75rem;
  }

  &:hover {
    color: ${theme('colors.ttRed')};

    svg {
      fill: ${theme('colors.ttRed')};
    }
  }

  span {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-weight: 600;
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-size: 1rem;

    ${props => props.theme.breakpoints.down('xs')} {
      margin-left: 0.7rem;
      font-size: 0.8125rem;
    }
  }
`;

ActionButtons.propTypes = {
  deckUid: PropTypes.string,
  hasSaved: PropTypes.bool,
  page: PropTypes.string,
  slideDeckPdf: PropTypes.object,
  slideDeckTitle: PropTypes.string,
  toggle: PropTypes.func,
};

function ActionButtons({ deckUid, hasSaved, page, slideDeckPdf, slideDeckTitle, toggle }) {
  const { preferences } = useGetUserDataSettings();
  const icon = (
    hasSaved
      ? <DeleteIcon />
      : <Icon icon='addToSlides' size={1.5} />
  )

  return (
    <>
      <Divider />
      <ActionsContainer $page={page}>
        {!!preferences?.acceptedAt && deckUid && (
          <SaveButton
            disableRipple={true}
            $hasSaved={hasSaved}
            onClick={() => toggle(!hasSaved)}
            $page={page}
          >
            {icon}
            {hasSaved ? 'Remove from dashboard' : 'Save to my Slides'}
          </SaveButton>
        )}
        {slideDeckPdf &&
          <IconContainer
            href={slideDeckPdf.url}
            download={slideDeckTitle}
            onClick={() => dataLayerPush('Downloaded slides')}
            $page={page}
            rel='noopener noreferrer'
            target='_blank'
          >
            <Icon icon='download' size={1.25} />
            <span>Download</span>
          </IconContainer>
        }
      </ActionsContainer>
    </>
  );
}

export default ActionButtons;
