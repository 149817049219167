import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import NextButton from './components/NextButton';
import PrevButton from './components/PrevButton';
import { useAuth } from 'context/AuthContext';
import { useTourContext } from 'context/TourContext';
import { REPLAY_TOUR_STEPS } from 'constants/replayTourSteps';
import getAllToursToShowQuery from '../../../graphql/queries/getAllToursToShow.graphql';
import updateShowDailyReplayTourMutation from '../../../graphql/mutations/updateShowDailyReplayTour.graphql';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Must load in Tour via @loadable/component since it tries accessing document and that breaks in SSR.
const LoadedTour = loadable(() => import('reactour'));

const Tour = styled(LoadedTour)`
  [data-tour-elem="controls"] {
    justify-content: space-between;
  }

  .reactour__close {
    display: none;
  }

  p {
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: 0.75rem;
    }
  }
`;

const CustomClose = styled.button`
  background: transparent;
  border: none;
  color: ${theme('colors.black')};
  cursor: pointer;
  font-size: 0.625rem;
  font-weight: 600;
  position: absolute;
  right: 8px;
  text-transform: uppercase;
  top: 8px;

  &:active {
    color: ${theme('colors.black')};
  }
`;

const DoneButton = styled(Typography)`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
`;

const windowHeight = typeof window !== 'undefined' ? window?.innerHeight/2 : 400;
const windowWidth = typeof window !== 'undefined' ? (window?.innerWidth-320)/2 : 16;

const steps = [
  {
    selector: '.daily-replays-row',
    content: (
      <>
        <p><strong>New Feature: Daily Replays</strong></p>
        <p>Catch up on what's been going on this week with daily playlists from the
        last 7 days of programming.</p>
        <p>Learn how to use Daily Replays in a quick step-by-step tutorial. Click
        LET'S GO to get started.</p>
      </>
    ),
    // Array of selectors, addition/removal of a matching node will trigger a rerender
    // of the mask shape. Useful in combination with highlightedSelectors when highlighted
    // region of mask should be redrawn after a user action
    mutationObservables: ['[data-tour="mutable-element"]'],
  },
  {
    selector: '.daily-replay-card-0',
    content: (
      <p>Hover over the playlist's thumbnail to get more information about the day's content.</p>
    ),
    mutationObservables: ['[data-tour="mutable-element"]'],
  },
  {
    selector: '.daily-replay-card-0',
    position: [540, 12],
    content: (
      <>
        <p>Get key takeaways from the day's programming, as well as that day's list of
        the top traded symbols at tastytrade (if available).</p>
        <p>Click PLAY ALL to begin watching the playlist, or click on a takeaway to start
        with that episode.</p>
      </>
    ),
    mutationObservables: ['.daily-replay-card-0', '[data-tour="mutable-element"]'],
    highlightedSelectors: ['.daily-replay-card-0'],
    resizeObservables: ['.daily-replay-card-0']
  },
  {
    selector: '.sidebar-content',
    content: (
      <p>Scroll to view the full day's list of episodes. Click on any episode to begin watching.</p>
    ),
    highlightedSelectors: ['.sidebar-content'],
    mutationObservables: ['.sidebar-content', '[data-tour="mutable-element"]'],
    resizeObservables: ['.sidebar-content']
  },
  {
    selector: '.daily-replay-toggle',
    position: 'bottom',
    content: (
      <p>
        Click here to toggle to view key takeaways from the day's programming, as
        well as that day's list of the top traded symbols at tastytrade.
      </p>
    ),
    highlightedSelectors: ['.daily-replay-toggle'],
    mutationObservables: ['.daily-replay-toggle', '[data-tour="mutable-element"]'],
    resizeObservables: ['.daily-replay-toggle']
  }
];

const mobileSteps = [
  {
    selector: '.daily-replays-row',
    content: (
      <>
        <p><strong>New Feature: Daily Replays</strong></p>
        <p>Catch up on what's been going on this week with daily playlists from the
          last 7 days of programming.</p>
        <p>Learn how to use Daily Replays in a quick step-by-step tutorial. Click
          LET'S GO to get started.</p>
      </>
    ),
    // Array of selectors, addition/removal of a matching node will trigger a rerender
    // of the mask shape. Useful in combination with highlightedSelectors when highlighted
    // region of mask should be redrawn after a user action
    mutationObservables: ['[data-tour="mutable-element"]'],
  },
  {
    selector: '.daily-replay-card-0',
    position: 'top',
    content: (
      <>
        <p>Click on the playlist's thumbnail to get more information about the day's content.</p>
      </>
    ),
    mutationObservables: ['[data-tour="mutable-element"]'],
  },
  {
    selector: '.daily-replay-card-modal-0',
    position: [`${windowWidth}`, 12],
    content: (
      <>
        <p>Get key takeaways from the day's programming, as well as that day's list of
          the top traded symbols at tastytrade (if available).</p>
      </>
    ),
    mutationObservables: ['.daily-replay-card-modal-0'],
    highlightedSelectors: ['.daily-replay-card-modal-0'],
    resizeObservables: ['.daily-replay-card-modal-0']
  },
  {
    selector: '.daily-replay-card-modal-0',
    position: [`${windowWidth}`, 12],
    content: (
      <>
        <p>Click PLAY ALL to begin watching the playlist, or click on a takeaway to start with that
          episode.</p>
      </>
    ),
    mutationObservables: ['.daily-replay-card-modal-0'],
    highlightedSelectors: ['.daily-replay-card-modal-0'],
    resizeObservables: ['.daily-replay-card-modal-0']
  },
  {
    selector: '.daily-replay-toggle-mobile',
    position: 'top',
    content: (
      <>
        <p>Scroll to view the full day's list of episodes. </p>
        <p>
          Click here to toggle to view key takeaways from the day's programming, as
          well as that day's list of the top traded symbols at tastytrade.
        </p>
      </>
    ),
    highlightedSelectors: ['.daily-replay-toggle'],
    mutationObservables: ['.daily-replay-toggle'],
    resizeObservables: ['.daily-replay-toggle']
  }
];

DailyReplaysTour.propTypes = {};

function DailyReplaysTour() {
  const { user } = useAuth();
  const { stepFour } = REPLAY_TOUR_STEPS;
  const [isMounted, setIsMounted] = useState(false);
  const { data: toursData, loading: toursLoading, error: toursError } = useQuery(getAllToursToShowQuery, {
    fetchPolicy: 'no-cache',
    skip: !user || typeof window === 'undefined'
  });

  const [updateShowDailyReplayTour] = useMutation(updateShowDailyReplayTourMutation);
  const showDailyReplay = toursData?.getAllToursToShow;
  const tourLength = steps?.length;
  const {
    state: { activeTour, currentStep, newStep, startAt },
    disableTour,
    setWillShowTour,
    updateCurrentStep
  } = useTourContext();

  const closeAndUpdateSettings = () => {
    disableTour();
    setWillShowTour(false);
    updateShowDailyReplayTour({ variables: { showDailyReplayTour: false }});
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 0);
  }, []);

  if (!user) {
    return null;
  }

  const isOpen = activeTour === 'dailyReplaysTour';

  if (!isOpen) {
    document.body.style.overflow = ''
  }

  return (
    <>
      {isMounted && (
        <Tour
          steps={isMobile ? mobileSteps : steps}
          isOpen={isOpen}
          badgeContent={(curr, tot) => `${curr}/${tot}`}
          maskSpace={startAt === stepFour ? 0 : 10}
          showNavigation={false}
          goToStep={newStep ? newStep : currentStep}
          inViewThreshold={250}
          lastStepNextButton={<DoneButton onClick={closeAndUpdateSettings}>Done</DoneButton>}
          nextButton={<NextButton endTour={closeAndUpdateSettings} />}
          prevButton={<PrevButton />}
          getCurrentStep={curr => updateCurrentStep(curr)}
          onAfterOpen={() => (document.body.style.overflow = 'hidden')}
          onBeforeClose={() => (document.body.style.overflow = '')}
          startAt={startAt}
        >
          <CustomClose onClick={closeAndUpdateSettings}>Skip Tutorial</CustomClose>
        </Tour>
      )}
    </>
  );
}

export default DailyReplaysTour;
