import React from 'react';
import PropTypes from 'prop-types';
import ModularBlock from './ModularBlock';
import ModularSchema from './ModularSchema';
import modularBlockShape from 'shapes/modularBlockShape';

const propTypes = {
  modularBlocks: PropTypes.arrayOf(modularBlockShape),
};

function ModularBlocks({ modularBlocks, ...props }) {
  if (!modularBlocks || modularBlocks.length === 0) {
    return null;
  }

  return (
    <div>
      <ModularSchema modularBlocks={modularBlocks} />
      {modularBlocks.map((modularBlock, index) => (
        <ModularBlock data={modularBlock} key={index} {...props} />
      ))}
    </div>
  );
}

ModularBlocks.propTypes = propTypes;

export default React.memo(ModularBlocks);
