import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import LoadingPage from 'components/LoadingPage';
import StyleGuide from 'routes/StyleGuide';
import Account from 'routes/Account';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Home'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/shows/:showSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/ShowPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/shows/:showSlug/episodes/:episodeSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/user-playlists/:playlistId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage/UserPlaylistPage'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/user-playlists/:playlistId/episodes/:episodeId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage/UserPlaylistPage'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/daily-replays/:dayOfWeek',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage/DailyReplayPage'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/daily-replays/:dayOfWeek/episodes/:episodeId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage/DailyReplayPage'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/definitions/:definitionSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/DefinitionPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/concepts-strategies/:definitionSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/KeyConceptsDefinitionPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/news-insights',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Blog/BlogListPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/news-insights/:blogPostSlug*',
    component: asyncComponent({
      loader: () => import('./routes/Blog/BlogPostPage'),
      Placeholder: LoadingPage,
    })
  },
  // TODO: remove after migration and redirects exist.
  {
    path: '/blog/articles',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Blog/BlogListPage'),
      Placeholder: LoadingPage,
    })
  },
  // TODO: remove after migration and redirects exist.
  {
    path: '/blog/articles/:blogPostSlug*',
    component: asyncComponent({
      loader: () => import('./routes/Blog/BlogPostPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/authors/:authorSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Blog/AuthorListPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/blog/categories/:categorySlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Blog/CategoryListPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/blog/tags/:tagSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Blog/TagListPage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/talent/:talentSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/TalentPage'),
      placeholder: LoadingPage
    })
  },
  // Path to handle episode URLs that do not have the show slug prefixed.
  // TODO: this path is temporary, we need to be able to build links to full episode urls.
  {
    path: '/episodes/:episodeSlug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/EpisodePage'),
      Placeholder: LoadingPage,
    })
  },
  {
    path: '/search',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/SearchPage'),
      Placeholder: LoadingPage,
    }),
  },
  {
    path: '/404',
    component: asyncComponent({
      loader: () => import('./routes/ErrorPages/NotFoundPage'),
      Placeholder: () => LoadingPage,
    }),
  },
  {
    path: '/403',
    component: asyncComponent({
      loader: () => import('./routes/ErrorPages/ForbiddenPage'),
      Placeholder: () => LoadingPage,
    }),
  },
  {
    path: '/500',
    component: asyncComponent({
      loader: () => import('./routes/ErrorPages/ServerErrorPage'),
      Placeholder: () => LoadingPage,
    }),
  },
  {
    path: '/profile',
    exact: true,
    component: Account,
  },
  {
    path: '/styleguide',
    exact: true,
    component: StyleGuide,
  },
  {
    path: '/:pageUrl*',
    exact: true,
    component: asyncComponent({
      loader: () => import('./routes/Page'),
      Placeholder: () => LoadingPage,
    }),
  },
  {
    component: asyncComponent({
      loader: () => import('./routes/ErrorPages/NotFoundPage'),
      Placeholder: LoadingPage,
    }),
  },
];
