import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';

export const SocialContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
`;

const SocialLink = styled.a`
  display: flex;
  margin-right: 0.375rem;

  ${ifProp('$fill', css`
    svg {
      fill: ${prop('$fill')};

      &:hover {
        fill: ${theme('colors.ttRed')};
      }
    }
  `)}
`;

SocialLinks.propTypes = {
  socialGroup: PropTypes.object,
};

function SocialLinks({ fill, socialGroup }) {
  const { facebook, instagram, linkedin, twitter } = socialGroup;
  const facebookAccount = get(facebook, 'href');
  const instaAccount = get(instagram, 'href');
  const linkedinAccount = get(linkedin, 'href');
  const twitterAccount = get(twitter, 'href');

  return (
    <SocialContainer>
      {facebookAccount &&
        <SocialLink $fill={fill} href={facebookAccount} target='_blank' rel='noopener'>
          <Icon icon='facebook' />
        </SocialLink>
      }
      {twitterAccount &&
        <SocialLink $fill={fill} href={twitterAccount} target='_blank' rel='noopener'>
          <Icon icon='twitter' />
        </SocialLink>
      }
      {linkedinAccount &&
        <SocialLink $fill={fill} href={linkedinAccount} target='_blank' rel='noopener'>
          <LinkedInIcon fontSize='large' />
        </SocialLink>
      }
      {instaAccount &&
        <SocialLink $fill={fill} href={instaAccount} target='_blank' rel='noopener'>
          <Icon icon='instagram' />
        </SocialLink>
      }
    </SocialContainer>
  );
}

export default React.memo(SocialLinks);
