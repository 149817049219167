import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseGridList from '@material-ui/core/GridList';
import BaseGridListTile from '@material-ui/core/GridListTile';
import researchSlideShape from 'shapes/researchSlideShape';
import { autoOptimizeImage } from 'utils/functions';

const GridListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: hidden;
`;

const GridList = styled(BaseGridList)`
  flex-wrap: nowrap;
`;

// using important here to override inline height added by material-ui
const GridListTile = styled(BaseGridListTile)`
  height: 100% !important;

  .MuiGridListTile-tile {
    height: inherit;

    img {
      height: 100%;
      width: 100%;
    }
  }
`;

SwipeableCarousel.propTypes = {
  carouselHeight: PropTypes.number,
  imageRef: PropTypes.object,
  slideDecks: PropTypes.arrayOf(researchSlideShape),
  totalImages: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
  }))
};

function SwipeableCarousel({
  carouselHeight,
  imageRef,
  slideDecks,
  totalImages
}) {
  return (
    <GridListWrapper>
      {slideDecks.map((slideDeck, deckIndex) => (
        <GridList key={`slideDeck-${deckIndex}`} cols={1}>
          {totalImages.map((slideImage, slideIndex) => (
            <GridListTile
              $height={carouselHeight}
              key={`slide-image-${slideIndex}`}
              ref={imageRef}
            >
              <img alt={`slide ${slideImage.title}`} src={autoOptimizeImage(slideImage.url)} />
            </GridListTile>
          ))}
        </GridList>
      ))}
    </GridListWrapper>
  );
}

export default SwipeableCarousel;
