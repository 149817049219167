import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled, { css } from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { ifProp, ifNotProp, prop, switchProp } from 'styled-tools';
import { optimizeContentCardImage } from 'utils/functions';
import { contentCardImageSizes } from 'utils/constants';
import { hoverAnimations } from './const';

const OuterContainer = styled.div`
  position: relative;

  ${switchProp('$size', {
    12: css`
      ${props => props.theme.breakpoints.up('xs')} {
        padding-top: 56.26%;
        width: 100%;
      }

      ${props => props.theme.breakpoints.up('md')} {
        padding-top: 31.5%;
        width: 50%;
      }
      `,
    6: css`
      padding-top: 56.26%;
      width: 100%
      `,
    4: css`
      padding-top: 71.2%;
      width: 100%
      `,
    3: css`
      padding-top: 100%;
      width: 100%;

      ${ifProp({'$fullWidth': true}, css`
        ${props => props.theme.breakpoints.up('lg')} {
          padding-top: 296px; //needs to be a constant
        }
      `)};
    `,
    },css`
  `)}
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  width: 100%;

  ${ifProp('$hoverAnimation', css`
    transition: margin 0.25s ease-out;
  `)};

  ${props => props.theme.breakpoints.up('md')} {
    ${switchProp('$hoverAnimation', {
      'float up': css`
        &:hover {
          margin-top: -5px;
          margin-bottom: 5px;
        }
      `,
   })}
  }
`;

const LazyLoadWrapper = styled(LazyLoad)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  img {
    height: 100%;
    max-width: 100%;
    object-fit: scale-down;
  }

  ${ifProp({'$size': 4}, css`
    overflow: hidden;

    ${ifNotProp('$icon', css`
      align-items: baseline;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    `)}
  `)}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

ImageContainer.propTypes = {
  altImageText: PropTypes.string,
  cardImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  size: PropTypes.number,
  fullWidth: PropTypes.bool,
  hoverAnimation: PropTypes.oneOf(hoverAnimations)
};

function ImageContainer({ altImageText, cardImage, size, fullWidth, hoverAnimation }) {
  const imgUrl = get(cardImage, 'url');
  const icon = imgUrl && imgUrl.includes('.svg');

  const PlaceholderSkeleton = (
    <Skeleton variant='rect' width='100%' height='100%' />
  );

  return (
    <OuterContainer $size={size} $fullWidth={fullWidth}>
      <Container
        $size={size}
        $hoverAnimation={hoverAnimation}
        $icon={icon}
      >
        <LazyLoadWrapper
          debounce={true}
          height='100%'
          $icon={icon}
          offset={100}
          placeholder={PlaceholderSkeleton}
          $size={size}
        >
          <Image
            alt={icon ?? altImageText}
            src={optimizeContentCardImage(cardImage, size, contentCardImageSizes)}
          />
        </LazyLoadWrapper>
      </Container>
    </OuterContainer>
  );
}

export default ImageContainer;
