import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp, theme } from 'styled-tools';
import { textColumnShape } from 'shapes/components/textBlockShape';
import Grid from '@material-ui/core/Grid';
import StackHeader from 'components/StackHeader';
import RichTextField from 'components/RichTextField';
import CtaButtons from 'components/CtaButtons';
import Box from '@material-ui/core/Box';
import columnSpanMap, { COLUMN_SPAN_OPTS } from 'constants/columnSpanMap';

const GridItem = styled(Grid)`
  ${switchProp('$span', {
    [COLUMN_SPAN_OPTS.twoFifths]: css`
      min-width: 40%;
    `,
    [COLUMN_SPAN_OPTS.threeFifths]: css`
      min-width: 60%;
    `,
  })}
`;

const RichTextBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 2.5rem;
    margin-top: 0;
  }

  img {
    width: 100%;
  }

  iframe {
    max-width: 100%;
  }
`;

TextColumn.propTypes = {
  data: textColumnShape.isRequired,
};

function TextColumn({ data }) {
  const {
    header,
    selectHeaderType,
    body,
    contentAlignment,
    columnSpan,
    buttonCta,
  } = data;

  const colCount = (columnSpan && columnSpanMap[columnSpan]) || true;
  return (
    <GridItem item xs={12} md={colCount} $span={columnSpan}>
      <RichTextBox textAlign={contentAlignment}>
        {header && (
          <StackHeader headerType={selectHeaderType} defaultHeaderType='H3'>
            {header}
          </StackHeader>
        )}
        {body && <RichTextField richText={body} />}
        {buttonCta && buttonCta.length > 0 && <CtaButtons items={buttonCta} />}
      </RichTextBox>
    </GridItem>
  );
}

export default React.memo(TextColumn);
