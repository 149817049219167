import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field, ErrorMessage } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import Label from '../Label';

const contentSquareMaskIds = {
  'GDPR': 'user-in-eu',
  'CCPA/CPRA': 'user-in-ca',
}

RegulatoryScopeField.propTypes = {
  touched: PropTypes.any,
  regulatoryScope: PropTypes.any,
  errors: PropTypes.any,
  disabled: PropTypes.bool,
};

function RegulatoryScopeField(props) {
  const scopeId = props?.regulatoryScope?.id;

  return <FormControl
    id={contentSquareMaskIds[scopeId]}
    component='fieldset'
    margin='normal'
    fullWidth
    error={props.touched.regulatoryScopes?.[props.regulatoryScope.id] &&
    props.errors.regulatoryScopes?.[props.regulatoryScope.id]}
  >
    <Label component='legend'>
      {props.regulatoryScope.scopeQuestion}
      {props.regulatoryScope.scopeExplanation && (
        <small>{props.regulatoryScope.scopeExplanation}</small>
      )}
    </Label>
    <Field
      component={RadioGroup}
      name={`regulatoryScopes.${props.regulatoryScope.id}`}
      row
    >
      <FormControlLabel
        value='0'
        control={<Radio disabled={props.disabled} size='small'/>}
        label="No"
        disabled={props.disabled}
      />
      <FormControlLabel
        value='1'
        control={<Radio disabled={props.disabled} size='small'/>}
        label="Yes"
        disabled={props.disabled}
      />
    </Field>
    <ErrorMessage
      name={`regulatoryScopes.${props.regulatoryScope.id}`}
      component={FormHelperText}
    />
  </FormControl>;
}

export default RegulatoryScopeField;
