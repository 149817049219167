import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';

const MegaMenuImage = styled.img`
  background-color: ${theme('colors.backgroundGray')};
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: top;
  position: absolute;
  top: 0;

  ${ifProp(
  '$trader',
  css`
    max-width: 5rem;
  `)}
`;

export default MegaMenuImage;
