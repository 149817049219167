import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ctaButtonShape from 'shapes/ctaButtonShape';
import CtaButton from 'components/CtaButton';
import Container from '@material-ui/core/Container';

export const ButtonsWrap = styled.div`
  width: 100%;
  & > * {
    margin: ${({ theme }) => theme.spacing(1)};
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ButtonsWrapContainer = styled(Container)`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0;
`;

CtaButtons.propTypes = {
  items: PropTypes.arrayOf(ctaButtonShape),
  icon: PropTypes.string,
  alertIcon: PropTypes.string,
  overrideStyle: PropTypes.string,
  overrideJustify: PropTypes.string,
  eventbriteId: PropTypes.string,
};

function CtaButtons({ items, icon, alertIcon, overrideStyle, overrideJustify, eventbriteId }) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <ButtonsWrapContainer>
      <ButtonsWrap>
        {items.map((item, i) =>
          <CtaButton
            key={i}
            data={item}
            icon={icon}
            alertIcon={alertIcon}
            overrideStyle={overrideStyle && overrideStyle}
            overrideJustify={overrideJustify && overrideJustify}
            eventbriteId={eventbriteId}
          />)}
      </ButtonsWrap>
    </ButtonsWrapContainer>
  );
}

export default CtaButtons;
