import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import Popover from '@material-ui/core/Popover';
import Button from 'components/Button';

const PopoverContainer = styled.div`
  height: 100%;

  .MuiButton-label {
    font-size: .85rem;
    color: ${prop('theme.colors.black')};
  }
`;

const Trigger = styled(Button)`
  ${switchProp('type', {
    link: css`
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 100%;

      &:hover {
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    `,
    button: css`
    `,
  })}

  ${switchProp('padding', {
    none: css`
      padding: 0;
    `,
    regular: css`
      padding: 0.75rem 1.25rem;
    `,
  })}

  .down {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
`;

const StyledPopover = styled(Popover)`
  margin-top: 0.45rem;
  box-shadow: 0 30px 40px -30px rgba(0, 0, 0, 0.7);

  ${switchProp('page', {
    'dashboard': css`
      .MuiPopover-paper {
        margin-top: 1px;
        margin-left: -8px;
      }
    `,
    'on-demand': css`
      .MuiPopover-paper {
        margin-top: 0.875rem;

         ${props => props.theme.breakpoints.down('sm')} {
           left: 1.0625rem !important;
         }
      }
    `,
  })}
`;

function SimplePopover({
  anchorEl,
  anchorOrigin,
  children,
  handleClick,
  handleClose,
  icon,
  iconPlacement = 'prefix',
  page,
  transformOrigin,
  triggerPadding = 'regular',
  triggerText,
  triggerType
}) {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PopoverContainer>
      <Trigger
        aria-describedby={id}
        onClick={handleClick}
        padding={triggerPadding}
        type={triggerType}
        className={open ? 'active' : ''}
      >
        {icon && iconPlacement === 'prefix' && (
          <>{icon}</>
        )}
        {triggerText}
        {icon && iconPlacement === 'suffix' && (
          <>{icon}</>
        )}
      </Trigger>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        page={page}
        transformOrigin={transformOrigin}
      >
        {children}
      </StyledPopover>
    </PopoverContainer>
  );
}

SimplePopover.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  children: PropTypes.element.isRequired,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  icon: PropTypes.element,
  iconPlacement: PropTypes.string,
  page: PropTypes.string,
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  triggerPadding: PropTypes.string,
  triggerText: PropTypes.string,
  triggerType: PropTypes.string
};

export default React.memo(SimplePopover);
