
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MenuFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Menu"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"menuItems"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"submenu"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"submenu"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MenuItemFields"},"directives":[]}]}}]}}]}}]}}],"loc":{"start":0,"end":196}};
    doc.loc.source = {"body":"#import \"./MenuItemFields.graphql\"\n\nfragment MenuFields on Menu {\n  menuItems {\n  ...MenuItemFields\n    submenu {\n    ...MenuItemFields\n      submenu {\n      ...MenuItemFields\n      }\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./MenuItemFields.graphql").definitions));


      module.exports = doc;
    
