import React, { useEffect, useState } from 'react';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import ModularBlocks from 'components/ModularBlocks';
import pageBodyShape from 'shapes/pageBodyShape';
import BaseBox from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BaseAccordion from '@material-ui/core/Accordion';
import BaseAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useAuth } from 'context/AuthContext';
import Icon from 'components/Icon';

const Box = styled(BaseBox)`
  background-color: ${theme('colors.black')};
  border-top: 1px solid #212626;
  box-shadow: inset 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.3);
  padding-top: 1rem;
  position: relative;

  ${props => props.theme.breakpoints.down('sm')} {
    position: relative;
    z-index: 11;
  }

  ${ifProp('$loggedIn', css`
    padding-bottom: 2.5rem;
  `)}
`;

const DisclaimerContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('md')} {
    width: 88%;
    padding-left: 0;
    padding-right: 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1280px;
  }

  @media (min-width: 1440px) {
    max-width: 1380px;
  }

  ${props => props.theme.breakpoints.up('xl')} {
    max-width: 1580px;
  }

  a,
  p {
    font-size: 0.75rem;
    padding-bottom: 0;
    color: ${theme('colors.white')};
    font-family: Heebo;
    letter-spacing: normal;
    font-stretch: normal;
  }

  a {
    &:hover {
      color: ${theme('colors.white')};
    }
  }

  .modular-block-disclaimerBody {
    max-width: inherit;
    padding: 0;
  }
`;

const Accordion = styled(BaseAccordion)`
  border-radius: 0;
  background-color: ${theme('colors.black')};
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: column-reverse;
  }

  .MuiAccordionDetails-root {
    padding: 0;

    .MuiBox-root {
      &:last-child {
        ${props => props.theme.breakpoints.up('sm')} {
          margin-bottom: 0;
        }
      }
    }
  }

  .MuiAccordionSummary-expandIcon {
    margin-top: -0.5rem;
    font-size: 1.3rem;

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: -1rem;
    }
  }
`;

const AccordionSummary = styled(BaseAccordionSummary)`
  ${props => props.theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
  }

  .MuiAccordionSummary-content {
    color: ${theme('colors.white')};
    font-size: 0.8125rem;
    font-weight: 300;
    margin: 1.25rem 0;

    ${props => props.theme.breakpoints.down('xs')} {
      flex-grow: 0;
    }
  }

  // since the icon and text are being flipped by column-reverse, it is not
  // possible to use 'display: none' or 'visibility: hidden' to hide this text
  // when the accordion expands
  ${ifProp('$showCopyright', css`
    .MuiAccordionSummary-content {
      ${props => props.theme.breakpoints.down('xs')} {
        height: 0;
        margin: 0;
        overflow: hidden;
      }
    }
  `)}
`;

function Disclaimer({ disclaimers }) {
  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const { user } = useAuth();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const year = new Date().getFullYear();

  useEffect(() => {
    isDesktop && setExpand(true);
  }, [isDesktop]);

  const handleChange = () => (event, open) => {
    setExpand(!!open);
  }

  return (
    <Box $loggedIn={!!user}>
      <DisclaimerContainer>
        <Accordion expanded={expand} onChange={handleChange()}>
          <AccordionSummary
            $showCopyright={expand}
            expandIcon={
              <Icon
                color={theme.palette.common.white}
                icon='chevronDown'
                size={1}
              />
            }
          >
          {!expand && <span>{`© copyright 2013 - ${year} tastylive, Inc.`}</span>}
          </AccordionSummary>
          <AccordionDetails>
            <ModularBlocks modularBlocks={disclaimers} />
          </AccordionDetails>
        </Accordion>
      </DisclaimerContainer>
    </Box>
  );
}

Disclaimer.propTypes = {
  disclaimers: pageBodyShape.isRequired
};

export default Disclaimer;
