import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import RichTextField, { RichTextBox } from 'components/RichTextField';
import Typography from '@material-ui/core/Typography';

const Section = styled.div`
  display: flex;

  ${RichTextBox} {
    font-weight: 400;
  }
`;

const MarkerContainer = styled.div`
  padding: 1.75rem 2.5rem 0 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const DefinitionLink = styled(RouterLink)`
  text-decoration: none;
  color: ${theme('colors.black')};

  &:hover {
    color: ${theme('colors.ttRed')};
  }
`;

const LetterMarker = styled.div`
  align-items: center;
  border-radius: 100%;
  background-color: ${theme('colors.borderGray')};
  color: ${theme('colors.white')};
  display: flex;
  font-family: serif;
  font-size: 2.625rem;
  height: 5rem;
  justify-content: center;
  position: sticky;
  text-transform: uppercase;
  top: 15.3125rem;
  width: 5rem;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 3.25rem;
    height: 6.25rem;
    width: 6.25rem;
  }
`;

const GlossaryItem = styled.div`
  border-bottom: 0.0625rem dashed ${theme('colors.borderGray')};
  padding: 1.75rem 0 0.875rem;

  ${ifProp(
    '$url',
    css`
      ${RichTextBox} {
        margin-bottom: 0;
      }
    `
  )}
`;

const Title = styled(Typography)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 1.25rem;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 1rem;
  }
`;

const Link = styled(RouterLink)`
  color: ${theme('colors.ttRed')};
  display: block;
  font-weight: 400;
  margin-bottom: 1em;
`;

const propTypes = {
  defsByLetter: PropTypes.arrayOf(PropTypes.object),
  letter: PropTypes.string,
};

function GlossarySection({ defsByLetter, letter }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    configureAnchors({
      offset: isDesktop ? -204 : -106,
      offscrollDuration: 20,
    });
  });

  return (
    <ScrollableAnchor id={`glossary-${letter}`}>
      <Section>
        <MarkerContainer>
          <LetterMarker>{letter}</LetterMarker>
        </MarkerContainer>
        <div>
          {defsByLetter.map((def, index) => {
            const {
              definition,
              title,
              url,
              supplementalInfo,
              primaryDefinition,
            } = def;

            return (
              <GlossaryItem key={`definition-${index}`} $url={url}>
                <Title variant='h2'>
                  {primaryDefinition || supplementalInfo ? (
                    <DefinitionLink
                      to={primaryDefinition?.url ? primaryDefinition.url : url}
                    >
                      {title}
                    </DefinitionLink>
                  ) : (
                    <>{title}</>
                  )}
                </Title>
                {definition && <RichTextField richText={definition} />}
                {(primaryDefinition || supplementalInfo) && (
                  <Link
                    to={primaryDefinition?.url ? primaryDefinition.url : url}
                  >
                    Learn more about {title}
                  </Link>
                )}
              </GlossaryItem>
            );
          })}
        </div>
      </Section>
    </ScrollableAnchor>
  );
}

GlossarySection.propTypes = propTypes;

export default GlossarySection;
