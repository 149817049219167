import FormControl from '@material-ui/core/FormControl';
import { ErrorMessage, Field } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckboxWithLabel } from 'formik-material-ui';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Label from '../Label';

const PolicyLinkList = styled.ul`
  margin-top: 0;
`;

PrivacyAcknowledgementField.propTypes = {
  touched: PropTypes.any,
  privacyAcknowledgement: PropTypes.any,
  errors: PropTypes.any,
};

function PrivacyAcknowledgementField({ errors, privacyAcknowledgement, touched }) {
  return (
    <FormControl
      margin='dense'
      fullWidth
      error={touched.privacyAcknowledgements?.[privacyAcknowledgement.id] &&
      errors.privacyAcknowledgements?.[privacyAcknowledgement.id]}
    >
      <ErrorMessage
        name={`privacyAcknowledgements.${privacyAcknowledgement.id}`}
        component={FormHelperText}
      />
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={`privacyAcknowledgements.${privacyAcknowledgement.id}`}
        Label={{ label: <Label as='span'>{privacyAcknowledgement.statement}</Label> }}
      />
      {(privacyAcknowledgement.policyLinks || []).length > 0 && (
        <PolicyLinkList>
          {privacyAcknowledgement.policyLinks.map((policyLink, index) => (
            <li key={index}>
              <Link href={policyLink.url} target='_blank' rel='noopener' color='secondary'>
                {policyLink.name}
              </Link>{' '}
              {(policyLink.version || privacyAcknowledgement.version) && (
                <small>({policyLink.version || privacyAcknowledgement.version})</small>
              )}
            </li>
          ))}
        </PolicyLinkList>
      )}
    </FormControl>
  );
}

export default PrivacyAcknowledgementField;
