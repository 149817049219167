import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import removeSavedSlideDecksMutation from '../../../../graphql/mutations/removeSavedSlideDecks.graphql';
import UndoSnackBar from 'components/UndoSnackBar';
import SlidesCard from 'components/SlidesCard';
import { dataLayerPush } from 'utils/dataLayer';
import EmptySlides from './Empty';
import SelectAllRow from '../SelectAllRow';
import { useDashboardContext } from 'context/DashboardContext';
import researchSlideShape from 'shapes/researchSlideShape';
import { useDashboardSlidesContext } from 'context/Dashboard/Slides';

MySlidesBody.propTypes = {
  edit: PropTypes.bool,
  slidesList: PropTypes.arrayOf(researchSlideShape),
  fetchNextPage: PropTypes.func,
  hasMore: PropTypes.bool,
  fetchMoreItems: PropTypes.func.isRequired,
  onUndoDeleteSlides: PropTypes.func.isRequired,
};

function MySlidesBody({ edit, slidesList = [], fetchNextPage, hasMore, fetchMoreItems, onUndoDeleteSlides }) {
  const [toDelete, setToDelete] = useState(null);
  const toDeleteRef = useRef(toDelete);
  toDeleteRef.current = toDelete;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [removeSlideDecks] = useMutation(removeSavedSlideDecksMutation);
  const [selectedSlides, setSelectedSlides] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { state: dashboardState } = useDashboardContext();
  const { deleteItems } = dashboardState;
  const { refetchShows, shows: slideDeckShows, setSelectedShows } = useDashboardSlidesContext();

  useEffect(() => {
    return () => handleDeleteSlides(false)
  }, [])

  useEffect(() => {
    if (selectedSlides.length) {
      handleClickDelete(selectedSlides)
    }
  }, [deleteItems])

  useEffect(() => {
    if (selectAllChecked && slidesList?.length > selectedSlides.length) {
      setSelectedSlides(slidesList?.map(slide => slide.pdfSlides[0].uid));
    }
  }, [slidesList?.length])

  useEffect(() => {
    if (!showSnackBar && slideDeckShows.selected.length > 0 && slidesList.length === 0) {
      setSelectedShows([]);
    }
  }, [showSnackBar])

  async function handleClickDelete(slides) {
    //if we delete multiple slides in rapid succession we need to delete the previous before replacing the state prop with the new one
    await handleDeleteSlides();

    // set the next episode to be deleted
    setToDelete(slides);
    setShowSnackBar(true);
    await fetchMoreItems(slides);
  }

  async function handleDeleteSlides(refetch = true) {
    if (toDeleteRef?.current) {
      await removeSlideDecks({
        variables: {
          slideDeckIds: toDeleteRef?.current.map(slide => slide.pdfSlides[0].uid)
        }
      })

      // this action will only perform if the refetch argument is passed - to avoid performing state updates on an unmount
      if (refetch) {
        refetchShows();
      }
      dataLayerPush(`Episode removed from 'My Slides'`);
    }
    setShowSnackBar(false);
  }
  function handleUndoDeleteSlides() {
    setShowSnackBar(false);
    setToDelete(null);
    onUndoDeleteSlides();
  }

  function handleCheck(uid) {
    const updatedSelected = selectedSlides.includes(uid) ? selectedSlides.filter(c => c !== uid) : [...selectedSlides, uid];

    if (updatedSelected.length === slidesList.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }

    setSelectedSlides(updatedSelected);
  }

  function handleSelectAll() {
    if (selectAllChecked) {
      setSelectAllChecked(false);
      setSelectedSlides([]);
    } else {
      setSelectAllChecked(true);
      setSelectedSlides(slidesList);
    }
  }

  return (
    <>
      {edit &&
        <SelectAllRow handleSelectAll={handleSelectAll} selectAllChecked={selectAllChecked} />
      }
      <InfiniteScroll
        dataLength={slidesList?.length} // This is important field to render the next data
        next={!showSnackBar && fetchNextPage}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        className='MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2'
        scrollableTarget='dashboardScrollContainer'
      >
        {slidesList?.map((slide, index) => (
          <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
            <SlidesCard
              edit={edit}
              episode={slide}
              onDelete={() => handleClickDelete([slide])}
              checked={selectedSlides.includes(slide)}
              onCheck={() => handleCheck(slide)}
            />
          </Grid>
        ))}
      </InfiniteScroll>

      {slidesList?.length === 0 &&
        <EmptySlides />
      }
      {showSnackBar &&
        <UndoSnackBar
          episode={toDelete}
          onAllowTimeOut={() => handleDeleteSlides()}
          onUndo={handleUndoDeleteSlides}
          message='You removed a slide.'
        />
      }
    </>
  );
};

export default React.memo(MySlidesBody);
