import theme from 'theme';

const width = (typeof window !== "undefined") ? window.screen : 0;

export const ttSnackbarOptions = {
    variant: 'error',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    }
  }