
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CtaButtonFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CtaButton"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buttonLink"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LinkFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"buttonType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonCtaMobileName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonCtaName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonIcon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonAction"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":190}};
    doc.loc.source = {"body":"#import \"./LinkFields.graphql\"\n\nfragment CtaButtonFields on CtaButton {\n  buttonLink {\n    ...LinkFields\n  }\n  buttonType\n  buttonCtaMobileName\n  buttonCtaName\n  buttonIcon\n  buttonAction\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./LinkFields.graphql").definitions));


      module.exports = doc;
    
