import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import menuItemShape from 'shapes/menuItemShape';
import Typography from '@material-ui/core/Typography';
import DropdownList from 'components/Header/components/SuperNavLink/DropdownList';
import SubMenuItem from 'components/Header/components/SuperNavLink/SubMenuItem';
import { useAuth } from 'context/AuthContext';
import UserAvatar from 'components/Header/components/UserAvatar';
import { css } from 'styled-components';
import { dataLayerPush } from 'utils/dataLayer';

const UserName = styled(Typography)`${({ theme }) => css`
  font-family: ${theme.typography.fontFamilySecondary};
`}`;

UserAccountMenu.propTypes = {
  menuItem: menuItemShape.isRequired,
  component: PropTypes.elementType,
  mobile: PropTypes.bool,
  onClick: PropTypes.func,
};

function UserAccountMenu({ menuItem, component: Component, onClick, mobile = false }) {
  const { user } = useAuth();

  // In mobile, we just return the two links directly since there's no dropdown.
  if (mobile) {
    return (
      <>
        <Component
          component={RouterLink}
          to='/profile'
          onClick={onClick}
        >
          Manage Profile
        </Component>
        <Component href='/api/logout' onClick={() => {onClick();}}>Log Out</Component>
      </>
    );
  }

  const firstName = user?.given_name || 'My Account';

  return (
    <>
      <Component component={RouterLink} to='/profile'>
        <UserAvatar id='userAvatar' />
        <UserName id='userFirstName'>{firstName.length <= 10 ? firstName : 'My Account'}</UserName>
      </Component>
      <DropdownList>
        <SubMenuItem as={RouterLink} to='/profile'>Manage Profile</SubMenuItem>
        <SubMenuItem href='/api/logout'>Log Out</SubMenuItem>
      </DropdownList>
    </>
  );
}

export default UserAccountMenu;
