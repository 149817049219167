
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KeyConceptsFeedFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"KeyConceptsFeed"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reference"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"definition"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"thumbnailImage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"buttonCta"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CtaButtonFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":274}};
    doc.loc.source = {"body":"#import \"../AssetFields.graphql\"\n#import \"../CtaButtonFields.graphql\"\n\nfragment KeyConceptsFeedFields on KeyConceptsFeed {\n  header\n  reference {\n    title\n    definition\n    thumbnailImage {\n      ...AssetFields\n    }\n    url\n  }\n  buttonCta {\n    ...CtaButtonFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../CtaButtonFields.graphql").definitions));


      module.exports = doc;
    
