import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import VideoPlayer from 'components/VideoPlayer';
import UnmuteOverlay from 'components/UnmuteOverlay';
import OptionsDisclaimer from 'components/OptionsDisclaimer';
import LiveVideoPlayer from 'components/VideoPlayer/LiveVideoPlayer';
import LiveVideoStatusBar from 'components/VideoPlayer/LiveVideoStatusBar';
import { autoOptimizeImage } from 'utils/functions';

const VideoPlayerWrapper = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
`;

const LivePlayerWrapper = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
`;

BlockVideoPlayer.propTypes = {
  videoData: PropTypes.shape({
    bcVideoFile: PropTypes.string,
    livePlayer: PropTypes.bool,
    thumbnailImage: PropTypes.shape({
      url: PropTypes.string,
    }),
    videoLink: PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
    })
  }),
};

function BlockVideoPlayer({ videoData }) {
  const { bcVideoFile, livePlayer, thumbnailImage, videoLink } = videoData;
  const [isMuted, setIsMuted] = useState(true);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [staticVideoLoaded, setStaticVideoLoaded] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState();

  const onVideoPlayerLoaded = (success) => {
    setPlayerLoaded(true);
    setVideoPlayer(success.ref);
  }

  return (
    <VideoPlayerWrapper>
      {livePlayer && (
        <>
          <LivePlayerWrapper>
            <LiveVideoPlayer onSuccess={onVideoPlayerLoaded} />
            {playerLoaded && isMuted && (
              <UnmuteOverlay
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                videoPlayer={videoPlayer}
              />
            )}
          </LivePlayerWrapper>
          <OptionsDisclaimer
            intro='Market Data provided by CME Group & powered by dxFeed Technology. Options involve risk and are not suitable for all investors.'
            placement='homepage'
          />
          <LiveVideoStatusBar />
        </>
      )}
      {bcVideoFile && (
        <LivePlayerWrapper>
          {staticVideoLoaded && (
            <VideoPlayer
              autoplay={true}
              videoId={bcVideoFile}
              onSuccess={onVideoPlayerLoaded}
            />
          )}
          {!staticVideoLoaded && (
            <>
              {thumbnailImage && (
                <img
                  alt='Video player placeholder'
                  src={autoOptimizeImage(thumbnailImage.url)}
                />
              )}
              <UnmuteOverlay
                setStaticVideoLoaded={setStaticVideoLoaded}
                videoPlayer={videoPlayer}
              />
            </>
          )}
        </LivePlayerWrapper>
      )}
    </VideoPlayerWrapper>
  );
}

export default React.memo(BlockVideoPlayer);
