import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MegaphoneIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_494" data-name="Group 494" transform="translate(-5.003 1.363)">
          <path id="Path_253" data-name="Path 253" d="M20.172-71.556l2.039,5,9.574.313L26.726-78.652Z" transform="translate(-11.124 81.529)" fill="#e31b23"/>
          <path id="Path_254" data-name="Path 254" d="M48.626-83.579a.852.852,0,0,0-1.119-.471.875.875,0,0,0-.464,1.136L52.1-70.506a.852.852,0,0,0,1.119.471.875.875,0,0,0,.464-1.136Z" transform="translate(-30.781 85.514)" fill="#e31b23"/>
          <path id="Path_255" data-name="Path 255" d="M62.166-67.678l-.264.111,1.492,3.66.264-.111c.609-.256,1.159-.566.441-2.329s-1.323-1.587-1.932-1.331Z" transform="translate(-41.726 73.645)" fill="#e31b23"/>
          <path id="Path_256" data-name="Path 256" d="M69.45-85.068a.5.5,0,0,0,.243-.218L71.5-88.518a.511.511,0,0,0-.187-.692.5.5,0,0,0-.681.19l-1.809,3.232a.511.511,0,0,0,.188.692A.5.5,0,0,0,69.45-85.068Z" transform="translate(-46.753 89.276)" fill="#e31b23"/>
          <path id="Path_257" data-name="Path 257" d="M82.067-54.259l-3.529-.986a.5.5,0,0,0-.614.354.51.51,0,0,0,.349.624l3.529.986a.494.494,0,0,0,.323-.02.5.5,0,0,0,.291-.334.508.508,0,0,0-.349-.624Z" transform="translate(-53.461 64.475)" fill="#e31b23"/>
          <path id="Path_258" data-name="Path 258" d="M80.956-73.683,77.1-72.062a.509.509,0,0,0-.271.662.5.5,0,0,0,.652.275l3.856-1.622a.51.51,0,0,0,.271-.662A.5.5,0,0,0,80.956-73.683Z" transform="translate(-52.644 77.935)" fill="#e31b23"/>
          <path id="Path_259" data-name="Path 259" d="M8.39-51.43l-2.4,1.008a.48.48,0,0,0-.2.182l-.756,1.39a.344.344,0,0,0-.015.261l1.047,2.568a.337.337,0,0,0,.192.175l1.5.44a.466.466,0,0,0,.269-.016l2.4-1.008Z" transform="translate(0 61.68)" fill="#e31b23"/>
          <path id="Path_260" data-name="Path 260" d="M20.525-31.035l-2.044.859,1.856,4.552a.142.142,0,0,0,.186.078l1.78-.748a.146.146,0,0,0,.077-.189Z" transform="translate(-9.884 46.809)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default React.memo(MegaphoneIcon);
