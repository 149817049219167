import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextArea from 'components/TextArea';
import Input from 'components/Input';
import Button from 'components/Button';
import submitFeedbackMutation from './submitFeedback.graphql';
import { useMutation } from '@apollo/client';
import { useAuth } from 'context/AuthContext';
import ErrorAlert from 'components/ErrorAlert';
import { findFieldError } from 'utils/functions';
import { validateEmail } from 'utils/commongRegex';

const FormTitle = styled(Typography)`
  align-items: center;
  display: flex;
  margin-bottom: 2.5rem;
`;

const ImageContainer = styled.div`
  margin-right: 1rem;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: 2rem;
  }

  img {
    ${props => props.theme.breakpoints.down('xs')} {
      max-width: 2.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1.625rem;
  text-align: center;

  button {
    min-width: 12.5rem;
  }
`;

ModalForm.propTypes = {
  onClose: PropTypes.func,
};

function ModalForm({ modalForm }) {
  const { user } = useAuth();
  const [email, setEmail] = useState(user?.email || '');
  const [successful, setSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [fieldError, setFieldError] = useState(null);

  const initialFields = useMemo(() => {
    if (!modalForm) {
      return [];
    }
    return modalForm.formFields.map((field, index) => {
      return { id: index, label: field.fieldLabel, error: null, data: '', fieldType: field.formInputType }
    })
  }, [modalForm]);

  const [fields, setFields] = useState(initialFields);

  const updateField = useCallback((index, data) => {
    setFields(fields.map((field, idx) => {
      if (idx === index) {
        field.data = data;
      }
      return field;
    }));
    }, []);

  const clearFields = () => {
    setFields(fields.map((field) => {
      field.data = '';
      return field;
    }));
  };

  const [submitFeedback, { loading, error }] = useMutation(submitFeedbackMutation, {
    onCompleted: ({ submitFeedback: result }) => {
      if (result.success) {
        setSuccessful(true);
        setEmail('');
        clearFields();
      } else {
        setEmailError(findFieldError(result.errors, 'email'));
        setFieldError(findFieldError(result.errors, 'fields'));
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear success tracking state before sending.
    setSuccessful(false);
    setEmailError(null);
    setFieldError(null);
    setFields(fields.map((e) => {
      e.error = null;
      return e;
    }));

    if (!validateEmail(email)) {
      setEmailError({ value: email, msg: 'Invalid Email' })
      return;
    }

    const fieldsToSend = fields.map((field) => {
      return { label: field.label, answer: field.data };
    });

    await submitFeedback({
      variables: {
        input: {
          subject: modalForm.title,
          email,
          fields: fieldsToSend,
        },
      },
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle component='div'>
        <ImageContainer>
          <picture>
            <source srcset={modalForm.iconWebp.url} type='image/webp' />
            <source srcset={modalForm.iconPng.url} type='image/png' />
            <img alt='podcast icon' src={modalForm.iconPng.url} />
          </picture>
        </ImageContainer>
        <Typography variant='h2'>
          {modalForm.userFacingTitle}
        </Typography>
      </FormTitle>

      {successful && (
        <Box mb={2}>
          <Alert severity='success'>{modalForm.successMessage}</Alert>
        </Box>
      )}

      {fieldError && (
        <Box>
          {fieldError.toString()}
        </Box>
      )}

      <Input
        id='emailAddress'
        label='Email Address'
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        width='full'
        required
        error={emailError && email === emailError.value}
        helperText={emailError && email === emailError.value && emailError.msg}
      />

      {fields.map((field, index) => {
        return (
          <>
            {field.fieldType === 'Multi Line Text' ? (
              <TextArea
                key={field.id}
                label={field.label}
                onChange={(e) => updateField(index, e.target.value)}
                value={field.data}
                rows={8}
                multiline={true}
                type='textarea'
                width='full'
                required
              />
            ) : (
              <Input
                key={field.id}
                label={field.label}
                onChange={(e) => updateField(index, e.target.value)}
                value={field.data}
                width='full'
                required
              />
            )}
          </>
        );
      })}

      {/* If we get an apollo error from the mutation, it's not an error with specific field so we show generic error. */}
      {error && (
        <ErrorAlert severity='error'>
          Uh oh, something went wrong with submitting your feedback.
        </ErrorAlert>
      )}

      <ButtonContainer>
        <Button color='primary' type='submit' variant='contained' disabled={loading}>Send</Button>
      </ButtonContainer>
    </form>
  );
}

export default React.memo(ModalForm);
