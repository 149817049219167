import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ModalWithTrigger from 'components/ModalWithTrigger';
import { Panel, InfoRow, InfoCol, Label, LinkContainer } from './SharedStyles';
import auth0UserShape from 'shapes/auth0UserShape';
import UpdateEmail from './UpdateEmail';
import { dataLayerPush } from 'utils/dataLayer';

EmailPanel.propTypes = {
  auth0Id: PropTypes.string,
  refetchAuth0: PropTypes.func,
  personalInfo: auth0UserShape,
  socialUser: PropTypes.bool,
};

function EmailPanel({ auth0Id, refetchAuth0, personalInfo, socialUser }) {
  const [open, setOpen] = useState(false);
  const { email } = personalInfo;

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Update Email'`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Panel item xs={12} sm={12} md={4}>
      <InfoRow>
        <InfoCol>
          <Label>Email</Label>
          {email &&
            <Typography>{email}</Typography>
          }
        </InfoCol>
        {!socialUser && (
          <LinkContainer>
            <ModalWithTrigger
              ariaLabel='Update email'
              ariaDescribe='Form where a user can update their email address'
              handleClose={handleClose}
              handleOpen={handleOpen}
              height={502}
              icon={<ChevronRightIcon />}
              open={open}
              padding='medium'
              triggerText='Update Email'
              triggerType='link'
              width={792}
            >
              <UpdateEmail
                auth0Id={auth0Id}
                email={email}
                refetchAuth0={refetchAuth0}
                handleClose={handleClose}
              />
            </ModalWithTrigger>
          </LinkContainer>
        )}
      </InfoRow>
    </Panel>
  );
}

export default React.memo(EmailPanel);
