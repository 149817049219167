import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import styled from 'styled-components';
import WelcomeMessage from './WelcomeMessage';
import PropTypes from 'prop-types';

const HelpIconWrapper = styled(HelpIcon)`
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
`;

HelpModal.propTypes = {
  onClose: PropTypes.func
}

function HelpModal({ onClose }) {
  const [showModal, setShowModal] = useState(false);

  function clickHelpIcon(e) {
    e.stopPropagation();
    setShowModal(!showModal);
  }

  function handleClose() {
    setShowModal(false)
    onClose();
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <HelpIconWrapper fontSize='small' onClick={e => clickHelpIcon(e)}  />
      <WelcomeMessage showOverride={true} show={showModal} onClose={handleClose} />
    </div>
  )
}

export default React.memo(HelpModal);
