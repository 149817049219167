import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useDashboardContext } from 'context/DashboardContext';
import SortOptions from 'constants/SortOptions';
import { dataLayerPush } from 'utils/dataLayer';
import Popover from 'components/Popover';

const DASHBOARD_SORT_OPTIONS = [
  { value: SortOptions.newest, label: 'Newest' },
  { value: SortOptions.oldest, label: 'Oldest' },
];

const IconContainer = styled.div`
  align-items: center;
  color: ${prop('theme.colors.mediumGray')};
  display: flex;
  font-weight: 600;
  text-transform: uppercase;
`;

const SortValue = styled.div`
  cursor: pointer;
  font-family: ${theme('typography.fontFamilySecondary')};
  min-width: 5rem;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${prop('theme.colors.ttRed')};
  }
`;

DesktopSort.propTypes = {
  dashComponent: PropTypes.func,
  expanded: PropTypes.string,
  page: PropTypes.string,
};

function DesktopSort({ dashComponent, expanded, page }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [desktopSortDirection, setDesktopSortDirection] = useState(DASHBOARD_SORT_OPTIONS[0].value);
  const { setSortBy } = useDashboardContext();

  const DashboardComponent = dashComponent;

  /**
   * React components to use for desktop filters.
   */
  const DESKTOP_FILTER_COMPONENTS = {
    panelMyShows: () => <DashboardComponent />,
    panelMySlides: () => <DashboardComponent />,
    panelPlaylists: false,
    panelEpisodes: false,
    panelDailyReplays: false,
  };

  const DesktopFilterComponent = DESKTOP_FILTER_COMPONENTS[expanded];

  if (!DesktopFilterComponent) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDesktopSorting = (event) => {
    const sortValue = event.target.innerHTML;

    setDesktopSortDirection(sortValue);
    setSortBy(sortValue);
    handleClose();
    dataLayerPush(`Dashboard sorted by ${sortValue}`);
  };

  return (
    <>
      <SortIcon className='standard-sort'/>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        handleClick={handleClick}
        handleClose={handleClose}
        icon={<KeyboardArrowDownIcon className='down'/>}
        iconPlacement='suffix'
        page={page}
        triggerPadding='none'
        triggerText={desktopSortDirection}
        triggerType='link'
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {DASHBOARD_SORT_OPTIONS.map((sortOption, index) => (
          <SortValue key={index} onClick={toggleDesktopSorting}>
            {sortOption.value}
          </SortValue>
        ))}
      </Popover>
    </>
  );
}

export default React.memo(DesktopSort);
