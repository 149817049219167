import React from 'react';
import PropTypes from 'prop-types';
import livestreamShape from 'shapes/livestreamShape';
import PipEnabledPlayer from 'components/VideoPlayer/PipEnabledPlayer';
import LiveShowStructuredData from 'components/VideoPlayer/LiveShowStructuredData';

LiveVideoPlayer.propTypes = {
  poster: PropTypes.string,
  autoplay: PropTypes.oneOf([false, true, 'muted', 'play', 'any']),
  onSuccess: PropTypes.func,
  playerSettings: livestreamShape,
  videoId: PropTypes.string,
};

function LiveVideoPlayer(props) {
  return (
    <>
      <LiveShowStructuredData />
      <PipEnabledPlayer live clearExistingPip {...props} />
    </>
  );
}

export default React.memo(LiveVideoPlayer);
