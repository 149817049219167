import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ListContainer from './components/ListContainer';
import LearnTermsContainer from './components/LearnTermsContainer';
import glossaryListShape from 'shapes/components/glossaryListShape';

const Divider = styled(Container)`
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 10.75rem;
  }

  hr {
    margin: 0.5rem 0 4rem;
  }
`;

const GlossaryContainer = styled.div`
  &.partial-list {
    padding-top: 1rem;
  }
`;

const GridItem = styled(Grid)`
  width: 100%;

  ${ifProp('$glossary', css`
    ${props => props.theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: row-reverse;
    }
  `)}
`;

const propTypes = {
  data: glossaryListShape,
  className: PropTypes.string,
};

function GlossaryList({ data, className }) {
  const {
    glossaryHeader,
    textBody,
    fullGlossary
  } = get(data, 'glossaryListTermsImportsAutomatically');

  return (
    <>
      {!fullGlossary &&
        <Divider maxWidth='xl'>
          <hr />
        </Divider>
      }
      <GlossaryContainer
        className={`${className} ${fullGlossary ? 'full-list' : 'partial-list'}`}
      >
        <Grid container $glossary={fullGlossary}>
          <GridItem item xs={12} md='auto' $glossary={fullGlossary}>
            {fullGlossary &&
              <ListContainer glossaryHeader={glossaryHeader} textBody={textBody} />
            }
            {!fullGlossary &&
              <LearnTermsContainer
                glossaryHeader={glossaryHeader}
                textBody={textBody}
              />
            }
          </GridItem>
        </Grid>
      </GlossaryContainer>
    </>
  );
}

GlossaryList.propTypes = propTypes;

export default GlossaryList;
