import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Hidden } from '@material-ui/core';
import EpisodeDropdownFeedNav from './EpisodeDropdownFeedNav';
import episodeFeedShape from 'shapes/episodeFeedShape';

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none; 
  }
`;

const StyledTab = styled(Tab)`
  font-family: ${prop('theme.typography.fontFamilySecondary')};
  font-size: 1.125rem;
  font-weight: 600;
  color: ${prop('theme.colors.black')};;
  min-width: fit-content;
  margin-right: 1.5rem;
  opacity: 1; 
  padding: 0;

  &.Mui-selected {
    color: ${prop('theme.colors.ttRed')};
  }
  
  &:hover {
    text-decoration: underline;
  }

  &.Mui-selected:hover {
    text-decoration: none;
  }

  && {
    .MuiTab-wrapper { 
      align-items: flex-start; 
    }
  }
`;

EpisodeTabbedFeedNav.propTypes = {
  tabTitle: PropTypes.string,
  episodeFeed: episodeFeedShape,
  setValue: PropTypes.func.isRequired 
};

function EpisodeTabbedFeedNav({ data, value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Hidden smDown>
        <StyledTabs value={value} onChange={handleChange} aria-label="Episode Feed Tabs">
          {data.map((tab, index) => (
            <StyledTab disableRipple key={index} label={tab.tabTitle} value={index} />
          ))}
        </StyledTabs>
      </Hidden> 

      <Hidden mdUp>
        <EpisodeDropdownFeedNav value={value} data={data} setValue={setValue}/>
      </Hidden>
    </div>
  );
}

export default EpisodeTabbedFeedNav;
