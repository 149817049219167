import React from 'react';
import PropTypes from 'prop-types';
import menuItemShape from 'shapes/menuItemShape';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useDashboardContext } from 'context/DashboardContext';
import FirstTimeDashboardHelper from '../FirstTimeDashboardHelper';
import SubsequentDashboardHelper from '../SubsequentDashboardHelper';

UserDashboard.propTypes = {
  menuItem: menuItemShape,
  component: PropTypes.elementType,
  mobile: PropTypes.bool,
};

function UserDashboard({ component: Component, mobile = false }) {
  const { toggleDashboard, setFirstTimeHelper } = useDashboardContext();

  function handleClickDashboard() {
    toggleDashboard();
    setFirstTimeHelper(false);
  }

  // If this is for the mobile menu, don't render anything.
  if (mobile) {
    return null;
  }

  const tooltipStyles = {
    zIndex: 2200,
  }

  return (
    <SubsequentDashboardHelper>
      <>
        <Tooltip title="DASHBOARD" PopperProps={{ style: tooltipStyles }}>
          <Component onClick={handleClickDashboard} data-testid='desktopShowDashboard'>
            <DashboardIcon />
          </Component>
        </Tooltip>
        <FirstTimeDashboardHelper />
      </>
    </SubsequentDashboardHelper>
  );
}

export default React.memo(UserDashboard);
