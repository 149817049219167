import React from 'react';
import modularBlockShape from 'shapes/modularBlockShape';
import BlockWrapper from './BlockWrapper';
import loadable from '@loadable/component';
import ShowOneColumn from './ShowOneColumn';
import BasicTextBlock from './BasicTextBlock';
import EventsList from './EventsList';
import EpisodeFeedBlock from './EpisodeFeedBlock';
import CalloutBlock from './CalloutBlock';
import MarketNewsFeedBlock from './MarketNewsFeedBlock';
import AdSlotBlock from './AdSlotBlock';
import VideoTwoBlock from './VideoTwoBlock';
import KeyConceptsFeedBlock from './KeyConceptsFeedBlock';
import TextBlock from './TextBlock';
import LogoGridFullbleedBanner from './LogoGridFullbleedBanner';
import ShowsFeed from './ShowsFeed';
import ShowTwoColumn from './ShowTwoColumn';
import ShowThreeColumn from './ShowThreeColumn';
import TestimonialsBlock from './TestimonialsBlock';
import BrandList from './BrandList';
import VideoHero from './VideoHero';
import TalentCards from './TalentCards';
import ConceptOfTheDay from './ConceptOfTheDay';
import GlossaryList from './GlossaryList';
import FullBleedCtaBanner from './FullBleedCtaBanner';
import TwoBlockContent from './TwoBlockContent';
import ContentCards from './ContentCards';
import BlogPostFeedBlock from './BlogPostFeedBlock';
import FileList from './FileList';

const modularBlockComponents = {
  textBlock: TextBlock,
  disclaimerBody: loadable(() => import('./TextBlock')),
  fileList: FileList,
  fullBleedCtaBanner: FullBleedCtaBanner,
  logoGridFullbleedBanner: LogoGridFullbleedBanner,
  showOneColumn: ShowOneColumn,
  showTwoColumn: ShowTwoColumn,
  showThreeColumn: ShowThreeColumn,
  testimonials: TestimonialsBlock,
  basicTextBlock: BasicTextBlock,
  twoBlockContent: TwoBlockContent,
  contentCards: ContentCards,
  comparisonTable: loadable(() => import('./ComparisonTable')),
  eventsList: EventsList,
  glossaryList: GlossaryList,
  videoHero: VideoHero,
  episodeFeed: EpisodeFeedBlock,
  blogPostFeed: BlogPostFeedBlock,
  recommendedShows: loadable(() => import('./RecommendedShowsBlock')),
  continueWatching: loadable(() => import('./ContinueWatchingBlock')),
  talentCards: TalentCards,
  brandList: BrandList,
  accolades: loadable(() => import('./Accolades')),
  conceptOfTheDay: ConceptOfTheDay,
  calloutBlock: CalloutBlock,
  adSlotBlock: AdSlotBlock,
  livePlayerBlock: loadable(() => import('./LivePlayerBlock')),
  pressPostsList: loadable(() => import('./PressPostsList')),
  twitterFeed: loadable(() => import('./TwitterFeed')),
  socialMediaLinks: loadable(() => import('./SocialLinks')),
  marketNewsFeedBlock: MarketNewsFeedBlock,
  videoTwoBlock: VideoTwoBlock,
  keyConceptsFeed: KeyConceptsFeedBlock,
  showsFeed: ShowsFeed,
};

const propTypes = {
  data: modularBlockShape,
};

function ModularBlock({ data, ...props }) {
  const blockType = Object.keys(data).find(k => !!data[k] && k !== '__typename');

  if (!blockType) {
    return null;
  }

  const ModularBlockComponent = modularBlockComponents[blockType];
  if (!ModularBlockComponent) {
    return null;
  }

  return (
    <BlockWrapper blockType={blockType} data={data[blockType]}>
      <ModularBlockComponent
        data={data[blockType]}
        className={`modular-block-${blockType}`}
        {...props}
      />
    </BlockWrapper>
  );
}

ModularBlock.propTypes = propTypes;

export default React.memo(ModularBlock);
