
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BrandListBlockFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BrandListBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandListing"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandLogo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"mobileBrandLogo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subheader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentBody"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buttonCta"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CtaButtonFields"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"additionalStyles"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdditionalStylesFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":504}};
    doc.loc.source = {"body":"#import \"../AssetFields.graphql\"\n#import \"../AdditionalStylesFields.graphql\"\n#import \"../BlockStyleFields.graphql\"\n#import \"../CtaButtonFields.graphql\"\n\nfragment BrandListBlockFields on BrandListBlock {\n  brandListing {\n    brandLogo {\n      ...AssetFields\n    }\n    mobileBrandLogo {\n      ...AssetFields\n    }\n    header\n    subheader\n    contentBody\n    buttonCta {\n      ...CtaButtonFields\n    }\n  }\n  additionalStyles {\n    ...AdditionalStylesFields\n  }\n  blockStyle {\n    ...BlockStyleFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../AssetFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../AdditionalStylesFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../BlockStyleFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../CtaButtonFields.graphql").definitions));


      module.exports = doc;
    
