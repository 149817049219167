import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ifNotProp, ifProp, prop, switchProp, theme } from 'styled-tools';
import Callout, { CalloutContent, HeaderBar } from './components/Callout';
import calloutBlockShape from 'shapes/components/calloutBlockShape';
import Typography from '@material-ui/core/Typography';
import RichTextField from 'components/RichTextField';
import BlockContainer from 'components/BlockContainer';
import StackHeader from 'components/StackHeader';
import { createHeaderId } from 'utils/functions';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import { useElementOnScreen } from 'hooks/useElementOnScreen';

const CalloutBlockContainer = styled(BlockContainer)`
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  .circle-list-callout {
    &:before {
      color: ${theme('colors.white')};
      background: ${theme('colors.ttRed')};
      border-radius: 100%;
      counter-increment: list-counter;
      content: counter(list-counter);
      display: block;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.625rem;
      margin: 0 0.75rem 0 0;
      max-height: 1.625rem;
      max-width: 1.625rem;
      text-align: center;
      width: 100%;
    }
  }

  ${ifProp(
    '$isKeyConcept',
    css`
      padding-left: 0;
      padding-right: 0;
    `
  )}

  ${ifProp(
    { $page: 'definition' },
    css`
      display: none;
    `
  )}

  ${ifProp(
    { $calloutCount: 0 },
    css`
      padding-bottom: 0;
    `
  )}
`;

// callout styles within textfield
const Body = styled(RichTextField)`
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
  }

  h3,
  h4,
  .h3,
  .h4 {
    font-family: ${theme('typography.fontFamily')};
  }

  h3,
  .h3 {
    font-size: 1rem;
  }

  h4,
  .h4 {
    font-size: 0.875rem;
  }

  img {
    aspect-ratio: 3 / 2;
    object-fit: contain;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  }
`;

const GridContainer = styled(Grid)`
  ${ifNotProp(
    { $columns: 'split' },
    css`
      box-shadow: 0px 10px 20px #00000029;
      margin-bottom: 2rem;
    `
  )}

  &:first-child {
    ${Body} {
      margin-top: 0;
    }
  }
`;

const GridItem = styled(Grid)`
  counter-reset: list-counter;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ifProp(
    { $columns: 'connected' },
    css`
      &:first-child {
        ${HeaderBar} {
          ${(props) => props.theme.breakpoints.up('md')} {
            border-right: 0.0625rem solid ${theme('colors.inputGray')};
          }
        }

        ${CalloutContent} {
          ${(props) => props.theme.breakpoints.up('md')} {
            border-right: 0.0625rem solid ${theme('colors.borderGray')};
          }
        }
      }
    `
  )}

  img {
    height: auto;
    width: 100%;
  }
`;

export const Header = styled(StackHeader)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 2rem;

  ${switchProp('$type', {
    'content-block': css`
      color: ${theme('colors.black')};
      font-size: 1.25rem;
      margin-bottom: 1rem;
    `,
    'callout': css`
      color: ${theme('colors.white')};
      margin-top: 0;
    `,
  })}

  ${switchProp('headerType', {
    H2: css`
      font-size: 1.25rem;
    `,
    H3: css`
      font-size: 1rem;
    `,
    H4: css`
      font-size: 0.875rem;
    `,
    H5: css`
      font-size: 1.1rem;
    `,
    H6: css`
      font-size: 1rem;
    `
  })}
`;

CalloutBlock.propTypes = {
  data: calloutBlockShape,
};

function CalloutBlock({ data, ...props }) {
  const { page } = props;
  const { blockHeader, callouts } = data;
  const calloutsLength = callouts?.length;
  const { setActiveContentItem } = useKeyConceptsContext();

  if (!data) {
    return null;
  }

  const setSubheaderId = (blockHeader) => {
    const { displayOnToc, tocHeader } = blockHeader;
    if (displayOnToc && tocHeader) {
      return createHeaderId(tocHeader);
    }
    return null;
  };

  const handleIntersection = (id) => {
    setActiveContentItem(`#${id}`);
  };

  useElementOnScreen(handleIntersection, '.callout');

  const setHeaderId = (blockHeader) => {
    const { contentHeader, displayOnToc, tocHeader } = blockHeader;

    if (displayOnToc && !tocHeader) {
      return createHeaderId(contentHeader);
    } else if (displayOnToc && tocHeader) {
      return createHeaderId(tocHeader);
    }
  };

  return (
    <CalloutBlockContainer
      $calloutCount={calloutsLength}
      $isKeyConcept={page.includes('concepts')}
      $page={page}
    >
      {blockHeader?.contentHeader && (
        <div id={setHeaderId(blockHeader)} className={calloutsLength < 1 && 'callout'}>
          <Header
            headerType={blockHeader?.headerType ?? 'H2'}
            $type='content-block'
          >
            {blockHeader.contentHeader}
          </Header>
        </div>
      )}
      {calloutsLength > 0 && (
        <div id={setHeaderId(blockHeader)}>
          {callouts.map((callout, index) => {
            const { calloutColumns, columnsType } = callout;
            const calloutColsLength = calloutColumns?.length;
            const size = 12 / calloutColsLength;

            return (
              <GridContainer
                container
                $columns={columnsType}
                key={index}
                spacing={columnsType === 'split' ? 4 : 0}
              >
                {calloutColumns.map((column, index) => {
                  const { columnBody, callout } = column;

                  return (
                    <GridItem
                      item
                      key={index}
                      md={12}
                      lg={size}
                      $columns={columnsType}
                    >
                      {columnBody &&
                        <Body
                          id={setHeaderId(blockHeader)}
                          className='callout'
                          richText={columnBody}
                        />
                      }
                      <Callout
                        callout={callout}
                        columnsType={columnsType}
                        id={setSubheaderId(callout) || setSubheaderId(blockHeader)}
                        blockHeader={blockHeader}
                      />
                    </GridItem>
                  );
                })}
              </GridContainer>
            );
          })}
        </div>
      )}
    </CalloutBlockContainer>
  );
}

export default CalloutBlock;
