import React, { useReducer, useContext, useState, useEffect } from 'react';
import { getEpisodeVideoId } from 'utils/episodeHelpers';

function getDefaultState() {
  const DEFAULT_STATE = {
    isDrawerOpen: false,
    sidePanelOpen: 'schedule',
    currentPage: 0,
    totalPages: 0,
    selectedEpisode: undefined,
    isVideoMode: true,
  };

  let storedState = {};

  if (typeof localStorage !== "undefined") {
    // TODO: load stored state of dashboard from localStorage
  }

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_DISPLAY_SCHEDULE = 'displaySchedule';
const ACTION_DISPLAY_TRADES = 'displayTrades';
const ACTION_NEXT_PAGE = 'nextPage';
const ACTION_PREV_PAGE = 'prevPage';
const ACTION_SET_PAGE = 'setPage';
const ACTION_SET_TOTAL_PAGES = 'setTotalPages';
const ACTION_PLAY_EPISODE = 'playEpisode';
const ACTION_BACK_TO_LIVE = 'backToLive';
const ACTION_OPEN_MOBILE_DRAWER = 'openMobileDrawer';
const ACTION_CLOSE_MOBILE_DRAWER = 'closeMobileDrawer';
const ACTION_TOGGLE_VIDEO_MODE = 'toggleVideoMode';

function livePlayerReducer(state, action) {
  switch (action.type) {
    case ACTION_DISPLAY_SCHEDULE:
      return {
        ...state,
        isDrawerOpen: true,
        sidePanelOpen: 'schedule',
        currentPage: 0,
        totalPages: 0,
      };
    case ACTION_DISPLAY_TRADES:
      return {
        ...state,
        isDrawerOpen: true,
        sidePanelOpen: 'trades',
        currentPage: 0,
        totalPages: 0,
      };
    case ACTION_NEXT_PAGE:
      return {
        ...state,
        currentPage: action.payload && typeof action.payload.next !== 'undefined' && null !== action.payload.next
          ? action.payload.next
          : state.currentPage + 1,
      };
    case ACTION_PREV_PAGE:
      return {
        ...state,
        currentPage: action.payload && typeof action.payload.prev !== 'undefined' && null !== action.payload.prev
          ? action.payload.prev
          : (state.currentPage > 0 ? state.currentPage - 1 : 0),
      };
    case ACTION_SET_PAGE:
      return {
        ...state,
        currentPage: action.payload || 0,
      };
    case ACTION_PLAY_EPISODE:
      // Only return new state object if episode isn't already playing.
      const episode = action.payload?.episode;
      if (!episode || state.selectedEpisode && state.selectedEpisode.uid === episode?.uid) {
        return state;
      }

      return {
        ...state,
        selectedEpisode: episode,
      };
    case ACTION_BACK_TO_LIVE:
      return {
        ...state,
        selectedEpisode: undefined,
      };
    case ACTION_SET_TOTAL_PAGES:
      if (action.payload.total === state.totalPages) {
        return state;
      }

      return {
        ...state,
        totalPages: action.payload.total,
      };
      case ACTION_OPEN_MOBILE_DRAWER:
        if (state.isDrawerOpen === true) {
          return state;
        }

        return {
          ...state,
          isDrawerOpen: true,
        };
      case ACTION_CLOSE_MOBILE_DRAWER:
        if (state.isDrawerOpen === false) {
          return state;
        }

        return {
          ...state,
          isDrawerOpen: false,
        };
      case ACTION_TOGGLE_VIDEO_MODE:
        return {
          ...state,
          isVideoMode: !state.isVideoMode,
        }
    default:
      return state;
  }
}

const LivePlayerContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  displaySchedule: () => {},
  displayTrades: () => {},
  nextPage: () => {},
  prevPage: () => {},
  setPage: (page) => {},
  playEpisode: (episode) => {},
  backToLive: () => {},
  setTotalPages: (total) => {},
  closeMobileDrawer: () => {},
  toggleVideoMode: () => {},
});

export const LivePlayerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    livePlayerReducer,
    getDefaultState()
  );

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    displaySchedule: () => dispatch({ type: ACTION_DISPLAY_SCHEDULE }),
    displayTrades: () => dispatch({ type: ACTION_DISPLAY_TRADES }),
    nextPage: (next = null) => dispatch({ type: ACTION_NEXT_PAGE, payload: { next } }),
    prevPage: (prev = null) => dispatch({ type: ACTION_PREV_PAGE, payload: { prev } }),
    setPage: (page) => dispatch({ type: ACTION_SET_PAGE, payload: page }),
    playEpisode: (episode) => dispatch({ type: ACTION_PLAY_EPISODE, payload: { episode } }),
    backToLive: () => dispatch({ type: ACTION_BACK_TO_LIVE }),
    setTotalPages: (total) => dispatch({ type: ACTION_SET_TOTAL_PAGES, payload: { total } }),
    closeMobileDrawer: () => dispatch({ type: ACTION_CLOSE_MOBILE_DRAWER }),
    toggleVideoMode: () => dispatch({ type: ACTION_TOGGLE_VIDEO_MODE }),
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state
    }));
  }, [state]);

  return (
    <LivePlayerContext.Provider value={contextValue}>
      {children}
    </LivePlayerContext.Provider>
  );
};

export const useLivePlayerContext = () => useContext(LivePlayerContext);
