import React from 'react';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import EmptyPage from '../EmptyPage';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

EmptyPlaylists.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

function EmptyPlaylists({
  title = 'You haven\'t added any episodes to this playlist yet.',
  message = <span>Create and curate custom playlists of your favorite tasty<em>live</em> videos.</span>
}) {
  const icon = <PlaylistAddIcon />;
  const history = useHistory();

  return (
    <EmptyPage
      title={title}
      text={message}
      icon={icon}
      buttonText='Discover Shows'
      onClick={() => history.push('/on-demand')}
    />
  )
}

export default React.memo(EmptyPlaylists);
