import styled, { css } from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';

const Label = styled(FormLabel)`${({ theme }) => css`
  color: ${theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  position: relative;
  transform: initial;

  &.Mui-focused {
    color: ${theme.colors.black};
  }

  small {
    display: block;
    margin-top: 0.25rem;
    text-transform: none;
    font-weight: normal;
  }
`}`;

export default Label;
