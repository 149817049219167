import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import LazyLoad from 'react-lazyload';
import ClampLines from 'react-clamp-lines';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FollowShowToggle from './FollowShowToggle';
import placeholderImg from 'assets/images/img-placeholder.png';
import { getShowThumbnailUrl, makeOverlayGradient } from 'utils/showHelpers';
import MobileModal from './components/MobileModal';
import linkResolver from 'utils/linkResolver';
import { useAuth } from 'context/AuthContext';
import showShape from 'shapes/showShape';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { dataLayerPush } from 'utils/dataLayer';

export const SHOW_CARD_SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  featured: 'featured',
};

const SkeletonCard = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.featured]: css`
      padding-top: 177.78%;
    `,
  })}
`;

const StyledSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled.div`
  color: ${theme('colors.white')};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  text-decoration: none;

  .MuiIconButton-root {
    color: black;
    transition: color 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover {
    text-decoration: none;

    .MuiIconButton-root {
      color: white;
    }
  }
`;

const ShowCardWrapper = styled.a`
  cursor: pointer;
  color: white;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding-top: 56.25%;
  opacity: 0;
  background: rgba(255, 255, 255, 0) 0% 0% no-repeat padding-box;
  transition: opacity 0.5s cubic-bezier(0.22, 1, 0.36, 1);

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.sm]: css`
      backdrop-filter: blur(6.2px);
    `,
    [SHOW_CARD_SIZES.md]: css`
      backdrop-filter: blur(7.3px);
    `,
    [SHOW_CARD_SIZES.lg]: css`
      backdrop-filter: blur(14px);
    `,
    [SHOW_CARD_SIZES.featured]: css`
      backdrop-filter: blur(5.6px);
    `,
  })}

  ${StyledCard}:hover &, ${ShowCardWrapper}:focus & {
    opacity: 1;
  }
`;

const CardOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.sm]: css`
      padding: 1rem 1rem 1rem;

      button {
        padding-left: 0;
        padding-right: 0;
      }
    `,
    [SHOW_CARD_SIZES.md]: css`
      padding: 2rem 2.5rem 2.5rem;
    `,
    [SHOW_CARD_SIZES.lg]: css`
      padding-bottom: 4rem;
      padding-left: 5rem;
    `,
    [SHOW_CARD_SIZES.featured]: css`
      padding: 2rem 2rem 2.5rem;
    `,
  })}

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    opacity: 0;

    ${switchProp('$size', {
      [SHOW_CARD_SIZES.sm]: css`
        background-image: ${(props) =>
          makeOverlayGradient(
            props.showOverlayColor,
            '42%',
            '-234%',
            '251deg'
          )};
      `,
      [SHOW_CARD_SIZES.md]: css`
        background-image: ${(props) =>
          makeOverlayGradient(
            props.showOverlayColor,
            '50%',
            '-250%',
            '248deg'
          )};
      `,
      [SHOW_CARD_SIZES.lg]: css`
        background-image: ${(props) =>
          makeOverlayGradient(props.showOverlayColor, '72%', '-70%', '244deg')};
      `,
      [SHOW_CARD_SIZES.featured]: css`
        background-image: ${(props) =>
          makeOverlayGradient(
            props.showOverlayColor,
            '73%',
            '-132%',
            'to bottom'
          )};
      `,
    })}
  }

  ${StyledCard}:hover &::before, ${ShowCardWrapper}:focus &::before {
    opacity: 1;
  }
`;

const DisplayOnHoverText = styled.div`
  opacity: 0;

  ${StyledCard}:hover &,  ${ShowCardWrapper}:focus & {
    opacity: 1;
  }
`;

const ShowTitle = styled(DisplayOnHoverText).attrs({
  as: Typography,
  variant: 'h2',
})`
  font-weight: 600;
  margin-bottom: 0.1875rem;
  margin-top: 0;

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.sm]: css`
      font-size: 0.75rem;
    `,
    [SHOW_CARD_SIZES.md]: css`
      font-size: 1.5625rem;
      margin-bottom: 1.25rem;
    `,
    [SHOW_CARD_SIZES.lg]: css`
      font-size: 2.5rem;
      margin-bottom: 1.25rem;
    `,
    [SHOW_CARD_SIZES.featured]: css`
      font-size: 1.875rem;
    `,
  })}
`;

const ShowTeaser = styled(DisplayOnHoverText).attrs({ as: Typography })`
  overflow: hidden;
  line-height: 1.45;
  font-size: 1.25rem;
  font-weight: 300;

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.sm]: css`
      width: 100%;
      margin: 0;
      font-size: 0.8125rem;
      line-height: 1.22;
    `,
    [SHOW_CARD_SIZES.md]: css`
      width: 100%;
    `,
    [SHOW_CARD_SIZES.lg]: css`
      max-width: 50%;
      margin-bottom: 2.5rem;
    `,
    [SHOW_CARD_SIZES.featured]: css`
      font-size: 1.125rem;
      max-height: 18.75rem;
      line-height: 1.5;
    `,
  })}
`;

const FollowShowContainer = styled.div`
  position: absolute;
  left: auto;
  top: 0.5rem;
  right: 0.5rem;
  bottom: auto;
  z-index: 100;
  display: none;

  .MuiIconButton-root {
    color: ${theme('colors.black')};
    transition: color 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  }

  ${StyledCard}:hover & {
    display: block;
    .MuiIconButton-root {
      color: ${theme('color.white')};
    }
  }
`;

const LazyLoadWrapper = styled(LazyLoad)``;

const ImageWrapper = styled.div`
  background-image: url('${prop('$backgroundImage')}');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  padding-top: 56.25%;
  position: relative;

  ${switchProp('$size', {
    [SHOW_CARD_SIZES.featured]: css`
      padding-top: 177.78%; // adjusted for new aspect ratio of 1280x720
    `,
  })}
`;

ShowCard.propTypes = {
  show: showShape.isRequired,
  size: PropTypes.oneOf(Object.keys(SHOW_CARD_SIZES)),
  loading: PropTypes.bool,
  className: PropTypes.string,
  shouldLazyLoad: PropTypes.bool,
  recommendation: PropTypes.string,
};

function ShowCard({
  show,
  size = SHOW_CARD_SIZES.lg,
  loading = false,
  shouldLazyLoad = true,
  index,
  className,
  recommendation = '',
}) {
  function LoadingPlaceholder() {
    return (
      <SkeletonCard $size={size} className={className + ' skeleton-card'}>
        <StyledSkeleton variant='rect' />
      </SkeletonCard>
    );
  }

  if (loading) {
    return <LoadingPlaceholder />;
  }

  if (!show) {
    return null;
  }

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const imageUrl = getShowThumbnailUrl(
    show,
    size === SHOW_CARD_SIZES.featured,
    size
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const showUrl = linkResolver(show);
  const { user } = useAuth();
  const history = useHistory();

  const { title, teaser, shortDescription, showOverlayColor } = show;
  const noTagDescription =
    shortDescription && shortDescription.replace(/<(.|\n)*?>/g, '');
  const hasDescription = teaser || shortDescription;

  function handleSelectShow(e) {
    // Prevent the default action of the link
    e.preventDefault();

    if (isDesktop) {
      dataLayerPush(`${recommendation} Show clicked`);
      history.push(showUrl);
    } else {
      setShowDetailsModal(true);
      dataLayerPush('Mobile show card modal opened');
    }
  }

  const description = (
    <ShowTeaser $size={size} component='div'>
      <ClampLines
        text={
          teaser ||
          (size === SHOW_CARD_SIZES.featured && noTagDescription
            ? noTagDescription
            : noTagDescription?.slice(0, 100)) ||
          ''
        }
        lines={size === 'sm' ? (isLarge ? 4 : 2) : size === 'md' ? 6 : 8}
        ellipsis='...'
        buttons={false}
      />
    </ShowTeaser>
  );

  return (
    <StyledCard $size={size} className={`${className} component-test-id`}>
      { shouldLazyLoad ? (
        <LazyLoadWrapper
          debounce={true}
          height='100%'
          offset={100}
          placeholder={<LoadingPlaceholder />}
        >
          <ImageWrapper
            $size={size}
            $backgroundImage={imageUrl || placeholderImg}
          />
        </LazyLoadWrapper>
        ) : (
          <ImageWrapper
            $size={size}
            $backgroundImage={imageUrl || placeholderImg}
          />
        )}
      <FollowShowContainer>
        <FollowShowToggle
          show={show}
          popperProps={{ placement: 'bottom-end' }}
        />
      </FollowShowContainer>
      <ShowCardWrapper
        href={showUrl}
        onKeyDown={(e) => e.key === 'Enter' && handleSelectShow()}
        onClick={handleSelectShow}
        tabIndex={0}
      >
        <Overlay $size={size} showOverlayColor={showOverlayColor} />
        <CardOverlay $size={size} showOverlayColor={showOverlayColor}>
          <ShowTitle $size={size}>{title}</ShowTitle>
          {isDesktop && hasDescription && <>{description}</>}
        </CardOverlay>
      </ShowCardWrapper>

      {/* Show Details Dialog with Menu - mobile only */}
      <MobileModal
        history={history}
        shortDescription={shortDescription}
        showDetailsModal={showDetailsModal}
        setShowDetailsModal={setShowDetailsModal}
        show={show}
        showUrl={showUrl}
        teaser={teaser}
        title={title}
        user={user}
      />
    </StyledCard>
  );
}

export default React.memo(ShowCard);
