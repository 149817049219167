import React from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import OverlayHover from './components/OverlayHover';
import talentShape from 'shapes/talentShape';
import {
  ImageContainer,
  ImageOverlay,
  ImageOverlayHover,
  Overlay,
  Wrapper,
} from './components/SharedStyles';

const LeadershipWrapper = styled(Wrapper)`
  position: relative;
  padding-top: 111%;
  border: 0;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  border-radius: 1rem;

  ${(props) => props.theme.breakpoints.up('xl')} {
    padding-top: 110.55%;
  }
`;

const LeadershipImageContainer = styled(ImageContainer)`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 1rem;
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);

  img {
    object-fit: cover;
  }
`;

const LeadershipImageOverlayHover = styled(OverlayHover)`
  ${ImageOverlayHover} {
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

const Link = styled(RouterLink)`
  position: static;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
  }
`;

const Name = styled(Typography)`
  font-weight: 400;

  ${ifProp(
    { $cardsInRow: 'four plus' },
    css`
      ${(props) => props.theme.breakpoints.up('lg')} {
        font-size: 2.25rem;
      }
    `
  )}
`;

const Title = styled(Typography)`
  font-size: 1.125rem;
`;

LeadershipCard.propTypes = {
  cardCount: PropTypes.number,
  leadership: talentShape.isRequired,
};

function LeadershipCard({ cardCount, leadership }) {
  const { bio, title, url, socialGroup, talentFeatureImage, talentTitle } =
    leadership;
  const cardsInRow = cardCount >= 4 ? 'four plus' : 'three under';

  if (!title ?? !url) {
    return null;
  }

  const mobileImage =
    talentFeatureImage.url +
    '?format=pjpg&quality=50&width=600&disable=upscale';
  const desktopImage =
    talentFeatureImage.url +
    '?format=pjpg&quality=50&width=860&disable=upscale';

  const imgSrcSet = `${mobileImage} 600w, ${desktopImage} 1400w`;

  return (
    <LeadershipWrapper>
      <LeadershipImageContainer $card='leadership'>
        {talentFeatureImage && (
          <img
            alt={title}
            srcset={imgSrcSet}
            src={!imgSrcSet ? desktopImage : ''}
            sizes='(max-width: 600px) 88vw, (max-width: 1400px) 45vw'
          />
        )}
        <Overlay />
        <ImageOverlay $card='leadership' $cardsInRow={cardsInRow}>
          <Name $cardsInRow={cardsInRow} variant='h2'>
            {title}
          </Name>
          <Title>{talentTitle}</Title>
        </ImageOverlay>
        <LeadershipImageOverlayHover
          bio={bio}
          card='leadership'
          cardsInRow={cardsInRow}
          socialGroup={socialGroup}
          title={title}
          talentTitle={talentTitle}
        />
        <Link to={url} />
      </LeadershipImageContainer>
    </LeadershipWrapper>
  );
}

export default LeadershipCard;
