import React, { useReducer, useContext, useState, useEffect } from 'react';

function getDefaultState(willShowTour) {
  const DEFAULT_STATE = {
    activeTour: null,
    currentStep: null,
    newStep: null,
    startAt: 0,
    customLink: null,
    willShowTour: null,
  };

  let storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

const ACTION_ENABLE_TOUR = 'enableTour';
const ACTION_DISABLE_TOUR = 'disableTour';
const ACTION_GO_TO_STEP = 'goToStep';
const ACTION_SET_START_AT = 'setStartAt';
const ACTION_SET_CUSTOM_LINK = 'setCustomLink';
const ACTION_SET_WILL_SHOW_TOUR = 'setWillShowTour';
const ACTION_UPDATE_CURRENT_STEP = 'updateCurrentStep';

function tourReducer(state, action) {
  switch (action.type) {
    case ACTION_ENABLE_TOUR:
      if (state.activeTour) {
        return state;
      }

      return {
        ...state,
        activeTour: action.payload,
      };
    case ACTION_DISABLE_TOUR:
      return {
        ...state,
        activeTour: null,
        currentStep: null,
        newStep: null,
        startAt: 0,
        customLink: null,
      };
    case ACTION_UPDATE_CURRENT_STEP:
      if (state.currentStep === action.payload) {
        return state;
      }

      return {
        ...state,
        currentStep: action.payload,
        newStep: null,
      };
    case ACTION_GO_TO_STEP:
      return {
        ...state,
        newStep: action.payload,
      }
    case ACTION_SET_START_AT:
      return {
        ...state,
        startAt: action.payload,
      }
    case ACTION_SET_CUSTOM_LINK:
      if (state.customLink) {
        return state;
      }

      return {
        ...state,
        customLink: action.payload,
      }
    case ACTION_SET_WILL_SHOW_TOUR:
      return {
        ...state,
        willShowTour: action.payload,
      }
    default:
      return state;
  }
}

const TourContext = React.createContext({
  state: getDefaultState(),
  dispatch: () => {},
  enableTour: (tourId) => {},
  disableTour: () => {},
  goToStep: (newStep) => {},
  setWillShowTour: (willShowTour) => {},
  setStartAt: (startAt) => {},
  setCustomLink: (customLink) => {},
  updateCurrentStep: (step) => {},
});

export const TourProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    tourReducer,
    getDefaultState()
  );

  const [contextValue, setContextValue] = useState({
    state,
    dispatch,
    enableTour: (tourId) => dispatch({ type: ACTION_ENABLE_TOUR, payload: tourId }),
    disableTour: () => dispatch({ type: ACTION_DISABLE_TOUR }),
    goToStep: (newStep) => dispatch({ type: ACTION_GO_TO_STEP, payload: newStep }),
    setStartAt: (startAt) => dispatch({ type: ACTION_SET_START_AT, payload: startAt }),
    setCustomLink: (customLink) => dispatch({ type: ACTION_SET_CUSTOM_LINK, payload: customLink }),
    updateCurrentStep: (step) => dispatch({ type: ACTION_UPDATE_CURRENT_STEP, payload: step }),
    setWillShowTour: (willShowTour) => dispatch({ type: ACTION_SET_WILL_SHOW_TOUR, payload: willShowTour }),
  });

  // Update context value and trigger re-render
  // This pattern avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue) => ({
      ...contextValue,
      state
    }));
  }, [state]);

  return (
    <TourContext.Provider value={contextValue}>
      {children}
    </TourContext.Provider>
  );
};

export const useTourContext = () => useContext(TourContext);
