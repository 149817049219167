import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

VideoPlayerPortal.propTypes = {
  mountId: PropTypes.string.isRequired,
  float: PropTypes.bool,
  children: PropTypes.node,
};

function VideoPlayerPortal({ mountId, float = false, children }) {
  const mountElement = useRef(null);
  const element = useRef(document.createElement('div'));
  const isFloating = useRef(false);

  useEffect(() => {
    mountElement.current = document.getElementById(mountId);
    if (mountElement.current) {
      mountElement.current.appendChild(element.current);
    }

    return () => {
      if (isFloating.current) {
        const pipWrapper = document.getElementById('floating-pip-wrapper');
        pipWrapper?.removeChild(element.current);
      } else {
        mountElement.current?.removeChild(element.current);
      }
    }
  }, [mountId]);

  // Handle moving video to PIP player.
  useEffect(() => {
    mountElement.current = document.getElementById(mountId);
    const pipWrapper = document.getElementById('floating-pip-wrapper');

    if (float) {
      if (mountElement.current) {
        mountElement.current.removeChild(element.current);
      }

      pipWrapper.appendChild(element.current);
      isFloating.current = true;
    } else if (isFloating.current) {
      pipWrapper.removeChild(element.current);

      if (mountElement.current) {
        mountElement.current.appendChild(element.current);
      }

      isFloating.current = false;
    }
  }, [float]);

  return ReactDOM.createPortal(children, element.current)
}

export default VideoPlayerPortal;
