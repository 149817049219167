import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from 'styled-tools';
import Icon from 'components/Icon';

const pulseAnimation = keyframes`
  0% {
    stroke-opacity: 1;
    transform: scale(1);
    opacity: 100%;
  }
  55% {
    stroke-opacity: .5;
    transform: scale(2);
    opacity: 50%;
  }
  60% {
    stroke-opacity: 0;
    transform: scale(2);
    opacity: 0%;
  }
  100% {
    stroke-opacity: 0;
    transform: scale(2);
    opacity: 0%;
    animation-delay: 8s; 
  }
`;

const OuterPulseAnimation = keyframes`
  0%, 100% {
    box-shadow: 0 0 0 0px red;
    opacity: 100%;
  }
  55% {
    box-shadow: 0 0 0 7px red;
    opacity: 40%; 
    animation-fill-mode: forwards; 
  }
  60% {
    box-shadow: 0 0 0 0px red; 
    opacity: 0%; 
    animation-fill-mode: forwards; 
  }
  100% {
    animation-delay: 8s; 
  }
`;

const IconContainer = styled.div`
  background-color: ${theme('colors.ttRed')};
  border-radius: 50%;
  height: 0.75rem;
  width: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerPulse = styled(Icon)`
  animation: ${pulseAnimation} 1.5s infinite;
`;

const OuterPulse = styled.div`
  position: absolute;
  width: 0.8rem;
  height: 0.75rem;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  background-color: red;
  animation: ${OuterPulseAnimation} 1.5s infinite; 
  animation-delay: 0.3s; 
`;

const LiveIndicatorAnimation = () => {
  return (
    <IconContainer>
      <InnerPulse icon="circleOutline" color="red"/> 
      <OuterPulse />
    </IconContainer>
  );
};

export default LiveIndicatorAnimation;
