import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { prop, theme } from 'styled-tools';
import styled  from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Playlists from './components/Playlists';
import Sidebar from './Sidebar';
import Main from './Main';
import { useDashboardContext } from 'context/DashboardContext';
import Hidden from '@material-ui/core/Hidden';
import BaseBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Icon from 'components/Icon';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import HistoryIcon from '@material-ui/icons/History';
import ReplayIcon from '@material-ui/icons/Replay';
import MyShows from 'routes/Dashboard/components/MyShows';
import MySlides from 'routes/Dashboard/components/MySlides';
import Episodes from 'routes/Dashboard/components/Episodes';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { dataLayerPush } from 'utils/dataLayer';
import DailyReplays from 'routes/Dashboard/components/DailyReplays';
import WelcomeMessage from './components/WelcomeMessage';
import HelpModal from './components/HelpModal';
import CloseIconWrapper from 'components/CloseButton';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiGrid-root:focus, {
    outline: 0; //nested element, solves chrome unwanted border issue but will not affect keyboard only use of the app
  }

  * {
    font-family: Roboto Condensed, sans-serif;
  }
`;

const ToolbarTop = styled(Toolbar)`
  background-color: ${prop('theme.colors.black')};
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
  padding: 0 1rem;
  color: ${theme('palette.common.white')};
  z-index: 1302; // Z-index higher than mobile filters (1301).

  ${props => props.theme.breakpoints.down('sm')} {
    cursor: pointer;
  }
`;

const DashboardTitle = styled.div`
  align-items: center;
  color: ${prop('theme.colors.white')};
  display: flex;
  text-transform: uppercase;

  .MuiSvgIcon-root {
    margin-right: 1.25rem;
  }

  .dashboard-header {
    font-size: 1rem;

    ${props => props.theme.breakpoints.up('sm')} {
      font-weight: 700;
    }
  }
`;

const DashboardCloseIconWrapper = styled(CloseIconWrapper)`
  top: .5rem;

  &:hover {
    background-color: ${theme('colors.mutedGray')};

    svg {
      fill: ${theme('colors.white')};
    }
  }
`;

const Close = styled(CloseIcon)`
  color: ${prop('theme.colors.white')};
  cursor: pointer;
`;

const GridContainer = styled(Grid)`
  background-color: ${prop('theme.colors.white')};
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
`;

const GridColumn = styled(Grid)`
  height: 100%;

  ${props => props.theme.breakpoints.down('xs')} {
    min-height: 18.75rem;
  }
`;

const BottomNavigation = styled(BaseBottomNavigation)`
  flex: 1 0 auto;
`;

const SlidesIcon = styled(Icon).attrs({ icon: 'slidesOutline' })`
  font-size: 1.25rem;
  margin: 0.125rem 0;
`;

const bottomNavItems = [
  {
    id: 'panelMyShows',
    label: 'Shows',
    gtmLabel: 'My Shows',
    icon: <YouTubeIcon />,
    component: MyShows
  },
  {
    id: 'panelMySlides',
    label: 'Slides',
    gtmLabel: 'My Slides',
    icon: <SlidesIcon />,
    component: MySlides
  },
  {
    id: 'panelPlaylists',
    label: 'Playlists',
    gtmLabel: 'Playlists',
    icon: <PlaylistPlayIcon />,
    component: Playlists
  },
  {
    id: 'panelEpisodes',
    label: 'History',
    gtmLabel: 'Watch History',
    icon: <HistoryIcon />,
    component: Episodes
  },
  {
    id: 'panelDailyReplays',
    label: 'Daily Replays',
    gtmLabel: 'Daily Replays',
    icon: <ReplayIcon />,
    component: DailyReplays,
  },
];

Dashboard.propTypes = {};

function Dashboard() {
  const { toggleDashboard, setSelectedPage, state } = useDashboardContext();
  const [activeBottomNav, setActiveBottomNav] = useState(() => bottomNavItems.findIndex(({ id }) => state.expanded === id) || 0);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [autoShowWelcomeMessage, setAutoShowWelcomeMessage] = useState(true)

  useEffect(() => {
    const newIndex = bottomNavItems.findIndex(({ id }) => state.expanded === id) || 0;

    if (newIndex !== activeBottomNav) {
      setActiveBottomNav(newIndex);
    }
  }, [state.expanded]);

  const handleChange = (panel, dashComponent) => (event, newExpanded) => {
    setSelectedPage(newExpanded ? panel : false, dashComponent);
    mapComponentEvent(panel);
  };

  const mapComponentEvent = (panel) => {
    const page = panel.toString();
    const component = bottomNavItems.filter(item => item.id === page);
    const dashPage = get(component[0], 'gtmLabel');
    dataLayerPush(`Viewed dashboard page '${dashPage}'`);
  }

  return (
    <DashboardContainer>
      {autoShowWelcomeMessage &&
        <WelcomeMessage onClose={() => setAutoShowWelcomeMessage(false)} />
      }
      {/* Passing in onClick only if mobile so that clicking anywhere on top bar will close dashboard in mobile. */}
      <ToolbarTop onClick={isMobileOrTablet ? toggleDashboard : undefined}>
        <DashboardTitle>
          <DashboardIcon />
          <Typography className='dashboard-header' variant='h3'>
            Dashboard
          </Typography>
          <HelpModal onClose={() => setAutoShowWelcomeMessage(false)} />
        </DashboardTitle>
        {isMobileOrTablet ? <KeyboardArrowDownIcon fontSize='large' /> : <DashboardCloseIconWrapper><Close onClick={toggleDashboard} /></DashboardCloseIconWrapper>}
      </ToolbarTop>

      <GridContainer container>
        {/* Sidebar - hidden in mobile */}
        <Hidden xsDown>
          <GridColumn item xs={12} sm={3} lg={2}>
            <Sidebar
              edit={state.isEditing}
              expanded={state.expanded}
              activeComponent={state.component}
              handleChange={handleChange}
            />
          </GridColumn>
        </Hidden>

        {/* Main content */}
        <GridColumn item xs={12} sm={9} lg={10}>
          <Main dashComponent={state.component} />
        </GridColumn>
      </GridContainer>

      {/* Bottom navigation - only in mobile */}
      <Hidden smUp>
        <BottomNavigation
          value={activeBottomNav}
          onChange={(event, newValue) => {
            const item = bottomNavItems[newValue] || {};
            setActiveBottomNav(newValue);
            handleChange(item.id, item.component)(event, true);
          }}
          showLabels
        >
          {bottomNavItems.map(({ id, label, icon }) => (
            <BottomNavigationAction key={id} label={label} icon={icon} />
          ))}
        </BottomNavigation>
      </Hidden>
    </DashboardContainer>
  );
}

export default React.memo(Dashboard);
