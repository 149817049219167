import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifNotProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import DateFormatter from 'components/DateFormatter';
import researchSlideShape from 'shapes/researchSlideShape';
import episodeShape from 'shapes/episodeShape';
import ActionSection from './ActionSection';
import Icon from 'components/Icon';
import hasSavedSlideDeck from '../../../../graphql/queries/hasSavedSlideDeck.graphql';

const TabletTitle = styled.div`
  display: none;

  > div {
    ${props => props.theme.breakpoints.down('sm')} {
      width: 100%;

      ${ifNotProp({$page: 'episode'}, css`
        display: none;
      `)}
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;

    button {
      color: ${theme('colors.black')};

      &:hover {
        color: ${theme('colors.ttRed')};
      }
    }
  }
`;

const Title = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
  text-transform: uppercase;
`;

const MobileDetails = styled.div`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8rem;
  padding: 1rem 1rem 1.5rem 1rem;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const ShowTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

const EpisodeTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
`;

SlideshowFooter.propTypes = {
  episode: episodeShape,
  fullDeck: researchSlideShape,
  isDesktop: PropTypes.bool,
  onArrowClick: PropTypes.func,
  onClose: PropTypes.func,
  page: PropTypes.string,
  slideDeckPdf: PropTypes.object,
  slideDeckTitle: PropTypes.string,
  onDelete: PropTypes.func,
};

function SlideshowFooter({
  episode,
  fullDeck,
  isDesktop,
  onArrowClick,
  onClose,
  page,
  slideDecks,
  slideDeckPdf,
  slideDeckTitle,
  onDelete
}) {
  const episodeTitle = get(episode, 'displayTitle');
  const showTitle = get(episode, 'show.title');
  const date = get(episode, 'publishedAt');

  const { data: checkForSavedDeck, refetch: recheckForSavedDeck } = useQuery(hasSavedSlideDeck, {
    skip: typeof window === 'undefined',
    variables: {
      slideDecksUid: slideDecks[0].uid,
    },
    fetchPolicy: 'no-cache',
  });
  const searchHasSaved = get(checkForSavedDeck, 'hasSavedSlideDeck');

  return (
    <>
      <TabletTitle $page={page}>
        <Box display='flex' alignItems='center' $page={page}>
          <Icon icon='chevronLeft' />
          <Title>View Slides</Title>
        </Box>
      </TabletTitle>
      <MobileDetails>
        {showTitle && <ShowTitle>{showTitle}</ShowTitle>}
        {episodeTitle && <EpisodeTitle>{episodeTitle}</EpisodeTitle>}
        {date && <DateFormatter date={date} format='MMM DD, YYYY' />}
      </MobileDetails>
      <ActionSection
        episode={episode}
        fullDeck={fullDeck}
        isDesktop={isDesktop}
        onArrowClick={onArrowClick}
        page={page}
        searchHasSaved={searchHasSaved}
        recheckForSavedDeck={recheckForSavedDeck}
        slideDeckPdf={slideDeckPdf}
        slideDeckTitle={slideDeckTitle}
        onDelete={onDelete}
      />
    </>
  );
}

export default SlideshowFooter;
