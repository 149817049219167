import React, { useEffect, useState } from 'react'
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useMutation, useLazyQuery } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import CheckboxesGroup from 'components/CheckboxGroup';
import Modal from 'components/Modal';
import ModalBody from './components/ModalBody';
import ModalHeader from './components/ModalHeader';
import episodeShape from 'shapes/episodeShape';
import myPlaylistsQuery from '../../graphql/queries/myPlaylists.graphql';
import episodePlaylistsQuery from './episodePlaylists.graphql';
import addEpisodeToPlaylistsMutation from '../../graphql/mutations/addEpisodeToPlaylists.graphql';
import removeEpisodeFromPlaylistsMutation from '../../graphql/mutations/removeEpisodeFromPlaylists.graphql';
import createPlaylistMutation from './createPlaylist.graphql';
import { dataLayerPush } from 'utils/dataLayer';
import { useDashboardContext } from 'context/DashboardContext';
import updateHasSavedEpisodesToPlaylistsMutation from '../../graphql/mutations/updateHasSavedEpisodesToPlaylists.graphql';
import TTSnackbarMessage from 'components/TTSnackbar/Message';
import { ttSnackbarOptions } from '../TTSnackbar';

const StyledModal = styled(Modal)`
  span {
    font-stretch: condensed;
    font-style: normal;
  }
  
  z-index: 1301 !important;
`;

const StyledCheckboxes = styled.div`
  padding-left: .3rem;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;

  .MuiSvgIcon-root {
    font-size: 1.4rem;
    color: ${theme('colors.black')};
  }
  .Mui-checked {
    .MuiSvgIcon-root {
      fill: ${theme('colors.ttRed')};
    }
  }

  .MuiTypography-body1 {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 1rem;
  }
`;

const PaddedDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

SaveToPlaylistModal.propTypes = {
  episode: episodeShape.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  page: PropTypes.string,
};

function SaveToPlaylistModal({ episode, open, page, onClose }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [createPlaylistFormOpen, setCreatePlaylistFormOpen] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [playlistFormErrors, setPlaylistFormErrors] = useState(false);
  const [snackId, setSnackId] = useState();
  const { state: dashboardState, updateDashboardLogs, setFirstTimeHelper, setSubsequentHelper, setHelperContentType, refetchPlaylists: refetchContextPlaylists } = useDashboardContext();
  const { showFirstTimeHelper } = dashboardState;
  const { hasSavedEpisodesToPlaylists } = dashboardState?.logs;
  const [updateHasSavedEpisodesToPlaylists] = useMutation(updateHasSavedEpisodesToPlaylistsMutation);

  const [loadMyPlaylists, { data: myPlaylistsData, refetch: refetchPlaylists }] = useLazyQuery(myPlaylistsQuery, { fetchPolicy: 'network-only' });
  const [loadEpisodePlaylists, { data: episodePlaylistsData, refetch: refetchEpisodePlaylists }] = useLazyQuery(episodePlaylistsQuery, {
    variables: {
      episodeId: episode.uid,
    },
    fetchPolicy: 'network-only'
  });

  const [addEpisodeToPlaylists] = useMutation(addEpisodeToPlaylistsMutation);
  const [removeEpisodeFromPlaylists] = useMutation(removeEpisodeFromPlaylistsMutation);
  const [createPlaylist] = useMutation(createPlaylistMutation);

  const playlists = get(myPlaylistsData, 'myPlaylists.items', []);
  const episodePlaylistIds = get(episodePlaylistsData, 'episodePlaylists.items', []).map(v => v.id);

  const watchLaterPlaylist = playlists.find(playlist => playlist.isDefault);
  const customPlaylists = playlists.filter(playlist => !playlist.isDefault);

  const watchLaterPlaylistId = watchLaterPlaylist ? watchLaterPlaylist.id : 'watchLater';

  const playlistValues = [
    {
      name: watchLaterPlaylistId,
      label: 'Save to Watch Later',
      checked: watchLaterPlaylist ? episodePlaylistIds.includes(watchLaterPlaylist.id) : false,
    },
    ...customPlaylists.map(playlist => ({
      name: playlist.id,
      label: `Save to ${playlist.name}`,
      checked: episodePlaylistIds.includes(playlist.id),
    }))
  ];

  // only loads data when modal is opened
  useEffect(() => {
    if (open) {
      loadMyPlaylists();
      loadEpisodePlaylists();
    }
  }, [open])

  useEffect(() => {
    if (showFirstTimeHelper) {
      closeSnackbar(snackId);
    }
  }, [showFirstTimeHelper])

  const handleChange = (values, playlistId, checked) => {
    const inputData = {
      variables: {
        input: {
          episodeId: episode.uid,
          playlistIds: [playlistId],
        },
      }
    };

    // Get playlist details.
    const playlistName = playlistId === watchLaterPlaylistId
      ? 'Watch Later'
      : customPlaylists.find(v => v.id === playlistId)?.name;

    if (checked) {
      addEpisodeToPlaylists(inputData).then(() => {
        handleLogs(playlistName);
        return refetchEpisodePlaylists();
      });
      dataLayerPush('Episode saved to a playlist');
    } else {
      removeEpisodeFromPlaylists(inputData).then(() => {
        return refetchEpisodePlaylists();
      });
      dataLayerPush('Episode removed from a playlist');
    }
  };

  const handleCreatePlaylistSubmit = (event) => {
    event.preventDefault();

    if (!newPlaylistName.trim()) {
      setPlaylistFormErrors(true);
      return;
    }

    createPlaylist({ variables: { input: { name: newPlaylistName.trim() } } })
      .then(({ data }) => {
        refetchContextPlaylists()
        setFirstTimeHelper(false);
        const snackId = enqueueSnackbar(<TTSnackbarMessage message={`New playlist created: ${data.createPlaylist?.name || newPlaylistName}`} />, ttSnackbarOptions);
        setSnackId(snackId)
        setNewPlaylistName('');
        setPlaylistFormErrors(false);
        setCreatePlaylistFormOpen(false);
        return refetchPlaylists();
      });
      dataLayerPush('Playlist created');
  }

  const updatePlaylistName = e => {
    return e.length <= 33 && setNewPlaylistName(e);
  }

  const handleLogs = async(playlistName) => {
    if (!hasSavedEpisodesToPlaylists) {
      await updateHasSavedEpisodesToPlaylists({ variables: { hasSavedEpisodesToPlaylists: true }});
      setFirstTimeHelper(true);
      updateDashboardLogs();
      setHelperContentType('episode');
    } else {
      setSubsequentHelper(true);
      enqueueSnackbar(<TTSnackbarMessage message={`Saved to playlist: ${playlistName}`} />, ttSnackbarOptions);
    }
  }

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      height={313}
      width={393}
      padding='thin'
      ariaLabel='Save Episode to Playlist'
      ariaDescribe='Save the selected episode to playlists that you can see in your user dashboard.'
    >
      <ModalHeader page={page} />
      <StyledCheckboxes>
        <CheckboxesGroup values={playlistValues} onChange={handleChange} />
      </StyledCheckboxes>
      <PaddedDivider />
      <ModalBody
        createPlaylistFormOpen={createPlaylistFormOpen}
        handleCreatePlaylistSubmit={handleCreatePlaylistSubmit}
        newPlaylistName={newPlaylistName}
        playlistFormErrors={playlistFormErrors}
        setNewPlaylistName={updatePlaylistName}
        setCreatePlaylistFormOpen={setCreatePlaylistFormOpen}
      />
    </StyledModal>
  );
}

export default SaveToPlaylistModal;