/**
 *
 * I found that I was replacing spaces on strings being used to set data-test-ids for dynamically created content. I created
 * this helper so I could easily call when needed.
 *
 */
export const formatStringForTestId = (toFormat) => {
  if (toFormat) {
    return toFormat?.replace(/ /g, '-');
  }

  return;
}
