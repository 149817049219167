import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import MuiGridList from '@material-ui/core/GridList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EpisodeDisplayOptions from 'constants/EpisodeDisplayOptions';
import { conceptsSwiperBreakpoints } from 'utils/constants';
import episodeShape from 'shapes/episodeShape';
import EpisodeCard from 'components/EpisodeCard';
import Swiper from 'components/Swiper';

const FeedWrapper = styled.div`
  ${props => props.theme.breakpoints.down('sm')} {
    overflow: hidden;
  }
`;

const GridListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const GridList = styled(MuiGridList)`
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;

  > div {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
`;

EpisodeFeed.propTypes = {
  episodes: PropTypes.arrayOf(episodeShape),
  feedName: PropTypes.string,
  loading: PropTypes.bool,
  page: PropTypes.string,
  rollDown: PropTypes.bool
};

function EpisodeFeed({ episodes, loading = false, feedName, page, rollDown, ...props }) {
  const [episodeIsHovered, setEpisodeIsHovered] = useState(false);
  const conceptsPage = page?.includes('concepts-strategies');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const willExpand = rollDown ? 'false' : isDesktop;

  return (
    <FeedWrapper {...props}>
      {/* Tablet/Desktop episode feed */}
      <Hidden smDown>
        <Swiper
          className='swiper-no-swiping'
          customBreakpoints={conceptsPage ? conceptsSwiperBreakpoints : null}
          feedCarousel={true}
          feedName={feedName}
          expand={willExpand}
          episodeIsHovered={episodeIsHovered}
        >
          {(loading ? Array.from(new Array(5)) : episodes).map((episode, index) => (
            <SwiperSlide key={episode?.uid ?? index} >
              <div
                onMouseEnter={() => setEpisodeIsHovered(true)}
                onMouseLeave={() => setEpisodeIsHovered(false)}
              >
                <EpisodeCard
                  autoWidth={true}
                  display={EpisodeDisplayOptions.vertical}
                  episode={episode}
                  loading={loading}
                  expand={willExpand}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Hidden>

      {/* Mobile-only episode feed */}
      <Hidden mdUp>
        <GridListWrapper>
          <GridList
            cols={1.5}
            cellHeight={116}
            spacing={13}
            component='div'
          >
            {(loading ? Array.from(new Array(5)) : episodes).map((episode, index) => (
              <EpisodeCard
                display={EpisodeDisplayOptions.vertical}
                episode={episode}
                episodeBlurb={false}
                loading={loading}
                homepageSlider={page === 'homepage'}
                key={episode?.uid ?? index}
              />
            ))}
          </GridList>
        </GridListWrapper>
      </Hidden>
    </FeedWrapper>
  );
}

export default EpisodeFeed;
