import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import getSiteSettingsQuery from './getSiteSettings.graphql';
import get from 'lodash.get';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SiteSettingsContext = React.createContext({});

export const SiteSettingsProvider = ({ children }) => {
  const { data, loading, error } = useQuery(getSiteSettingsQuery, {
    skip: typeof window === 'undefined',
  });

  const { siteAlerts } = data?.siteSettings || {};
  const showAlert = get(siteAlerts, 'isActive');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [displayAlert, setDisplayAlert] = useState(!!showAlert);
  const showLowLevelAlert = siteAlerts && siteAlerts.alertLevel === 'lowlevel';

  useEffect(() => {
    if (!!showLowLevelAlert && isMobile) {
      setDisplayAlert(false);
    } else {
      setDisplayAlert(showAlert)
    }
  }, [isMobile, showLowLevelAlert]);

  return (
    <SiteSettingsContext.Provider value={{
      siteSettings: data && data.siteSettings,
      displayAlert,
      setDisplayAlert,
      loading,
      error,
    }}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export const useSiteSettings = () => useContext(SiteSettingsContext);
