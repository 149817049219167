import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import BaseDrawer from '@material-ui/core/Drawer';
import VisibilityWrapper from 'components/VisibilityWrapper';
import PageLink from 'components/PageLink';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BaseListSubheader from '@material-ui/core/ListSubheader';
import SpecialBehaviorLink from 'components/Header/components/SpecialBehaviorLink';
import menuItemShape from 'shapes/menuItemShape';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { dataLayerPush } from 'utils/dataLayer';
import LeavingModal from 'components/LeavingModal';

const Drawer = styled(BaseDrawer)`
  font-family: ${theme('typography.fontFamilySecondary')};
  /* !important needed here to override MUI inline styles */
  inset: 60px 0 0 !important;

  .MuiBackdrop-root {
    top: 60px;
  }

  .MuiDrawer-paper {
    left: 0;
    background-color: ${theme('palette.common.black')};
    color: ${theme('palette.common.white')};
    top: 60px;
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }

  ${ifProp('$displayAlert', css`
    /* !important needed here to override MUI inline styles */
    inset: 116px 0 0 !important;

    .MuiBackdrop-root {
      top: 116px;
    }

    .MuiDrawer-paper {
      top: 116px;
    }
  `)}
`;

const ListSubheader = styled(BaseListSubheader)`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #babcbe;
  background-color: ${theme('palette.common.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: .8125rem;
  font-weight: bold;
  line-height: 1.125;
  margin: 1.75rem 0 0.875rem;
  padding-left: 1.875rem;
`;

const TopLevelListItem = styled(ListItem)`
  font-size: 1rem;
  text-transform: uppercase;
  padding: .875rem .875rem .875rem 1.875rem;

  ${ifProp('$hasChildren', css`
    color: #babcbe;
    font-size: .8125rem;
    margin-top: 1rem;
    padding-top: .4625rem;
    padding-bottom: .4625rem;
    border-top: 1px solid ${theme('colors.mutedGray')};
    border-bottom: 1px solid ${theme('colors.mutedGray')};
  `)}
`;

const BrokerButton = styled(Button)`
  width: 100%;
  border-radius: 0;
  position: fixed;
  bottom: 0;
`;

function ListItemLink(props) {
  return <TopLevelListItem button component="a" {...props} />;
}

SuperNavMobile.propTypes = {
  displayAlert: PropTypes.bool,
  menuItems: PropTypes.arrayOf(menuItemShape),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function SuperNavMobile({ displayAlert, menuItems, open, onClose }) {
  const container = typeof window !== 'undefined' ? () => window.document.body : undefined;
  const history = useHistory();
  const [openNavModal, setOpenNavModal] = useState(false);

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Open a tastytrade account'`);
    setOpenNavModal(true);
  };

  const handleClose = () => {
    setOpenNavModal(false);
  };


  const onLinkClick = () => {
    // When a link is clicked, close the drawer.
    onClose();
  }

  // Order the menu items for mobile. For now, we reverse the order, but in the future this may be
  // something we'd want to allow setting in CS so that mobile can differ from desktop.
  const orderedMenuItems = (menuItems || []).slice(0);
  orderedMenuItems.reverse();

  return (
    <Drawer
      container={container}
      $displayAlert={displayAlert}
      variant='temporary'
      anchor='right'
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List
        component='nav'
        subheader={
          <ListSubheader component='div'>
            <span>Profile</span>
          </ListSubheader>
        }
      >
        {orderedMenuItems.map((menuItem, i) => {
          const { link, linkText, submenu, visibility, specialBehavior } = menuItem || {};
          const hasChildren = submenu && submenu.length > 0;

          return (
            <VisibilityWrapper visibility={visibility} key={i}>
              {hasChildren ? (
                <TopLevelListItem button $hasChildren={hasChildren}>
                  <ListItemText primary={linkText} disableTypography />
                </TopLevelListItem>
              ) : (
                specialBehavior ? (
                  <SpecialBehaviorLink
                    menuItem={menuItem}
                    component={ListItemLink}
                    onClick={onLinkClick}
                    mobile
                  />
                ) : (
                  <PageLink
                    component={TopLevelListItem}
                    componentProps={{ button: true }}
                    link={link}
                    onClick={onLinkClick}
                    absoluteComponent='a'
                  >
                    <ListItemText primary={linkText} disableTypography />
                  </PageLink>
                )
              )}
              {submenu && submenu.length > 0 && submenu.map((subItem, subIndex) => (
                <PageLink
                  component={TopLevelListItem}
                  componentProps={{ button: true }}
                  link={subItem.link}
                  onClick={onLinkClick}
                  key={subIndex}
                  absoluteComponent='a'
                >
                  <ListItemText primary={subItem.linkText} disableTypography />
                </PageLink>
              ))}
            </VisibilityWrapper>
          );
        })}
        <li>
          <BrokerButton
            onClick={handleOpen}
            variant='contained'
            color='primary'
          >
            Open Tastytrade Account
          </BrokerButton>
          <LeavingModal
            onClose={handleClose}
            open={openNavModal}
            padding='narrow'
            url='https://open.tastytrade.com/signup?utm_medium=internal_link&utm_source=100'
            testId='openTastyTradeAccountModal'
          />
        </li>
      </List>
    </Drawer>
  );
}

export default React.memo(SuperNavMobile);
