import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CtaButton from 'components/CtaButton';
import RichTextField from 'components/RichTextField';
import brandListShape from 'shapes/components/brandListShape';
import { flattenBlockStyle } from 'utils/blockStyleUtils';
import { autoOptimizeImage } from 'utils/functions';

const BrandListContainer = styled(Grid)`
  padding: 3rem 2rem;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 3rem 4.75rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: 3rem 4rem;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding: 3rem 8.375rem;
  }

  ${ifProp('$backgroundColor', css`
    background-color: ${prop('$backgroundColor')};
  `)}

  ${ifProp('$marginTop', css`
    margin-top: ${prop('$marginTop')}rem;
  `)}

  ${ifProp('$paddingTop', css`
    padding-top: ${prop('$paddingTop')}rem;
  `)}

  ${ifProp('$marginBottom', css`
    margin-bottom: ${prop('$marginBottom')}rem;
  `)}

  ${ifProp('$paddingBottom', css`
    padding-bottom: ${prop('$paddingBottom')}rem;
  `)}

  ${ifProp('$selectTextColor', css`
    color: ${prop('$selectTextColor')};
  `)}
`;

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
  padding-top: 2rem;

  ${props => props.theme.breakpoints.down('xs')} {
    text-align: center;
  }

  * {
    font-size: 0.875rem;
  }

  &:not(:last-child){
    ${props => props.theme.breakpoints.down('xs')} {

      ${ifProp('$selectTextColor', css`
        border-bottom: solid 1px ${prop('$selectTextColor')};
      `)}
    }
  }
`;

const BrandImage = styled.div`
  min-height: 5rem;
  background-image: url("${prop('$img')}");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;

  ${props => props.theme.breakpoints.down('xs')} {
    background-image: url("${prop('$mobileImg')}");
    background-position: center;
  }
`;

const CtaContainer = styled.div`
  &:not(:first-child){
    padding-top: 1rem;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 0;
  }
`;

const CtaButtonContainer = styled.div`
  &:not(:first-child) {
    padding-top: 1rem;
  }
`;

BrandList.propTypes = {
  data: brandListShape.isRequired,
  className: PropTypes.string,
};

function BrandList({ data, className }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { additionalStyles } = data;
  const { blockStyle } = additionalStyles || {};
  const {
    backgroundColor,
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom,
    selectTextColor
  } = flattenBlockStyle(blockStyle);

  return (
    <BrandListContainer
      className={className}
      maxWidth='xl'
      container
      $backgroundColor={backgroundColor}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $selectTextColor={selectTextColor}
    >
      <Grid container spacing={isMobile ? 4 : 10}>
        {data.brandListing.map((listing, index) => (
          <GridItem
            item
            key={`brand-list-${index}`}
            xs={12}
            sm={6}
            md={4}
            lg={true}
            $selectTextColor={selectTextColor}
          >
            <Box>
              <BrandImage
                $img={autoOptimizeImage(get(listing, 'brandLogo.url'))}
                $mobileImg={autoOptimizeImage(
                  get(listing, 'mobileBrandLogo.url')
                )}
              />
              <RichTextField richText={listing.contentBody} />
            </Box>
            <CtaContainer>
              {listing.buttonCta.map((ctaItem, index) => (
                <CtaButtonContainer key={`tem-${index}`}>
                  <CtaButton
                    data={ctaItem}
                    color={selectTextColor}
                    iconColor={selectTextColor}
                    icon={ctaItem.buttonType === 'link' ? 'explore' : ''}
                  />
                </CtaButtonContainer>
              ))}
            </CtaContainer>
          </GridItem>
        ))}
      </Grid>
    </BrandListContainer>
  );
}

export default React.memo(BrandList);
