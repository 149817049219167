import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import BaseContainer from '@material-ui/core/Container';
import RichTextField from 'components/RichTextField';
import BaseStackHeader from 'components/StackHeader';
import TestimonialCard from './TestimonialCard';
import testimonialsBlockShape from 'shapes/components/testimonialsBlockShape';
import GridListTile from '@material-ui/core/GridListTile';
import Hidden from '@material-ui/core/Hidden';
import GridListSlider, { GridList } from 'components/GridListSlider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Container = styled(BaseContainer)`
  min-height: 32rem;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 25.25rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-height: 28.5rem;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1380px;
    padding-left: 7.75rem;
    padding-right: 7.75rem;
    margin-bottom: 4rem;
    min-height: 28.5rem;
  }

  .swiper-slide {
    height: auto;
  }

  ${GridList} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StackHeader = styled(BaseStackHeader)`
  margin-bottom: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

TestimonialsBlock.propTypes = {
  data: testimonialsBlockShape.isRequired,
  className: PropTypes.string,
};

function TestimonialsBlock({ data, className }) {
  const {
    selectTestimonials,
    testimonialDescription,
    testimonialHeader,
    testimonialStyles
  } = data


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container className={className} maxWidth='xl'>
      {testimonialHeader &&
        <StackHeader defaultHeaderType='H2'>{testimonialHeader}</StackHeader>
      }
      {testimonialDescription &&
        <RichTextField richText={testimonialDescription} />
      }

      {/* Tablet/Desktop testimonial feed */}
      <Hidden smDown>
        <Grid container spacing={2}>
          {selectTestimonials && selectTestimonials.map((testimonial) => {
            const selectTestimonial = get(testimonial, 'selectTestimonial');
            const size = 12 / selectTestimonials.length;

            return (
              <Grid item md={size} key={selectTestimonial.uid}>
                <TestimonialCard
                  testimonial={selectTestimonial}
                  testimonialStyles={testimonialStyles}
                />
              </Grid>
            )
          })}
        </Grid>
      </Hidden>

      {/* mobile-only testimonial feed */}
      <Hidden mdUp >
        <GridListSlider tileWidth={isMobile ? '95%' : '75%'} >
          {selectTestimonials && selectTestimonials.map((testimonial, i) => {
            const selectTestimonial = get(testimonial, 'selectTestimonial');
            return (
              <GridListTile key={i} component='div'>
                <TestimonialCard
                  testimonial={selectTestimonial}
                  testimonialStyles={testimonialStyles}
                />
              </GridListTile>
            );
         })}
        </GridListSlider>
      </Hidden>
    </Container>
  );
}

export default TestimonialsBlock;
