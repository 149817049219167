import React, { createContext, useContext, useEffect, useState } from 'react';

const KeyConceptsContext = createContext(undefined);

const defaultState = {
  activeTerm: null,
  activeContentItem: null,
  mobileMenuOpen: false,
  searchResults: [],
  searchTerm: '',
  expanded: {},
}

export const KeyConceptsProvider = ({ children, activeTerm, activeContentItem }) => {
  const stateKey = 'tastylive-key-concept-state';

  // default state
  if (activeTerm) {
    defaultState.activeTerm = activeTerm;
  }

  if (activeContentItem) {
    defaultState.activeContentItem = activeContentItem;
  }

  function parseSessionStorage() {
    const ret = typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem(stateKey));
    if (ret) {
      ret.activeTerm = activeTerm || null;
      ret.activeContentItem = activeContentItem || null;
    }
    return ret;
  }

  const [state, setState] = useState(parseSessionStorage() || defaultState);

  useEffect(() => {
    typeof window !== 'undefined' && sessionStorage.setItem(stateKey, JSON.stringify(state));
  }, [state])

  function setOtherFeatures(activeTerm) {
    if (activeTerm) {
      window.history.pushState(
        {},
        activeTerm,
        window.location.origin + '/concepts-strategies/' + activeTerm.url.split("/").pop()
      );
    }
    window.scroll(0,0);
  }

  /* CONTEXT CALLABLE FUNCTIONS */
  const setActiveTerm = (activeTerm) => {
    setOtherFeatures(activeTerm);
    setState({
      ...state,
      activeTerm,
      activeContentItem: null
    });
  };

  const toggleMobileMenu = (activeTerm) => {
    setOtherFeatures(activeTerm);
    setState({
      activeTerm,
      mobileMenuOpen: !state.mobileMenuOpen,
      activeContentItem: null
    });
  };

  const setActiveContentItem = (activeContentItem) => {
    setState({ ...state, activeContentItem })
  }

  const setSearchResults = (results) => {
    if (results === state.searchResults) {
      return state;
    }

    setState(prevState => {
      return {
        ...prevState,
        searchResults: results,
      }
    })
  }

  const setSearchTerm = (term) => {
    if (term === state.searchTerm) {
      return state;
    }

    setState(prevState => {
      return {
        ...prevState,
        searchTerm: term
      }
    })
  }

  const setExpanded = (key, value) => {
    setState(prevState => {
      return {
        ...prevState,
        expanded: { ...prevState.expanded, [key]: value }
      }
    })
  }
  /* END CONTEXT CALLABLE FUNCTIONS */

  // actual context data
  const contextData = {
    ...state,
    setActiveTerm,
    toggleMobileMenu,
    setActiveContentItem,
    setSearchResults,
    setSearchTerm,
    setExpanded
  }

  return (
    <KeyConceptsContext.Provider value={contextData}>
      {children}
    </KeyConceptsContext.Provider>
  )
}

export const useKeyConceptsContext = () => {
  const context = useContext(KeyConceptsContext);

  if (context === undefined) {
    console.error("The component calling useKeyConceptsContext is not wrapped inside the  KeyConceptsContextProvider");
    return defaultState;
  }

  return context;
};
