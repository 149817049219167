import React from 'react';
import PropTypes from 'prop-types';
import headerTypeShape from 'shapes/headerTypeShape';
import Typography from '@material-ui/core/Typography';

StackHeader.propTypes = {
  headerType: headerTypeShape,
  defaultHeaderType: headerTypeShape,
  children: PropTypes.node,
};

function StackHeader({ children, headerType, defaultHeaderType = 'H2', ...props }) {
  if (!headerType) {
    headerType = defaultHeaderType;
  }

  return (
    <Typography variant={headerType.toLowerCase()} {...props}>
      {children}
    </Typography>
  );
}

export default React.memo(StackHeader);
