const config = {
  gatewayApi: {
    baseUrl: process.env.RAZZLE_GRAPHQL_API,
    baseUrlSSR: process.env.RAZZLE_GRAPHQL_API_SSR,
  },
  livePlayer: {
    schedule: {
      pollInterval: process.env.RAZZLE_SCHEDULE_POLL_INTERVAL || 60000,
      activeShowCheckInterval: 10000,
    },
    defaultVolume: 0.8,
  },
  mediaConfig: {
    embedBaseUrl: 'https://players.brightcove.net/6057994532001/TOAkQJPlM_default/index.html',
  },
  episodePage: {
    upNextAdPlacementAfter: 4,
  },
  episodeFeeds: {
    pollInterval: 60000,
  },
  blogPostFeeds: {
    pollInterval: 60000,
    feedsPublicUrl: process.env.FEEDS_API_PUBLIC_URL || process.env.GATEWAY_API_PUBLIC_URL || 'https://feeds.tastylive.com',
  },
  dailyReplays: {
    pollInterval: 60000,
  },
  tradeOrders: {
    pollInterval: 60000,
  },
  auth0: {
    domain: process.env.RAZZLE_AUTH0_DOMAIN || 'auth.tastylive.com',
    clientId: process.env.RAZZLE_AUTH0_CLIENT_ID,
    scope: 'openid profile offline_access email https://tastytrade.com/data_privacy_settings',
    audience: process.env.RAZZLE_AUTH0_API_AUDIENCE || 'https://api.tastytrade.com/api/v2/',
    redirectUri: process.env.RAZZLE_AUTH0_REDIRECT_URI || 'http://localhost:3000/api/callback',
    thinkificRedirectUri: process.env.RAZZLE_AUTH0_THINKIFIC_REDIRECT_URI || 'http://localhost:3000/api/thinkific/callback',
    postLogoutRedirectUri: process.env.RAZZLE_AUTH0_POST_LOGOUT_REDIRECT_URI || 'http://localhost:3000/',
    session: {
      keyName: 'auth0_session',
      // The secret used to encrypt the cookie.
      cookieSecret: process.env.SESSION_SECRET || 'randomsecretthatis32charsatleast',
      // The cookie lifetime (expiration) in seconds.
      cookieLifetime: 2592000, // 30 days.
      // (Optional) The cookie domain this should run on. Leave it blank to restrict it to your domain.
      cookieDomain: null,
      // (Optional) SameSite configuration for the session cookie. Defaults to 'lax', but can be changed to 'strict' or 'none'. Set it to false if you want to disable the SameSite setting.
      cookieSameSite: 'lax',
      // (Optional) Store the id_token in the session. Defaults to false.
      storeIdToken: true,
      // (Optional) Store the access_token in the session. Defaults to false.
      storeAccessToken: true,
      // (Optional) Store the refresh_token in the session. Defaults to false.
      storeRefreshToken: true,
    },
    oidcClient: {
      // (Optional) Configure the timeout in milliseconds for HTTP requests to Auth0.
      httpTimeout: 2500,
      // (Optional) Configure the clock tolerance in milliseconds, if the time on your server is running behind.
      clockTolerance: 10000,
    },
  },
  appSearch: {
    engineName: process.env.RAZZLE_APP_SEARCH_ENGINE_NAME,
    hostIdentifier: process.env.RAZZLE_APP_SEARCH_HOST_IDENTIFIER,
    searchKey: process.env.RAZZLE_APP_SEARCH_SEARCH_KEY,
    endpointBase: process.env.RAZZLE_APP_SEARCH_ENDPOINT_BASE,
  },
  analytics: {
    gaTrackerId: process.env.RAZZLE_GA_TRACKER_ID,
    gtmContainerId: process.env.RAZZLE_GTM_CONTAINER_ID,
  },
  prodSiteBaseUrl: 'https://www.tastylive.com',
  freshDesk: {
    baseUrl: 'https://tastylive.freshdesk.com',
    articles: {
      baseUrl: 'https://tastylive.freshdesk.com/support/solutions/articles/',
      dashboard: 'https://tastylive.freshdesk.com/support/solutions/articles/48001170652-tastylive-dashboard-guide'
    }
  },
  localStorageKeys: {
    showStickyFooterAd: 'showStickyFooterAd',
    videoPlayerSettings: 'videoPlayerSettings',
  }
};

export default config;
