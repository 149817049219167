import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper as BaseSwiper } from 'swiper/react';
import { ifProp, prop, theme } from 'styled-tools';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { dataLayerPush } from 'utils/dataLayer';
import 'swiper/swiper-bundle.css';

// install Swiper components
SwiperCore.use([Navigation, A11y]);

const navButtonSize = 2.75;
const navButtonGutter = 1.25;
const gutterSize = navButtonSize + navButtonGutter;

const SwiperContainer = styled.div`
  position: relative;
  padding-left: ${gutterSize}em;
  padding-right: ${gutterSize}em;
  margin-left: -${gutterSize}em;
  margin-right: -${gutterSize}em;

  ${ifProp(
    'gutterSizeOverride',
    css`
      padding-left: ${prop('gutterSizeOverride')};
      padding-right: ${prop('gutterSizeOverride')};
      margin-left: -${prop('gutterSizeOverride')};
      margin-right: -${prop('gutterSizeOverride')};
    `
  )}

  ${ifProp(
    { $expand: true },
    css`
      height: 18.75rem;
      padding-left: 5rem;
      padding-right: 5rem;
      margin-left: -5rem;
      margin-right: -5rem;

      ${(props) => props.theme.breakpoints.up('xl')} {
        height: 20rem;
      }

      @media (min-width: 2300px) {
        height: 25rem;
      }

      @media (min-width: 2600px) {
        height: 30rem;
      }

      @media (min-width: 3000px) {
        height: 35rem;
      }
    `
  )};

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 0.75rem;
  }
`;

const StyledSwiper = styled(BaseSwiper)`
  ${ifProp(
    { $expand: true },
    css`
      &&& {
        padding-top: 3rem;
        margin-top: -3.25rem;
        // we HAVE to pad the edges so expanded cards dont get cut off
        // we CAN NOT overflow this container, it is scrollable. this negative margin corrects the balance
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        ${ifProp(
          { $episodeIsHovered: true },
          css`
            height: 30rem;
            z-index: 10;
          `
        )};

        @media (min-width: 2300px) {
          padding-left: 3rem;
          padding-top: 3rem;
          padding-right: 3rem;

          ${ifProp(
            { $episodeIsHovered: true },
            css`
              height: 35rem;
            `
          )};
        }

        @media (min-width: 2600px) {
          padding-left: 4rem;
          padding-top: 4rem;
          padding-right: 4rem;

          ${ifProp(
            { $episodeIsHovered: true },
            css`
              height: 37.5rem;
            `
          )};
        }

        @media (min-width: 3000px) {
          padding-left: 5rem;
          padding-top: 5rem;
          padding-right: 5rem;

          ${ifProp(
            { $episodeIsHovered: true },
            css`
              height: 40rem;
            `
          )};
        }
      }
    `
  )};

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
`;

const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    width: ${navButtonSize}em;
    height: ${navButtonSize}em;
    margin-top: calc(-1 * ${navButtonSize}em / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${theme.palette.grey[700]};
    border-radius: 50%;
    color: ${theme.palette.grey[700]};
    visibility: hidden;

    :hover & {
      color: ${theme.colors.ttRed};
      border-color: ${theme.colors.ttRed};
    }

    ${SwiperContainer}:hover & {
      visibility: visible;
    }

    ${ifProp(
      'hideNavButtons',
      css`
        display: none;
      `
    )}

    ${(props) => props.theme.breakpoints.down('md')} {
      font-size: 0.75rem;
    }

    &.swiper-button-disabled {
      display: none;
    }

    ${ifProp(
      'prev',
      css`
        left: 0;
        right: auto;
      `
    )}

    ${ifProp(
      'next',
      css`
        right: 0;
        left: auto;
      `
    )}

  ${ifProp(
      '$feedCarousel',
      css`
        top: 46%;
      `
    )}

  .MuiSvgIcon-root {
      font-size: 2.625rem;
    }
  `}
`;

const NavButton = styled(IconContainer)`
  &&& {
    ${ifProp(
      { $expand: true },
      css`
        top: 0;
        width: ${navButtonSize * 1.75}rem;
        height: 100%;
        border-radius: 0;
        border: 0;

        ${(props) => props.theme.breakpoints.down('md')} {
          width: 6rem;
        }
      `
    )}
  }
`;

const VideoPreviewHoverBlocker = styled.div`
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  z-index: 12;
  left: 0;

  ${ifProp(
    { $edge: 'right' },
    css`
      right: -10px;
      left: auto;
      top: 80px;
      z-index: 10;
    `
  )}

  @media(min-width: 2300px) {
    width: 3rem;
  }

  @media (min-width: 2600px) {
    width: 4rem;
  }

  @media (min-width: 3000px) {
    width: 5rem;
  }
`;

const DEFAULT_SPACE_BETWEEN = 16;
const DEFAULT_SLIDES_PER_VIEW = 5;
const DEFAULT_SLIDES_PER_GROUP = 5;

Swiper.propTypes = {
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  slidesPerGroup: PropTypes.number,
  children: PropTypes.node,
  feedCarousel: PropTypes.bool,
  feedName: PropTypes.string,
  gutterSizeOverride: PropTypes.number,
  hideNavButtons: PropTypes.bool,
  className: PropTypes.string,
  expand: PropTypes.bool,
  episodeIsHovered: PropTypes.bool,
};

function Swiper({
  children,
  className,
  customBreakpoints,
  feedCarousel,
  feedName,
  gutterSizeOverride,
  hideNavButtons,
  slidesPerView = DEFAULT_SLIDES_PER_VIEW,
  slidesPerGroup = DEFAULT_SLIDES_PER_GROUP,
  episodeIsHovered = false,
  expand = false,
  ...props
}) {
  // Refs for prev/next elements for swiper.
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const feedCarouselNext = () => {
    if (feedName) {
      dataLayerPush(`Clicked next button on '${feedName}' Feed Carousel`);
    }
  };

  const feedCarouselPrev = () => {
    if (feedName) {
      dataLayerPush(`Clicked previous button on '${feedName}' Feed Carousel`);
    }
  };

  return (
    <SwiperContainer gutterSizeOverride={gutterSizeOverride} $expand={expand}>
      <NavButton
        $feedCarousel={feedCarousel}
        $expand={expand}
        hideNavButtons={hideNavButtons}
        onClick={feedCarouselPrev}
        prev
        ref={prevRef}
      >
        <IconContainer>
          <ChevronLeftIcon />
        </IconContainer>
      </NavButton>
      <NavButton
        $feedCarousel={feedCarousel}
        $expand={expand}
        hideNavButtons={hideNavButtons}
        onClick={feedCarouselNext}
        next
        ref={nextRef}
      >
        <IconContainer>
          <ChevronRightIcon />
        </IconContainer>
      </NavButton>
      <StyledSwiper
        $expand={expand}
        $episodeIsHovered={episodeIsHovered}
        className={isDesktop ? className : ''}
        spaceBetween={DEFAULT_SPACE_BETWEEN}
        noSwiping={true}
        breakpoints={
          customBreakpoints
            ? customBreakpoints
            : {
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 16,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 16,
                },
                // when window width is >= 640px
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  spaceBetween: 16,
                },
                1280: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  spaceBetween: 16,
                },
                1440: {
                  slidesPerView: 5,
                  slidesPerGroup: 5,
                  spaceBetween: 16,
                },
              }
        }
        navigation={{
          prevEl: prevRef.current ? prevRef.current : null,
          nextEl: nextRef.current ? nextRef.current : null,
        }}
        onInit={(swiper) => {
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.prevEl = prevRef.current;
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.nextEl = nextRef.current;

          // Re-init navigation
          swiper.navigation.update();
        }}
        {...props}
      >
        {children}
        {expand && (
          <>
            <VideoPreviewHoverBlocker $edge={'left'} />
            <VideoPreviewHoverBlocker $edge={'right'} />
          </>
        )}
      </StyledSwiper>
    </SwiperContainer>
  );
}

export default React.memo(Swiper);
