
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ModalFormFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FeedbackForm"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userFacingTitle"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"successMessage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"iconWebp"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"iconPng"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"emailFieldLabel"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formFields"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldLabel"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formInputType"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":207}};
    doc.loc.source = {"body":"fragment ModalFormFields on FeedbackForm {\n  title\n  userFacingTitle\n  successMessage\n  iconWebp {\n    url\n  }\n  iconPng {\n    url\n  }\n  emailFieldLabel\n  formFields {\n    fieldLabel\n    formInputType\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
