import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetFollowedShows } from './useGetFollowedShows';
import { useMutation } from '@apollo/client';
import unfollowShowMutation from '../../../graphql/mutations/unfollowShow.graphql';
import followShowMutation from '../../../graphql/mutations/followShow.graphql';
import { dataLayerPush } from 'utils/dataLayer';

const FollowedShowsContext = createContext(undefined);

const defaultState = {
  error: null,
  followedShows: {
    count: 0,
    items: []
  },
  loading: true,
  selectedEpisodes: []
}

export const DashboardShowsProvider = ({ children }) => {
  // default states
  const [state, setState] = useState(defaultState);
  const [show, setShow] = useState(undefined);

  /* INTERNAL FUNCTIONS */
  const { data, error, loading, refetch } = useGetFollowedShows();

  // update follow show data for component handling of loading and error states
  useEffect(() => {
    const followedShows = data?.followedShows ?? state.followedShows;

    // skip followed shows so that if undefined, it keeps its empty default and we dont have to check for this everywhere else
    setState( { ...state, followedShows, error, loading })
  }, [data, error, loading]);

  async function handleFollowShow(show) {
    await setShow(show);
    const response = await performFollowShow({ variables: { showId: show.uid }});
    const { success, errors } = response.data.followShow;
    if (success) {
      dataLayerPush('show followed');
    } else {
      dataLayerPush(errors[0]?.msg);
    }
    refetch();
    setShow(undefined);

    return !success ? errors[0].msg : undefined;
  }

  const [performFollowShow] = useMutation(followShowMutation, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify(show),
        fields: {
          isFollowing: () => data.followShow.success,
        },
      });
    },
  });

  async function handleUnfollowShow(show) {
    await setShow(show);
    await performUnfollowShow({ variables: { showId: show.uid }});
    dataLayerPush('show unfollowed');
    refetch();
    setShow(undefined);
  }

  const [performUnfollowShow] = useMutation(unfollowShowMutation, {
    update(cache) {
      cache.modify({
        id: cache.identify(show),
        fields: {
          isFollowing: () => false,
        },
      });
    },
  });
  /* END INTERNAL FUNCTIONS */

  /* CONTEXT CALLABLE FUNCTIONS */
  const setFollowedShows = (shows) => setState({ ...state, followedShows: shows });
  const setSelectedEpisodes = (episodes) => setState({ ...state, selectedEpisodes: episodes });
  const refetchFollowedShows = () => refetch();
  const followShow = (show) => handleFollowShow(show);
  const unfollowShow = (show) => handleUnfollowShow(show);
  /* end CONTEXT CALLABLE FUNCTIONS */

  // actual context data
  const contextData = {
    ...state,
    setFollowedShows,
    setSelectedEpisodes,
    refetchFollowedShows,
    followShow,
    unfollowShow
  }

  return (
    <FollowedShowsContext.Provider value={contextData}>
      {children}
    </FollowedShowsContext.Provider>
  )
}

export const useFollowedShowsContext = () => {
  const context = useContext(FollowedShowsContext);

  if (context === undefined) {
    console.error("The component calling useDashboardShowsContext is not wrapped inside the DashboardShowsContextProvider");
    return defaultState;
  }

  return context;
};
