import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ComputerChipIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
        <g id="Group_493" data-name="Group 493" transform="translate(-3.991)">
          <path id="Path_235" data-name="Path 235" d="M35.391-64.826H19V-81H35.391Zm-14.8-1.565H33.8V-79.435H20.586Z" transform="translate(-11.087 84.913)" fill="#e31b23"/>
          <path id="Path_236" data-name="Path 236" d="M41-93.391h1.565V-96H41Z" transform="translate(-27.255 96)" fill="#e31b23"/>
          <path id="Path_237" data-name="Path 237" d="M53-93.391h1.565V-96H53Z" transform="translate(-36.094 96)" fill="#e31b23"/>
          <path id="Path_238" data-name="Path 238" d="M29-93.391h1.565V-96H29Z" transform="translate(-18.415 96)" fill="#e31b23"/>
          <path id="Path_239" data-name="Path 239" d="M65-93.391h1.565V-96H65Z" transform="translate(-44.933 96)" fill="#e31b23"/>
          <path id="Path_240" data-name="Path 240" d="M41-11.391h1.565V-14H41Z" transform="translate(-27.255 35.391)" fill="#e31b23"/>
          <path id="Path_241" data-name="Path 241" d="M53-11.391h1.565V-14H53Z" transform="translate(-36.094 35.391)" fill="#e31b23"/>
          <path id="Path_242" data-name="Path 242" d="M29-11.391h1.565V-14H29Z" transform="translate(-18.415 35.391)" fill="#e31b23"/>
          <path id="Path_243" data-name="Path 243" d="M65-11.391h1.565V-14H65Z" transform="translate(-44.933 35.391)" fill="#e31b23"/>
          <path id="Path_244" data-name="Path 244" d="M86-57.435h2.609V-59H86Z" transform="translate(-60.392 68.652)" fill="#e31b23"/>
          <path id="Path_245" data-name="Path 245" d="M86-45.435h2.609V-47H86Z" transform="translate(-60.392 59.783)" fill="#e31b23"/>
          <path id="Path_246" data-name="Path 246" d="M86-69.435h2.609V-71H86Z" transform="translate(-60.392 77.522)" fill="#e31b23"/>
          <path id="Path_247" data-name="Path 247" d="M86-33.435h2.609V-35H86Z" transform="translate(-60.392 50.913)" fill="#e31b23"/>
          <path id="Path_248" data-name="Path 248" d="M4-57.435H6.609V-59H4Z" transform="translate(0 68.652)" fill="#e31b23"/>
          <path id="Path_249" data-name="Path 249" d="M4-45.435H6.609V-47H4Z" transform="translate(0 59.783)" fill="#e31b23"/>
          <path id="Path_250" data-name="Path 250" d="M4-69.435H6.609V-71H4Z" transform="translate(0 77.522)" fill="#e31b23"/>
          <path id="Path_251" data-name="Path 251" d="M4-33.435H6.609V-35H4Z" transform="translate(0 50.913)" fill="#e31b23"/>
          <path id="Path_252" data-name="Path 252" d="M37-56.217h6.783V-63H37Z" transform="translate(-24.283 71.609)" fill="#e31b23"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default React.memo(ComputerChipIcon);
