export const alphaLetters = [
  {
    letter: 'a',
    status: 'inactive'
  },
  {
    letter: 'b',
    status: 'inactive'
  },
  {
    letter: 'c',
    status: 'inactive'
  },
  {
    letter: 'd',
    status: 'inactive'
  },
  {
    letter: 'e',
    status: 'inactive'
  },
  {
    letter: 'f',
    status: 'inactive'
  },
  {
    letter: 'g',
    status: 'inactive'
  },
  {
    letter: 'h',
    status: 'inactive'
  },
  {
    letter: 'i',
    status: 'inactive'
  },
  {
    letter: 'j',
    status: 'inactive'
  },
  {
    letter: 'k',
    status: 'inactive'
  },
  {
    letter: 'l',
    status: 'inactive'
  },
  {
    letter: 'm',
    status: 'inactive'
  },
  {
    letter: 'n',
    status: 'inactive'
  },
  {
    letter: 'o',
    status: 'inactive'
  },
  {
    letter: 'p',
    status: 'inactive'
  },
  {
    letter: 'q',
    status: 'inactive'
  },
  {
    letter: 'r',
    status: 'inactive'
  },
  {
    letter: 's',
    status: 'inactive'
  },
  {
    letter: 't',
    status: 'inactive'
  },
  {
    letter: 'u',
    status: 'inactive'
  },
  {
    letter: 'v',
    status: 'inactive'
  },
  {
    letter: 'w',
    status: 'inactive'
  },
  {
    letter: 'x',
    status: 'inactive'
  },
  {
    letter: 'y',
    status: 'inactive'
  },
  {
    letter: 'z',
    status: 'inactive'
  }
];
