import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { removeTrailingBackslash } from 'utils/linkUtils';
import qs from 'query-string';
import config from 'config';

function validatePageNumber(page, count, perPage, startPage = 0) {
  if (page < startPage) {
    return startPage;
  } else if (page > count / perPage) {
    return Math.ceil(count / perPage);
  } else {
    return page;
  }
}

function usePagination(count, perPage, withQueryString = false, startPage = 0, pageValidation = true) {
  const location = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(location.search);
  const initPage = withQueryString && Number(queryParams.page) ? Number(queryParams.page) : startPage;
  const [page, setPage] = useState(initPage);

  const handlePageChange = useCallback(
    pageNum => {
      pageNum = validatePageNumber(pageNum, count, perPage, startPage);
      setPage(pageNum);
      if (withQueryString) {
        const pageStr = '?page=' + pageNum;
        history.push(removeTrailingBackslash(location.pathname) + pageStr);
        // Updating the canonical value
        document.querySelectorAll(
          '[rel="canonical"]')[0].href =
          removeTrailingBackslash(`${config.prodSiteBaseUrl}${location.pathname}`) + pageStr;
      }
    },
    [count, history, location.pathname, perPage]
  );

  const previousPage = () => {
    handlePageChange(page - 1);
  };

  const nextPage = () => {
    handlePageChange(page + 1);
  };

  useEffect(() => {
    if (pageValidation) {
      const pageNum = validatePageNumber(page, count, perPage, startPage);
      if (pageNum !== page) {
        handlePageChange(pageNum);
      }
    }
  }, [page, count, perPage, handlePageChange]);

  return {
    page,
    perPage,
    setPage: handlePageChange,
    previousPage,
    nextPage,
    hasPreviousPage: page > startPage,
    hasNextPage: count > (page + (startPage === 0 ? 1 : 0)) * perPage,
  };
}

export default usePagination;
