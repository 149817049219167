import React from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { REPLAY_TOUR_STEPS } from 'constants/replayTourSteps';
import { useTourContext } from 'context/TourContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

const GoButton = styled(Typography)`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
`;

NextButton.propTypes = {};

function NextButton() {
  const { state: { currentStep, customLink, startAt }, disableTour, setWillShowTour } = useTourContext();
  const { stepOne, stepThree, stepFour } = REPLAY_TOUR_STEPS;
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const goToDailyReplays = () => {
    if (isDesktop && currentStep === stepThree) {
      disableTour();
      history.push(customLink);
    }

    if (!isDesktop && currentStep === stepFour) {
      disableTour();
      history.push(customLink);
    }
  }

  return (
    <>
      {(currentStep === stepOne || (currentStep === null && startAt === stepOne)) ? (
        <GoButton>Let's Go</GoButton>
      ) : (
        <ArrowForwardIcon onClick={goToDailyReplays} />
      )}
    </>
  );
}

export default NextButton;
