import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import RichTextField from 'components/RichTextField';
import { ifNotProp, ifProp, prop, switchProp } from 'styled-tools';
import singleContentCardShape from 'shapes/singleContentCardShape';
import { flattenBlockStyle } from 'utils/blockStyleUtils';
import { convertRem } from 'utils/functions';
import CtaContainer from './CtaContainer';
import CardContainer from './CardContainer';

const Container = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  padding: 2rem 1rem;
  height: 100%;

  h3 {
    font-size: 2rem;
  }
  ${switchProp('size', {
    12: css`
      justify-content: center;

      ${props => props.theme.breakpoints.up('sm')} {
        width: 100%;
        padding: 3rem 4rem;
      }

      ${props => props.theme.breakpoints.up('md')} {
        width: 50%;
        padding: 4rem;
      }

      ${props => props.theme.breakpoints.up('lg')} {
        // resorting to !important, as one instance of this container has conflicting padding that cannot be found in code or CMS
        padding: 5rem !important;

        ${ifProp('marginRight', css`
          margin-right: ${prop('marginRight')}rem;
        `)}

        ${ifProp('paddingBottom', css`
          padding-bottom: ${prop('paddingBottom')}rem;
        `)}

        ${ifProp('paddingLeft', css`
          padding-left: ${prop('paddingLeft')}rem;
        `)}

        ${ifProp('paddingRight', css`
          padding-right: ${prop('paddingRight')}rem;
        `)}

        ${ifProp('paddingTop', css`
          padding-top: ${prop('paddingTop')}rem;
        `)}
      }
    `,
    6: css`
      justify-content: space-between;
      padding: 1.5rem 1.25rem;
    `,
    4: css`
      justify-content: space-between;
      padding: 2.25rem;
    
      ${props => props.theme.breakpoints.up('md')} {
        min-height: 23rem;
      }
        
      ${props => props.theme.breakpoints.up('lg')} {
        min-height: 18rem;
      }

      h3 {
        font-size: 1.875rem;
      }
    `,
    3: css`
      justify-content: space-between;
      padding: 2.25rem;
    `,
  })}

  &.expanding-copy {
    padding: 1.5rem 1.5rem 2rem;

    ${props => props.theme.breakpoints.up('md')} {
      height: inherit;
      padding: 1rem 0 0;
      transition: transform 250ms, padding 250ms;
      width: 100%;
    }

    ${CardContainer}:hover & {
      ${props => props.theme.breakpoints.up('md')} {
        padding: 0.5rem 0 0;
        transform: scale(0.9);
      }
    }
  }

  a,
  button {
    ${props => props.theme.breakpoints.up('sm')} {
      width: fit-content;
    }
  }
`;

const Header = styled(Typography)`
  margin-bottom: 0;
  font-weight: 600;

  ${ifProp('subheader', css`
    margin-bottom: 1.75rem;
  `)}

  &.MuiTypography-h2 {
    1.75rem;

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: 2.0677rem;
    }

    ${props => props.theme.breakpoints.up('md')} {
      font-size: 2.556rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      font-size: 2.4436rem
    }
  }

  &.MuiTypography-h4 {
    ${ifProp({$hover: 'expand'}, css`
      ${props => props.theme.breakpoints.down('sm')} {
        font-size: 1.25rem;
      }
    `)}
  }
`;

const Subheader = styled(Typography)`
`;

const Spacer = styled.div`
  display: block;
  height: 1rem;
`;

const Body = styled(RichTextField)`
  margin: 0;

  ${ifProp({$hover: 'expand'}, css`
    ${props => props.theme.breakpoints.up('md')} {
      margin: 0;
      opacity: 0;
    }

    ${CardContainer}:hover & {
      ${props => props.theme.breakpoints.up('md')} {
        opacity: 1;
      }
    }
  `)}

  p {
    font-size: 0.8125rem;
    margin: 0;

    ${props => props.theme.breakpoints.up('md')} {
      ${ifNotProp({$hover: 'expand'}, css`
        font-size: 1rem;
      `)}
    }

    ${ifProp({$hover: 'expand'}, css`
      margin-top: 0.5rem;
    `)}
  }
`;

CopyContainer.propTypes = {
  data: singleContentCardShape,
  hoverAnimation: PropTypes.string,
  cardLink: PropTypes.object,
  size: PropTypes.number
};

function CopyContainer({ data, hoverAnimation, size }) {
  const {
    additionalStyles,
    buttonCta,
    header,
    headerType,
    cardLink,
    modalComponent,
    modalForm,
    subheader,
    subheaderType,
    textBody,
    spacer
  } = data;
  const { blockStyle } = additionalStyles || {};

  const {
    marginLeft,
    marginRight,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
  } = flattenBlockStyle(blockStyle);

  return (
    <Container
      className={hoverAnimation === 'expand' ? 'expanding-copy' : ''}
      marginRight={convertRem(marginRight)}
      paddingBottom={convertRem(paddingBottom)}
      paddingLeft={convertRem(paddingLeft)}
      paddingRight={convertRem(paddingRight)}
      paddingTop={convertRem(paddingTop)}
      size={size}
    >
      {subheader && (
        <Subheader
          variant={(subheaderType && subheaderType.toLowerCase()) || 'h4'}
        >
          {subheader}
        </Subheader>
      )}
      {header && (
        <Header
          subheader={!!subheader}
          $hover={hoverAnimation}
          variant={(headerType && headerType.toLowerCase()) || 'h3'}
          size={size}
        >
          {header}
        </Header>
      )}
      {textBody &&
        <Body $hover={hoverAnimation} richText={textBody} />
      }
      <Spacer></Spacer>
      {buttonCta.buttonType &&
        <CtaContainer
          modalForm={modalForm}
          componentName={modalComponent}
          data={buttonCta}
          hoverAnimation={hoverAnimation}
          cardLink={cardLink}
        />
      }
    </Container>
  );
}

export default React.memo(CopyContainer);
