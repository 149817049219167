import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FeaturedComponent from '../FeaturedComponent';
import getRandomConceptQuery from '../../../../../../graphql/queries/getRandomConcept.graphql';

RandomizedConcept.propTypes = {
  megaMenuClick: PropTypes.func.isRequired,
  subItem: PropTypes.object,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourAction: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.func,
  menuOpen: PropTypes.bool,
};

function RandomizedConcept({
  megaMenuClick,
  subItem,
  tourHighlighted,
  traderResourcesTourMenuClick,
  traderResourcesTourAction,
  menuOpen
}) {
  const { data } = useQuery(getRandomConceptQuery, {
    variables: {
      filters: {
        displayLearn: true,
      }
    },
  });

  const items = get(data, 'randomConcept.items', []);
  const featuredItem = items[0];

  if (!featuredItem) {
    return null;
  }

  const definition = get(featuredItem, 'definition');
  const finalDefinition = definition?.split(/[.!?]/g, 1) + '.';

  return (
    <FeaturedComponent
      exploreMore='Get the Deep Dive'
      featuredImage={featuredItem?.thumbnailImage?.url}
      featuredItem={featuredItem}
      featuredTitle={featuredItem?.title}
      featuredSnippet={finalDefinition}
      megaMenuClick={megaMenuClick}
      tourHighlighted={tourHighlighted}
      traderResourcesTourMenuClick={traderResourcesTourMenuClick}
      traderResourcesTourAction={traderResourcesTourAction}
      subItem={subItem}
      menuOpen={menuOpen}
    />
  )
};

export default RandomizedConcept;
