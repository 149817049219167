import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FeaturedComponent from '../FeaturedComponent';
import getRandomBlogPostQuery from '../../../../../../graphql/queries/getRandomBlogPost.graphql'

RandomizedArticle.propTypes = {
  megaMenuClick: PropTypes.func.isRequired,
  subItem: PropTypes.object,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourAction: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.func,
  menuOpen: PropTypes.bool,
};

function RandomizedArticle ({
  tourHighlighted,
  traderResourcesTourMenuClick,
  megaMenuClick,
  traderResourcesTourAction,
  subItem,
  menuOpen
}) {
  const { loading: blogLoading, data } = useQuery(getRandomBlogPostQuery);

  const items = get(data, 'randomBlogPost.items', []);
  const featuredItem = items[0]

  if (!featuredItem) {
    return null;
  }

  return (
    <FeaturedComponent
      exploreMore='Explore Featured Article'
      featuredImage={featuredItem?.metadata?.thumbnailImage?.url}
      featuredItem={featuredItem}
      featuredTitle={featuredItem?.title}
      featuredDate={featuredItem?.postDate}
      featuredSnippet={featuredItem?.definition}
      megaMenuClick={megaMenuClick}
      tourHighlighted={tourHighlighted}
      traderResourcesTourMenuClick={traderResourcesTourMenuClick}
      traderResourcesTourAction={traderResourcesTourAction}
      subItem={subItem}
      menuOpen={menuOpen}
    />
  )
};

export default RandomizedArticle;
