import './utils/pip-polyfill';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { getSerializedData } from '@jaredpalmer/after';
import { ApolloProvider } from '@apollo/client';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import createApolloClient from './createApolloClient';
import Layout from './components/Layout';
import theme from './theme';
import { SiteSettingsProvider } from 'context/SiteSettingsContext';
import { DashboardProvider } from 'context/DashboardContext';
import { Cookies, CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import initDayJs from 'utils/initDayJs';
import { AuthProvider } from 'context/AuthContext';
import { QueryParamProvider } from 'use-query-params';
import AnalyticsListener from 'components/AnalyticsListener';
import { PictureInPictureProvider } from 'context/PictureInPictureContext';
import { VideoPlayerProvider } from 'context/VideoPlayerContext';
import { DashboardContextsProvider } from './context/Dashboard';
import { TourProvider } from 'context/TourContext';
import { ServerProvider } from 'context/ServerContext/ServerContext';

/**
 *
 * By splitting most of the client.js work into this file, and importing it for use in the client.js file, I was able to get around
 * first the SSR and any issues that came from that while testing, but it also got me past other errors as I needed the
 * client, but there is a rule that you can't export anything else out of the client.js file.
 *
 */

const generateClassName = createGenerateClassName();

const cookies = new Cookies();
const preloadedState = getSerializedData('preloaded_state');
export const client = createApolloClient({
  ssrForceFetchDelay: 100,
}, null, preloadedState);

const preloadedUserState = getSerializedData('preloaded_user_state') || {};

initDayJs();

function CookieHandler({ children }) {
  if (process.env.NODE_ENV === 'test') {
    return <>{children}</>;
  }

  return (
    <CookiesProvider>
      {children}
    </CookiesProvider>
  )
}

const AuthComponent = ({children, ...props}) => {
  if (process.env.NODE_ENV === 'test') {
    return <>{children}</>;
  }

  return <AuthProvider value={props.value}>{children}</AuthProvider>;
};

const InnerApp = ({ children }) => {
  // Force StylesProvider to re-render on client side as a hack workaround
  // for an SSR issue.
  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    setKey(1);
  }, []);

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <StylesProvider key={key} generateClassName={generateClassName} injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <SnackbarProvider hideIconVariant maxSnack={3}>
            <Layout>
              {children}
            </Layout>
          </SnackbarProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

const App = ({ children }) => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AuthComponent value={preloadedUserState}>
            <ServerProvider>
              <CookieHandler>
                <SiteSettingsProvider>
                  <AnalyticsListener>
                    <PictureInPictureProvider>
                      <VideoPlayerProvider>
                        <DashboardProvider>
                          <DashboardContextsProvider>
                            <TourProvider>
                              <InnerApp>
                                {children}
                              </InnerApp>
                            </TourProvider>
                          </DashboardContextsProvider>
                        </DashboardProvider>
                      </VideoPlayerProvider>
                    </PictureInPictureProvider>
                  </AnalyticsListener>
                </SiteSettingsProvider>
              </CookieHandler>
            </ServerProvider>
          </AuthComponent>
        </QueryParamProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
