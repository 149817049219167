import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { AdSlot as BaseAdSlot } from 'react-dfp';
import adUnitShape from 'shapes/adUnitShape';
import { dataLayerPush } from 'utils/dataLayer';

AdSlot.propTypes = {
  adUnits: PropTypes.arrayOf(adUnitShape),
  showUid: PropTypes.string,
};

function AdSlot({ adUnits, showUid }) {
  // Find the active ad unit based on configuration in CS.
  const activeAdUnit = adUnits.find(({ adSlotConfig }) => {
    const { displayByDefault = false, displayTargeting } = adSlotConfig || {};

    // If no show to filter by and this is default, return this.
    if (!showUid) {
      return displayByDefault;
    }

    const targetShows = get(displayTargeting, 'displayForSpecificShows', []);

    return !!targetShows.find(({ uid }) => uid === showUid);
  }) || adUnits.find(({ adSlotConfig }) => get(adSlotConfig, 'displayByDefault', false));

  if (!activeAdUnit) {
    return null;
  }

  const { adUnit, sizes = [] } = activeAdUnit.adSlotConfig;

  return (
    <div onClick={()=>{dataLayerPush(adUnit?.adUnit, 'campaign_id')}}>
      <BaseAdSlot
        adUnit={adUnit}
        className='mask-in-screenshot'
        sizes={sizes.map(({ width, height }) => [width, height])}
      />
    </div>
  );
}

export default React.memo(AdSlot);
