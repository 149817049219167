import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import get from 'lodash.get';
import Grid from '@material-ui/core/Grid';
import BaseStackHeader from 'components/StackHeader';
import RichTextField from 'components/RichTextField';
import BlockContainer from 'components/BlockContainer';
import { css } from 'styled-components';
import config from 'config';
import useInterval from 'hooks/useInterval';
import BlogPostFeed from '../../BlogPostFeed';
import blogPostFeedBlockShape from 'shapes/components/blogPostFeedBlockShape';
import getBlogPostsQuery from '../../../graphql/queries/getBlogPosts.graphql';
import { ifProp } from 'styled-tools';
import defaultTo from 'lodash.defaultto';

const StyledBlockContainer = styled(BlockContainer)`
  ${ifProp({page: 'trader-resources'}, css`
    ${props => props.theme.breakpoints.up('md')} {
      min-height: 21.875rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    ${props => props.theme.breakpoints.up('lg')} {
      max-width: 100%;
      min-height: 25rem;
      padding-left: 8.75rem;
      padding-right: 8.75rem;
    }

    @media (min-width: 1820px) {
      padding-left: 11.5rem;
      padding-right: 11.5rem;
    }
  `)}

  ${ifProp('$noPadding', css`
    padding-left: 0;
    padding-right: 0;
  `)}

  ${ifProp('$noMax', css`
    max-width: none;
  `)}
`;

const StackHeader = styled(BaseStackHeader)`${({ theme }) => css`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`}`;

// TODO: Map key concept and blog category to sort
const blogPostSortMap = {
  most_recent: {
    sortBy: 'post_date',
    sortDirection: 'descending',
  }
};

BlogPostFeedBlock.propTypes = {
  data: blogPostFeedBlockShape.isRequired,
  className: PropTypes.string,
};

function BlogPostFeedBlock({ data, className, ...props }) {
  const { page } = props;
  const {
    header,
    headerType = 'H2',
    description,
    blogPostCount,
    blogPostFeed: {
      sortType = 'most_recent',
      liveUpdate = {},
      filters = {},
    } = {},
  } = data;

  // Prepare query variables from the CS block data.
  const sort = blogPostSortMap[sortType] || {};
  const pagination = { limit: blogPostCount || 4 };

  const {
    definitions,
    blogCategories,
  } = !!filters;

  const queryFilters = {
    definitionUids: defaultTo(definitions, []).map(v => v.uid),
    blogCategoryUids: defaultTo(blogCategories, []).map(v => v.uid),
  };

  const { data: blogPostsData, loading, refetch } = useQuery(getBlogPostsQuery, {
    skip: typeof window === 'undefined',
    variables: {
      pagination,
      sort,
      filters: queryFilters
    },
    fetchPolicy: 'network-only',
    pollInterval: config.blogPostFeeds.pollInterval
  });

  // Setup interval that tracks current time and updates recent blog post feed.
  useInterval(() => {
    if (liveUpdate?.willUpdate) {
      refetch();
    }
  }, liveUpdate?.updateInterval ?? config.blogPostFeeds.pollInterval);

  const blogPosts = get(blogPostsData, 'blogPosts.items', []);
  const limitOfFourBlogPosts = blogPosts.slice(0, 4);

  return (
    <>
      {(!loading && blogPosts && blogPosts.length > 0) && (
        <StyledBlockContainer
          $carouselGutters
          className={className}
          $noMax={!page || page?.includes('concepts')}
          maxWidth='xl'
          {...props}
        >
          {/* Header and description, if set */}
          {(header || description) && (
            <Grid container>
              <Grid item xs={12}>
                {header && (
                  <StackHeader headerType={headerType} defaultHeaderType='H2' gutterBottom>
                    {header}
                  </StackHeader>
                )}
                {description && <RichTextField richText={description} />}
              </Grid>
            </Grid>
          )}

          <Grid container>
            {/* Blog Post Feed */}
            <BlogPostFeed blogPosts={limitOfFourBlogPosts} page={page} />
          </Grid>
        </StyledBlockContainer>
      )}
    </>
  );
}

export default BlogPostFeedBlock;
