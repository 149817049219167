
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentCardsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ContentCardsBlock"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blockReference"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mobileLayout"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentCards"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SingleContentCardFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"additionalStyles"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"blockStyle"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BlockStyleFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":411}};
    doc.loc.source = {"body":"#import \"../BlockStyleFields.graphql\"\n#import \"../SingleContentCardFields.graphql\"\n\nfragment ContentCardsFields on ContentCardsBlock {\n  blockReference {\n    mobileLayout\n    contentCards {\n      ...SingleContentCardFields\n    }\n    additionalStyles {\n      blockStyle {\n        ...BlockStyleFields\n      }\n    }\n    blockStyle {\n      ...BlockStyleFields\n    }\n  }\n  blockStyle {\n    ...BlockStyleFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../BlockStyleFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../SingleContentCardFields.graphql").definitions));


      module.exports = doc;
    
