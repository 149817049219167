import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const CheckboxLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    ${switchProp('weight', {
      regular: css`
        font-weight: 400;
      `,
      medium: css`
        font-weight: 500;
      `,
      bold: css`
        font-weight: 700;
      `
    })}
  }
`;

const CheckboxInput = styled(Checkbox)`
  padding: 0.375rem;

  .MuiSvgIcon-root {
    font-size: 1.25rem;
  }
`;

const propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelPlace: PropTypes.string,
  labelWeight: PropTypes.string
}

function CheckboxSingle({
  label,
  labelPlace,
  labelWeight = 'regular',
  value,
  onChange,
}) {
  return (
    <FormControl component='fieldset'>
      <FormGroup aria-label={`checkbox for ${label}`}>
        <CheckboxLabel
          control={
            <CheckboxInput
              name={label}
              checked={value}
              color='secondary'
              onChange={onChange}
            />
          }
          label={label}
          labelPlacement={labelPlace}
          value={label}
          weight={labelWeight}
        />
      </FormGroup>
    </FormControl>
  );
}

CheckboxSingle.propTypes = propTypes;

export default React.memo(CheckboxSingle);
