import React  from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import AbsoluteOverlay from '../AbsoluteOverlay';
import { colors } from 'theme';

const CheckboxWrapper = styled(Checkbox)`
  svg {
    fill: white;
    
    ${ifProp({checked: true}, css`
      fill: ${theme('colors.ttRed')};
    `)};
  }
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  background-color: ${prop('$color')};
`;

CheckboxFloating.propTypes = {
  onCheck: PropTypes.func,
  checked: PropTypes.bool,
  backgroundColor: PropTypes.string,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  zIndex: PropTypes.number
};

function CheckboxFloating({
  onCheck,
  checked = false,
  backgroundColor = colors.white,
  top = 0,
  right = 0,
  bottom = null,
  left = null,
  zIndex = 11
}) {
  return (
    <AbsoluteOverlay
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      zIndex={zIndex}
      opacity={1}
    >
      {checked &&
        <BackgroundColor $color={backgroundColor} />
      }
      <CheckboxWrapper checked={checked} onChange={() => onCheck && onCheck()} />
    </AbsoluteOverlay>
  );
}

export default CheckboxFloating;
