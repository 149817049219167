import React from 'react';
import Grid from '@material-ui/core/Grid';
import ShowCard from 'components/ShowCard';
import showOneColumnShape from 'shapes/components/showOneColumnShape';

ShowOneColumn.propTypes = {
  data: showOneColumnShape,
};

function ShowOneColumn({ data }) {
  const { featuredShow, shouldLazyLoad } = data;

  if (!featuredShow) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ShowCard show={featuredShow} shouldLazyLoad={shouldLazyLoad} />
      </Grid>
    </Grid>
  )
}

export default React.memo(ShowOneColumn);
