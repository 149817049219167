/** @module tt/utils/constants */

export const DISPLAY_TIMEZONE = 'America/Chicago';
export const DISPLAY_TIMEZONE_LABEL = 'CT';

export const ASCENDING = 'oldest_to_newest';
export const DESCENDING = 'newest_to_oldest';
export const COLOR_THEMES = {
  dark: 'dark',
  light: 'light',
};

export const MOBILE_LAYOUTS = {
  feed: 'feed',
  grid: 'grid',
}

export const DEFAULT_GRADIENT_COLOR = '#000000';
export const BLOG_AD_POSITION = 2;

export const DATA_PRIVACY_CLAIM = 'https://data-privacy.tastylive.com/data_privacy_settings';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const episodeImageSizes = {
  sm: {
    height: 158.34,
    width: 281.5,
  },
  md: {
    height: 260,
    width: 460,
  },
  lg: {
    height: 390,
    width: 690,
  },
  xl: {
    width: 1280,
    height: 720,
  }
};

export const showImageSizes = {
  sm: {
    height: 166.5,
    width: 296,
  },
  md: {
    height: 349,
    width: 608,
  },
  lg: {
    height: 693,
    width: 1232,
  },
  featured: {
    height: 711.11,
    width: 400,
  },
  hero: {
    height: 720,
    width: 1280,
  }
};

// Take blog card height and x2 so the quality remains intact, but is still
// optimized; not including width b/c the blog featured images are not as
// consistent in sizing like the show and episode cards
export const blogImageSizes = {
  smaller: {
    height: 292,
  },
  small: {
    height: 320,
  },
  regular: {
    height: 440,
  }
};

export const contentCardImageSizes = {
  12: {
    width: 620,
  },
  6: {
    width: 620,
  },
  4: {
    width: 536,
  },
  3: {
    width: 296,
  }
};

export const liveEndTime = {
  1: 17,
  2: 17,
  3: 17,
  4: 17,
  5: 15,
}

export const conceptsSwiperBreakpoints = {
  768: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
  },
  1280: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 16,
  },
  1440: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
  1600: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  }
}
