import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import blogPostShape from 'shapes/blogPostShape';
import MuiCard from '@material-ui/core/Card';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MuiCardActionArea from '@material-ui/core/CardActionArea';
import RichTextField from 'components/RichTextField';
import DateFormatter from 'components/DateFormatter';
import { Link as RouterLink } from 'react-router-dom';
import { getOptimizedBlogImage } from 'utils/blogHelpers';
import { blogImageSizes } from 'utils/constants';
import Skeleton from '@material-ui/lab/Skeleton';
import LazyLoad from 'react-lazyload';

const Card = styled(MuiCard)`
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  height: 100%;
  max-width: ${prop('$width')}px;

  ${ifProp(
    '$noMax',
    css`
      max-width: none;
      min-width: 18rem;
    `
  )}

  position: relative;
  transition: transform 250ms, box-shadow 250ms;
  width: 100%;

  &:hover {
    cursor: pointer;
    height: fit-content;
    height: -moz-fit-content;
    z-index: 12;

    ${(props) => props.theme.breakpoints.up('md')} {
      background-color: ${theme('colors.white')};
      box-shadow: 0px 10px 20px #00000029;
      position: absolute;
      transform: scale(1.15);
    }
  }

  .MuiCardActionArea-root {
    &:hover {
      .MuiCardActionArea-focusHighlight {
        opacity: 0;
      }
    }
  }
`;

const CardActionArea = styled(MuiCardActionArea)`
  display: flex;
  flex-direction: column;
`;

const CardMediaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CardMedia = styled(MuiCardMedia)`
  display: flex;
  justify-content: center;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

const CardMediaOverlay = styled.div`
  bottom: 0;
  display: flex;
  content: '';
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${Card}:hover & {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const BookIcon = styled(MenuBookIcon)`
  align-self: center;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 4.5rem;
  visibility: hidden;
  z-index: 1;

  ${Card}:hover & {
    visibility: visible;
  }
`;

const CardWrapper = styled.div`
  ${switchProp('$size', {
    smaller: css`
      ${(props) => props.theme.breakpoints.up('md')} {
        min-height: 18.125rem;
      }
    `,
    small: css`
      ${(props) => props.theme.breakpoints.up('md')} {
        min-height: 20.875rem;
      }
    `,
    regular: css`
      ${(props) => props.theme.breakpoints.up('md')} {
        min-height: 24.125rem;
      }
    `,
  })}
`;

const CardContent = styled(MuiCardContent)`
  padding: 1rem 0 0;
  transition: transform 250ms, padding 250ms;
  width: 100%;

  ${Card}:hover & {
    ${(props) => props.theme.breakpoints.up('md')} {
      padding: 0.5rem 0 0;
      transform: scale(0.9);
    }
  }
`;

const Title = styled(Typography)`
  margin-bottom: 0.625rem;
`;

const Date = styled(Typography)`
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const Name = styled(Typography)`
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
`;

const CardBody = styled(Typography)`
  color: ${theme('colors.black')};
  opacity: 0;

  ${Card}:hover & {
    opacity: 1;

    p,
    .MuiBox-root {
      ${(props) => props.theme.breakpoints.up('md')} {
        font-size: 0.8125rem;
      }
    }
  }

  p,
  .MuiBox-root {
    &:first-child {
      font-size: 0.875rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:not(:first-child) {
      display: none;
    }
  }

  .MuiBox-root {
    margin-top: 0.625rem;
  }

  p {
    margin-top: 0;
  }
`;

BlogCard.propTypes = {
  blogPost: blogPostShape,
  cardSize: PropTypes.string,
  $noMax: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

function BlogCard({ blogPost, cardSize = 'regular', $noMax, lazyLoad = true }) {
  const cardRef = useRef();
  const [widthOffset, setWidthOffset] = useState(0);
  const { uid, title, url, postDate, metadata } = blogPost;

  const { excerpt, contributor, thumbnailImage, thumbnailAltText, categories } =
    metadata || {};

  useEffect(() => {
    function handleResize() {
      if (cardRef.current) {
        const cardWidth = cardRef.current.offsetWidth;

        setWidthOffset(cardWidth);
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [cardRef.current]);

  return (
    <CardWrapper ref={cardRef} $size={cardSize}>
      <Card $width={widthOffset} $noMax={$noMax}>
        <CardActionArea component={RouterLink} to={url}>
          {thumbnailImage && (
            <CardMediaWrapper>
              {lazyLoad ? (
                <LazyLoad
                  debounce={true}
                  offset={100}
                  // Currently, width and height is not being calculated on the first render, so set height to fixed value
                  height={180}
                  placeholder={
                    <Skeleton variant='rect' width='100%' height={180} />
                  }
                >
                  <CardMedia
                    image={getOptimizedBlogImage(
                      thumbnailImage,
                      cardSize,
                      blogImageSizes
                    )}
                    $size={cardSize}
                    title={thumbnailAltText}
                    className={'blog-card-media'}
                  />
                </LazyLoad>
              ) : (
                <CardMedia
                  image={getOptimizedBlogImage(
                    thumbnailImage,
                    cardSize,
                    blogImageSizes
                  )}
                  $size={cardSize}
                  title={thumbnailAltText}
                  className={'blog-card-media'}
                />
              )}
              <CardMediaOverlay>
                <BookIcon />
              </CardMediaOverlay>
            </CardMediaWrapper>
          )}
          <CardContent>
            <Title variant='h4' component='h3'>
              {title}
            </Title>
            <Name variant='body2' color='textSecondary'>
              {contributor?.title ? contributor?.title : ''}
            </Name>
            <Date variant='body2' color='textSecondary'>
              <DateFormatter date={postDate} />
            </Date>
            <CardBody variant='body2' color='textSecondary'>
              <RichTextField richText={excerpt} />
            </CardBody>
          </CardContent>
        </CardActionArea>
      </Card>
    </CardWrapper>
  );
}

export default BlogCard;
