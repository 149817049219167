import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import Typography from '@material-ui/core/Typography';
import { SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import RichTextField from 'components/RichTextField';
import BaseIcon from 'components/Icon';
import assetShape from 'shapes/assetShape';
import { dataLayerPush } from 'utils/dataLayer';
import { autoOptimizeImage } from 'utils/functions';

const CarouselCardContainer = styled.div`
  background-color: ${prop('theme.colors.white')};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:hover {
    cursor: grab;
  }
`;

const CarouselCardImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const StyledIcon = styled(BaseIcon)`
  transition: transform 0.3s ease;
  transform: none;
  transform-style: preserve-3d;

  &:hover {
    color: ${prop('theme.colors.ttRed')};
  }
`;

const CtaButtonText = styled(Link)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 1rem;
  font-weight: 700;
  color: ${prop('theme.colors.black')};
  margin-right: 0.5rem;
  text-decoration: none;
`;

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem;

  &:hover ${StyledIcon} {
    transform: translate3d(0.3rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    color: ${prop('theme.colors.ttRed')};
  }

  &:hover ${CtaButtonText} {
    color: ${prop('theme.colors.ttRed')};
  }
`;

const HeaderCopy = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
`;

const CardBodyContainer = styled.div`
  padding: 2rem 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .MuiBox-root {
    &:second-child {
      font-size: 2rem;
    }
    margin-top: .5rem;
  }

  p {
    margin-top: 0;
  }
`;

const Description = styled(RichTextField)`
  overflow: auto;
  font-size: 1rem;
  font-weight: 400;
  height: 10rem;
  overflow: hidden;
  mask-image: linear-gradient(black 50%, transparent 100%);

  ${({ theme }) => `
    ${theme.breakpoints.down('lg')} {
      height: 14rem;
    }
    ${theme.breakpoints.down('md')} {
      height: 13rem;
    }
    ${theme.breakpoints.down('sm')} {
      height: 12rem;
    }
  `}
`;

CarouselCard.propTypes = {
  image: assetShape,
  title: PropTypes.string,
  body: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  gtmEvent: PropTypes.string,
};

function CarouselCard({ image, title, body, url, buttonText, gtmEvent }) {
  const cardClick = (gtmEvent) => {
    dataLayerPush(gtmEvent);
  };
  const showImageUrl = image && autoOptimizeImage(image?.url, true, '1000');

  return (
    <SplideSlide>
      <CarouselCardContainer>
        <div>
          {image && (
            <CarouselCardImage loading='lazy' src={showImageUrl} />
          )}
          <CardBodyContainer>
            {title && (
              <HeaderCopy>{title}</HeaderCopy>
            )}
            {body && (
              <Description richText={body} tabIndex={'-1'} />
            )}
          </CardBodyContainer>
        </div>
        <CtaContainer>
          <CtaButtonText onClick={() => cardClick(gtmEvent)} to={url} variant='h4'>
            {buttonText}
          </CtaButtonText>
          <StyledIcon icon={'arrowRight'} />
        </CtaContainer>
      </CarouselCardContainer>
    </SplideSlide>
  );
}

export default CarouselCard;
