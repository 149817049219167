/** @module tt/utils/blockStyleUtils */

import 'types/typedefs';
import merge from 'lodash.merge';
import { convertNullValuesToUndefined } from 'utils/objectUtils';

export const name = 'blockStyleUtils';

/**
 * @param {module:tt/typedefs.BlockStyle[]} blockStyles - Array of block style objects to combine.
 * @returns {module:tt/typedefs.BlockStyle} - A new block style object created by merging the blockStyles together.
 */
export function combineBlockStyles(blockStyles = []) {
  let combinedBlockStyles = {};
  const filteredBlockStyles = blockStyles.filter(x => !!x);
  if (filteredBlockStyles.length > 0) {
    // Combine all block styles elements using a deep merge.
    // Note lodash.merge will skip any keys that are undefined, but if
    // a key is null or empty string it would override the prior value.
    // To prevent unintended overrides to null, we convert all null values to undefined before merge.
    combinedBlockStyles = merge({}, ...filteredBlockStyles.map(convertNullValuesToUndefined));
  }

  return combinedBlockStyles;
}

/**
 * Convert the new BlockStyle object into a flattened object structure that matches the old additionalStyles format.
 *
 * @param {module:tt/typedefs.BlockStyle} blockStyle
 * @returns {module:tt/typedefs.AdditionalStyles|{}}
 */
export function flattenBlockStyle(blockStyle) {
  if (!blockStyle) {
    return {};
  }

  return {
    applyBoxShadow: blockStyle?.borders?.applyBoxShadow || false,
    applyBorder: blockStyle?.borders?.applyBorder || false,
    flipOrientation: blockStyle?.alignmentAndOrientation?.flipOrientation || false,
    contentAlignment: blockStyle?.alignmentAndOrientation?.alignment,
    selectTextColor: blockStyle?.fontsAndColors?.textColor || 'Black',
    backgroundColor: blockStyle?.background?.backgroundColor,
    marginBottom: blockStyle?.spacing?.marginBottom,
    marginLeft: blockStyle?.spacing?.marginLeft,
    marginRight: blockStyle?.spacing?.marginRight,
    marginTop: blockStyle?.spacing?.marginTop,
    paddingBottom: blockStyle?.spacing?.paddingBottom,
    paddingLeft: blockStyle?.spacing?.paddingLeft,
    paddingRight: blockStyle?.spacing?.paddingRight,
    paddingTop: blockStyle?.spacing?.paddingTop,
    cssClassName: blockStyle?.customCss?.cssClassName,
  };
}
