import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

function cssUnitFromCS(value) {
  // If value is a string that is not a straight number, then we assume it includes
  // the unit, is a percentage, or is a special string like "auto".
  if (isNaN(value)) {
    return value;
  }

  // Otherwise we add rem the value.
  return `${value}rem`;
}

function hasValue(value) {
  return typeof value !== 'undefined' && value !== null;
}

const blockStylesCss = css`${({ $blockStyle, theme }) => css`
  font-size: 1rem;
  font-weight: 300;
  
  // Background
  ${ifProp('$blockStyle.background.backgroundColor', css`
    background-color: ${prop('$blockStyle.background.backgroundColor')};
  `)}

  ${ifProp('$blockStyle.background.backgroundImage.url', css`
    background-image: url("${prop('$blockStyle.background.backgroundImage.url')}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    ${theme.breakpoints.up('md')} {
      background-size: cover;
    }
  `)}
  
  // Borders
  ${ifProp('$blockStyle.borders.applyBorder', css`
    border: 0.0625rem solid ${theme.colors.borderGray};
  `)}

  ${ifProp('$blockStyle.borders.applyBoxShadow', css`
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  `)}

  // Spacing
  ${hasValue($blockStyle?.spacing?.marginTop) ? css`
    margin-top: ${cssUnitFromCS($blockStyle.spacing.marginTop)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.marginBottom) ? css`
    margin-bottom: ${cssUnitFromCS($blockStyle.spacing.marginBottom)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.marginLeft) ? css`
    margin-left: ${cssUnitFromCS($blockStyle.spacing.marginLeft)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.marginRight) ? css`
    margin-right: ${cssUnitFromCS($blockStyle.spacing.marginRight)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.paddingTop) ? css`
    padding-top: ${cssUnitFromCS($blockStyle.spacing.paddingTop)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.paddingBottom) ? css`
    padding-bottom: ${cssUnitFromCS($blockStyle.spacing.paddingBottom)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.paddingLeft) ? css`
    padding-left: ${cssUnitFromCS($blockStyle.spacing.paddingLeft)};
  ` : ''}

  ${hasValue($blockStyle?.spacing?.paddingRight) ? css`
    padding-right: ${cssUnitFromCS($blockStyle.spacing.paddingRight)};
  ` : ''}
  
  // Custom CSS Rules
  ${($blockStyle?.customCss?.customCssRules?.value || []).map(({ key, value }) => `${key}: ${value};`).join('\n')}
`}`;

export default blockStylesCss;
