import React, { useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { dataLayerPush } from 'utils/dataLayer';
import RichTextField from 'components/RichTextField';
import logoGridFullbleedBannerShape from 'shapes/components/logoGridFullbleedBannerShape';
import { autoOptimizeImage } from 'utils/functions';

const BannerContainer = styled.div`
  padding: 0;
  display: grid;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  grid-template-columns: 1fr .5fr;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
`;

const HeaderBackground = styled.img`
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-column-end: 3;
  }
`;

const HeaderContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  grid-row-gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4.5rem 3rem;
  display: flex;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-column-end: 3;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

const FeatureImage = styled.img`
  grid-area: 1 / 2 / 3 / 3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-row-start: 2;
    grid-column-start: 1;
  }
`;

const FooterContainer = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  background-color: ${theme('colors.darkCharcoal')};
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 2rem;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-row: 3 / 4;
    grid-column-end: 3;
  }
`;

const HeaderTextContainer = styled.div`
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
`;

const HeaderText = styled.h1`
  font-size: 6vw;
  line-height: .9;
  color: ${theme('colors.white')};
  margin: 0;

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 2.5rem;
    line-height: 1.1;
  }
`;

const SubsequentHeaderLine = styled.span`
  color: ${theme('colors.darkCharcoal')};
`;

const SubheaderText = styled.div`
  font-size: 1.5rem;
  color: ${theme('colors.white')};
  font-weight: 400;
  line-height: 1.4;

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 1.25rem;
  }
`;

const HeaderButton = styled.a`
  color: ${theme('colors.white')};
  padding: .5rem 1.5rem;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border: .15rem solid white;
  font-weight: 700;
  transition: all .3s cubic-bezier(.23, 1, .32, 1);
  text-decoration: none;
  font-family: ${props => props.theme.typography.fontFamilySecondary};

  :hover {
    color: ${theme('colors.ttRed')};
    background-color: ${theme('colors.white')};
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const FooterImage = styled.img`
  margin-top: 1em;
  width: 10rem;

  ${props => props.theme.breakpoints.up('lg')} {
    width: 11rem;
  }
`;

const DisclaimerText = styled(RichTextField)`
  margin-right: 2em;

  a,
  p {
    font-size: 0.75rem;
    color: ${theme('colors.white')};
    letter-spacing: normal;
    font-stretch: normal;
  }

  a {
    &:hover {
      color: ${theme('colors.white')};
    }
  }
`;

LogoGridFullbleedBanner.propTypes = {
  data: logoGridFullbleedBannerShape,
  className: PropTypes.string,
};

function LogoGridFullbleedBanner({ className, data, ...props }) {
  const blockData = useMemo(() => data.blockReference, data);
  const headerTextLines = useMemo(
    () => blockData.header.headerText.split(/<br *\/?>/).map((line) => line.replaceAll(/<[^>]*>/g, '')),
    [blockData]);
  const subheaderText = useMemo(
    () => blockData.header.subheader.replaceAll(/<[^>]*>/g, ''),
    [blockData]);
  const headerBackgroundImageUrl = autoOptimizeImage(blockData.header.background.url);
  const featureImageUrl = autoOptimizeImage(get(blockData, 'image.featureImage.url'));
  const footerImageUrl = autoOptimizeImage(get(blockData, 'footer.logo.url'));

  return (
    <BannerContainer {...props}>
    <HeaderBackground loading={'lazy'} src={headerBackgroundImageUrl} {...props} />
    <HeaderContainer {...props}>
      <HeaderTextContainer {...props}>
        <HeaderText {...props}>{headerTextLines.map((line, idx) => {
          if (idx > 0) {
            return (<SubsequentHeaderLine key={idx} {...props}><br/>{line}</SubsequentHeaderLine>)
          } else {
            return <span key={idx}>{line}</span>;
          }
        })}
        </HeaderText>
        <SubheaderText>{subheaderText}</SubheaderText>
      </HeaderTextContainer>
      <HeaderButton
        {...props}
        href={get(blockData, 'header.buttonCta.buttonLink.linkUrl.href', 'https://tastytrade.com/')}
        onClick={() => dataLayerPush('Homepage ad hero banner clicked')}
        target='_blank'
      >
        {blockData.header.buttonCta.buttonCtaName}
      </HeaderButton>
    </HeaderContainer>
    <FeatureImage {...props} loading={'lazy'} src={featureImageUrl} />
    <FooterContainer {...props}>
      <FooterImage {...props} loading={'lazy'} src={footerImageUrl} />
      <DisclaimerText richText={get(blockData, 'footer.disclaimer')} />
    </FooterContainer>
  </BannerContainer>
 );
}

export default LogoGridFullbleedBanner
