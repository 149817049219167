
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AdSlotConfigFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AdSlotConfig"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adUnit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sizes"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"width"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"height"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"displayByDefault"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayTargeting"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayForSpecificShows"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentTypeUid"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":203}};
    doc.loc.source = {"body":"fragment AdSlotConfigFields on AdSlotConfig {\n  adUnit\n  sizes {\n    width\n    height\n  }\n  displayByDefault\n  displayTargeting {\n    displayForSpecificShows {\n      uid\n      contentTypeUid\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
