import styled from 'styled-components';
import { theme } from 'styled-tools';

export default styled.div`
  display: none;
  position: absolute;
  min-width: 100%;
  background-color: ${theme('colors.mutedGray')};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 1099;
`;
