import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import BaseContainer from '@material-ui/core/Container';

const Container = styled(BaseContainer)`
  height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  position: sticky;
  top: 216px;

  /* Setting to allow the letter nav to scroll on mobile landscape */
  @media screen and (min-width: 320px) and (max-width: 959px) and (orientation: landscape) {
    overflow: auto;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    top: 126px;
  }

  ${props => props.theme.breakpoints.up('md')} {
    height: inherit;
    padding-left: 3rem;
    padding-right: 3rem;
    position: initial;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
`;

const Header = styled.div`
  background: ${theme('colors.white')};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;

  ${props => props.theme.breakpoints.up('md')} {
    box-shadow: none;
    position: sticky;
    padding: 0.75rem 0 1.25rem;
    right: 0;
    top: 6rem;
    width: 100%;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 1.25rem;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const AlphaContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
  padding-bottom: 11rem;

  /* Setting to allow the letter nav to scroll on mobile landscape */
  @media screen and (min-width: 320px) and (max-width: 959px) and (orientation: landscape) {
    height: auto;
  }

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    padding-bottom: 0;
    width: 100%;
  }
`;

// letters w/o definitions are considered 'inactive' and thus do not have a
// wrapper section with an anchor tag; setting inactive letters as a div
const Letter = styled.a.attrs(props => ({
  as: (props.status === 'inactive' && 'div')
}))`
  color: ${theme('colors.black')};
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms cubic-bezier(.23, 1, .32, 1);

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 1rem;
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 1.375rem;
    font-weight: 300;
  }

  ${ifProp({ status: 'inactive' }, css`
    cursor: pointer;
    opacity: 0.3;
  `)}

  &:hover {
    color: ${theme('colors.lightRed')};
    transform: scale(1.25);

    ${props => props.theme.breakpoints.up('md')} {
      transform: scale(1.5);
    }
  }

  &:nth-child(3n + 2),
  &:nth-child(3n + 3) {
    span {
      ${props => props.theme.breakpoints.down('sm')} {
        display: none;
      }
    }

    &:before {
      ${props => props.theme.breakpoints.down('sm')} {
        content: "•";
      }
    }
  }
`;

const propTypes = {
  alphaLetters: PropTypes.arrayOf(PropTypes.object),
  glossaryHeader: PropTypes.string,
  textBody: PropTypes.string,
};

function LetterNav({ alphaLetters, glossaryHeader, textBody }) {
  return (
    <Header>
      <Container className='glossary-nav' maxWidth='xl'>
        {glossaryHeader &&
          <Title variant='h1'>{glossaryHeader}</Title>
        }
        <AlphaContainer>
          {alphaLetters && alphaLetters.map((alphaLetter, index) => {
            const { letter, status } = alphaLetter;

            return (
              <Letter
                href={status === 'active' ? `#glossary-${letter}` : '#'}
                key={`letter-${index}`}
                status={status}
              >
                <span>{letter}</span>
              </Letter>
            )
          })}
        </AlphaContainer>
      </Container>
    </Header>
  );
}

LetterNav.propTypes = propTypes;

export default LetterNav;
