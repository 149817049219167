import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

const EpisodeCardWrapper = styled.div`
  position: relative;

  ${switchProp('$size', {
    small: css`
      ${props => props.theme.breakpoints.up('md')} {
        min-height: 20.875rem;
      }
    `,
    regular: css`
      ${props => props.theme.breakpoints.up('md')} {
        min-height: 16.125rem;
      }
    `
  })}
  
  .MuiListItem-secondaryAction {
    padding-right: 30px;
  }
  
  li {
    list-style: none;
  }
`;

export default EpisodeCardWrapper;
