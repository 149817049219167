import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash.get';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BaseAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import BaseAccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PageLink from 'components/PageLink';
import RichTextField from 'components/RichTextField';
import Icon from 'components/Icon';
import menuShape from 'shapes/menuShape';

export const Accordion = styled(BaseAccordion)`
  background-color: ${theme('colors.white')};
  border-bottom: none;
  color: ${theme('colors.black')};

  &.MuiAccordion-rounded {
    border-radius: 0;
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    min-height: 3rem;
    padding: 0rem;
    border-bottom: 1px solid ${theme('colors.borderGray')};
  }

  .MuiAccordionSummary-expandIcon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-weight: 400;

    svg {
      fill: ${theme('colors.black')};
      stroke: ${theme('colors.black')};
      stroke-width: 2px;
    }

    &:hover {
      background-color: ${theme('colors.iconHoverBackgroundGray')};

      svg {
        fill: ${theme('colors.ttRed')};
        stroke: ${theme('colors.ttRed')};
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 1rem 0;

    h4 {
      font-size: 0.9rem;
    }

    &.Mui-expanded {
      margin: 0.75rem 0;
      min-height: initial;
    }
  }
`;

const FooterHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const FooterItem = styled.p`
  margin-bottom: 1rem;
  margin-top: 0;
`;

const FooterLink = styled(Link)`
  color: ${theme('colors.white')};
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    font-weight: 500;
    text-decoration: underline;
  }

  span {
    br {
      display: none;
    }
  }
`;

const AccordionDetails = styled(BaseAccordionDetails)`
  background-color: ${theme('colors.black')};
  border-top: 1px solid ${theme('colors.borderGray')};
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 1rem;
`;

MobileSection.propTypes = {
  menu: menuShape,
  menuHeader: PropTypes.string,
}

function MobileSection({ menu, menuHeader }) {
  const theme = useTheme();

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        expandIcon={
          <Icon
            color={theme.palette.common.black}
            icon='chevronDown'
            size={0.6}
          />
        }
      >
        {menuHeader && <FooterHeader variant='h4'>{menuHeader}</FooterHeader>}
      </AccordionSummary>
      <AccordionDetails>
        {menu.menuItems && menu.menuItems.map(({ link, linkText, allowHtmlInLinkText }, key) => (
          <FooterItem key={`child_menu_item_${key}`}>
            <PageLink component={FooterLink} link={link}>
              {allowHtmlInLinkText
                ? <RichTextField richText={linkText} component='span' $disableMargin />
                : linkText
              }
            </PageLink>
          </FooterItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(MobileSection);
