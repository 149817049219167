import React from 'react';
import PropTypes from 'prop-types';
import DateFormatter from 'components/DateFormatter';
import { switchProp, ifProp, theme, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import blogPostShape from 'shapes/blogPostShape';
import { autoOptimizeImage } from 'utils/functions';

const Divider = styled.div`
  width: 1.5rem;
  height: 2px;
  opacity: 0;
  background-color: #e21f26;
  transition: opacity 0.3s ease-out;

  @media (hover: none) {
    display: none;
  }
`;

const StyledBlogPost = styled(Link)`
  display: flex;
  grid-column-gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  color: black;
  text-decoration: none;
  padding-top: .25rem;

  ${ifProp('$isJustIn', css`
    aspect-ratio: 208 / 175
  `)};

   p:first-of-type {
    text-transform: uppercase;
    font-family: ${theme('typography.fontFamilySecondary')};
  }

  ${switchProp('hoverAnimation', css`
    font-size: 0.8rem;
    margin-top: 0.5rem;
  `)};
  
  @media (max-width: 915px) {
    p:nth-of-type(2) {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      ${Divider} {
        opacity: 1;
      }
    }
  }
`;

const StyledBlogImage = styled.img`
  align-self: flex-start;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const BlogDetails = styled(Typography)`
  font-family: ${theme('typography.fontFamily')};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

GridCard.propTypes = {
  blogPost: blogPostShape,
  useSeoData: PropTypes.bool,
  isJustInSection: PropTypes.bool,
};

function GridCard({ blogPost, useSeoData, isJustInSection }) {
  const { seo, title, postDate, metadata } = blogPost
  const thumbnailImageUrl = metadata?.thumbnailImage &&
    autoOptimizeImage(metadata?.thumbnailImage?.url, true, '1000');

  return (
    <StyledBlogPost to={blogPost?.url} $isJustIn={isJustInSection}>
      {isJustInSection && blogPost?.metadata?.thumbnailImage && (
        <StyledBlogImage
          src={thumbnailImageUrl}
          alt={metadata?.thumbnailAltText || 'Thumbnail'}
        />
      )}
      <div>
        <BlogDetails variant={'h4'}>{useSeoData && seo?.seoTitle ? seo?.seoTitle : title}</BlogDetails>
        <BlogDetails variant={'body2'}>
          <DateFormatter
            date={postDate}
            format='MMM D, YYYY'
          />
        </BlogDetails>
        <Divider/>
      </div>
    </StyledBlogPost>
  );
};

export default GridCard;
