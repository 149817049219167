import React from 'react'
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import DailyReplaysLoading from './Loading';
import DailyReplaysBody from './Body';
import DashboardAlert from '../DashboardAlert';
import DashboardPageHeader from '../DashboardPageHeader';
import { useDashboardContext } from 'context/DashboardContext';
import getDailyReplayQuery from './dailyReplay.graphql';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { makeDailyReplayUrl } from 'utils/linkUtils';
import DashboardButton from 'components/DashboardButton';

DailyReplays.propTypes = {
  edit: PropTypes.bool,
};

function DailyReplays({ edit }) {
  const { state: dashboardState } = useDashboardContext();
  const { currentDailyReplay } = dashboardState;
  const dailyReplayName = currentDailyReplay && dayjs(currentDailyReplay.date).format('dddd, MMM D, YYYY');

  const { error, data, loading } = useQuery(getDailyReplayQuery, {
    variables: {
      uid: currentDailyReplay && currentDailyReplay.uid,
    },
    skip: !currentDailyReplay,
    fetchPolicy: 'no-cache',
  });

  const scheduleItems = get(data, 'dailyReplay.scheduleItems', []);
  const reverseSchedule = Array.from(scheduleItems).reverse();
  const hasEpisodes = !!scheduleItems.find(v => !!v.episode);

  return (
    <>
      <DashboardPageHeader
        mb={0}
        actions={currentDailyReplay && hasEpisodes && (
          <DashboardButton
            component={RouterLink}
            variant='outlined'
            color='primary'
            to={makeDailyReplayUrl(dayjs(currentDailyReplay.date).format('dddd'))}
          >
            Play All
          </DashboardButton>
        )}
      >
        {dailyReplayName}
      </DashboardPageHeader>
      {(loading || !currentDailyReplay) && <DailyReplaysLoading />}
      {error && (
        <DashboardAlert
          message='Failed to load the Daily Replay, please reload to try again.'
          severity='error'
        />
      )}
      {!loading && !error && data && (
        <DailyReplaysBody scheduleItems={scheduleItems} />
      )}
    </>
  );
}

export default React.memo(DailyReplays);
