import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FeaturedComponent from '../FeaturedComponent';
import getEpisodesQuery from '../../../../../../graphql/queries/getEpisodes.graphql';

MostRecentEpisode.propTypes = {
  megaMenuClick: PropTypes.func.isRequired,
  tourHighlighted: PropTypes.bool,
  traderResourcesTourMenuClick: PropTypes.bool,
  subItem: PropTypes.bool,
  traderResourcesTourAction: PropTypes.bool,
  menuOpen: PropTypes.bool,
};

function MostRecentEpisode ({
  megaMenuClick,
  subItem,
  tourHighlighted,
  traderResourcesTourMenuClick,
  traderResourcesTourAction,
  menuOpen
}) {
  const { loading, data } = useQuery(getEpisodesQuery, {
    variables: {
      pagination: {
        limit: 1,
      },
      sort: {
        sortBy: 'published_at',
        sortDirection: 'descending',
      },
    },
  });

  if (loading) {
    return null;
  }

  const items = get(data, 'episodes.items', []);
  const featuredItem = items[0];

  if (!featuredItem) {
    return null;
  }

  const title = get(featuredItem, 'displayTitle');
  const featuredDescription = featuredItem?.shortDescription
    ? featuredItem.shortDescription
    : featuredItem.show.shortDescription;

  return (
    <FeaturedComponent
      exploreMore={`Explore ${title}`}
      featuredImage={featuredItem?.thumbnailImage?.url}
      featuredItem={featuredItem}
      featuredTitle={title}
      featuredSnippet={featuredDescription}
      megaMenuClick={megaMenuClick}
      tourHighlighted={tourHighlighted}
      traderResourcesTourMenuClick={traderResourcesTourMenuClick}
      traderResourcesTourAction={traderResourcesTourAction}
      subItem={subItem}
      menuOpen={menuOpen}
    />
  )
};

export default MostRecentEpisode;
