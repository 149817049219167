
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MenuItemFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MenuItem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"linkIcon"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"linkText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isMegaMenu"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"visibility"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deviceVisibility"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"specialBehavior"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cssClassName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"allowHtmlInLinkText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"forceColumnBreak"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"snippet"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"imageAsset"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ImageAssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"link"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LinkFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":334}};
    doc.loc.source = {"body":"#import \"./LinkFields.graphql\"\n#import \"./ImageAssetFields.graphql\"\n\nfragment MenuItemFields on MenuItem {\n  linkIcon\n  linkText\n  isMegaMenu\n  visibility\n  deviceVisibility\n  specialBehavior\n  cssClassName\n  allowHtmlInLinkText\n  forceColumnBreak\n  snippet\n  imageAsset {\n    ...ImageAssetFields\n  }\n  link {\n    ...LinkFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./LinkFields.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./ImageAssetFields.graphql").definitions));


      module.exports = doc;
    
