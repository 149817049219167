import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'context/AuthContext';
import styled from 'styled-components';
import BaseAvatar from '@material-ui/core/Avatar';
import { theme } from 'styled-tools';

const Avatar = styled(BaseAvatar)`
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.625rem;
  background-color: ${theme('colors.mutedGray')};
`;

UserAvatar.propTypes = {
  component: PropTypes.elementType,
  id: PropTypes.string,
};

function UserAvatar({ component: Component = Avatar, id }) {
  const { user } = useAuth();

  const firstName = (user && user.given_name) || 'Account';
  const avatar = user && user.picture;

  return avatar ? (
    <Component alt={firstName} id={id} src={avatar} />
  ) : (
    <Component id={id}>{firstName.charAt(0)}</Component>
  );
}

export default React.memo(UserAvatar);
